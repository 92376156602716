export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
    AWSDate: { input: any; output: any }
    AWSDateTime: { input: any; output: any }
    AWSEmail: { input: any; output: any }
    AWSJSON: { input: any; output: any }
    AWSTime: { input: any; output: any }
}

export type AwsModelMutationMap = {
    create?: InputMaybe<Scalars['Boolean']['input']>
    delete?: InputMaybe<Scalars['Boolean']['input']>
    update?: InputMaybe<Scalars['Boolean']['input']>
}

export type AwsModelQueryMap = {
    get?: InputMaybe<Scalars['Boolean']['input']>
    list?: InputMaybe<Scalars['Boolean']['input']>
}

export type AwsModelSubscriptionMap = {
    onCreate?: InputMaybe<Scalars['Boolean']['input']>
    onDelete?: InputMaybe<Scalars['Boolean']['input']>
    onUpdate?: InputMaybe<Scalars['Boolean']['input']>
}

export type AwsModelTimestampMap = {
    createdAt?: InputMaybe<Scalars['Boolean']['input']>
    updatedAt?: InputMaybe<Scalars['Boolean']['input']>
}

export enum ActionType {
    CREATE = 'CREATE',
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    VIEW = 'VIEW',
}

export type Address = {
    __typename?: 'Address'
    Company?: Maybe<Company>
    address?: Maybe<Scalars['String']['output']>
    addressCompanyId?: Maybe<Scalars['ID']['output']>
    city?: Maybe<Scalars['String']['output']>
    companyID: Scalars['ID']['output']
    country?: Maybe<Scalars['String']['output']>
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    lat?: Maybe<Scalars['Float']['output']>
    long?: Maybe<Scalars['String']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
    zipcode?: Maybe<Scalars['String']['output']>
}

export enum AggregationByReservableType {
    ALL = 'ALL',
    PARKING = 'PARKING',
    ROOM = 'ROOM',
    SEAT = 'SEAT',
    ZONE = 'ZONE',
}

export enum AvailabilityItemPreviewElement {
    EMPTY = 'EMPTY',
    FIRST_HALF = 'FIRST_HALF',
    FULL = 'FULL',
    SECOND_HALF = 'SECOND_HALF',
}

export enum AvailabilityType {
    BOOKED_FOR_PERSON = 'BOOKED_FOR_PERSON',
    BOOKED_FOR_TEAM = 'BOOKED_FOR_TEAM',
    FREE_FOR_ALL = 'FREE_FOR_ALL',
    UNAVALIABLE = 'UNAVALIABLE',
}

export type BambooHrIntegration = {
    __typename?: 'BambooHRIntegration'
    companyDomain: Scalars['String']['output']
    companyId: Scalars['ID']['output']
    hasInvitationEnabled: Scalars['Boolean']['output']
    lastSyncAt?: Maybe<Scalars['AWSDateTime']['output']>
    syncStatus: SyncStatus
}

export type BambooHrIntegrationInput = {
    apiKey: Scalars['String']['input']
    companyDomain: Scalars['String']['input']
    companyId: Scalars['ID']['input']
    hasInvitationEnabled: Scalars['Boolean']['input']
}

export type BillingPayment = {
    __typename?: 'BillingPayment'
    amount: Scalars['Float']['output']
    currency: Scalars['String']['output']
    id: Scalars['String']['output']
    invoiceUrl: Scalars['String']['output']
    payoutDate: Scalars['String']['output']
    subscriptionId: Scalars['String']['output']
}

export type BillingPlan = {
    __typename?: 'BillingPlan'
    billingPeriod: Scalars['String']['output']
    billingType: Scalars['String']['output']
    id: Scalars['String']['output']
    initialPrice: Array<Money>
    name: Scalars['String']['output']
    recurringPrice: Array<Money>
}

export type BillingPlans = {
    __typename?: 'BillingPlans'
    plans: Array<BillingPlan>
}

export type BillingSubscription = {
    companyId: Scalars['String']['output']
    expiresAt: Scalars['AWSDateTime']['output']
    status: BillingSubscriptionStatus
    subscriptionPlanId: Scalars['String']['output']
}

export type BillingSubscriptionInput = {
    companyId: Scalars['String']['input']
    subscriptionId: Scalars['String']['input']
}

export enum BillingSubscriptionStatus {
    ACTIVE = 'ACTIVE',
    EXTERNAL_CANCELLED = 'EXTERNAL_CANCELLED',
    PAST_DUE = 'PAST_DUE',
    PAUSED = 'PAUSED',
    TERMINATED = 'TERMINATED',
    TRIAL = 'TRIAL',
    TRIAL_EXPIRED = 'TRIAL_EXPIRED',
    UNKNOWN = 'UNKNOWN',
}

export type BillingSubscriptions = {
    __typename?: 'BillingSubscriptions'
    items?: Maybe<Array<Maybe<BillingSubscription>>>
}

export type BillingTrialInput = {
    companyId: Scalars['String']['input']
}

export type Booking = {
    __typename?: 'Booking'
    BookingRequest?: Maybe<BookingRequest>
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    Space?: Maybe<Space>
    TeamEventRequest?: Maybe<TeamEventRequest>
    bookingBookingRequestId?: Maybe<Scalars['ID']['output']>
    bookingCompanyId?: Maybe<Scalars['ID']['output']>
    bookingEmployeeId?: Maybe<Scalars['ID']['output']>
    bookingRequestID?: Maybe<Scalars['ID']['output']>
    bookingReservableId?: Maybe<Scalars['ID']['output']>
    bookingSpaceId?: Maybe<Scalars['ID']['output']>
    bookingSubType?: Maybe<BookingSubType>
    bookingTeamEventRequestId?: Maybe<Scalars['ID']['output']>
    bookingType?: Maybe<BookingType>
    checkInStatus?: Maybe<BookingCheckInStatus>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date?: Maybe<Scalars['String']['output']>
    dayOfWeek?: Maybe<Scalars['String']['output']>
    description?: Maybe<Scalars['String']['output']>
    employeeID: Scalars['ID']['output']
    endBookingTime?: Maybe<Scalars['AWSTime']['output']>
    endTime?: Maybe<Scalars['AWSDateTime']['output']>
    id: Scalars['ID']['output']
    isBlocked?: Maybe<Scalars['Boolean']['output']>
    isFullDay?: Maybe<Scalars['Boolean']['output']>
    isTeamEvent?: Maybe<Scalars['Boolean']['output']>
    isoWeekNumber?: Maybe<Scalars['Int']['output']>
    owner?: Maybe<Scalars['ID']['output']>
    reservable?: Maybe<Reservable>
    reservableID?: Maybe<Scalars['ID']['output']>
    reservables?: Maybe<ModelBookingReservableConnection>
    slackCheckInMessageId?: Maybe<Scalars['String']['output']>
    source?: Maybe<BookingSource>
    spaceID?: Maybe<Scalars['ID']['output']>
    startBookingTime?: Maybe<Scalars['AWSTime']['output']>
    startTime?: Maybe<Scalars['AWSDateTime']['output']>
    status?: Maybe<BookingStatus>
    teamEventRequestID?: Maybe<Scalars['ID']['output']>
    title?: Maybe<Scalars['String']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber?: Maybe<Scalars['Int']['output']>
    year?: Maybe<Scalars['Int']['output']>
}

export type BookingReservablesArgs = {
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type BookingCancellation = {
    __typename?: 'BookingCancellation'
    Booking?: Maybe<Booking>
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    bookingCancellationBookingId?: Maybe<Scalars['ID']['output']>
    bookingCancellationCompanyId?: Maybe<Scalars['ID']['output']>
    bookingCancellationEmployeeId?: Maybe<Scalars['ID']['output']>
    bookingID: Scalars['ID']['output']
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    employeeID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    owner?: Maybe<Scalars['ID']['output']>
    removeRepeatable: Scalars['Boolean']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export enum BookingCheckInStatus {
    CONFIRMED = 'CONFIRMED',
    REQUESTED = 'REQUESTED',
}

export type BookingEquipment = {
    __typename?: 'BookingEquipment'
    CalendarEvent?: Maybe<CalendarEvents>
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    Equipment?: Maybe<Equipment>
    Office?: Maybe<Office>
    calendarEventID?: Maybe<Scalars['ID']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date?: Maybe<Scalars['String']['output']>
    employeeID?: Maybe<Scalars['ID']['output']>
    endTime: Scalars['AWSDateTime']['output']
    equipmentID?: Maybe<Scalars['ID']['output']>
    id: Scalars['ID']['output']
    isAllDay?: Maybe<Scalars['Boolean']['output']>
    isPrivate?: Maybe<Scalars['Boolean']['output']>
    isoWeekNumber?: Maybe<Scalars['Int']['output']>
    officeID?: Maybe<Scalars['ID']['output']>
    owner?: Maybe<Scalars['ID']['output']>
    startTime: Scalars['AWSDateTime']['output']
    status?: Maybe<BookingEquipmentStatus>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    weekNumber?: Maybe<Scalars['Int']['output']>
    year?: Maybe<Scalars['Int']['output']>
}

export enum BookingEquipmentStatus {
    ERROR = 'ERROR',
    PROCESSED = 'PROCESSED',
    PROCESSING = 'PROCESSING',
}

export type BookingRequest = {
    __typename?: 'BookingRequest'
    Bookings?: Maybe<ModelBookingConnection>
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    ParkingSlot?: Maybe<Reservable>
    PreviousBookingRequest?: Maybe<BookingRequest>
    Space?: Maybe<Space>
    addParkingSlot?: Maybe<Scalars['Boolean']['output']>
    bookingRequestCompanyId?: Maybe<Scalars['ID']['output']>
    bookingRequestEmployeeId?: Maybe<Scalars['ID']['output']>
    bookingRequestPreviousBookingRequestId?: Maybe<Scalars['ID']['output']>
    bookingRequestReservableId?: Maybe<Scalars['ID']['output']>
    bookingRequestSpaceId?: Maybe<Scalars['ID']['output']>
    bookingSubType?: Maybe<BookingSubType>
    bookingType?: Maybe<BookingType>
    checkInStatus?: Maybe<BookingCheckInStatus>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    dates?: Maybe<Array<Scalars['AWSDate']['output']>>
    daysOfWeek?: Maybe<Array<Scalars['String']['output']>>
    effectiveFrom: Scalars['AWSDate']['output']
    effectiveTo?: Maybe<Scalars['AWSDate']['output']>
    employeeID: Scalars['ID']['output']
    endTime?: Maybe<Scalars['AWSTime']['output']>
    id: Scalars['ID']['output']
    isFullDay?: Maybe<Scalars['Boolean']['output']>
    isTeamEvent?: Maybe<Scalars['Boolean']['output']>
    owner?: Maybe<Scalars['ID']['output']>
    parkingSlotID?: Maybe<Scalars['ID']['output']>
    previousBookingRequestID?: Maybe<Scalars['ID']['output']>
    repeatType?: Maybe<RepeatType>
    reservable?: Maybe<Reservable>
    reservableID?: Maybe<Scalars['ID']['output']>
    source?: Maybe<BookingSource>
    spaceID?: Maybe<Scalars['ID']['output']>
    startTime?: Maybe<Scalars['AWSTime']['output']>
    status?: Maybe<BookingRequestStatus>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type BookingRequestBookingsArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export enum BookingRequestStatus {
    CREATED = 'CREATED',
    ERROR = 'ERROR',
    SCHEDULED = 'SCHEDULED',
}

export type BookingReservable = {
    __typename?: 'BookingReservable'
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    booking?: Maybe<Booking>
    bookingID: Scalars['ID']['output']
    bookingReservableCompanyId?: Maybe<Scalars['ID']['output']>
    checkInStatus?: Maybe<BookingCheckInStatus>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date?: Maybe<Scalars['AWSDate']['output']>
    dayOfWeek?: Maybe<Scalars['String']['output']>
    employeeID?: Maybe<Scalars['ID']['output']>
    endBookingTime?: Maybe<Scalars['AWSTime']['output']>
    endTime?: Maybe<Scalars['AWSDateTime']['output']>
    id: Scalars['ID']['output']
    owner?: Maybe<Scalars['String']['output']>
    reservable?: Maybe<Reservable>
    reservableID: Scalars['ID']['output']
    startBookingTime?: Maybe<Scalars['AWSTime']['output']>
    startTime?: Maybe<Scalars['AWSDateTime']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export enum BookingSource {
    GOOGLE = 'GOOGLE',
    MS = 'MS',
    PERSONIO = 'PERSONIO',
    SLACK = 'SLACK',
}

export enum BookingStatus {
    APPROVED = 'APPROVED',
    CANCELED = 'CANCELED',
    PENDING = 'PENDING',
}

export enum BookingSubType {
    BUSINESS_TRIP = 'BUSINESS_TRIP',
    SICK_LEAVE = 'SICK_LEAVE',
    VACATION = 'VACATION',
}

export enum BookingType {
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    OFFICE = 'OFFICE',
    REMOTE = 'REMOTE',
}

export enum CalendarEventCheckInStatus {
    CONFIRMED = 'CONFIRMED',
    REQUESTED = 'REQUESTED',
}

export type CalendarEventEmployee = {
    __typename?: 'CalendarEventEmployee'
    CalendarEvent?: Maybe<CalendarEvents>
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    calendarEventID: Scalars['ID']['output']
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date?: Maybe<Scalars['String']['output']>
    employeeFullName?: Maybe<Scalars['String']['output']>
    employeeID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    isOwner?: Maybe<Scalars['Boolean']['output']>
    isoWeekNumber?: Maybe<Scalars['Int']['output']>
    owner?: Maybe<Scalars['ID']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber?: Maybe<Scalars['Int']['output']>
    year?: Maybe<Scalars['Int']['output']>
}

export type CalendarEventRequest = {
    __typename?: 'CalendarEventRequest'
    CalendarEvents?: Maybe<ModelCalendarEventsConnection>
    Catering?: Maybe<Catering>
    Company?: Maybe<Company>
    Employees?: Maybe<ModelCalendarEventEmployeeConnection>
    OrganiserEmployee?: Maybe<Employee>
    PreviousCalendarEventRequest?: Maybe<CalendarEventRequest>
    Reservable?: Maybe<Reservable>
    Space?: Maybe<Space>
    calendarEventRequestCompanyId?: Maybe<Scalars['ID']['output']>
    calendarEventRequestPreviousCalendarEventRequestId?: Maybe<Scalars['ID']['output']>
    calendarEventRequestReservableId?: Maybe<Scalars['ID']['output']>
    calendarEventRequestSpaceId?: Maybe<Scalars['ID']['output']>
    cateringID?: Maybe<Scalars['ID']['output']>
    checkInStatus?: Maybe<CalendarEventCheckInStatus>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dates?: Maybe<Array<Scalars['AWSDate']['output']>>
    daysOfWeek?: Maybe<Array<Scalars['String']['output']>>
    description?: Maybe<Scalars['String']['output']>
    effectiveFrom: Scalars['AWSDate']['output']
    effectiveTo?: Maybe<Scalars['AWSDate']['output']>
    employeesIDs: Array<Scalars['ID']['output']>
    endTime?: Maybe<Scalars['AWSTime']['output']>
    equipmentItems?: Maybe<Array<EquipmentItemModel>>
    id: Scalars['ID']['output']
    isAllDay?: Maybe<Scalars['Boolean']['output']>
    isOnlineMeeting?: Maybe<Scalars['Boolean']['output']>
    isPrivate?: Maybe<Scalars['Boolean']['output']>
    menuItems?: Maybe<Array<MenuItemModel>>
    onlineMeetingUrl?: Maybe<Scalars['String']['output']>
    organiserEmployeeID: Scalars['ID']['output']
    owner?: Maybe<Scalars['ID']['output']>
    previousCalendarEventRequestID?: Maybe<Scalars['ID']['output']>
    repeatType?: Maybe<RepeatType>
    reservableID?: Maybe<Scalars['ID']['output']>
    source?: Maybe<CalendarEventSource>
    spaceID?: Maybe<Scalars['ID']['output']>
    startTime?: Maybe<Scalars['AWSTime']['output']>
    status?: Maybe<CalendarEventRequestStatus>
    title?: Maybe<Scalars['String']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    useRelatedReservable?: Maybe<Scalars['Boolean']['output']>
}

export type CalendarEventRequestCalendarEventsArgs = {
    filter?: InputMaybe<ModelCalendarEventsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type CalendarEventRequestEmployeesArgs = {
    employeeFullName?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export enum CalendarEventRequestStatus {
    CREATED = 'CREATED',
    ERROR = 'ERROR',
    SCHEDULED = 'SCHEDULED',
}

export enum CalendarEventSource {
    GOOGLE = 'GOOGLE',
    MSTEAMS = 'MSTEAMS',
}

export enum CalendarEventStatus {
    ERROR = 'ERROR',
    PROCESSED = 'PROCESSED',
    PROCESSING = 'PROCESSING',
}

export type CalendarEvents = {
    __typename?: 'CalendarEvents'
    CalendarEventRequest?: Maybe<CalendarEventRequest>
    Catering?: Maybe<Catering>
    Company?: Maybe<Company>
    Employees?: Maybe<ModelCalendarEventEmployeeConnection>
    Office?: Maybe<Office>
    OrganiserEmployee?: Maybe<Employee>
    Reservable?: Maybe<Reservable>
    Space?: Maybe<Space>
    calendarEventRequestID?: Maybe<Scalars['ID']['output']>
    canCurrentUserEdit?: Maybe<Scalars['Boolean']['output']>
    cateringID?: Maybe<Scalars['ID']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date?: Maybe<Scalars['String']['output']>
    dayOfMonth?: Maybe<Scalars['Int']['output']>
    daysOfWeek?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    description?: Maybe<Scalars['String']['output']>
    employeesIDs: Array<Scalars['ID']['output']>
    endTime: Scalars['AWSDateTime']['output']
    equipmentItems?: Maybe<Array<EquipmentItemModel>>
    firstDayOfWeek?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    interval?: Maybe<Scalars['Int']['output']>
    isAllDay?: Maybe<Scalars['Boolean']['output']>
    isOnlineMeeting?: Maybe<Scalars['Boolean']['output']>
    isPrivate?: Maybe<Scalars['Boolean']['output']>
    menuItems?: Maybe<Array<MenuItemModel>>
    month?: Maybe<Scalars['Int']['output']>
    numberOfOccurrences?: Maybe<Scalars['Int']['output']>
    officeID?: Maybe<Scalars['ID']['output']>
    onlineMeetingUrl?: Maybe<Scalars['String']['output']>
    organiserEmployeeID?: Maybe<Scalars['ID']['output']>
    owner?: Maybe<Scalars['ID']['output']>
    parentEventExternalId?: Maybe<Scalars['String']['output']>
    patternType?: Maybe<RecurrencePatternType>
    rangeEndDate?: Maybe<Scalars['AWSDate']['output']>
    rangeStartDate?: Maybe<Scalars['AWSDate']['output']>
    rangeType?: Maybe<RecurrenceRangeType>
    recurrenceTimeZone?: Maybe<Scalars['String']['output']>
    reservableID?: Maybe<Scalars['ID']['output']>
    source?: Maybe<CalendarEventSource>
    spaceID?: Maybe<Scalars['ID']['output']>
    startTime: Scalars['AWSDateTime']['output']
    status?: Maybe<CalendarEventStatus>
    title?: Maybe<Scalars['String']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    useRelatedReservable?: Maybe<Scalars['Boolean']['output']>
    weekIndex?: Maybe<Scalars['String']['output']>
}

export type CalendarEventsEmployeesArgs = {
    employeeFullName?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CalendarIntegration = {
    __typename?: 'CalendarIntegration'
    authType?: Maybe<Scalars['String']['output']>
    companyId: Scalars['String']['output']
    enableWorkingLocation?: Maybe<Scalars['Boolean']['output']>
    serviceEmail?: Maybe<Scalars['String']['output']>
    source: CalendarIntegrationSource
}

export type CalendarIntegrationSource = {
    __typename?: 'CalendarIntegrationSource'
    source: CalendarType
}

export enum CalendarType {
    GOOGLE = 'GOOGLE',
    MICROSOFT = 'MICROSOFT',
}

export type Catering = {
    __typename?: 'Catering'
    Company: Company
    MenuItems?: Maybe<ModelMenuItemConnection>
    Office?: Maybe<Office>
    cateringCompanyId: Scalars['ID']['output']
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    email: Scalars['String']['output']
    enabled: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    officeID?: Maybe<Scalars['ID']['output']>
    title?: Maybe<Scalars['String']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type CateringMenuItemsArgs = {
    filter?: InputMaybe<ModelMenuItemFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type Company = {
    __typename?: 'Company'
    CustomRoles?: Maybe<ModelCustomRoleConnection>
    Departments?: Maybe<ModelDepartmentConnection>
    Employees?: Maybe<ModelEmployeeConnection>
    EquipmentCategories?: Maybe<ModelEquipmentCategoryConnection>
    Offices?: Maybe<ModelOfficeConnection>
    Permissions?: Maybe<ModelPermissionConnection>
    Positions?: Maybe<ModelPositionConnection>
    Rules?: Maybe<Rules>
    address?: Maybe<Scalars['String']['output']>
    calendarIntegration?: Maybe<CalendarType>
    city?: Maybe<Scalars['String']['output']>
    companyLogo?: Maybe<Scalars['String']['output']>
    companyRulesId?: Maybe<Scalars['ID']['output']>
    country?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    id: Scalars['ID']['output']
    microsoftTenantId?: Maybe<Scalars['String']['output']>
    numberOfEmployees?: Maybe<Scalars['Int']['output']>
    numberOfOffices?: Maybe<Scalars['Int']['output']>
    phoneNumber?: Maybe<Scalars['String']['output']>
    ssoIntegration?: Maybe<SsoType>
    tariff?: Maybe<Tariff>
    title?: Maybe<Scalars['String']['output']>
    trialExpiresAt?: Maybe<Scalars['AWSDateTime']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    userProvisioningIntegration?: Maybe<SyncSource>
    zipcode?: Maybe<Scalars['String']['output']>
}

export type CompanyCustomRolesArgs = {
    filter?: InputMaybe<ModelCustomRoleFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CompanyDepartmentsArgs = {
    filter?: InputMaybe<ModelDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CompanyEmployeesArgs = {
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CompanyEquipmentCategoriesArgs = {
    filter?: InputMaybe<ModelEquipmentCategoryFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CompanyOfficesArgs = {
    filter?: InputMaybe<ModelOfficeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CompanyPermissionsArgs = {
    filter?: InputMaybe<ModelPermissionFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CompanyPositionsArgs = {
    filter?: InputMaybe<ModelPositionFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CompanyDayAggregation = {
    __typename?: 'CompanyDayAggregation'
    Company?: Maybe<Company>
    bookedHours?: Maybe<Scalars['Int']['output']>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyDayAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date: Scalars['AWSDate']['output']
    employeesInOffice?: Maybe<Scalars['Int']['output']>
    homeBookingsSum: Scalars['Int']['output']
    id: Scalars['ID']['output']
    officeBookingsSum: Scalars['Int']['output']
    officeCapacityLast: Scalars['Int']['output']
    type?: Maybe<AggregationByReservableType>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type CompanyTariffUpdated = {
    __typename?: 'CompanyTariffUpdated'
    companyId: Scalars['ID']['output']
    tariff: Tariff
}

export type CompanyWeekAggregation = {
    __typename?: 'CompanyWeekAggregation'
    Company?: Maybe<Company>
    bookedHours?: Maybe<Scalars['Int']['output']>
    bookingsDistribution: Array<DashboardWeekAggregationBookingsDistribution>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    companyWeekAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dayAggregations?: Maybe<ModelCompanyDayAggregationConnection>
    employeesInOffice?: Maybe<Scalars['Int']['output']>
    employeesLast: Scalars['Int']['output']
    id: Scalars['ID']['output']
    officeBookingsSum: Scalars['Int']['output']
    officeCapacityMax: Scalars['Int']['output']
    type?: Maybe<AggregationByReservableType>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type CompanyWeekAggregationDayAggregationsArgs = {
    filter?: InputMaybe<ModelCompanyDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberDate?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type CreateAddressInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressCompanyId?: InputMaybe<Scalars['ID']['input']>
    city?: InputMaybe<Scalars['String']['input']>
    companyID: Scalars['ID']['input']
    country?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    lat?: InputMaybe<Scalars['Float']['input']>
    long?: InputMaybe<Scalars['String']['input']>
    zipcode?: InputMaybe<Scalars['String']['input']>
}

export type CreateBookingCancellationInput = {
    bookingCancellationBookingId?: InputMaybe<Scalars['ID']['input']>
    bookingCancellationCompanyId?: InputMaybe<Scalars['ID']['input']>
    bookingCancellationEmployeeId?: InputMaybe<Scalars['ID']['input']>
    bookingID: Scalars['ID']['input']
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeeID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    removeRepeatable: Scalars['Boolean']['input']
}

export type CreateBookingEquipmentInput = {
    calendarEventID?: InputMaybe<Scalars['ID']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    endTime: Scalars['AWSDateTime']['input']
    equipmentID?: InputMaybe<Scalars['ID']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    isAllDay?: InputMaybe<Scalars['Boolean']['input']>
    isPrivate?: InputMaybe<Scalars['Boolean']['input']>
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    startTime: Scalars['AWSDateTime']['input']
    status?: InputMaybe<BookingEquipmentStatus>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type CreateBookingInput = {
    bookingBookingRequestId?: InputMaybe<Scalars['ID']['input']>
    bookingCompanyId?: InputMaybe<Scalars['ID']['input']>
    bookingEmployeeId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestID?: InputMaybe<Scalars['ID']['input']>
    bookingReservableId?: InputMaybe<Scalars['ID']['input']>
    bookingSpaceId?: InputMaybe<Scalars['ID']['input']>
    bookingSubType?: InputMaybe<BookingSubType>
    bookingTeamEventRequestId?: InputMaybe<Scalars['ID']['input']>
    bookingType?: InputMaybe<BookingType>
    checkInStatus?: InputMaybe<BookingCheckInStatus>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    dayOfWeek?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    employeeID: Scalars['ID']['input']
    endBookingTime?: InputMaybe<Scalars['AWSTime']['input']>
    endTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    isBlocked?: InputMaybe<Scalars['Boolean']['input']>
    isFullDay?: InputMaybe<Scalars['Boolean']['input']>
    isTeamEvent?: InputMaybe<Scalars['Boolean']['input']>
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    slackCheckInMessageId?: InputMaybe<Scalars['String']['input']>
    source?: InputMaybe<BookingSource>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startBookingTime?: InputMaybe<Scalars['AWSTime']['input']>
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<BookingStatus>
    teamEventRequestID?: InputMaybe<Scalars['ID']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type CreateBookingRequestInput = {
    addParkingSlot?: InputMaybe<Scalars['Boolean']['input']>
    bookingRequestCompanyId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestEmployeeId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestPreviousBookingRequestId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestReservableId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestSpaceId?: InputMaybe<Scalars['ID']['input']>
    bookingSubType?: InputMaybe<BookingSubType>
    bookingType?: InputMaybe<BookingType>
    checkInStatus?: InputMaybe<BookingCheckInStatus>
    companyID: Scalars['ID']['input']
    dates?: InputMaybe<Array<Scalars['AWSDate']['input']>>
    daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>
    effectiveFrom: Scalars['AWSDate']['input']
    effectiveTo?: InputMaybe<Scalars['AWSDate']['input']>
    employeeID: Scalars['ID']['input']
    endTime?: InputMaybe<Scalars['AWSTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    isFullDay?: InputMaybe<Scalars['Boolean']['input']>
    isTeamEvent?: InputMaybe<Scalars['Boolean']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    parkingSlotID?: InputMaybe<Scalars['ID']['input']>
    previousBookingRequestID?: InputMaybe<Scalars['ID']['input']>
    repeatType?: InputMaybe<RepeatType>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    source?: InputMaybe<BookingSource>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startTime?: InputMaybe<Scalars['AWSTime']['input']>
    status?: InputMaybe<BookingRequestStatus>
    ttl?: InputMaybe<Scalars['Int']['input']>
}

export type CreateBookingReservableInput = {
    bookingID: Scalars['ID']['input']
    bookingReservableCompanyId?: InputMaybe<Scalars['ID']['input']>
    checkInStatus?: InputMaybe<BookingCheckInStatus>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    dayOfWeek?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    endBookingTime?: InputMaybe<Scalars['AWSTime']['input']>
    endTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    reservableID: Scalars['ID']['input']
    startBookingTime?: InputMaybe<Scalars['AWSTime']['input']>
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
}

export type CreateCalendarEventEmployeeInput = {
    calendarEventID: Scalars['ID']['input']
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    employeeFullName?: InputMaybe<Scalars['String']['input']>
    employeeID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    isOwner?: InputMaybe<Scalars['Boolean']['input']>
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type CreateCalendarEventRequestInput = {
    calendarEventRequestCompanyId?: InputMaybe<Scalars['ID']['input']>
    calendarEventRequestPreviousCalendarEventRequestId?: InputMaybe<Scalars['ID']['input']>
    calendarEventRequestReservableId?: InputMaybe<Scalars['ID']['input']>
    calendarEventRequestSpaceId?: InputMaybe<Scalars['ID']['input']>
    cateringID?: InputMaybe<Scalars['ID']['input']>
    checkInStatus?: InputMaybe<CalendarEventCheckInStatus>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    dates?: InputMaybe<Array<Scalars['AWSDate']['input']>>
    daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>
    description?: InputMaybe<Scalars['String']['input']>
    effectiveFrom: Scalars['AWSDate']['input']
    effectiveTo?: InputMaybe<Scalars['AWSDate']['input']>
    employeesIDs: Array<Scalars['ID']['input']>
    endTime?: InputMaybe<Scalars['AWSTime']['input']>
    equipmentItems?: InputMaybe<Array<EquipmentItemModelInput>>
    id?: InputMaybe<Scalars['ID']['input']>
    isAllDay?: InputMaybe<Scalars['Boolean']['input']>
    isOnlineMeeting?: InputMaybe<Scalars['Boolean']['input']>
    isPrivate?: InputMaybe<Scalars['Boolean']['input']>
    menuItems?: InputMaybe<Array<MenuItemModelInput>>
    onlineMeetingUrl?: InputMaybe<Scalars['String']['input']>
    organiserEmployeeID: Scalars['ID']['input']
    owner?: InputMaybe<Scalars['ID']['input']>
    previousCalendarEventRequestID?: InputMaybe<Scalars['ID']['input']>
    repeatType?: InputMaybe<RepeatType>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    source?: InputMaybe<CalendarEventSource>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startTime?: InputMaybe<Scalars['AWSTime']['input']>
    status?: InputMaybe<CalendarEventRequestStatus>
    title?: InputMaybe<Scalars['String']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    useRelatedReservable?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateCalendarEventsInput = {
    calendarEventRequestID?: InputMaybe<Scalars['ID']['input']>
    canCurrentUserEdit?: InputMaybe<Scalars['Boolean']['input']>
    cateringID?: InputMaybe<Scalars['ID']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    dayOfMonth?: InputMaybe<Scalars['Int']['input']>
    daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    employeesIDs: Array<Scalars['ID']['input']>
    endTime: Scalars['AWSDateTime']['input']
    equipmentItems?: InputMaybe<Array<EquipmentItemModelInput>>
    firstDayOfWeek?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    interval?: InputMaybe<Scalars['Int']['input']>
    isAllDay?: InputMaybe<Scalars['Boolean']['input']>
    isOnlineMeeting?: InputMaybe<Scalars['Boolean']['input']>
    isPrivate?: InputMaybe<Scalars['Boolean']['input']>
    menuItems?: InputMaybe<Array<MenuItemModelInput>>
    month?: InputMaybe<Scalars['Int']['input']>
    numberOfOccurrences?: InputMaybe<Scalars['Int']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    onlineMeetingUrl?: InputMaybe<Scalars['String']['input']>
    organiserEmployeeID?: InputMaybe<Scalars['ID']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    parentEventExternalId?: InputMaybe<Scalars['String']['input']>
    patternType?: InputMaybe<RecurrencePatternType>
    rangeEndDate?: InputMaybe<Scalars['AWSDate']['input']>
    rangeStartDate?: InputMaybe<Scalars['AWSDate']['input']>
    rangeType?: InputMaybe<RecurrenceRangeType>
    recurrenceTimeZone?: InputMaybe<Scalars['String']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    source?: InputMaybe<CalendarEventSource>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startTime: Scalars['AWSDateTime']['input']
    status?: InputMaybe<CalendarEventStatus>
    title?: InputMaybe<Scalars['String']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    useRelatedReservable?: InputMaybe<Scalars['Boolean']['input']>
    weekIndex?: InputMaybe<Scalars['String']['input']>
}

export type CreateCateringInput = {
    cateringCompanyId: Scalars['ID']['input']
    companyID: Scalars['ID']['input']
    email: Scalars['String']['input']
    enabled: Scalars['Boolean']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    title?: InputMaybe<Scalars['String']['input']>
}

export type CreateCompanyDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date: Scalars['AWSDate']['input']
    employeesInOffice?: InputMaybe<Scalars['Int']['input']>
    homeBookingsSum: Scalars['Int']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeBookingsSum: Scalars['Int']['input']
    officeCapacityLast: Scalars['Int']['input']
    type?: InputMaybe<AggregationByReservableType>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateCompanyInput = {
    address?: InputMaybe<Scalars['String']['input']>
    calendarIntegration?: InputMaybe<CalendarType>
    city?: InputMaybe<Scalars['String']['input']>
    companyLogo?: InputMaybe<Scalars['String']['input']>
    companyRulesId?: InputMaybe<Scalars['ID']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    microsoftTenantId?: InputMaybe<Scalars['String']['input']>
    numberOfEmployees?: InputMaybe<Scalars['Int']['input']>
    numberOfOffices?: InputMaybe<Scalars['Int']['input']>
    phoneNumber?: InputMaybe<Scalars['String']['input']>
    ssoIntegration?: InputMaybe<SsoType>
    tariff?: InputMaybe<Tariff>
    title?: InputMaybe<Scalars['String']['input']>
    trialExpiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    userProvisioningIntegration?: InputMaybe<SyncSource>
    zipcode?: InputMaybe<Scalars['String']['input']>
}

export type CreateCompanyWeekAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsDistribution: Array<DashboardWeekAggregationBookingsDistributionInput>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    companyWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeesInOffice?: InputMaybe<Scalars['Int']['input']>
    employeesLast: Scalars['Int']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeBookingsSum: Scalars['Int']['input']
    officeCapacityMax: Scalars['Int']['input']
    type?: InputMaybe<AggregationByReservableType>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateCustomRoleInput = {
    companyID: Scalars['ID']['input']
    description?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    name: Scalars['String']['input']
}

export type CreateDemoAccountInput = {
    active?: InputMaybe<Scalars['Boolean']['input']>
    companyName?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    demoAccountEmployeeId?: InputMaybe<Scalars['ID']['input']>
    email: Scalars['String']['input']
    employeeID: Scalars['ID']['input']
    firstname: Scalars['String']['input']
    id: Scalars['ID']['input']
    lastname: Scalars['String']['input']
    phone?: InputMaybe<Scalars['String']['input']>
    source?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<DemoAccountStatus>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type CreateDepartmentDayAggregationEmployeesInput = {
    companyID: Scalars['ID']['input']
    departmentDayAggregationID: Scalars['ID']['input']
    employeeID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
}

export type CreateDepartmentDayAggregationInput = {
    averageDaysInOffice?: InputMaybe<Scalars['Float']['input']>
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date: Scalars['AWSDate']['input']
    departmentDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    departmentID: Scalars['ID']['input']
    homeBookingsSum: Scalars['Int']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    notAvailableBookingsSum: Scalars['Int']['input']
    officeBookingsSum: Scalars['Int']['input']
    teamSize?: InputMaybe<Scalars['Int']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateDepartmentInput = {
    companyID: Scalars['ID']['input']
    departmentCompanyId?: InputMaybe<Scalars['ID']['input']>
    departmentTeamLeadId?: InputMaybe<Scalars['ID']['input']>
    employeeIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    name: Scalars['String']['input']
}

export type CreateDepartmentLastMonthAttendanceInput = {
    attendanceScore?: InputMaybe<Scalars['Int']['input']>
    attendanceScoreString?: InputMaybe<Scalars['String']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    departmentID: Scalars['ID']['input']
    departmentLastMonthAttendanceCompanyId?: InputMaybe<Scalars['ID']['input']>
    departmentLastMonthAttendanceDepartmentId?: InputMaybe<Scalars['ID']['input']>
    departmentNameLowerCase: Scalars['String']['input']
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    lastDateTogether?: InputMaybe<Scalars['AWSDate']['input']>
    lastDateTogetherForSorting: Scalars['AWSDate']['input']
    monthlyAttendance?: InputMaybe<Scalars['Float']['input']>
    monthlyAttendanceString?: InputMaybe<Scalars['String']['input']>
    officeDaysScore?: InputMaybe<Scalars['Int']['input']>
    officeDaysScoreString?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weeklyAttendance: Scalars['Float']['input']
    weeklyAttendanceString: Scalars['String']['input']
}

export type CreateDepartmentRulesInput = {
    blockReservationOnNonOfficeDays?: InputMaybe<Scalars['Boolean']['input']>
    companyID: Scalars['ID']['input']
    departmentID: Scalars['ID']['input']
    enableMinDaysInOffice?: InputMaybe<Scalars['Boolean']['input']>
    horizonOfPlanningInDays: Scalars['Int']['input']
    horizonOfPlanningTimeAmount?: InputMaybe<Scalars['Int']['input']>
    horizonOfPlanningTimeUnit?: InputMaybe<HorizonOfPlanningTimeUnit>
    id?: InputMaybe<Scalars['ID']['input']>
    maxDaysInOffice?: InputMaybe<Scalars['Int']['input']>
    minDaysInOffice?: InputMaybe<Scalars['Int']['input']>
    minDaysInOfficeTimeUnit?: InputMaybe<MinDaysInOfficeTimeUnit>
    officeDays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CreateDepartmentWeekAggregationInput = {
    averageDaysInOffice?: InputMaybe<Scalars['Float']['input']>
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsDistribution: Array<DashboardWeekAggregationBookingsDistributionInput>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    departmentID: Scalars['ID']['input']
    departmentWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeesInOffice?: InputMaybe<Scalars['Int']['input']>
    employeesLast: Scalars['Int']['input']
    homeBookingsSum: Scalars['Int']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    notAvailableBookingsSum: Scalars['Int']['input']
    officeBookingsSum: Scalars['Int']['input']
    teamSize?: InputMaybe<Scalars['Int']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateEmployeeDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsByHoursDistribution: Array<EmployeeAggregationAttendanceByHoursDistributionInput>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date: Scalars['AWSDate']['input']
    employeeDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeesLast: Scalars['Int']['input']
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    notAvailableBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateEmployeeInput = {
    birthday?: InputMaybe<Scalars['AWSDate']['input']>
    buddyID?: InputMaybe<Scalars['ID']['input']>
    companyID: Scalars['ID']['input']
    defaultPlan?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    demoAccountID?: InputMaybe<Scalars['ID']['input']>
    departmentIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    departmentIDsString?: InputMaybe<Scalars['String']['input']>
    email?: InputMaybe<Scalars['AWSEmail']['input']>
    emailForSearch?: InputMaybe<Scalars['String']['input']>
    employeeCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeeDemoAccountId?: InputMaybe<Scalars['ID']['input']>
    employeeDepartmentIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    employeeOfficeId?: InputMaybe<Scalars['ID']['input']>
    employeePositionId?: InputMaybe<Scalars['ID']['input']>
    externalID?: InputMaybe<Scalars['String']['input']>
    favouriteOfficeID?: InputMaybe<Scalars['ID']['input']>
    firstname?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    lastname?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<UserLocale>
    officeID?: InputMaybe<Scalars['ID']['input']>
    photo?: InputMaybe<Scalars['String']['input']>
    positionID?: InputMaybe<Scalars['ID']['input']>
    role?: InputMaybe<Role>
    shareAttendance?: InputMaybe<ShareAttendance>
    source?: InputMaybe<SyncSource>
    status?: InputMaybe<EmployeeStatus>
}

export type CreateEmployeeLastMonthAttendanceInput = {
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    departmentIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    departmentIDsString?: InputMaybe<Scalars['String']['input']>
    departmentNamesString?: InputMaybe<Scalars['String']['input']>
    employeeID: Scalars['ID']['input']
    employeeLastMonthAttendanceCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeeLastMonthAttendanceDepartmentId?: InputMaybe<Scalars['ID']['input']>
    employeeLastMonthAttendanceEmployeeId?: InputMaybe<Scalars['ID']['input']>
    employeeNameLowerCase: Scalars['String']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    lastDateInOffice?: InputMaybe<Scalars['AWSDate']['input']>
    lastDateInOfficeForSorting: Scalars['AWSDate']['input']
    monthlyAttendance?: InputMaybe<Scalars['Float']['input']>
    monthlyAttendanceString?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weeklyAttendance: Scalars['Float']['input']
    weeklyAttendanceString: Scalars['String']['input']
}

export type CreateEmployeeToDepartmentInput = {
    companyID: Scalars['ID']['input']
    departmentID: Scalars['ID']['input']
    employeeID: Scalars['ID']['input']
    employeeToDepartmentCompanyId: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
}

export type CreateEmployeeWeekAggregationInput = {
    averageDaysInOffice?: InputMaybe<Scalars['Float']['input']>
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsByHoursDistribution: Array<EmployeeAggregationAttendanceByHoursDistributionInput>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeeWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeesInOffice?: InputMaybe<Scalars['Int']['input']>
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    notAvailableBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateEquipmentImageInput = {
    companyID: Scalars['ID']['input']
    equipmentID: Scalars['ID']['input']
    equipmentImageCompanyId?: InputMaybe<Scalars['ID']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    isMain?: InputMaybe<Scalars['Boolean']['input']>
    location?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
}

export type CreateEquipmentInput = {
    canBookedSeparately?: InputMaybe<Scalars['Boolean']['input']>
    companyID: Scalars['ID']['input']
    description?: InputMaybe<Scalars['String']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    equipmentCategoryID?: InputMaybe<Scalars['ID']['input']>
    equipmentCompanyId: Scalars['ID']['input']
    equipmentEquipmentCategoryId?: InputMaybe<Scalars['ID']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    quantity?: InputMaybe<Scalars['Int']['input']>
}

export type CreateGoogleImportedBookingsInput = {
    calendarEventID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    googleID: Scalars['String']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    updated?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type CreateGoogleImportedWorkingLocationsInput = {
    bookingID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    googleID: Scalars['String']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    updated?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type CreateIntegrationInput = {
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    group: IntegrationGroup
    id?: InputMaybe<Scalars['ID']['input']>
    integrationCompanyId?: InputMaybe<Scalars['ID']['input']>
    order: Scalars['Int']['input']
    status: IntegrationStatus
    type: IntegrationType
}

export type CreateMenuItemInput = {
    cateringID: Scalars['ID']['input']
    companyID: Scalars['ID']['input']
    description?: InputMaybe<Scalars['String']['input']>
    enabled: Scalars['Boolean']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    menuItemCompanyId?: InputMaybe<Scalars['ID']['input']>
    title: Scalars['String']['input']
}

export type CreateMicrosoftSubscriptionInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    isFreeTrial?: InputMaybe<Scalars['Boolean']['input']>
    microsoftSubscriptionCompanyId?: InputMaybe<Scalars['ID']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    offerId?: InputMaybe<Scalars['String']['input']>
    ownerEmail?: InputMaybe<Scalars['String']['input']>
    ownerID?: InputMaybe<Scalars['ID']['input']>
    ownerName?: InputMaybe<Scalars['String']['input']>
    planId?: InputMaybe<Scalars['String']['input']>
    quantity?: InputMaybe<Scalars['Int']['input']>
    status?: InputMaybe<MicrosoftSubscriptionStatus>
    tenantId: Scalars['String']['input']
}

export type CreateNotificationInput = {
    employeeID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    placeholders?: InputMaybe<Scalars['AWSJSON']['input']>
    sentAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    status: NotificationStatus
    type: NotificationType
}

export type CreateOfficeBuilderRequestInput = {
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    offices: Array<OfficeBuilderOfficeInput>
    owner?: InputMaybe<Scalars['ID']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type CreateOfficeDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date: Scalars['AWSDate']['input']
    homeBookingsSum: Scalars['Int']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeBookingsSum: Scalars['Int']['input']
    officeCapacityLast: Scalars['Int']['input']
    officeDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    officeDayAggregationOfficeId?: InputMaybe<Scalars['ID']['input']>
    officeID: Scalars['ID']['input']
    type?: InputMaybe<AggregationByReservableType>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateOfficeImageInput = {
    companyID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    location?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    officeImageCompanyId?: InputMaybe<Scalars['ID']['input']>
    officeImageOfficeId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateOfficeInput = {
    address?: InputMaybe<Scalars['String']['input']>
    companyID: Scalars['ID']['input']
    externalOfficeId?: InputMaybe<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    name: Scalars['String']['input']
    officeAddressId?: InputMaybe<Scalars['ID']['input']>
    officeCateringId?: InputMaybe<Scalars['ID']['input']>
    officeCompanyId?: InputMaybe<Scalars['ID']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    phoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    placesBlocked?: InputMaybe<Scalars['Int']['input']>
    placesFixed?: InputMaybe<Scalars['Int']['input']>
    placesFlex?: InputMaybe<Scalars['Int']['input']>
    placesTotal?: InputMaybe<Scalars['Int']['input']>
    properties?: InputMaybe<Scalars['AWSJSON']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    workingFrom?: InputMaybe<Scalars['AWSTime']['input']>
    workingHours?: InputMaybe<Scalars['AWSJSON']['input']>
    workingTo?: InputMaybe<Scalars['AWSTime']['input']>
}

export type CreateOfficeWeekAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsDistribution: Array<DashboardWeekAggregationBookingsDistributionInput>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeesLast: Scalars['Int']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeBookingsSum: Scalars['Int']['input']
    officeCapacityMax: Scalars['Int']['input']
    officeID: Scalars['ID']['input']
    officeWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    officeWeekAggregationOfficeId?: InputMaybe<Scalars['ID']['input']>
    percentageOfUtilization?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreatePermissionInput = {
    allowedActions: Array<ActionType>
    companyID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    resourceType: ResourceType
    roleID: Scalars['ID']['input']
    scope?: InputMaybe<Scope>
}

export type CreatePersonioImportedAbsencesInput = {
    bookingRequestID: Scalars['ID']['input']
    bookingSubType?: InputMaybe<BookingSubType>
    bookingType?: InputMaybe<BookingType>
    companyID: Scalars['ID']['input']
    effectiveFrom: Scalars['AWSDate']['input']
    effectiveTo: Scalars['AWSDate']['input']
    employeeEmail: Scalars['String']['input']
    halfDayEnd?: InputMaybe<Scalars['Boolean']['input']>
    halfDayStart?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    personioID: Scalars['Int']['input']
    personioImportedAbsencesBookingRequestId?: InputMaybe<Scalars['ID']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
}

export type CreatePersonioImportedBookingsInput = {
    bookingID: Scalars['ID']['input']
    effectiveFrom: Scalars['AWSDate']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    personioID: Scalars['String']['input']
    personioImportedBookingsBookingId?: InputMaybe<Scalars['ID']['input']>
}

export type CreatePositionInput = {
    companyID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    name: Scalars['String']['input']
    positionCompanyId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateReservableImageInput = {
    companyID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    isMain?: InputMaybe<Scalars['Boolean']['input']>
    location?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    reservableImageCompanyId?: InputMaybe<Scalars['ID']['input']>
    reservableImageReservableId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateReservableInput = {
    adminID?: InputMaybe<Scalars['ID']['input']>
    availabilityByDatesAndTime?: InputMaybe<Scalars['Boolean']['input']>
    availabilityPreviewByDates?: InputMaybe<Array<AvailabilityItemPreviewElement>>
    availabilityType: AvailabilityType
    color?: InputMaybe<Scalars['String']['input']>
    companyID: Scalars['ID']['input']
    departmentID?: InputMaybe<Scalars['ID']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    externalRoomID?: InputMaybe<Scalars['String']['input']>
    height?: InputMaybe<Scalars['Float']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    isDefault?: InputMaybe<Scalars['String']['input']>
    maxAmountOfPeople?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    relatedReservableID?: InputMaybe<Scalars['ID']['input']>
    reservableCompanyId?: InputMaybe<Scalars['ID']['input']>
    reservableDepartmentId?: InputMaybe<Scalars['ID']['input']>
    reservableEmployeeId?: InputMaybe<Scalars['ID']['input']>
    reservableSpaceId?: InputMaybe<Scalars['ID']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    type: ReservableType
    width?: InputMaybe<Scalars['Float']['input']>
    x?: InputMaybe<Scalars['Float']['input']>
    y?: InputMaybe<Scalars['Float']['input']>
    zonePoints?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
}

export type CreateReservableTagInput = {
    companyID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    reservableID: Scalars['ID']['input']
    reservableTagCompanyId: Scalars['ID']['input']
    reservableTagOfficeId?: InputMaybe<Scalars['ID']['input']>
    reservableTagSpaceId?: InputMaybe<Scalars['ID']['input']>
    reservableType?: InputMaybe<ReservableType>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    tagID: Scalars['ID']['input']
}

export type CreateReservableToDepartmentInput = {
    companyID: Scalars['ID']['input']
    departmentID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    reservableID: Scalars['ID']['input']
    reservableToDepartmentCompanyId: Scalars['ID']['input']
    reservableToDepartmentDepartmentId?: InputMaybe<Scalars['ID']['input']>
    reservableToDepartmentReservableId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateReservableToEmployeeInput = {
    employeeID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    reservableID: Scalars['ID']['input']
}

export type CreateRoomDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    dataByHour: Array<RoomDayAggregationBookingsByHoursDistributionInput>
    date: Scalars['AWSDate']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeID: Scalars['ID']['input']
    reservableID: Scalars['ID']['input']
    roomBookingsSum: Scalars['Int']['input']
    roomCapacityLast: Scalars['Int']['input']
    roomDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    roomNameLowerCase: Scalars['String']['input']
    spaceID: Scalars['ID']['input']
    visitors?: InputMaybe<Scalars['Int']['input']>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateRoomWeekAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    percentageOfUtilization?: InputMaybe<Scalars['Int']['input']>
    reservableID: Scalars['ID']['input']
    roomBookingsSum: Scalars['Int']['input']
    roomCapacityMax: Scalars['Int']['input']
    roomNameLowerCase: Scalars['String']['input']
    roomWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    spaceID: Scalars['ID']['input']
    visitors?: InputMaybe<Scalars['Int']['input']>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateRulesInput = {
    autoAssignWaitingList: Scalars['Boolean']['input']
    blockReservationOnNonWorkingDays?: InputMaybe<Scalars['Boolean']['input']>
    checkInCancellingTime?: InputMaybe<Scalars['Int']['input']>
    checkInWaitingTime?: InputMaybe<Scalars['Int']['input']>
    dayOfAttendanceNotifications?: InputMaybe<Scalars['Int']['input']>
    defaultShareAttendance?: InputMaybe<ShareAttendance>
    deleteDataAfter?: InputMaybe<Scalars['Int']['input']>
    deletePersonalDataAfter?: InputMaybe<Scalars['Int']['input']>
    denyRecurringBookingOverThePlanningHorizon?: InputMaybe<Scalars['Boolean']['input']>
    disableOnboardingQuiz: Scalars['Boolean']['input']
    disableProfileEditing?: InputMaybe<Scalars['Boolean']['input']>
    emailForMonthlyReport?: InputMaybe<Scalars['String']['input']>
    enableMinDaysInOffice?: InputMaybe<Scalars['Boolean']['input']>
    homeIsDefault: Scalars['Boolean']['input']
    horizonOfPlanningInDays: Scalars['Int']['input']
    horizonOfPlanningTimeAmount?: InputMaybe<Scalars['Int']['input']>
    horizonOfPlanningTimeUnit?: InputMaybe<HorizonOfPlanningTimeUnit>
    hourOfAttendanceNotifications?: InputMaybe<Scalars['Int']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    isPersonalSeatAvailableToday?: InputMaybe<Scalars['Boolean']['input']>
    isTeamSeatAvailableToday?: InputMaybe<Scalars['Boolean']['input']>
    maxDaysInOffice?: InputMaybe<Scalars['Int']['input']>
    minDaysInOffice?: InputMaybe<Scalars['Int']['input']>
    minDaysInOfficeTimeUnit?: InputMaybe<MinDaysInOfficeTimeUnit>
    startTimeOfNotifications?: InputMaybe<Scalars['Int']['input']>
    turnOffCheckInForPersonalSeats?: InputMaybe<Scalars['Boolean']['input']>
    turnOnAttendanceNotifications?: InputMaybe<Scalars['Boolean']['input']>
    turnOnCheckIn?: InputMaybe<Scalars['Boolean']['input']>
    turnOnDeletingData?: InputMaybe<Scalars['Boolean']['input']>
    turnOnExperimentalFeatures?: InputMaybe<Scalars['Boolean']['input']>
    turnOnMonthlyReport?: InputMaybe<Scalars['Boolean']['input']>
    updateEmployeeDataFromSource?: InputMaybe<Scalars['Boolean']['input']>
    workingDays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type CreateSlackOAuthStateInput = {
    companyID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
}

export type CreateSlackOnboradingMessageInput = {
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeeID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    slackMessageID: Scalars['String']['input']
    slackOnboradingMessageCompanyId?: InputMaybe<Scalars['ID']['input']>
    slackOnboradingMessageEmployeeId?: InputMaybe<Scalars['ID']['input']>
    state: SlackOnboradingMessageState
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type CreateSpaceCapacityDayAggregationInput = {
    availableSeatsSum: Scalars['Int']['input']
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date: Scalars['AWSDate']['input']
    employeeID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    spaceCapacityDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    spaceCapacityDayAggregationEmployeeId?: InputMaybe<Scalars['ID']['input']>
    spaceCapacityDayAggregationSpaceId?: InputMaybe<Scalars['ID']['input']>
    spaceID: Scalars['ID']['input']
    ttl?: InputMaybe<Scalars['Int']['input']>
    type: SpaceCapacityDayAggregationType
    waitingListItemsSum: Scalars['Int']['input']
}

export type CreateSpaceDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    dataByHour: Array<SpaceDayAggregationBookingsByHoursDistributionInput>
    date: Scalars['AWSDate']['input']
    homeBookingsSum: Scalars['Int']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    spaceBookingsSum: Scalars['Int']['input']
    spaceCapacityLast: Scalars['Int']['input']
    spaceDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    spaceID: Scalars['ID']['input']
    type?: InputMaybe<AggregationByReservableType>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateSpaceInput = {
    address?: InputMaybe<Scalars['String']['input']>
    availableParkingSlots?: InputMaybe<Scalars['Int']['input']>
    availableParkingSlotsByPeriod?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    floorPlanFilePath?: InputMaybe<Scalars['String']['input']>
    getCapacity?: InputMaybe<SpaceCapacityInput>
    id?: InputMaybe<Scalars['ID']['input']>
    isAvailableToEmployees?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    order?: InputMaybe<Scalars['Int']['input']>
    spaceCompanyId?: InputMaybe<Scalars['ID']['input']>
    spaceOfficeId?: InputMaybe<Scalars['ID']['input']>
    spaceRowID?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatID?: InputMaybe<Scalars['ID']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalParkingSlots?: InputMaybe<Scalars['Int']['input']>
    totalRooms?: InputMaybe<Scalars['Int']['input']>
    totalSeats?: InputMaybe<Scalars['Int']['input']>
    workingHoursFrom?: InputMaybe<Scalars['AWSTime']['input']>
    workingHoursTo?: InputMaybe<Scalars['AWSTime']['input']>
}

export type CreateSpaceRowInput = {
    companyID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeID: Scalars['ID']['input']
    order?: InputMaybe<Scalars['Int']['input']>
}

export type CreateSpaceWeekAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsDistribution: Array<DashboardWeekAggregationBookingsDistributionInput>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeesLast: Scalars['Int']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    percentageOfUtilization?: InputMaybe<Scalars['Int']['input']>
    spaceBookingsSum: Scalars['Int']['input']
    spaceCapacityMax: Scalars['Int']['input']
    spaceID: Scalars['ID']['input']
    spaceWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    type?: InputMaybe<AggregationByReservableType>
    weekNumber: Scalars['Int']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year: Scalars['Int']['input']
}

export type CreateSuggestedSeatInput = {
    companyID: Scalars['ID']['input']
    date: Scalars['AWSDate']['input']
    employeeID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    reason: SuggestedSeatReason
    reservableID: Scalars['ID']['input']
    spaceID?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatCompanyId?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatEmployeeId?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatReservableId?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatSpaceId?: InputMaybe<Scalars['ID']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
}

export type CreateSyncUserEventInput = {
    action?: InputMaybe<SyncUserEventAction>
    companyID: Scalars['ID']['input']
    department?: InputMaybe<Scalars['String']['input']>
    email: Scalars['String']['input']
    externalId?: InputMaybe<Scalars['String']['input']>
    firstname: Scalars['String']['input']
    hasInvitationEnabled?: InputMaybe<Scalars['Boolean']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    lastname: Scalars['String']['input']
    office?: InputMaybe<Scalars['String']['input']>
    position?: InputMaybe<Scalars['String']['input']>
    profilePicS3key?: InputMaybe<Scalars['String']['input']>
    shouldEmailInvitation?: InputMaybe<Scalars['Boolean']['input']>
    source?: InputMaybe<SyncSource>
}

export type CreateTagInput = {
    companyID: Scalars['ID']['input']
    id?: InputMaybe<Scalars['ID']['input']>
    name: Scalars['String']['input']
    reservableType?: InputMaybe<ReservableType>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    tagCompanyId: Scalars['ID']['input']
    tagSpaceId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateTeamEventRequestInput = {
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date: Scalars['AWSDate']['input']
    dates?: InputMaybe<Array<Scalars['AWSDate']['input']>>
    daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>
    description?: InputMaybe<Scalars['String']['input']>
    employeeIDs: Array<Scalars['ID']['input']>
    endTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    repeatType?: InputMaybe<RepeatType>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    spaceID: Scalars['ID']['input']
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<TeamEventRequestStatus>
    teamEventRequestSpaceId?: InputMaybe<Scalars['ID']['input']>
    title: Scalars['String']['input']
    ttl?: InputMaybe<Scalars['Int']['input']>
}

export type CustomRole = {
    __typename?: 'CustomRole'
    Company?: Maybe<Company>
    Employees?: Maybe<ModelEmployeeConnection>
    Permissions?: Maybe<ModelPermissionConnection>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export type CustomRoleEmployeesArgs = {
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type CustomRolePermissionsArgs = {
    filter?: InputMaybe<ModelPermissionFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type DashboardWeekAggregationBookingsDistribution = {
    __typename?: 'DashboardWeekAggregationBookingsDistribution'
    amountOfDays: Scalars['Int']['output']
    bookingsSum: Scalars['Int']['output']
}

export type DashboardWeekAggregationBookingsDistributionInput = {
    amountOfDays: Scalars['Int']['input']
    bookingsSum: Scalars['Int']['input']
}

export type DeleteAddressInput = {
    id: Scalars['ID']['input']
}

export type DeleteBookingCancellationInput = {
    id: Scalars['ID']['input']
}

export type DeleteBookingEquipmentInput = {
    id: Scalars['ID']['input']
}

export type DeleteBookingInput = {
    id: Scalars['ID']['input']
}

export type DeleteBookingRequestInput = {
    id: Scalars['ID']['input']
}

export type DeleteBookingReservableInput = {
    id: Scalars['ID']['input']
}

export type DeleteCalendarEventEmployeeInput = {
    id: Scalars['ID']['input']
}

export type DeleteCalendarEventRequestInput = {
    id: Scalars['ID']['input']
}

export type DeleteCalendarEventsInput = {
    id: Scalars['ID']['input']
}

export type DeleteCateringInput = {
    id: Scalars['ID']['input']
}

export type DeleteCompanyDayAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteCompanyInput = {
    id: Scalars['ID']['input']
}

export type DeleteCompanyWeekAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteCustomRoleInput = {
    id: Scalars['ID']['input']
}

export type DeleteDepartmentDayAggregationEmployeesInput = {
    id: Scalars['ID']['input']
}

export type DeleteDepartmentDayAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteDepartmentInput = {
    id: Scalars['ID']['input']
}

export type DeleteDepartmentLastMonthAttendanceInput = {
    id: Scalars['ID']['input']
}

export type DeleteDepartmentRulesInput = {
    id: Scalars['ID']['input']
}

export type DeleteDepartmentWeekAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteEmployeeDayAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteEmployeeInput = {
    id: Scalars['ID']['input']
}

export type DeleteEmployeeLastMonthAttendanceInput = {
    id: Scalars['ID']['input']
}

export type DeleteEmployeeToDepartmentInput = {
    id: Scalars['ID']['input']
}

export type DeleteEmployeeWeekAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteEquipmentImageInput = {
    id: Scalars['ID']['input']
}

export type DeleteEquipmentInput = {
    id: Scalars['ID']['input']
}

export type DeleteGoogleImportedBookingsInput = {
    id: Scalars['ID']['input']
}

export type DeleteGoogleImportedWorkingLocationsInput = {
    id: Scalars['ID']['input']
}

export type DeleteIntegrationInput = {
    id: Scalars['ID']['input']
}

export type DeleteMenuItemInput = {
    id: Scalars['ID']['input']
}

export type DeleteMicrosoftSubscriptionInput = {
    id: Scalars['ID']['input']
}

export type DeleteNotificationInput = {
    id: Scalars['ID']['input']
}

export type DeleteOfficeBuilderRequestInput = {
    id: Scalars['ID']['input']
}

export type DeleteOfficeDayAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteOfficeImageInput = {
    id: Scalars['ID']['input']
}

export type DeleteOfficeInput = {
    id: Scalars['ID']['input']
}

export type DeleteOfficeWeekAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeletePermissionInput = {
    id: Scalars['ID']['input']
}

export type DeletePersonioImportedAbsencesInput = {
    id: Scalars['ID']['input']
}

export type DeletePersonioImportedBookingsInput = {
    id: Scalars['ID']['input']
}

export type DeletePositionInput = {
    id: Scalars['ID']['input']
}

export type DeleteReservableImageInput = {
    id: Scalars['ID']['input']
}

export type DeleteReservableInput = {
    id: Scalars['ID']['input']
}

export type DeleteReservableTagInput = {
    id: Scalars['ID']['input']
}

export type DeleteReservableToDepartmentInput = {
    id: Scalars['ID']['input']
}

export type DeleteReservableToEmployeeInput = {
    id: Scalars['ID']['input']
}

export type DeleteRoomDayAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteRoomWeekAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteSlackOAuthStateInput = {
    id: Scalars['ID']['input']
}

export type DeleteSlackOnboradingMessageInput = {
    id: Scalars['ID']['input']
}

export type DeleteSpaceCapacityDayAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteSpaceDayAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteSpaceInput = {
    id: Scalars['ID']['input']
}

export type DeleteSpaceRowInput = {
    id: Scalars['ID']['input']
}

export type DeleteSpaceWeekAggregationInput = {
    id: Scalars['ID']['input']
}

export type DeleteSuggestedSeatInput = {
    id: Scalars['ID']['input']
}

export type DeleteTagInput = {
    id: Scalars['ID']['input']
}

export type DeleteTeamEventRequestInput = {
    id: Scalars['ID']['input']
}

export type DemoAccount = {
    __typename?: 'DemoAccount'
    Employee?: Maybe<Employee>
    active?: Maybe<Scalars['Boolean']['output']>
    companyName?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    demoAccountEmployeeId?: Maybe<Scalars['ID']['output']>
    email: Scalars['String']['output']
    employeeID: Scalars['ID']['output']
    firstname: Scalars['String']['output']
    id: Scalars['ID']['output']
    lastname: Scalars['String']['output']
    phone?: Maybe<Scalars['String']['output']>
    source?: Maybe<Scalars['String']['output']>
    status?: Maybe<DemoAccountStatus>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
}

export enum DemoAccountStatus {
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    EXPIRED = 'EXPIRED',
}

export type Department = {
    __typename?: 'Department'
    Company?: Maybe<Company>
    DepartmentRules?: Maybe<DepartmentRules>
    EmployeeLastMonthAttendances?: Maybe<ModelEmployeeLastMonthAttendanceConnection>
    EmployeeToDepartment?: Maybe<ModelEmployeeToDepartmentConnection>
    ReservableToDepartments?: Maybe<ModelReservableToDepartmentConnection>
    TeamLead?: Maybe<Employee>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    departmentCompanyId?: Maybe<Scalars['ID']['output']>
    departmentTeamLeadId?: Maybe<Scalars['ID']['output']>
    employeeIDs?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    size?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type DepartmentEmployeeLastMonthAttendancesArgs = {
    employeeNameLowerCase?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type DepartmentEmployeeToDepartmentArgs = {
    filter?: InputMaybe<ModelEmployeeToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type DepartmentReservableToDepartmentsArgs = {
    filter?: InputMaybe<ModelReservableToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type DepartmentDayAggregation = {
    __typename?: 'DepartmentDayAggregation'
    Company?: Maybe<Company>
    Department?: Maybe<Department>
    averageDaysInOffice?: Maybe<Scalars['Float']['output']>
    bookedHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date: Scalars['AWSDate']['output']
    departmentDayAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    departmentID: Scalars['ID']['output']
    employeesInOffice?: Maybe<ModelDepartmentDayAggregationEmployeesConnection>
    homeBookingsSum: Scalars['Int']['output']
    id: Scalars['ID']['output']
    notAvailableBookingsSum: Scalars['Int']['output']
    officeBookingsSum: Scalars['Int']['output']
    teamSize?: Maybe<Scalars['Int']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type DepartmentDayAggregationEmployeesInOfficeArgs = {
    filter?: InputMaybe<ModelDepartmentDayAggregationEmployeesFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type DepartmentDayAggregationEmployees = {
    __typename?: 'DepartmentDayAggregationEmployees'
    companyID?: Maybe<Scalars['String']['output']>
    createdAt: Scalars['AWSDateTime']['output']
    departmentDayAggregation: DepartmentDayAggregation
    departmentDayAggregationID: Scalars['ID']['output']
    employee: Employee
    employeeID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export type DepartmentLastMonthAttendance = {
    __typename?: 'DepartmentLastMonthAttendance'
    Company?: Maybe<Company>
    Department?: Maybe<Department>
    EmployeeLastMonthAttendances?: Maybe<ModelEmployeeLastMonthAttendanceConnection>
    SuggestedSeats?: Maybe<ModelSuggestedSeatConnection>
    attendanceScore?: Maybe<Scalars['Int']['output']>
    attendanceScoreString?: Maybe<Scalars['String']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    departmentID: Scalars['ID']['output']
    departmentLastMonthAttendanceCompanyId?: Maybe<Scalars['ID']['output']>
    departmentLastMonthAttendanceDepartmentId?: Maybe<Scalars['ID']['output']>
    departmentNameLowerCase: Scalars['String']['output']
    employeesLast?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    lastDateTogether?: Maybe<Scalars['AWSDate']['output']>
    lastDateTogetherForSorting: Scalars['AWSDate']['output']
    monthlyAttendance?: Maybe<Scalars['Float']['output']>
    monthlyAttendanceString?: Maybe<Scalars['String']['output']>
    officeDaysScore?: Maybe<Scalars['Int']['output']>
    officeDaysScoreString?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    weeklyAttendance: Scalars['Float']['output']
    weeklyAttendanceString: Scalars['String']['output']
}

export type DepartmentLastMonthAttendanceEmployeeLastMonthAttendancesArgs = {
    employeeNameLowerCase?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type DepartmentLastMonthAttendanceSuggestedSeatsArgs = {
    date?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelSuggestedSeatFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type DepartmentRules = {
    __typename?: 'DepartmentRules'
    Department?: Maybe<Department>
    blockReservationOnNonOfficeDays?: Maybe<Scalars['Boolean']['output']>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    departmentID: Scalars['ID']['output']
    enableMinDaysInOffice?: Maybe<Scalars['Boolean']['output']>
    horizonOfPlanningInDays: Scalars['Int']['output']
    horizonOfPlanningTimeAmount?: Maybe<Scalars['Int']['output']>
    horizonOfPlanningTimeUnit?: Maybe<HorizonOfPlanningTimeUnit>
    id: Scalars['ID']['output']
    maxDaysInOffice?: Maybe<Scalars['Int']['output']>
    minDaysInOffice?: Maybe<Scalars['Int']['output']>
    minDaysInOfficeTimeUnit?: Maybe<MinDaysInOfficeTimeUnit>
    officeDays?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type DepartmentWeekAggregation = {
    __typename?: 'DepartmentWeekAggregation'
    Company?: Maybe<Company>
    Department?: Maybe<Department>
    averageDaysInOffice?: Maybe<Scalars['Float']['output']>
    bookedHours?: Maybe<Scalars['Int']['output']>
    bookingsDistribution: Array<DashboardWeekAggregationBookingsDistribution>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dayAggregations?: Maybe<ModelDepartmentDayAggregationConnection>
    departmentID: Scalars['ID']['output']
    departmentWeekAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    employeesInOffice?: Maybe<Scalars['Int']['output']>
    employeesLast: Scalars['Int']['output']
    homeBookingsSum: Scalars['Int']['output']
    id: Scalars['ID']['output']
    notAvailableBookingsSum: Scalars['Int']['output']
    officeBookingsSum: Scalars['Int']['output']
    teamSize?: Maybe<Scalars['Int']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type DepartmentWeekAggregationDayAggregationsArgs = {
    filter?: InputMaybe<ModelDepartmentDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberDate?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type Discount = {
    __typename?: 'Discount'
    type: DiscountType
    value: Scalars['Float']['output']
}

export enum DiscountType {
    FLAT = 'FLAT',
    PERCENTAGE = 'PERCENTAGE',
}

export type Employee = {
    __typename?: 'Employee'
    Bookings?: Maybe<ModelBookingConnection>
    BookingsByCreatedAt?: Maybe<ModelBookingConnection>
    BookingsByStartTime?: Maybe<ModelBookingConnection>
    BookingsByWeek?: Maybe<ModelBookingConnection>
    CalendarEventsByWeek?: Maybe<ModelCalendarEventEmployeeConnection>
    Company?: Maybe<Company>
    DemoAccount?: Maybe<DemoAccount>
    EmployeeToDepartment?: Maybe<ModelEmployeeToDepartmentConnection>
    FavouriteOffice?: Maybe<Office>
    Office?: Maybe<Office>
    Position?: Maybe<Position>
    Reservable?: Maybe<Reservable>
    ReservableToEmployees?: Maybe<ModelReservableToEmployeeConnection>
    Reservables?: Maybe<ModelReservableConnection>
    Role?: Maybe<CustomRole>
    SlackOnboardingMessages?: Maybe<ModelSlackOnboradingMessageConnection>
    SuggestedSeats?: Maybe<ModelSuggestedSeatConnection>
    active?: Maybe<Scalars['Boolean']['output']>
    birthday?: Maybe<Scalars['AWSDate']['output']>
    buddy?: Maybe<Employee>
    buddyID?: Maybe<Scalars['ID']['output']>
    cognitoUserId?: Maybe<Scalars['ID']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    defaultPlan?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    demoAccountID?: Maybe<Scalars['ID']['output']>
    departmentDayAggregations?: Maybe<ModelDepartmentDayAggregationEmployeesConnection>
    departmentID?: Maybe<Scalars['ID']['output']>
    departmentIDs?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
    departmentIDsString?: Maybe<Scalars['String']['output']>
    departmentsString?: Maybe<Scalars['String']['output']>
    email?: Maybe<Scalars['String']['output']>
    emailForSearch?: Maybe<Scalars['String']['output']>
    employeeCompanyId?: Maybe<Scalars['ID']['output']>
    employeeDemoAccountId?: Maybe<Scalars['ID']['output']>
    employeeFavouriteOfficeId?: Maybe<Scalars['ID']['output']>
    employeeOfficeId?: Maybe<Scalars['ID']['output']>
    employeePositionId?: Maybe<Scalars['ID']['output']>
    externalID?: Maybe<Scalars['String']['output']>
    favouriteColleagueIDs?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
    favouriteOfficeID?: Maybe<Scalars['ID']['output']>
    favouriteReservableID?: Maybe<Scalars['ID']['output']>
    firstname?: Maybe<Scalars['String']['output']>
    fullName?: Maybe<Scalars['String']['output']>
    fullNameLowerCase?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    isSeatingInOffice?: Maybe<Scalars['Boolean']['output']>
    lastname?: Maybe<Scalars['String']['output']>
    locale?: Maybe<UserLocale>
    notificationChannels?: Maybe<Array<Maybe<NotificationChannel>>>
    officeID?: Maybe<Scalars['ID']['output']>
    photo?: Maybe<Scalars['String']['output']>
    positionID?: Maybe<Scalars['ID']['output']>
    preferredScheduleID?: Maybe<Scalars['ID']['output']>
    role?: Maybe<Role>
    roleID?: Maybe<Scalars['ID']['output']>
    roleString?: Maybe<Scalars['String']['output']>
    shareAttendance?: Maybe<ShareAttendance>
    source?: Maybe<SyncSource>
    status?: Maybe<EmployeeStatus>
    statusString?: Maybe<Scalars['String']['output']>
    turnOnExperimentalFeatures?: Maybe<Scalars['Boolean']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
}

export type EmployeeBookingsArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EmployeeBookingsByCreatedAtArgs = {
    createdAt?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EmployeeBookingsByStartTimeArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type EmployeeBookingsByWeekArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearIsoWeekNumber?: InputMaybe<ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyConditionInput>
}

export type EmployeeCalendarEventsByWeekArgs = {
    filter?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearIsoWeekNumber?: InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyConditionInput>
}

export type EmployeeEmployeeToDepartmentArgs = {
    departmentID?: InputMaybe<ModelIdKeyConditionInput>
    filter?: InputMaybe<ModelEmployeeToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EmployeeReservableToEmployeesArgs = {
    filter?: InputMaybe<ModelReservableToEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EmployeeReservablesArgs = {
    filter?: InputMaybe<ModelReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EmployeeSlackOnboardingMessagesArgs = {
    filter?: InputMaybe<ModelSlackOnboradingMessageFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EmployeeSuggestedSeatsArgs = {
    date?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelSuggestedSeatFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EmployeeDepartmentDayAggregationsArgs = {
    filter?: InputMaybe<ModelDepartmentDayAggregationEmployeesFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EmployeeIsSeatingInOfficeArgs = {
    date?: InputMaybe<Scalars['AWSDate']['input']>
}

export type EmployeeAggregationAttendanceByHoursDistribution = {
    __typename?: 'EmployeeAggregationAttendanceByHoursDistribution'
    employeesInOffice: Scalars['Int']['output']
    hour: Scalars['Int']['output']
}

export type EmployeeAggregationAttendanceByHoursDistributionInput = {
    employeesInOffice: Scalars['Int']['input']
    hour: Scalars['Int']['input']
}

export type EmployeeDayAggregation = {
    __typename?: 'EmployeeDayAggregation'
    Company?: Maybe<Company>
    bookedHours?: Maybe<Scalars['Int']['output']>
    bookingsByHoursDistribution: Array<EmployeeAggregationAttendanceByHoursDistribution>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date: Scalars['AWSDate']['output']
    employeeDayAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    employeesLast: Scalars['Int']['output']
    homeBookingsSum?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    notAvailableBookingsSum?: Maybe<Scalars['Int']['output']>
    officeBookingsSum?: Maybe<Scalars['Int']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type EmployeeLastMonthAttendance = {
    __typename?: 'EmployeeLastMonthAttendance'
    Company?: Maybe<Company>
    Department?: Maybe<Department>
    Employee?: Maybe<Employee>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    departmentID?: Maybe<Scalars['ID']['output']>
    departmentIDs?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
    departmentIDsString?: Maybe<Scalars['String']['output']>
    departmentNamesString?: Maybe<Scalars['String']['output']>
    employeeID: Scalars['ID']['output']
    employeeLastMonthAttendanceCompanyId?: Maybe<Scalars['ID']['output']>
    employeeLastMonthAttendanceDepartmentId?: Maybe<Scalars['ID']['output']>
    employeeLastMonthAttendanceEmployeeId?: Maybe<Scalars['ID']['output']>
    employeeNameLowerCase: Scalars['String']['output']
    id: Scalars['ID']['output']
    lastDateInOffice?: Maybe<Scalars['AWSDate']['output']>
    lastDateInOfficeForSorting: Scalars['AWSDate']['output']
    monthlyAttendance?: Maybe<Scalars['Float']['output']>
    monthlyAttendanceString?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
    weeklyAttendance: Scalars['Float']['output']
    weeklyAttendanceString: Scalars['String']['output']
}

export type EmployeeMutationResult = {
    __typename?: 'EmployeeMutationResult'
    employee?: Maybe<Employee>
    error?: Maybe<Scalars['String']['output']>
}

export enum EmployeeStatus {
    ADDED = 'ADDED',
    INVITED = 'INVITED',
    REGISTRED = 'REGISTRED',
}

export type EmployeeToDepartment = {
    __typename?: 'EmployeeToDepartment'
    Company: Company
    Department?: Maybe<Department>
    Employee?: Maybe<Employee>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    departmentID: Scalars['ID']['output']
    employeeID: Scalars['ID']['output']
    employeeToDepartmentCompanyId: Scalars['ID']['output']
    id: Scalars['ID']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export type EmployeeWeekAggregation = {
    __typename?: 'EmployeeWeekAggregation'
    Company?: Maybe<Company>
    averageDaysInOffice?: Maybe<Scalars['Float']['output']>
    bookedHours?: Maybe<Scalars['Int']['output']>
    bookingsByHoursDistribution: Array<EmployeeAggregationAttendanceByHoursDistribution>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dayAggregations?: Maybe<ModelEmployeeDayAggregationConnection>
    employeeWeekAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    employeesInOffice?: Maybe<Scalars['Int']['output']>
    employeesLast?: Maybe<Scalars['Int']['output']>
    homeBookingsSum?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    notAvailableBookingsSum?: Maybe<Scalars['Int']['output']>
    officeBookingsSum?: Maybe<Scalars['Int']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type EmployeeWeekAggregationDayAggregationsArgs = {
    filter?: InputMaybe<ModelEmployeeDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberDate?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type EmployeesWithDepartments = {
    departmentIDs?: InputMaybe<Array<Scalars['ID']['input']>>
    id: Scalars['ID']['input']
}

export type Equipment = {
    __typename?: 'Equipment'
    Bookings?: Maybe<ModelBookingEquipmentConnection>
    Company: Company
    EquipmentCategory?: Maybe<EquipmentCategory>
    EquipmentImages?: Maybe<ModelEquipmentImageConnection>
    Office?: Maybe<Office>
    canBookedSeparately?: Maybe<Scalars['Boolean']['output']>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    description?: Maybe<Scalars['String']['output']>
    email?: Maybe<Scalars['String']['output']>
    enabled?: Maybe<Scalars['Boolean']['output']>
    equipmentCategoryID?: Maybe<Scalars['ID']['output']>
    equipmentCompanyId: Scalars['ID']['output']
    equipmentEquipmentCategoryId?: Maybe<Scalars['ID']['output']>
    id: Scalars['ID']['output']
    name?: Maybe<Scalars['String']['output']>
    officeID?: Maybe<Scalars['ID']['output']>
    quantity?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type EquipmentBookingsArgs = {
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type EquipmentEquipmentImagesArgs = {
    filter?: InputMaybe<ModelEquipmentImageFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EquipmentCategory = {
    __typename?: 'EquipmentCategory'
    Company?: Maybe<Company>
    Equipments?: Maybe<ModelEquipmentConnection>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    equipmentCategoryCompanyId?: Maybe<Scalars['ID']['output']>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export type EquipmentCategoryEquipmentsArgs = {
    filter?: InputMaybe<ModelEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<ModelStringKeyConditionInput>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type EquipmentImage = {
    __typename?: 'EquipmentImage'
    Company?: Maybe<Company>
    Equipment?: Maybe<Equipment>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    equipmentID: Scalars['ID']['output']
    equipmentImageCompanyId?: Maybe<Scalars['ID']['output']>
    id: Scalars['ID']['output']
    isMain?: Maybe<Scalars['Boolean']['output']>
    location?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type EquipmentItemModel = {
    __typename?: 'EquipmentItemModel'
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    quantity?: Maybe<Scalars['Int']['output']>
    title?: Maybe<Scalars['String']['output']>
}

export type EquipmentItemModelInput = {
    description?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    quantity?: InputMaybe<Scalars['Int']['input']>
    title?: InputMaybe<Scalars['String']['input']>
}

export type GenericIntegration = {
    __typename?: 'GenericIntegration'
    group: IntegrationGroup
    status: IntegrationStatus
    type: IntegrationType
}

export type GetCalendarIntegration = {
    __typename?: 'GetCalendarIntegration'
    clientId: Scalars['String']['output']
    companyId?: Maybe<Scalars['String']['output']>
    health?: Maybe<Scalars['String']['output']>
    scopes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    serviceEmail?: Maybe<Scalars['String']['output']>
    type: CalendarType
}

export type GetGoogleCalendarIntegration = {
    __typename?: 'GetGoogleCalendarIntegration'
    authType?: Maybe<Scalars['String']['output']>
    clientId: Scalars['String']['output']
    companyId?: Maybe<Scalars['String']['output']>
    enableWorkingLocation?: Maybe<Scalars['Boolean']['output']>
    scopes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    serviceEmail?: Maybe<Scalars['String']['output']>
    type: CalendarType
}

export type GetMicrosoftCalendarIntegration = {
    __typename?: 'GetMicrosoftCalendarIntegration'
    companyId?: Maybe<Scalars['String']['output']>
    enableAbsenceSyncing?: Maybe<Scalars['Boolean']['output']>
    enableStatusSyncing?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    type: CalendarType
}

export type GetRoomMappingsRequest = {
    tenantId?: InputMaybe<Scalars['String']['input']>
    type: CalendarType
}

export type GetRoomMappingsResponse = {
    __typename?: 'GetRoomMappingsResponse'
    reservableRoomId?: Maybe<Scalars['String']['output']>
    roomEmail?: Maybe<Scalars['String']['output']>
    roomId?: Maybe<Scalars['String']['output']>
    roomName?: Maybe<Scalars['String']['output']>
}

export type GoogleImportedBookings = {
    __typename?: 'GoogleImportedBookings'
    CalendarEvent?: Maybe<CalendarEvents>
    calendarEventID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    googleID: Scalars['String']['output']
    id: Scalars['ID']['output']
    updated?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
}

export type GoogleImportedWorkingLocations = {
    __typename?: 'GoogleImportedWorkingLocations'
    Booking?: Maybe<Booking>
    bookingID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    googleID: Scalars['String']['output']
    id: Scalars['ID']['output']
    updated?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
}

export type GoogleRoom = {
    __typename?: 'GoogleRoom'
    resourceEmail: Scalars['String']['output']
    resourceId: Scalars['String']['output']
    resourceName: Scalars['String']['output']
}

export enum HorizonOfPlanningTimeUnit {
    DAY = 'DAY',
    MONTH = 'MONTH',
    WEEK = 'WEEK',
}

export type ImageResizeInput = {
    fit: ResizeFit
    targetHeight: Scalars['Int']['input']
    targetWidth: Scalars['Int']['input']
}

export type Integration = {
    __typename?: 'Integration'
    Company?: Maybe<Company>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    group: IntegrationGroup
    id: Scalars['ID']['output']
    integrationCompanyId?: Maybe<Scalars['ID']['output']>
    order: Scalars['Int']['output']
    status: IntegrationStatus
    type: IntegrationType
    updatedAt: Scalars['AWSDateTime']['output']
}

export enum IntegrationGroup {
    NOTIFICATIONS = 'NOTIFICATIONS',
    SSO = 'SSO',
    USERS_SYNC = 'USERS_SYNC',
}

export enum IntegrationStatus {
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
    ERROR = 'ERROR',
    SOON = 'SOON',
}

export enum IntegrationType {
    NOTIFICATIONS_GOOGLE_CALENDAR = 'NOTIFICATIONS_GOOGLE_CALENDAR',
    NOTIFICATIONS_MICROSOFT_CALENDAR = 'NOTIFICATIONS_MICROSOFT_CALENDAR',
    NOTIFICATIONS_MICROSOFT_TEAMS = 'NOTIFICATIONS_MICROSOFT_TEAMS',
    NOTIFICATIONS_SLACK = 'NOTIFICATIONS_SLACK',
    SSO_BAMBOO_HR = 'SSO_BAMBOO_HR',
    SSO_GOOGLE = 'SSO_GOOGLE',
    SSO_MICROSOFT_AD = 'SSO_MICROSOFT_AD',
    SSO_OIDC_SAML = 'SSO_OIDC_SAML',
    SSO_OKTA = 'SSO_OKTA',
    SSO_ONELOGIN = 'SSO_ONELOGIN',
    USERS_SYNC_BAMBOO_HR = 'USERS_SYNC_BAMBOO_HR',
    USERS_SYNC_GOOGLE = 'USERS_SYNC_GOOGLE',
    USERS_SYNC_HI_BOB = 'USERS_SYNC_HI_BOB',
    USERS_SYNC_MICROSOFT_AD = 'USERS_SYNC_MICROSOFT_AD',
    USERS_SYNC_PERSONIO = 'USERS_SYNC_PERSONIO',
    USERS_SYNC_SCIM = 'USERS_SYNC_SCIM',
    USERS_SYNC_WORKDAY = 'USERS_SYNC_WORKDAY',
}

export type MsCalendarIntegration = {
    __typename?: 'MSCalendarIntegration'
    companyId?: Maybe<Scalars['String']['output']>
    enableAbsenceSyncing?: Maybe<Scalars['Boolean']['output']>
    enableStatusSyncing?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
}

export type ManagedEmployeeCount = {
    __typename?: 'ManagedEmployeeCount'
    count: Scalars['Int']['output']
}

export type MapReservableToExternalRoomRequest = {
    integrationId: Scalars['String']['input']
    reservableRoomId: Scalars['String']['input']
    roomEmail: Scalars['String']['input']
    roomId: Scalars['String']['input']
    roomName: Scalars['String']['input']
    type?: InputMaybe<CalendarType>
}

export type MapReservableToGoogleRoomRequest = {
    integrationId: Scalars['String']['input']
    reservableRoomId: Scalars['String']['input']
    roomEmail: Scalars['String']['input']
    roomId: Scalars['String']['input']
    roomName: Scalars['String']['input']
    type?: InputMaybe<CalendarType>
}

export type MenuItem = {
    __typename?: 'MenuItem'
    Catering?: Maybe<Catering>
    Company?: Maybe<Company>
    cateringID: Scalars['ID']['output']
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    description?: Maybe<Scalars['String']['output']>
    enabled: Scalars['Boolean']['output']
    id: Scalars['ID']['output']
    menuItemCompanyId?: Maybe<Scalars['ID']['output']>
    title: Scalars['String']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export type MenuItemModel = {
    __typename?: 'MenuItemModel'
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    quantity?: Maybe<Scalars['Int']['output']>
    title?: Maybe<Scalars['String']['output']>
}

export type MenuItemModelInput = {
    description?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    quantity?: InputMaybe<Scalars['Int']['input']>
    title?: InputMaybe<Scalars['String']['input']>
}

export type MicrosoftSubscription = {
    __typename?: 'MicrosoftSubscription'
    Company?: Maybe<Company>
    OwnerEmployee?: Maybe<Employee>
    companyID?: Maybe<Scalars['ID']['output']>
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    id: Scalars['ID']['output']
    isFreeTrial?: Maybe<Scalars['Boolean']['output']>
    microsoftSubscriptionCompanyId?: Maybe<Scalars['ID']['output']>
    name?: Maybe<Scalars['String']['output']>
    offerId?: Maybe<Scalars['String']['output']>
    ownerEmail?: Maybe<Scalars['String']['output']>
    ownerID?: Maybe<Scalars['ID']['output']>
    ownerName?: Maybe<Scalars['String']['output']>
    planId?: Maybe<Scalars['String']['output']>
    quantity?: Maybe<Scalars['Int']['output']>
    status?: Maybe<MicrosoftSubscriptionStatus>
    tenantId: Scalars['String']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export enum MicrosoftSubscriptionStatus {
    PENDING = 'PENDING',
    SUBSCRIBED = 'SUBSCRIBED',
    SUSPENDED = 'SUSPENDED',
    UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export enum MinDaysInOfficeTimeUnit {
    MONTH = 'MONTH',
    WEEK = 'WEEK',
}

export type ModelActionTypeInput = {
    eq?: InputMaybe<ActionType>
    ne?: InputMaybe<ActionType>
}

export type ModelAddressConditionInput = {
    address?: InputMaybe<ModelStringInput>
    addressCompanyId?: InputMaybe<ModelIdInput>
    and?: InputMaybe<Array<InputMaybe<ModelAddressConditionInput>>>
    city?: InputMaybe<ModelStringInput>
    companyID?: InputMaybe<ModelIdInput>
    country?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    lat?: InputMaybe<ModelFloatInput>
    long?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelAddressConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelAddressConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
    zipcode?: InputMaybe<ModelStringInput>
}

export type ModelAddressConnection = {
    __typename?: 'ModelAddressConnection'
    items: Array<Maybe<Address>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelAddressFilterInput = {
    address?: InputMaybe<ModelStringInput>
    addressCompanyId?: InputMaybe<ModelIdInput>
    and?: InputMaybe<Array<InputMaybe<ModelAddressFilterInput>>>
    city?: InputMaybe<ModelStringInput>
    companyID?: InputMaybe<ModelIdInput>
    country?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    lat?: InputMaybe<ModelFloatInput>
    long?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelAddressFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelAddressFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
    zipcode?: InputMaybe<ModelStringInput>
}

export type ModelAggregationByReservableTypeInput = {
    eq?: InputMaybe<AggregationByReservableType>
    ne?: InputMaybe<AggregationByReservableType>
}

export enum ModelAttributeTypes {
    _null = '_null',
    binary = 'binary',
    binarySet = 'binarySet',
    bool = 'bool',
    list = 'list',
    map = 'map',
    number = 'number',
    numberSet = 'numberSet',
    string = 'string',
    stringSet = 'stringSet',
}

export type ModelAvailabilityItemPreviewElementListInput = {
    contains?: InputMaybe<AvailabilityItemPreviewElement>
    eq?: InputMaybe<Array<InputMaybe<AvailabilityItemPreviewElement>>>
    ne?: InputMaybe<Array<InputMaybe<AvailabilityItemPreviewElement>>>
    notContains?: InputMaybe<AvailabilityItemPreviewElement>
}

export type ModelAvailabilityTypeInput = {
    eq?: InputMaybe<AvailabilityType>
    ne?: InputMaybe<AvailabilityType>
}

export type ModelBookingByCompanyStartTimeAndStatusCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelBookingByCompanyStartTimeAndStatusCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelBookingByCompanyStartTimeAndStatusCompositeKeyInput>>>
    eq?: InputMaybe<ModelBookingByCompanyStartTimeAndStatusCompositeKeyInput>
    ge?: InputMaybe<ModelBookingByCompanyStartTimeAndStatusCompositeKeyInput>
    gt?: InputMaybe<ModelBookingByCompanyStartTimeAndStatusCompositeKeyInput>
    le?: InputMaybe<ModelBookingByCompanyStartTimeAndStatusCompositeKeyInput>
    lt?: InputMaybe<ModelBookingByCompanyStartTimeAndStatusCompositeKeyInput>
}

export type ModelBookingByCompanyStartTimeAndStatusCompositeKeyInput = {
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<BookingStatus>
}

export type ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
}

export type ModelBookingByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput = {
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyInput>>>
    eq?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyInput>
    ge?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyInput>
    gt?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyInput>
    le?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyInput>
    lt?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyInput>
}

export type ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    dayOfWeek?: InputMaybe<Scalars['String']['input']>
}

export type ModelBookingByReservableAndStartTimeAndStatusCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelBookingByReservableAndStartTimeAndStatusCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelBookingByReservableAndStartTimeAndStatusCompositeKeyInput>>>
    eq?: InputMaybe<ModelBookingByReservableAndStartTimeAndStatusCompositeKeyInput>
    ge?: InputMaybe<ModelBookingByReservableAndStartTimeAndStatusCompositeKeyInput>
    gt?: InputMaybe<ModelBookingByReservableAndStartTimeAndStatusCompositeKeyInput>
    le?: InputMaybe<ModelBookingByReservableAndStartTimeAndStatusCompositeKeyInput>
    lt?: InputMaybe<ModelBookingByReservableAndStartTimeAndStatusCompositeKeyInput>
}

export type ModelBookingByReservableAndStartTimeAndStatusCompositeKeyInput = {
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<BookingStatus>
}

export type ModelBookingCancellationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelBookingCancellationConditionInput>>>
    bookingCancellationBookingId?: InputMaybe<ModelIdInput>
    bookingCancellationCompanyId?: InputMaybe<ModelIdInput>
    bookingCancellationEmployeeId?: InputMaybe<ModelIdInput>
    bookingID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelBookingCancellationConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingCancellationConditionInput>>>
    owner?: InputMaybe<ModelIdInput>
    removeRepeatable?: InputMaybe<ModelBooleanInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelBookingCancellationConnection = {
    __typename?: 'ModelBookingCancellationConnection'
    items: Array<Maybe<BookingCancellation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelBookingCancellationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelBookingCancellationFilterInput>>>
    bookingCancellationBookingId?: InputMaybe<ModelIdInput>
    bookingCancellationCompanyId?: InputMaybe<ModelIdInput>
    bookingCancellationEmployeeId?: InputMaybe<ModelIdInput>
    bookingID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelBookingCancellationFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingCancellationFilterInput>>>
    owner?: InputMaybe<ModelIdInput>
    removeRepeatable?: InputMaybe<ModelBooleanInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelBookingCheckInStatusInput = {
    eq?: InputMaybe<BookingCheckInStatus>
    ne?: InputMaybe<BookingCheckInStatus>
}

export type ModelBookingConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelBookingConditionInput>>>
    bookingBookingRequestId?: InputMaybe<ModelIdInput>
    bookingCompanyId?: InputMaybe<ModelIdInput>
    bookingEmployeeId?: InputMaybe<ModelIdInput>
    bookingRequestID?: InputMaybe<ModelIdInput>
    bookingReservableId?: InputMaybe<ModelIdInput>
    bookingSpaceId?: InputMaybe<ModelIdInput>
    bookingSubType?: InputMaybe<ModelBookingSubTypeInput>
    bookingTeamEventRequestId?: InputMaybe<ModelIdInput>
    bookingType?: InputMaybe<ModelBookingTypeInput>
    checkInStatus?: InputMaybe<ModelBookingCheckInStatusInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    dayOfWeek?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    endBookingTime?: InputMaybe<ModelStringInput>
    endTime?: InputMaybe<ModelStringInput>
    isBlocked?: InputMaybe<ModelBooleanInput>
    isFullDay?: InputMaybe<ModelBooleanInput>
    isTeamEvent?: InputMaybe<ModelBooleanInput>
    isoWeekNumber?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelBookingConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingConditionInput>>>
    owner?: InputMaybe<ModelIdInput>
    reservableID?: InputMaybe<ModelIdInput>
    slackCheckInMessageId?: InputMaybe<ModelStringInput>
    source?: InputMaybe<ModelBookingSourceInput>
    spaceID?: InputMaybe<ModelIdInput>
    startBookingTime?: InputMaybe<ModelStringInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelBookingStatusInput>
    teamEventRequestID?: InputMaybe<ModelIdInput>
    title?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelBookingConnection = {
    __typename?: 'ModelBookingConnection'
    items: Array<Maybe<Booking>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyInput>
}

export type ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyInput = {
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelBookingEquipmentConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelBookingEquipmentConditionInput>>>
    calendarEventID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    equipmentID?: InputMaybe<ModelIdInput>
    isAllDay?: InputMaybe<ModelBooleanInput>
    isPrivate?: InputMaybe<ModelBooleanInput>
    isoWeekNumber?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelBookingEquipmentConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingEquipmentConditionInput>>>
    owner?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelBookingEquipmentStatusInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelBookingEquipmentConnection = {
    __typename?: 'ModelBookingEquipmentConnection'
    items: Array<Maybe<BookingEquipment>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelBookingEquipmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelBookingEquipmentFilterInput>>>
    calendarEventID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    equipmentID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    isAllDay?: InputMaybe<ModelBooleanInput>
    isPrivate?: InputMaybe<ModelBooleanInput>
    isoWeekNumber?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelBookingEquipmentFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingEquipmentFilterInput>>>
    owner?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelBookingEquipmentStatusInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelBookingEquipmentStatusInput = {
    eq?: InputMaybe<BookingEquipmentStatus>
    ne?: InputMaybe<BookingEquipmentStatus>
}

export type ModelBookingFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelBookingFilterInput>>>
    bookingBookingRequestId?: InputMaybe<ModelIdInput>
    bookingCompanyId?: InputMaybe<ModelIdInput>
    bookingEmployeeId?: InputMaybe<ModelIdInput>
    bookingRequestID?: InputMaybe<ModelIdInput>
    bookingReservableId?: InputMaybe<ModelIdInput>
    bookingSpaceId?: InputMaybe<ModelIdInput>
    bookingSubType?: InputMaybe<ModelBookingSubTypeInput>
    bookingTeamEventRequestId?: InputMaybe<ModelIdInput>
    bookingType?: InputMaybe<ModelBookingTypeInput>
    checkInStatus?: InputMaybe<ModelBookingCheckInStatusInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    dayOfWeek?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    endBookingTime?: InputMaybe<ModelStringInput>
    endTime?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    isBlocked?: InputMaybe<ModelBooleanInput>
    isFullDay?: InputMaybe<ModelBooleanInput>
    isTeamEvent?: InputMaybe<ModelBooleanInput>
    isoWeekNumber?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelBookingFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingFilterInput>>>
    owner?: InputMaybe<ModelIdInput>
    reservableID?: InputMaybe<ModelIdInput>
    slackCheckInMessageId?: InputMaybe<ModelStringInput>
    source?: InputMaybe<ModelBookingSourceInput>
    spaceID?: InputMaybe<ModelIdInput>
    startBookingTime?: InputMaybe<ModelStringInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelBookingStatusInput>
    teamEventRequestID?: InputMaybe<ModelIdInput>
    title?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelBookingRequestConditionInput = {
    addParkingSlot?: InputMaybe<ModelBooleanInput>
    and?: InputMaybe<Array<InputMaybe<ModelBookingRequestConditionInput>>>
    bookingRequestCompanyId?: InputMaybe<ModelIdInput>
    bookingRequestEmployeeId?: InputMaybe<ModelIdInput>
    bookingRequestPreviousBookingRequestId?: InputMaybe<ModelIdInput>
    bookingRequestReservableId?: InputMaybe<ModelIdInput>
    bookingRequestSpaceId?: InputMaybe<ModelIdInput>
    bookingSubType?: InputMaybe<ModelBookingSubTypeInput>
    bookingType?: InputMaybe<ModelBookingTypeInput>
    checkInStatus?: InputMaybe<ModelBookingCheckInStatusInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    dates?: InputMaybe<ModelStringInput>
    daysOfWeek?: InputMaybe<ModelStringInput>
    effectiveFrom?: InputMaybe<ModelStringInput>
    effectiveTo?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    isFullDay?: InputMaybe<ModelBooleanInput>
    isTeamEvent?: InputMaybe<ModelBooleanInput>
    not?: InputMaybe<ModelBookingRequestConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingRequestConditionInput>>>
    owner?: InputMaybe<ModelIdInput>
    parkingSlotID?: InputMaybe<ModelIdInput>
    previousBookingRequestID?: InputMaybe<ModelIdInput>
    repeatType?: InputMaybe<ModelRepeatTypeInput>
    reservableID?: InputMaybe<ModelIdInput>
    source?: InputMaybe<ModelBookingSourceInput>
    spaceID?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelBookingRequestStatusInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelBookingRequestConnection = {
    __typename?: 'ModelBookingRequestConnection'
    items: Array<Maybe<BookingRequest>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelBookingRequestFilterInput = {
    addParkingSlot?: InputMaybe<ModelBooleanInput>
    and?: InputMaybe<Array<InputMaybe<ModelBookingRequestFilterInput>>>
    bookingRequestCompanyId?: InputMaybe<ModelIdInput>
    bookingRequestEmployeeId?: InputMaybe<ModelIdInput>
    bookingRequestPreviousBookingRequestId?: InputMaybe<ModelIdInput>
    bookingRequestReservableId?: InputMaybe<ModelIdInput>
    bookingRequestSpaceId?: InputMaybe<ModelIdInput>
    bookingSubType?: InputMaybe<ModelBookingSubTypeInput>
    bookingType?: InputMaybe<ModelBookingTypeInput>
    checkInStatus?: InputMaybe<ModelBookingCheckInStatusInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    dates?: InputMaybe<ModelStringInput>
    daysOfWeek?: InputMaybe<ModelStringInput>
    effectiveFrom?: InputMaybe<ModelStringInput>
    effectiveTo?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    isFullDay?: InputMaybe<ModelBooleanInput>
    isTeamEvent?: InputMaybe<ModelBooleanInput>
    not?: InputMaybe<ModelBookingRequestFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingRequestFilterInput>>>
    owner?: InputMaybe<ModelIdInput>
    parkingSlotID?: InputMaybe<ModelIdInput>
    previousBookingRequestID?: InputMaybe<ModelIdInput>
    repeatType?: InputMaybe<ModelRepeatTypeInput>
    reservableID?: InputMaybe<ModelIdInput>
    source?: InputMaybe<ModelBookingSourceInput>
    spaceID?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelBookingRequestStatusInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelBookingRequestStatusInput = {
    eq?: InputMaybe<BookingRequestStatus>
    ne?: InputMaybe<BookingRequestStatus>
}

export type ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyInput>>>
    eq?: InputMaybe<ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyInput>
    ge?: InputMaybe<ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyInput>
    gt?: InputMaybe<ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyInput>
    le?: InputMaybe<ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyInput>
    lt?: InputMaybe<ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyInput>
}

export type ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyInput = {
    date?: InputMaybe<Scalars['AWSDate']['input']>
    dayOfWeek?: InputMaybe<Scalars['String']['input']>
}

export type ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyInput>>>
    eq?: InputMaybe<ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyInput>
    ge?: InputMaybe<ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyInput>
    gt?: InputMaybe<ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyInput>
    le?: InputMaybe<ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyInput>
    lt?: InputMaybe<ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyInput>
}

export type ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyInput = {
    endTime?: InputMaybe<Scalars['String']['input']>
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type ModelBookingReservableConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelBookingReservableConditionInput>>>
    bookingID?: InputMaybe<ModelIdInput>
    bookingReservableCompanyId?: InputMaybe<ModelIdInput>
    checkInStatus?: InputMaybe<ModelBookingCheckInStatusInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    dayOfWeek?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    endBookingTime?: InputMaybe<ModelStringInput>
    endTime?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelBookingReservableConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingReservableConditionInput>>>
    owner?: InputMaybe<ModelStringInput>
    reservableID?: InputMaybe<ModelIdInput>
    startBookingTime?: InputMaybe<ModelStringInput>
    startTime?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelBookingReservableConnection = {
    __typename?: 'ModelBookingReservableConnection'
    items: Array<Maybe<BookingReservable>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelBookingReservableFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelBookingReservableFilterInput>>>
    bookingID?: InputMaybe<ModelIdInput>
    bookingReservableCompanyId?: InputMaybe<ModelIdInput>
    checkInStatus?: InputMaybe<ModelBookingCheckInStatusInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    dayOfWeek?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    endBookingTime?: InputMaybe<ModelStringInput>
    endTime?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelBookingReservableFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelBookingReservableFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    reservableID?: InputMaybe<ModelIdInput>
    startBookingTime?: InputMaybe<ModelStringInput>
    startTime?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelBookingSourceInput = {
    eq?: InputMaybe<BookingSource>
    ne?: InputMaybe<BookingSource>
}

export type ModelBookingStatusInput = {
    eq?: InputMaybe<BookingStatus>
    ne?: InputMaybe<BookingStatus>
}

export type ModelBookingSubTypeInput = {
    eq?: InputMaybe<BookingSubType>
    ne?: InputMaybe<BookingSubType>
}

export type ModelBookingTypeInput = {
    eq?: InputMaybe<BookingType>
    ne?: InputMaybe<BookingType>
}

export type ModelBooleanInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']['input']>
    attributeType?: InputMaybe<ModelAttributeTypes>
    eq?: InputMaybe<Scalars['Boolean']['input']>
    ne?: InputMaybe<Scalars['Boolean']['input']>
}

export type ModelCalendarEventCheckInStatusInput = {
    eq?: InputMaybe<CalendarEventCheckInStatus>
    ne?: InputMaybe<CalendarEventCheckInStatus>
}

export type ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput>
}

export type ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyInput = {
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelCalendarEventEmployeeConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCalendarEventEmployeeConditionInput>>>
    calendarEventID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeFullName?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    isOwner?: InputMaybe<ModelBooleanInput>
    isoWeekNumber?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelCalendarEventEmployeeConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelCalendarEventEmployeeConditionInput>>>
    owner?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelCalendarEventEmployeeConnection = {
    __typename?: 'ModelCalendarEventEmployeeConnection'
    items: Array<Maybe<CalendarEventEmployee>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelCalendarEventEmployeeFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCalendarEventEmployeeFilterInput>>>
    calendarEventID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeFullName?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    isOwner?: InputMaybe<ModelBooleanInput>
    isoWeekNumber?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelCalendarEventEmployeeFilterInput>>>
    owner?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelCalendarEventRequestConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCalendarEventRequestConditionInput>>>
    calendarEventRequestCompanyId?: InputMaybe<ModelIdInput>
    calendarEventRequestPreviousCalendarEventRequestId?: InputMaybe<ModelIdInput>
    calendarEventRequestReservableId?: InputMaybe<ModelIdInput>
    calendarEventRequestSpaceId?: InputMaybe<ModelIdInput>
    cateringID?: InputMaybe<ModelIdInput>
    checkInStatus?: InputMaybe<ModelCalendarEventCheckInStatusInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    dates?: InputMaybe<ModelStringInput>
    daysOfWeek?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    effectiveFrom?: InputMaybe<ModelStringInput>
    effectiveTo?: InputMaybe<ModelStringInput>
    employeesIDs?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    isAllDay?: InputMaybe<ModelBooleanInput>
    isOnlineMeeting?: InputMaybe<ModelBooleanInput>
    isPrivate?: InputMaybe<ModelBooleanInput>
    not?: InputMaybe<ModelCalendarEventRequestConditionInput>
    onlineMeetingUrl?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelCalendarEventRequestConditionInput>>>
    organiserEmployeeID?: InputMaybe<ModelIdInput>
    owner?: InputMaybe<ModelIdInput>
    previousCalendarEventRequestID?: InputMaybe<ModelIdInput>
    repeatType?: InputMaybe<ModelRepeatTypeInput>
    reservableID?: InputMaybe<ModelIdInput>
    source?: InputMaybe<ModelCalendarEventSourceInput>
    spaceID?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelCalendarEventRequestStatusInput>
    title?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    useRelatedReservable?: InputMaybe<ModelBooleanInput>
}

export type ModelCalendarEventRequestConnection = {
    __typename?: 'ModelCalendarEventRequestConnection'
    items: Array<Maybe<CalendarEventRequest>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelCalendarEventRequestFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCalendarEventRequestFilterInput>>>
    calendarEventRequestCompanyId?: InputMaybe<ModelIdInput>
    calendarEventRequestPreviousCalendarEventRequestId?: InputMaybe<ModelIdInput>
    calendarEventRequestReservableId?: InputMaybe<ModelIdInput>
    calendarEventRequestSpaceId?: InputMaybe<ModelIdInput>
    cateringID?: InputMaybe<ModelIdInput>
    checkInStatus?: InputMaybe<ModelCalendarEventCheckInStatusInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    dates?: InputMaybe<ModelStringInput>
    daysOfWeek?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    effectiveFrom?: InputMaybe<ModelStringInput>
    effectiveTo?: InputMaybe<ModelStringInput>
    employeesIDs?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    isAllDay?: InputMaybe<ModelBooleanInput>
    isOnlineMeeting?: InputMaybe<ModelBooleanInput>
    isPrivate?: InputMaybe<ModelBooleanInput>
    not?: InputMaybe<ModelCalendarEventRequestFilterInput>
    onlineMeetingUrl?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelCalendarEventRequestFilterInput>>>
    organiserEmployeeID?: InputMaybe<ModelIdInput>
    owner?: InputMaybe<ModelIdInput>
    previousCalendarEventRequestID?: InputMaybe<ModelIdInput>
    repeatType?: InputMaybe<ModelRepeatTypeInput>
    reservableID?: InputMaybe<ModelIdInput>
    source?: InputMaybe<ModelCalendarEventSourceInput>
    spaceID?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelCalendarEventRequestStatusInput>
    title?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    useRelatedReservable?: InputMaybe<ModelBooleanInput>
}

export type ModelCalendarEventRequestStatusInput = {
    eq?: InputMaybe<CalendarEventRequestStatus>
    ne?: InputMaybe<CalendarEventRequestStatus>
}

export type ModelCalendarEventSourceInput = {
    eq?: InputMaybe<CalendarEventSource>
    ne?: InputMaybe<CalendarEventSource>
}

export type ModelCalendarEventStatusInput = {
    eq?: InputMaybe<CalendarEventStatus>
    ne?: InputMaybe<CalendarEventStatus>
}

export type ModelCalendarEventsConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCalendarEventsConditionInput>>>
    calendarEventRequestID?: InputMaybe<ModelIdInput>
    canCurrentUserEdit?: InputMaybe<ModelBooleanInput>
    cateringID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    dayOfMonth?: InputMaybe<ModelIntInput>
    daysOfWeek?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    employeesIDs?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    firstDayOfWeek?: InputMaybe<ModelStringInput>
    interval?: InputMaybe<ModelIntInput>
    isAllDay?: InputMaybe<ModelBooleanInput>
    isOnlineMeeting?: InputMaybe<ModelBooleanInput>
    isPrivate?: InputMaybe<ModelBooleanInput>
    month?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelCalendarEventsConditionInput>
    numberOfOccurrences?: InputMaybe<ModelIntInput>
    officeID?: InputMaybe<ModelIdInput>
    onlineMeetingUrl?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelCalendarEventsConditionInput>>>
    organiserEmployeeID?: InputMaybe<ModelIdInput>
    owner?: InputMaybe<ModelIdInput>
    parentEventExternalId?: InputMaybe<ModelStringInput>
    patternType?: InputMaybe<ModelRecurrencePatternTypeInput>
    rangeEndDate?: InputMaybe<ModelStringInput>
    rangeStartDate?: InputMaybe<ModelStringInput>
    rangeType?: InputMaybe<ModelRecurrenceRangeTypeInput>
    recurrenceTimeZone?: InputMaybe<ModelStringInput>
    reservableID?: InputMaybe<ModelIdInput>
    source?: InputMaybe<ModelCalendarEventSourceInput>
    spaceID?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelCalendarEventStatusInput>
    title?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    useRelatedReservable?: InputMaybe<ModelBooleanInput>
    weekIndex?: InputMaybe<ModelStringInput>
}

export type ModelCalendarEventsConnection = {
    __typename?: 'ModelCalendarEventsConnection'
    items: Array<Maybe<CalendarEvents>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelCalendarEventsFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCalendarEventsFilterInput>>>
    calendarEventRequestID?: InputMaybe<ModelIdInput>
    canCurrentUserEdit?: InputMaybe<ModelBooleanInput>
    cateringID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    dayOfMonth?: InputMaybe<ModelIntInput>
    daysOfWeek?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    employeesIDs?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    firstDayOfWeek?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    interval?: InputMaybe<ModelIntInput>
    isAllDay?: InputMaybe<ModelBooleanInput>
    isOnlineMeeting?: InputMaybe<ModelBooleanInput>
    isPrivate?: InputMaybe<ModelBooleanInput>
    month?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelCalendarEventsFilterInput>
    numberOfOccurrences?: InputMaybe<ModelIntInput>
    officeID?: InputMaybe<ModelIdInput>
    onlineMeetingUrl?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelCalendarEventsFilterInput>>>
    organiserEmployeeID?: InputMaybe<ModelIdInput>
    owner?: InputMaybe<ModelIdInput>
    parentEventExternalId?: InputMaybe<ModelStringInput>
    patternType?: InputMaybe<ModelRecurrencePatternTypeInput>
    rangeEndDate?: InputMaybe<ModelStringInput>
    rangeStartDate?: InputMaybe<ModelStringInput>
    rangeType?: InputMaybe<ModelRecurrenceRangeTypeInput>
    recurrenceTimeZone?: InputMaybe<ModelStringInput>
    reservableID?: InputMaybe<ModelIdInput>
    source?: InputMaybe<ModelCalendarEventSourceInput>
    spaceID?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelCalendarEventStatusInput>
    title?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    useRelatedReservable?: InputMaybe<ModelBooleanInput>
    weekIndex?: InputMaybe<ModelStringInput>
}

export type ModelCalendarTypeInput = {
    eq?: InputMaybe<CalendarType>
    ne?: InputMaybe<CalendarType>
}

export type ModelCateringConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCateringConditionInput>>>
    cateringCompanyId?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    email?: InputMaybe<ModelStringInput>
    enabled?: InputMaybe<ModelBooleanInput>
    not?: InputMaybe<ModelCateringConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelCateringConditionInput>>>
    title?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelCateringConnection = {
    __typename?: 'ModelCateringConnection'
    items: Array<Maybe<Catering>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelCateringFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCateringFilterInput>>>
    cateringCompanyId?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    email?: InputMaybe<ModelStringInput>
    enabled?: InputMaybe<ModelBooleanInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelCateringFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelCateringFilterInput>>>
    title?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelCompanyConditionInput = {
    address?: InputMaybe<ModelStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelCompanyConditionInput>>>
    calendarIntegration?: InputMaybe<ModelCalendarTypeInput>
    city?: InputMaybe<ModelStringInput>
    companyLogo?: InputMaybe<ModelStringInput>
    companyRulesId?: InputMaybe<ModelIdInput>
    country?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelStringInput>
    microsoftTenantId?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelCompanyConditionInput>
    numberOfEmployees?: InputMaybe<ModelIntInput>
    numberOfOffices?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelCompanyConditionInput>>>
    phoneNumber?: InputMaybe<ModelStringInput>
    ssoIntegration?: InputMaybe<ModelSsoTypeInput>
    tariff?: InputMaybe<ModelTariffInput>
    title?: InputMaybe<ModelStringInput>
    trialExpiresAt?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
    userProvisioningIntegration?: InputMaybe<ModelSyncSourceInput>
    zipcode?: InputMaybe<ModelStringInput>
}

export type ModelCompanyConnection = {
    __typename?: 'ModelCompanyConnection'
    items: Array<Maybe<Company>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    le?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
}

export type ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelCompanyDayAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCompanyDayAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeesInOffice?: InputMaybe<ModelIntInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelCompanyDayAggregationConditionInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    officeCapacityLast?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelCompanyDayAggregationConditionInput>>>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelCompanyDayAggregationConnection = {
    __typename?: 'ModelCompanyDayAggregationConnection'
    items: Array<Maybe<CompanyDayAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelCompanyDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCompanyDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeesInOffice?: InputMaybe<ModelIntInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelCompanyDayAggregationFilterInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    officeCapacityLast?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelCompanyDayAggregationFilterInput>>>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelCompanyFilterInput = {
    address?: InputMaybe<ModelStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelCompanyFilterInput>>>
    calendarIntegration?: InputMaybe<ModelCalendarTypeInput>
    city?: InputMaybe<ModelStringInput>
    companyLogo?: InputMaybe<ModelStringInput>
    companyRulesId?: InputMaybe<ModelIdInput>
    country?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    microsoftTenantId?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelCompanyFilterInput>
    numberOfEmployees?: InputMaybe<ModelIntInput>
    numberOfOffices?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelCompanyFilterInput>>>
    phoneNumber?: InputMaybe<ModelStringInput>
    ssoIntegration?: InputMaybe<ModelSsoTypeInput>
    tariff?: InputMaybe<ModelTariffInput>
    title?: InputMaybe<ModelStringInput>
    trialExpiresAt?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
    userProvisioningIntegration?: InputMaybe<ModelSyncSourceInput>
    zipcode?: InputMaybe<ModelStringInput>
}

export type ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelCompanyWeekAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCompanyWeekAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    companyWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeesInOffice?: InputMaybe<ModelIntInput>
    employeesLast?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelCompanyWeekAggregationConditionInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    officeCapacityMax?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelCompanyWeekAggregationConditionInput>>>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelCompanyWeekAggregationConnection = {
    __typename?: 'ModelCompanyWeekAggregationConnection'
    items: Array<Maybe<CompanyWeekAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelCompanyWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCompanyWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    companyWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeesInOffice?: InputMaybe<ModelIntInput>
    employeesLast?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelCompanyWeekAggregationFilterInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    officeCapacityMax?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelCompanyWeekAggregationFilterInput>>>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelCustomRoleConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCustomRoleConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelCustomRoleConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelCustomRoleConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelCustomRoleConnection = {
    __typename?: 'ModelCustomRoleConnection'
    items: Array<Maybe<CustomRole>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelCustomRoleFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelCustomRoleFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelCustomRoleFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelCustomRoleFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelDemoAccountConnection = {
    __typename?: 'ModelDemoAccountConnection'
    items: Array<Maybe<DemoAccount>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelDemoAccountFilterInput = {
    active?: InputMaybe<ModelBooleanInput>
    and?: InputMaybe<Array<InputMaybe<ModelDemoAccountFilterInput>>>
    companyName?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    demoAccountEmployeeId?: InputMaybe<ModelIdInput>
    email?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    firstname?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    lastname?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelDemoAccountFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelDemoAccountFilterInput>>>
    phone?: InputMaybe<ModelStringInput>
    source?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelDemoAccountStatusInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelDemoAccountStatusInput = {
    eq?: InputMaybe<DemoAccountStatus>
    ne?: InputMaybe<DemoAccountStatus>
}

export type ModelDepartmentConnection = {
    __typename?: 'ModelDepartmentConnection'
    items: Array<Maybe<Department>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    le?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
}

export type ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyInput>
    le?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyInput>
}

export type ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelDepartmentDayAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentDayAggregationConditionInput>>>
    averageDaysInOffice?: InputMaybe<ModelFloatInput>
    bookedHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    departmentDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    departmentID?: InputMaybe<ModelIdInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelDepartmentDayAggregationConditionInput>
    notAvailableBookingsSum?: InputMaybe<ModelIntInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentDayAggregationConditionInput>>>
    teamSize?: InputMaybe<ModelIntInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelDepartmentDayAggregationConnection = {
    __typename?: 'ModelDepartmentDayAggregationConnection'
    items: Array<Maybe<DepartmentDayAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelDepartmentDayAggregationEmployeesConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentDayAggregationEmployeesConditionInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentDayAggregationID?: InputMaybe<ModelIdInput>
    employeeID?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelDepartmentDayAggregationEmployeesConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentDayAggregationEmployeesConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelDepartmentDayAggregationEmployeesConnection = {
    __typename?: 'ModelDepartmentDayAggregationEmployeesConnection'
    items: Array<Maybe<DepartmentDayAggregationEmployees>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelDepartmentDayAggregationEmployeesFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentDayAggregationEmployeesFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentDayAggregationID?: InputMaybe<ModelIdInput>
    employeeID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelDepartmentDayAggregationEmployeesFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentDayAggregationEmployeesFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelDepartmentDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentDayAggregationFilterInput>>>
    averageDaysInOffice?: InputMaybe<ModelFloatInput>
    bookedHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    departmentDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    departmentID?: InputMaybe<ModelIdInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelDepartmentDayAggregationFilterInput>
    notAvailableBookingsSum?: InputMaybe<ModelIntInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentDayAggregationFilterInput>>>
    teamSize?: InputMaybe<ModelIntInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelDepartmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentCompanyId?: InputMaybe<ModelIdInput>
    departmentTeamLeadId?: InputMaybe<ModelIdInput>
    employeeIDs?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelDepartmentFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentFilterInput>>>
    size?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelDepartmentLastMonthAttendanceConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentLastMonthAttendanceConditionInput>>>
    attendanceScore?: InputMaybe<ModelIntInput>
    attendanceScoreString?: InputMaybe<ModelStringInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    departmentLastMonthAttendanceCompanyId?: InputMaybe<ModelIdInput>
    departmentLastMonthAttendanceDepartmentId?: InputMaybe<ModelIdInput>
    departmentNameLowerCase?: InputMaybe<ModelStringInput>
    employeesLast?: InputMaybe<ModelIntInput>
    lastDateTogether?: InputMaybe<ModelStringInput>
    lastDateTogetherForSorting?: InputMaybe<ModelStringInput>
    monthlyAttendance?: InputMaybe<ModelFloatInput>
    monthlyAttendanceString?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelDepartmentLastMonthAttendanceConditionInput>
    officeDaysScore?: InputMaybe<ModelIntInput>
    officeDaysScoreString?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentLastMonthAttendanceConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
    weeklyAttendance?: InputMaybe<ModelFloatInput>
    weeklyAttendanceString?: InputMaybe<ModelStringInput>
}

export type ModelDepartmentLastMonthAttendanceConnection = {
    __typename?: 'ModelDepartmentLastMonthAttendanceConnection'
    items: Array<Maybe<DepartmentLastMonthAttendance>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelDepartmentLastMonthAttendanceFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentLastMonthAttendanceFilterInput>>>
    attendanceScore?: InputMaybe<ModelIntInput>
    attendanceScoreString?: InputMaybe<ModelStringInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    departmentLastMonthAttendanceCompanyId?: InputMaybe<ModelIdInput>
    departmentLastMonthAttendanceDepartmentId?: InputMaybe<ModelIdInput>
    departmentNameLowerCase?: InputMaybe<ModelStringInput>
    employeesLast?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    lastDateTogether?: InputMaybe<ModelStringInput>
    lastDateTogetherForSorting?: InputMaybe<ModelStringInput>
    monthlyAttendance?: InputMaybe<ModelFloatInput>
    monthlyAttendanceString?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelDepartmentLastMonthAttendanceFilterInput>
    officeDaysScore?: InputMaybe<ModelIntInput>
    officeDaysScoreString?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentLastMonthAttendanceFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
    weeklyAttendance?: InputMaybe<ModelFloatInput>
    weeklyAttendanceString?: InputMaybe<ModelStringInput>
}

export type ModelDepartmentRulesConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentRulesConditionInput>>>
    blockReservationOnNonOfficeDays?: InputMaybe<ModelBooleanInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    enableMinDaysInOffice?: InputMaybe<ModelBooleanInput>
    horizonOfPlanningInDays?: InputMaybe<ModelIntInput>
    horizonOfPlanningTimeAmount?: InputMaybe<ModelIntInput>
    horizonOfPlanningTimeUnit?: InputMaybe<ModelHorizonOfPlanningTimeUnitInput>
    maxDaysInOffice?: InputMaybe<ModelIntInput>
    minDaysInOffice?: InputMaybe<ModelIntInput>
    minDaysInOfficeTimeUnit?: InputMaybe<ModelMinDaysInOfficeTimeUnitInput>
    not?: InputMaybe<ModelDepartmentRulesConditionInput>
    officeDays?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentRulesConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelDepartmentRulesConnection = {
    __typename?: 'ModelDepartmentRulesConnection'
    items: Array<Maybe<DepartmentRules>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelDepartmentRulesFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentRulesFilterInput>>>
    blockReservationOnNonOfficeDays?: InputMaybe<ModelBooleanInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    enableMinDaysInOffice?: InputMaybe<ModelBooleanInput>
    horizonOfPlanningInDays?: InputMaybe<ModelIntInput>
    horizonOfPlanningTimeAmount?: InputMaybe<ModelIntInput>
    horizonOfPlanningTimeUnit?: InputMaybe<ModelHorizonOfPlanningTimeUnitInput>
    id?: InputMaybe<ModelIdInput>
    maxDaysInOffice?: InputMaybe<ModelIntInput>
    minDaysInOffice?: InputMaybe<ModelIntInput>
    minDaysInOfficeTimeUnit?: InputMaybe<ModelMinDaysInOfficeTimeUnitInput>
    not?: InputMaybe<ModelDepartmentRulesFilterInput>
    officeDays?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentRulesFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelDepartmentWeekAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentWeekAggregationConditionInput>>>
    averageDaysInOffice?: InputMaybe<ModelFloatInput>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    departmentWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    employeesInOffice?: InputMaybe<ModelIntInput>
    employeesLast?: InputMaybe<ModelIntInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelDepartmentWeekAggregationConditionInput>
    notAvailableBookingsSum?: InputMaybe<ModelIntInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentWeekAggregationConditionInput>>>
    teamSize?: InputMaybe<ModelIntInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelDepartmentWeekAggregationConnection = {
    __typename?: 'ModelDepartmentWeekAggregationConnection'
    items: Array<Maybe<DepartmentWeekAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelDepartmentWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelDepartmentWeekAggregationFilterInput>>>
    averageDaysInOffice?: InputMaybe<ModelFloatInput>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    departmentWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    employeesInOffice?: InputMaybe<ModelIntInput>
    employeesLast?: InputMaybe<ModelIntInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelDepartmentWeekAggregationFilterInput>
    notAvailableBookingsSum?: InputMaybe<ModelIntInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelDepartmentWeekAggregationFilterInput>>>
    teamSize?: InputMaybe<ModelIntInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyInput>>>
    eq?: InputMaybe<ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyInput>
    ge?: InputMaybe<ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyInput>
    gt?: InputMaybe<ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyInput>
    le?: InputMaybe<ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyInput>
    lt?: InputMaybe<ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyInput>
}

export type ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyInput = {
    firstname?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<EmployeeStatus>
}

export type ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyInput>>>
    eq?: InputMaybe<ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyInput>
    ge?: InputMaybe<ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyInput>
    gt?: InputMaybe<ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyInput>
    le?: InputMaybe<ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyInput>
    lt?: InputMaybe<ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyInput>
}

export type ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyInput = {
    firstname?: InputMaybe<Scalars['String']['input']>
    status?: InputMaybe<EmployeeStatus>
}

export type ModelEmployeeConnection = {
    __typename?: 'ModelEmployeeConnection'
    items: Array<Maybe<Employee>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    le?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput>
}

export type ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelEmployeeDayAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeDayAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    employeesLast?: InputMaybe<ModelIntInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelEmployeeDayAggregationConditionInput>
    notAvailableBookingsSum?: InputMaybe<ModelIntInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeDayAggregationConditionInput>>>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelEmployeeDayAggregationConnection = {
    __typename?: 'ModelEmployeeDayAggregationConnection'
    items: Array<Maybe<EmployeeDayAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelEmployeeDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    employeesLast?: InputMaybe<ModelIntInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelEmployeeDayAggregationFilterInput>
    notAvailableBookingsSum?: InputMaybe<ModelIntInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeDayAggregationFilterInput>>>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelEmployeeFilterInput = {
    active?: InputMaybe<ModelBooleanInput>
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeFilterInput>>>
    birthday?: InputMaybe<ModelStringInput>
    buddyID?: InputMaybe<ModelIdInput>
    cognitoUserId?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    defaultPlan?: InputMaybe<ModelStringInput>
    demoAccountID?: InputMaybe<ModelIdInput>
    departmentID?: InputMaybe<ModelIdInput>
    departmentIDs?: InputMaybe<ModelIdInput>
    departmentIDsString?: InputMaybe<ModelStringInput>
    departmentsString?: InputMaybe<ModelStringInput>
    email?: InputMaybe<ModelStringInput>
    emailForSearch?: InputMaybe<ModelStringInput>
    employeeCompanyId?: InputMaybe<ModelIdInput>
    employeeDemoAccountId?: InputMaybe<ModelIdInput>
    employeeFavouriteOfficeId?: InputMaybe<ModelIdInput>
    employeeOfficeId?: InputMaybe<ModelIdInput>
    employeePositionId?: InputMaybe<ModelIdInput>
    externalID?: InputMaybe<ModelStringInput>
    favouriteColleagueIDs?: InputMaybe<ModelIdInput>
    favouriteOfficeID?: InputMaybe<ModelIdInput>
    favouriteReservableID?: InputMaybe<ModelIdInput>
    firstname?: InputMaybe<ModelStringInput>
    fullName?: InputMaybe<ModelStringInput>
    fullNameLowerCase?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    isSeatingInOffice?: InputMaybe<ModelBooleanInput>
    lastname?: InputMaybe<ModelStringInput>
    locale?: InputMaybe<ModelUserLocaleInput>
    not?: InputMaybe<ModelEmployeeFilterInput>
    notificationChannels?: InputMaybe<ModelNotificationChannelListInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeFilterInput>>>
    photo?: InputMaybe<ModelStringInput>
    positionID?: InputMaybe<ModelIdInput>
    preferredScheduleID?: InputMaybe<ModelIdInput>
    role?: InputMaybe<ModelRoleInput>
    roleID?: InputMaybe<ModelIdInput>
    roleString?: InputMaybe<ModelStringInput>
    shareAttendance?: InputMaybe<ModelShareAttendanceInput>
    source?: InputMaybe<ModelSyncSourceInput>
    status?: InputMaybe<ModelEmployeeStatusInput>
    statusString?: InputMaybe<ModelStringInput>
    turnOnExperimentalFeatures?: InputMaybe<ModelBooleanInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelEmployeeLastMonthAttendanceConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeLastMonthAttendanceConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    departmentIDs?: InputMaybe<ModelIdInput>
    departmentIDsString?: InputMaybe<ModelStringInput>
    departmentNamesString?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    employeeLastMonthAttendanceCompanyId?: InputMaybe<ModelIdInput>
    employeeLastMonthAttendanceDepartmentId?: InputMaybe<ModelIdInput>
    employeeLastMonthAttendanceEmployeeId?: InputMaybe<ModelIdInput>
    employeeNameLowerCase?: InputMaybe<ModelStringInput>
    lastDateInOffice?: InputMaybe<ModelStringInput>
    lastDateInOfficeForSorting?: InputMaybe<ModelStringInput>
    monthlyAttendance?: InputMaybe<ModelFloatInput>
    monthlyAttendanceString?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelEmployeeLastMonthAttendanceConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeLastMonthAttendanceConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
    weeklyAttendance?: InputMaybe<ModelFloatInput>
    weeklyAttendanceString?: InputMaybe<ModelStringInput>
}

export type ModelEmployeeLastMonthAttendanceConnection = {
    __typename?: 'ModelEmployeeLastMonthAttendanceConnection'
    items: Array<Maybe<EmployeeLastMonthAttendance>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelEmployeeLastMonthAttendanceFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    departmentIDs?: InputMaybe<ModelIdInput>
    departmentIDsString?: InputMaybe<ModelStringInput>
    departmentNamesString?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    employeeLastMonthAttendanceCompanyId?: InputMaybe<ModelIdInput>
    employeeLastMonthAttendanceDepartmentId?: InputMaybe<ModelIdInput>
    employeeLastMonthAttendanceEmployeeId?: InputMaybe<ModelIdInput>
    employeeNameLowerCase?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    lastDateInOffice?: InputMaybe<ModelStringInput>
    lastDateInOfficeForSorting?: InputMaybe<ModelStringInput>
    monthlyAttendance?: InputMaybe<ModelFloatInput>
    monthlyAttendanceString?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
    weeklyAttendance?: InputMaybe<ModelFloatInput>
    weeklyAttendanceString?: InputMaybe<ModelStringInput>
}

export type ModelEmployeeStatusInput = {
    eq?: InputMaybe<EmployeeStatus>
    ne?: InputMaybe<EmployeeStatus>
}

export type ModelEmployeeToDepartmentConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeToDepartmentConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    employeeID?: InputMaybe<ModelIdInput>
    employeeToDepartmentCompanyId?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelEmployeeToDepartmentConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeToDepartmentConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelEmployeeToDepartmentConnection = {
    __typename?: 'ModelEmployeeToDepartmentConnection'
    items: Array<Maybe<EmployeeToDepartment>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelEmployeeToDepartmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeToDepartmentFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    employeeID?: InputMaybe<ModelIdInput>
    employeeToDepartmentCompanyId?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelEmployeeToDepartmentFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeToDepartmentFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelEmployeeWeekAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeWeekAggregationConditionInput>>>
    averageDaysInOffice?: InputMaybe<ModelFloatInput>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeeWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    employeesInOffice?: InputMaybe<ModelIntInput>
    employeesLast?: InputMaybe<ModelIntInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelEmployeeWeekAggregationConditionInput>
    notAvailableBookingsSum?: InputMaybe<ModelIntInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeWeekAggregationConditionInput>>>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelEmployeeWeekAggregationConnection = {
    __typename?: 'ModelEmployeeWeekAggregationConnection'
    items: Array<Maybe<EmployeeWeekAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelEmployeeWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEmployeeWeekAggregationFilterInput>>>
    averageDaysInOffice?: InputMaybe<ModelFloatInput>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeeWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    employeesInOffice?: InputMaybe<ModelIntInput>
    employeesLast?: InputMaybe<ModelIntInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelEmployeeWeekAggregationFilterInput>
    notAvailableBookingsSum?: InputMaybe<ModelIntInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelEmployeeWeekAggregationFilterInput>>>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelEquipmentCategoryConnection = {
    __typename?: 'ModelEquipmentCategoryConnection'
    items: Array<Maybe<EquipmentCategory>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelEquipmentCategoryFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEquipmentCategoryFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    equipmentCategoryCompanyId?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelEquipmentCategoryFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelEquipmentCategoryFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelEquipmentConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEquipmentConditionInput>>>
    canBookedSeparately?: InputMaybe<ModelBooleanInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    email?: InputMaybe<ModelStringInput>
    enabled?: InputMaybe<ModelBooleanInput>
    equipmentCategoryID?: InputMaybe<ModelIdInput>
    equipmentCompanyId?: InputMaybe<ModelIdInput>
    equipmentEquipmentCategoryId?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelEquipmentConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelEquipmentConditionInput>>>
    quantity?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelEquipmentConnection = {
    __typename?: 'ModelEquipmentConnection'
    items: Array<Maybe<Equipment>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelEquipmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEquipmentFilterInput>>>
    canBookedSeparately?: InputMaybe<ModelBooleanInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    email?: InputMaybe<ModelStringInput>
    enabled?: InputMaybe<ModelBooleanInput>
    equipmentCategoryID?: InputMaybe<ModelIdInput>
    equipmentCompanyId?: InputMaybe<ModelIdInput>
    equipmentEquipmentCategoryId?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelEquipmentFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelEquipmentFilterInput>>>
    quantity?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelEquipmentImageConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEquipmentImageConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    equipmentID?: InputMaybe<ModelIdInput>
    equipmentImageCompanyId?: InputMaybe<ModelIdInput>
    isMain?: InputMaybe<ModelBooleanInput>
    location?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelEquipmentImageConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelEquipmentImageConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelEquipmentImageConnection = {
    __typename?: 'ModelEquipmentImageConnection'
    items: Array<Maybe<EquipmentImage>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelEquipmentImageFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelEquipmentImageFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    equipmentID?: InputMaybe<ModelIdInput>
    equipmentImageCompanyId?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    isMain?: InputMaybe<ModelBooleanInput>
    location?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelEquipmentImageFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelEquipmentImageFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelFloatInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']['input']>
    attributeType?: InputMaybe<ModelAttributeTypes>
    between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
    eq?: InputMaybe<Scalars['Float']['input']>
    ge?: InputMaybe<Scalars['Float']['input']>
    gt?: InputMaybe<Scalars['Float']['input']>
    le?: InputMaybe<Scalars['Float']['input']>
    lt?: InputMaybe<Scalars['Float']['input']>
    ne?: InputMaybe<Scalars['Float']['input']>
}

export type ModelFloatKeyConditionInput = {
    between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
    eq?: InputMaybe<Scalars['Float']['input']>
    ge?: InputMaybe<Scalars['Float']['input']>
    gt?: InputMaybe<Scalars['Float']['input']>
    le?: InputMaybe<Scalars['Float']['input']>
    lt?: InputMaybe<Scalars['Float']['input']>
}

export type ModelGoogleImportedBookingsConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelGoogleImportedBookingsConditionInput>>>
    calendarEventID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    googleID?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelGoogleImportedBookingsConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelGoogleImportedBookingsConditionInput>>>
    updated?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelGoogleImportedBookingsConnection = {
    __typename?: 'ModelGoogleImportedBookingsConnection'
    items: Array<Maybe<GoogleImportedBookings>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelGoogleImportedBookingsFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelGoogleImportedBookingsFilterInput>>>
    calendarEventID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    googleID?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelGoogleImportedBookingsFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelGoogleImportedBookingsFilterInput>>>
    updated?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelGoogleImportedWorkingLocationsConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelGoogleImportedWorkingLocationsConditionInput>>>
    bookingID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    googleID?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelGoogleImportedWorkingLocationsConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelGoogleImportedWorkingLocationsConditionInput>>>
    updated?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelGoogleImportedWorkingLocationsConnection = {
    __typename?: 'ModelGoogleImportedWorkingLocationsConnection'
    items: Array<Maybe<GoogleImportedWorkingLocations>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelGoogleImportedWorkingLocationsFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelGoogleImportedWorkingLocationsFilterInput>>>
    bookingID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    googleID?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelGoogleImportedWorkingLocationsFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelGoogleImportedWorkingLocationsFilterInput>>>
    updated?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelHorizonOfPlanningTimeUnitInput = {
    eq?: InputMaybe<HorizonOfPlanningTimeUnit>
    ne?: InputMaybe<HorizonOfPlanningTimeUnit>
}

export type ModelIdInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']['input']>
    attributeType?: InputMaybe<ModelAttributeTypes>
    beginsWith?: InputMaybe<Scalars['ID']['input']>
    between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    contains?: InputMaybe<Scalars['ID']['input']>
    eq?: InputMaybe<Scalars['ID']['input']>
    ge?: InputMaybe<Scalars['ID']['input']>
    gt?: InputMaybe<Scalars['ID']['input']>
    le?: InputMaybe<Scalars['ID']['input']>
    lt?: InputMaybe<Scalars['ID']['input']>
    ne?: InputMaybe<Scalars['ID']['input']>
    notContains?: InputMaybe<Scalars['ID']['input']>
    size?: InputMaybe<ModelSizeInput>
}

export type ModelIdKeyConditionInput = {
    beginsWith?: InputMaybe<Scalars['ID']['input']>
    between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    eq?: InputMaybe<Scalars['ID']['input']>
    ge?: InputMaybe<Scalars['ID']['input']>
    gt?: InputMaybe<Scalars['ID']['input']>
    le?: InputMaybe<Scalars['ID']['input']>
    lt?: InputMaybe<Scalars['ID']['input']>
}

export type ModelIntInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']['input']>
    attributeType?: InputMaybe<ModelAttributeTypes>
    between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    eq?: InputMaybe<Scalars['Int']['input']>
    ge?: InputMaybe<Scalars['Int']['input']>
    gt?: InputMaybe<Scalars['Int']['input']>
    le?: InputMaybe<Scalars['Int']['input']>
    lt?: InputMaybe<Scalars['Int']['input']>
    ne?: InputMaybe<Scalars['Int']['input']>
}

export type ModelIntKeyConditionInput = {
    between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    eq?: InputMaybe<Scalars['Int']['input']>
    ge?: InputMaybe<Scalars['Int']['input']>
    gt?: InputMaybe<Scalars['Int']['input']>
    le?: InputMaybe<Scalars['Int']['input']>
    lt?: InputMaybe<Scalars['Int']['input']>
}

export type ModelIntegrationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelIntegrationConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    group?: InputMaybe<ModelIntegrationGroupInput>
    integrationCompanyId?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelIntegrationConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelIntegrationConditionInput>>>
    order?: InputMaybe<ModelIntInput>
    status?: InputMaybe<ModelIntegrationStatusInput>
    type?: InputMaybe<ModelIntegrationTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelIntegrationConnection = {
    __typename?: 'ModelIntegrationConnection'
    items: Array<Maybe<Integration>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelIntegrationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelIntegrationFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    group?: InputMaybe<ModelIntegrationGroupInput>
    id?: InputMaybe<ModelIdInput>
    integrationCompanyId?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelIntegrationFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelIntegrationFilterInput>>>
    order?: InputMaybe<ModelIntInput>
    status?: InputMaybe<ModelIntegrationStatusInput>
    type?: InputMaybe<ModelIntegrationTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelIntegrationGroupInput = {
    eq?: InputMaybe<IntegrationGroup>
    ne?: InputMaybe<IntegrationGroup>
}

export type ModelIntegrationStatusInput = {
    eq?: InputMaybe<IntegrationStatus>
    ne?: InputMaybe<IntegrationStatus>
}

export type ModelIntegrationTypeInput = {
    eq?: InputMaybe<IntegrationType>
    ne?: InputMaybe<IntegrationType>
}

export type ModelMenuItemConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelMenuItemConditionInput>>>
    cateringID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    enabled?: InputMaybe<ModelBooleanInput>
    menuItemCompanyId?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelMenuItemConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelMenuItemConditionInput>>>
    title?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelMenuItemConnection = {
    __typename?: 'ModelMenuItemConnection'
    items: Array<Maybe<MenuItem>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelMenuItemFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelMenuItemFilterInput>>>
    cateringID?: InputMaybe<ModelIdInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    enabled?: InputMaybe<ModelBooleanInput>
    id?: InputMaybe<ModelIdInput>
    menuItemCompanyId?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelMenuItemFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelMenuItemFilterInput>>>
    title?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelMicrosoftSubscriptionConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelMicrosoftSubscriptionConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    isFreeTrial?: InputMaybe<ModelBooleanInput>
    microsoftSubscriptionCompanyId?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelMicrosoftSubscriptionConditionInput>
    offerId?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelMicrosoftSubscriptionConditionInput>>>
    ownerEmail?: InputMaybe<ModelStringInput>
    ownerID?: InputMaybe<ModelIdInput>
    ownerName?: InputMaybe<ModelStringInput>
    planId?: InputMaybe<ModelStringInput>
    quantity?: InputMaybe<ModelIntInput>
    status?: InputMaybe<ModelMicrosoftSubscriptionStatusInput>
    tenantId?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelMicrosoftSubscriptionConnection = {
    __typename?: 'ModelMicrosoftSubscriptionConnection'
    items: Array<Maybe<MicrosoftSubscription>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelMicrosoftSubscriptionFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelMicrosoftSubscriptionFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    isFreeTrial?: InputMaybe<ModelBooleanInput>
    microsoftSubscriptionCompanyId?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelMicrosoftSubscriptionFilterInput>
    offerId?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelMicrosoftSubscriptionFilterInput>>>
    ownerEmail?: InputMaybe<ModelStringInput>
    ownerID?: InputMaybe<ModelIdInput>
    ownerName?: InputMaybe<ModelStringInput>
    planId?: InputMaybe<ModelStringInput>
    quantity?: InputMaybe<ModelIntInput>
    status?: InputMaybe<ModelMicrosoftSubscriptionStatusInput>
    tenantId?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelMicrosoftSubscriptionStatusInput = {
    eq?: InputMaybe<MicrosoftSubscriptionStatus>
    ne?: InputMaybe<MicrosoftSubscriptionStatus>
}

export type ModelMinDaysInOfficeTimeUnitInput = {
    eq?: InputMaybe<MinDaysInOfficeTimeUnit>
    ne?: InputMaybe<MinDaysInOfficeTimeUnit>
}

export type ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyInput>>>
    eq?: InputMaybe<ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyInput>
    ge?: InputMaybe<ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyInput>
    gt?: InputMaybe<ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyInput>
    le?: InputMaybe<ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyInput>
    lt?: InputMaybe<ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyInput>
}

export type ModelNotificationByEmployeeAndTypeAndStatusCompositeKeyInput = {
    employeeID?: InputMaybe<Scalars['ID']['input']>
    type?: InputMaybe<NotificationType>
}

export type ModelNotificationChannelListInput = {
    contains?: InputMaybe<NotificationChannel>
    eq?: InputMaybe<Array<InputMaybe<NotificationChannel>>>
    ne?: InputMaybe<Array<InputMaybe<NotificationChannel>>>
    notContains?: InputMaybe<NotificationChannel>
}

export type ModelNotificationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelNotificationConditionInput>>>
    createdAt?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelNotificationConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelNotificationConditionInput>>>
    placeholders?: InputMaybe<ModelStringInput>
    sentAt?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelNotificationStatusInput>
    type?: InputMaybe<ModelNotificationTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelNotificationConnection = {
    __typename?: 'ModelNotificationConnection'
    items: Array<Maybe<Notification>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelNotificationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelNotificationFilterInput>>>
    createdAt?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelNotificationFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelNotificationFilterInput>>>
    placeholders?: InputMaybe<ModelStringInput>
    sentAt?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelNotificationStatusInput>
    type?: InputMaybe<ModelNotificationTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelNotificationStatusInput = {
    eq?: InputMaybe<NotificationStatus>
    ne?: InputMaybe<NotificationStatus>
}

export type ModelNotificationTypeInput = {
    eq?: InputMaybe<NotificationType>
    ne?: InputMaybe<NotificationType>
}

export type ModelOfficeBuilderRequestConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelOfficeBuilderRequestConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelOfficeBuilderRequestConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeBuilderRequestConditionInput>>>
    owner?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelOfficeBuilderRequestConnection = {
    __typename?: 'ModelOfficeBuilderRequestConnection'
    items: Array<Maybe<OfficeBuilderRequest>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelOfficeBuilderRequestFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelOfficeBuilderRequestFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelOfficeBuilderRequestFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeBuilderRequestFilterInput>>>
    owner?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelOfficeConditionInput = {
    address?: InputMaybe<ModelStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelOfficeConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    externalOfficeId?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelOfficeConditionInput>
    officeAddressId?: InputMaybe<ModelIdInput>
    officeCateringId?: InputMaybe<ModelIdInput>
    officeCompanyId?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeConditionInput>>>
    order?: InputMaybe<ModelIntInput>
    phoneNumbers?: InputMaybe<ModelStringInput>
    placesBlocked?: InputMaybe<ModelIntInput>
    placesFixed?: InputMaybe<ModelIntInput>
    placesFlex?: InputMaybe<ModelIntInput>
    placesTotal?: InputMaybe<ModelIntInput>
    properties?: InputMaybe<ModelStringInput>
    shortDescription?: InputMaybe<ModelStringInput>
    timeZone?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
    workingFrom?: InputMaybe<ModelStringInput>
    workingHours?: InputMaybe<ModelStringInput>
    workingTo?: InputMaybe<ModelStringInput>
}

export type ModelOfficeConnection = {
    __typename?: 'ModelOfficeConnection'
    items: Array<Maybe<Office>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelOfficeDayAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelOfficeDayAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelOfficeDayAggregationConditionInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    officeCapacityLast?: InputMaybe<ModelIntInput>
    officeDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    officeDayAggregationOfficeId?: InputMaybe<ModelIdInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeDayAggregationConditionInput>>>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelOfficeDayAggregationConnection = {
    __typename?: 'ModelOfficeDayAggregationConnection'
    items: Array<Maybe<OfficeDayAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelOfficeDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelOfficeDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelOfficeDayAggregationFilterInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    officeCapacityLast?: InputMaybe<ModelIntInput>
    officeDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    officeDayAggregationOfficeId?: InputMaybe<ModelIdInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeDayAggregationFilterInput>>>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelOfficeFilterInput = {
    address?: InputMaybe<ModelStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelOfficeFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    externalOfficeId?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelOfficeFilterInput>
    officeAddressId?: InputMaybe<ModelIdInput>
    officeCateringId?: InputMaybe<ModelIdInput>
    officeCompanyId?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeFilterInput>>>
    order?: InputMaybe<ModelIntInput>
    phoneNumbers?: InputMaybe<ModelStringInput>
    placesBlocked?: InputMaybe<ModelIntInput>
    placesFixed?: InputMaybe<ModelIntInput>
    placesFlex?: InputMaybe<ModelIntInput>
    placesTotal?: InputMaybe<ModelIntInput>
    properties?: InputMaybe<ModelStringInput>
    shortDescription?: InputMaybe<ModelStringInput>
    timeZone?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelStringInput>
    workingFrom?: InputMaybe<ModelStringInput>
    workingHours?: InputMaybe<ModelStringInput>
    workingTo?: InputMaybe<ModelStringInput>
}

export type ModelOfficeImageConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelOfficeImageConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    location?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelOfficeImageConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    officeImageCompanyId?: InputMaybe<ModelIdInput>
    officeImageOfficeId?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeImageConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelOfficeImageConnection = {
    __typename?: 'ModelOfficeImageConnection'
    items: Array<Maybe<OfficeImage>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelOfficeImageFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelOfficeImageFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    location?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelOfficeImageFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    officeImageCompanyId?: InputMaybe<ModelIdInput>
    officeImageOfficeId?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeImageFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelOfficeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelOfficeWeekAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelOfficeWeekAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeesLast?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelOfficeWeekAggregationConditionInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    officeCapacityMax?: InputMaybe<ModelIntInput>
    officeID?: InputMaybe<ModelIdInput>
    officeWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    officeWeekAggregationOfficeId?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeWeekAggregationConditionInput>>>
    percentageOfUtilization?: InputMaybe<ModelIntInput>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelOfficeWeekAggregationConnection = {
    __typename?: 'ModelOfficeWeekAggregationConnection'
    items: Array<Maybe<OfficeWeekAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelOfficeWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelOfficeWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeesLast?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelOfficeWeekAggregationFilterInput>
    officeBookingsSum?: InputMaybe<ModelIntInput>
    officeCapacityMax?: InputMaybe<ModelIntInput>
    officeID?: InputMaybe<ModelIdInput>
    officeWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    officeWeekAggregationOfficeId?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelOfficeWeekAggregationFilterInput>>>
    percentageOfUtilization?: InputMaybe<ModelIntInput>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelPermissionConditionInput = {
    allowedActions?: InputMaybe<ModelActionTypeInput>
    and?: InputMaybe<Array<InputMaybe<ModelPermissionConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelPermissionConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelPermissionConditionInput>>>
    resourceType?: InputMaybe<ModelResourceTypeInput>
    roleID?: InputMaybe<ModelIdInput>
    scope?: InputMaybe<ModelScopeInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelPermissionConnection = {
    __typename?: 'ModelPermissionConnection'
    items: Array<Maybe<Permission>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelPermissionFilterInput = {
    allowedActions?: InputMaybe<ModelActionTypeInput>
    and?: InputMaybe<Array<InputMaybe<ModelPermissionFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelPermissionFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelPermissionFilterInput>>>
    resourceType?: InputMaybe<ModelResourceTypeInput>
    roleID?: InputMaybe<ModelIdInput>
    scope?: InputMaybe<ModelScopeInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyInput>
    ge?: InputMaybe<ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyInput>
    gt?: InputMaybe<ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyInput>
    le?: InputMaybe<ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyInput>
    lt?: InputMaybe<ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyInput>
}

export type ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyInput = {
    effectiveFrom?: InputMaybe<Scalars['String']['input']>
    employeeEmail?: InputMaybe<Scalars['String']['input']>
}

export type ModelPersonioImportedAbsencesConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelPersonioImportedAbsencesConditionInput>>>
    bookingRequestID?: InputMaybe<ModelIdInput>
    bookingSubType?: InputMaybe<ModelBookingSubTypeInput>
    bookingType?: InputMaybe<ModelBookingTypeInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    effectiveFrom?: InputMaybe<ModelStringInput>
    effectiveTo?: InputMaybe<ModelStringInput>
    employeeEmail?: InputMaybe<ModelStringInput>
    halfDayEnd?: InputMaybe<ModelBooleanInput>
    halfDayStart?: InputMaybe<ModelBooleanInput>
    not?: InputMaybe<ModelPersonioImportedAbsencesConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelPersonioImportedAbsencesConditionInput>>>
    personioID?: InputMaybe<ModelIntInput>
    personioImportedAbsencesBookingRequestId?: InputMaybe<ModelIdInput>
    reservableID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelPersonioImportedAbsencesConnection = {
    __typename?: 'ModelPersonioImportedAbsencesConnection'
    items: Array<Maybe<PersonioImportedAbsences>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelPersonioImportedAbsencesFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelPersonioImportedAbsencesFilterInput>>>
    bookingRequestID?: InputMaybe<ModelIdInput>
    bookingSubType?: InputMaybe<ModelBookingSubTypeInput>
    bookingType?: InputMaybe<ModelBookingTypeInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    effectiveFrom?: InputMaybe<ModelStringInput>
    effectiveTo?: InputMaybe<ModelStringInput>
    employeeEmail?: InputMaybe<ModelStringInput>
    halfDayEnd?: InputMaybe<ModelBooleanInput>
    halfDayStart?: InputMaybe<ModelBooleanInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelPersonioImportedAbsencesFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelPersonioImportedAbsencesFilterInput>>>
    personioID?: InputMaybe<ModelIntInput>
    personioImportedAbsencesBookingRequestId?: InputMaybe<ModelIdInput>
    reservableID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelPersonioImportedBookingsConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelPersonioImportedBookingsConditionInput>>>
    bookingID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    effectiveFrom?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelPersonioImportedBookingsConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelPersonioImportedBookingsConditionInput>>>
    personioID?: InputMaybe<ModelStringInput>
    personioImportedBookingsBookingId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelPersonioImportedBookingsConnection = {
    __typename?: 'ModelPersonioImportedBookingsConnection'
    items: Array<Maybe<PersonioImportedBookings>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelPersonioImportedBookingsFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelPersonioImportedBookingsFilterInput>>>
    bookingID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    effectiveFrom?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelPersonioImportedBookingsFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelPersonioImportedBookingsFilterInput>>>
    personioID?: InputMaybe<ModelStringInput>
    personioImportedBookingsBookingId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelPositionConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelPositionConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelPositionConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelPositionConditionInput>>>
    positionCompanyId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelPositionConnection = {
    __typename?: 'ModelPositionConnection'
    items: Array<Maybe<Position>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelPositionFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelPositionFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelPositionFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelPositionFilterInput>>>
    positionCompanyId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelRecurrencePatternTypeInput = {
    eq?: InputMaybe<RecurrencePatternType>
    ne?: InputMaybe<RecurrencePatternType>
}

export type ModelRecurrenceRangeTypeInput = {
    eq?: InputMaybe<RecurrenceRangeType>
    ne?: InputMaybe<RecurrenceRangeType>
}

export type ModelRepeatTypeInput = {
    eq?: InputMaybe<RepeatType>
    ne?: InputMaybe<RepeatType>
}

export type ModelReservableConditionInput = {
    adminID?: InputMaybe<ModelIdInput>
    and?: InputMaybe<Array<InputMaybe<ModelReservableConditionInput>>>
    availabilityByDatesAndTime?: InputMaybe<ModelBooleanInput>
    availabilityPreviewByDates?: InputMaybe<ModelAvailabilityItemPreviewElementListInput>
    availabilityType?: InputMaybe<ModelAvailabilityTypeInput>
    color?: InputMaybe<ModelStringInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    employeeID?: InputMaybe<ModelIdInput>
    externalRoomID?: InputMaybe<ModelStringInput>
    height?: InputMaybe<ModelFloatInput>
    isDefault?: InputMaybe<ModelStringInput>
    maxAmountOfPeople?: InputMaybe<ModelIntInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelReservableConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableConditionInput>>>
    relatedReservableID?: InputMaybe<ModelIdInput>
    reservableCompanyId?: InputMaybe<ModelIdInput>
    reservableDepartmentId?: InputMaybe<ModelIdInput>
    reservableEmployeeId?: InputMaybe<ModelIdInput>
    reservableSpaceId?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    type?: InputMaybe<ModelReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    width?: InputMaybe<ModelFloatInput>
    x?: InputMaybe<ModelFloatInput>
    y?: InputMaybe<ModelFloatInput>
    zonePoints?: InputMaybe<ModelFloatInput>
}

export type ModelReservableConnection = {
    __typename?: 'ModelReservableConnection'
    items: Array<Maybe<Reservable>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelReservableFilterInput = {
    adminID?: InputMaybe<ModelIdInput>
    and?: InputMaybe<Array<InputMaybe<ModelReservableFilterInput>>>
    availabilityByDatesAndTime?: InputMaybe<ModelBooleanInput>
    availabilityPreviewByDates?: InputMaybe<ModelAvailabilityItemPreviewElementListInput>
    availabilityType?: InputMaybe<ModelAvailabilityTypeInput>
    color?: InputMaybe<ModelStringInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    employeeID?: InputMaybe<ModelIdInput>
    externalRoomID?: InputMaybe<ModelStringInput>
    height?: InputMaybe<ModelFloatInput>
    id?: InputMaybe<ModelIdInput>
    isDefault?: InputMaybe<ModelStringInput>
    maxAmountOfPeople?: InputMaybe<ModelIntInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelReservableFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableFilterInput>>>
    relatedReservableID?: InputMaybe<ModelIdInput>
    reservableCompanyId?: InputMaybe<ModelIdInput>
    reservableDepartmentId?: InputMaybe<ModelIdInput>
    reservableEmployeeId?: InputMaybe<ModelIdInput>
    reservableSpaceId?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    type?: InputMaybe<ModelReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    width?: InputMaybe<ModelFloatInput>
    x?: InputMaybe<ModelFloatInput>
    y?: InputMaybe<ModelFloatInput>
    zonePoints?: InputMaybe<ModelFloatInput>
}

export type ModelReservableImageConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelReservableImageConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    isMain?: InputMaybe<ModelBooleanInput>
    location?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelReservableImageConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableImageConditionInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    reservableImageCompanyId?: InputMaybe<ModelIdInput>
    reservableImageReservableId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelReservableImageConnection = {
    __typename?: 'ModelReservableImageConnection'
    items: Array<Maybe<ReservableImage>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelReservableImageFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelReservableImageFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    isMain?: InputMaybe<ModelBooleanInput>
    location?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelReservableImageFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableImageFilterInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    reservableImageCompanyId?: InputMaybe<ModelIdInput>
    reservableImageReservableId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelReservableTagConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelReservableTagConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelReservableTagConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableTagConditionInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    reservableTagCompanyId?: InputMaybe<ModelIdInput>
    reservableTagOfficeId?: InputMaybe<ModelIdInput>
    reservableTagSpaceId?: InputMaybe<ModelIdInput>
    reservableType?: InputMaybe<ModelReservableTypeInput>
    spaceID?: InputMaybe<ModelIdInput>
    tagID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelReservableTagConnection = {
    __typename?: 'ModelReservableTagConnection'
    items: Array<Maybe<ReservableTag>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelReservableTagFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelReservableTagFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelReservableTagFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableTagFilterInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    reservableTagCompanyId?: InputMaybe<ModelIdInput>
    reservableTagOfficeId?: InputMaybe<ModelIdInput>
    reservableTagSpaceId?: InputMaybe<ModelIdInput>
    reservableType?: InputMaybe<ModelReservableTypeInput>
    spaceID?: InputMaybe<ModelIdInput>
    tagID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelReservableToDepartmentConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelReservableToDepartmentConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelReservableToDepartmentConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableToDepartmentConditionInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    reservableToDepartmentCompanyId?: InputMaybe<ModelIdInput>
    reservableToDepartmentDepartmentId?: InputMaybe<ModelIdInput>
    reservableToDepartmentReservableId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelReservableToDepartmentConnection = {
    __typename?: 'ModelReservableToDepartmentConnection'
    items: Array<Maybe<ReservableToDepartment>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelReservableToDepartmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelReservableToDepartmentFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    departmentID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelReservableToDepartmentFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableToDepartmentFilterInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    reservableToDepartmentCompanyId?: InputMaybe<ModelIdInput>
    reservableToDepartmentDepartmentId?: InputMaybe<ModelIdInput>
    reservableToDepartmentReservableId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelReservableToEmployeeConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelReservableToEmployeeConditionInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelReservableToEmployeeConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableToEmployeeConditionInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelReservableToEmployeeConnection = {
    __typename?: 'ModelReservableToEmployeeConnection'
    items: Array<Maybe<ReservableToEmployee>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelReservableToEmployeeFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelReservableToEmployeeFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelReservableToEmployeeFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelReservableToEmployeeFilterInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelReservableTypeInput = {
    eq?: InputMaybe<ReservableType>
    ne?: InputMaybe<ReservableType>
}

export type ModelResourceTypeInput = {
    eq?: InputMaybe<ResourceType>
    ne?: InputMaybe<ResourceType>
}

export type ModelRoleInput = {
    eq?: InputMaybe<Role>
    ne?: InputMaybe<Role>
}

export type ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelRoomDayAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelRoomDayAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelRoomDayAggregationConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelRoomDayAggregationConditionInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    roomBookingsSum?: InputMaybe<ModelIntInput>
    roomCapacityLast?: InputMaybe<ModelIntInput>
    roomDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    roomNameLowerCase?: InputMaybe<ModelStringInput>
    spaceID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
    visitors?: InputMaybe<ModelIntInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelRoomDayAggregationConnection = {
    __typename?: 'ModelRoomDayAggregationConnection'
    items: Array<Maybe<RoomDayAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelRoomDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelRoomDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelRoomDayAggregationFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelRoomDayAggregationFilterInput>>>
    reservableID?: InputMaybe<ModelIdInput>
    roomBookingsSum?: InputMaybe<ModelIntInput>
    roomCapacityLast?: InputMaybe<ModelIntInput>
    roomDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    roomNameLowerCase?: InputMaybe<ModelStringInput>
    spaceID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
    visitors?: InputMaybe<ModelIntInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>>>
    eq?: InputMaybe<ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>>>
    eq?: InputMaybe<ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelRoomWeekAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelRoomWeekAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelRoomWeekAggregationConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelRoomWeekAggregationConditionInput>>>
    percentageOfUtilization?: InputMaybe<ModelIntInput>
    reservableID?: InputMaybe<ModelIdInput>
    roomBookingsSum?: InputMaybe<ModelIntInput>
    roomCapacityMax?: InputMaybe<ModelIntInput>
    roomNameLowerCase?: InputMaybe<ModelStringInput>
    roomWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
    visitors?: InputMaybe<ModelIntInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelRoomWeekAggregationConnection = {
    __typename?: 'ModelRoomWeekAggregationConnection'
    items: Array<Maybe<RoomWeekAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelRoomWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelRoomWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelRoomWeekAggregationFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelRoomWeekAggregationFilterInput>>>
    percentageOfUtilization?: InputMaybe<ModelIntInput>
    reservableID?: InputMaybe<ModelIdInput>
    roomBookingsSum?: InputMaybe<ModelIntInput>
    roomCapacityMax?: InputMaybe<ModelIntInput>
    roomNameLowerCase?: InputMaybe<ModelStringInput>
    roomWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
    visitors?: InputMaybe<ModelIntInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelRulesConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelRulesConditionInput>>>
    autoAssignWaitingList?: InputMaybe<ModelBooleanInput>
    blockReservationOnNonWorkingDays?: InputMaybe<ModelBooleanInput>
    checkInCancellingTime?: InputMaybe<ModelIntInput>
    checkInWaitingTime?: InputMaybe<ModelIntInput>
    createdAt?: InputMaybe<ModelStringInput>
    dayOfAttendanceNotifications?: InputMaybe<ModelIntInput>
    defaultShareAttendance?: InputMaybe<ModelShareAttendanceInput>
    deleteDataAfter?: InputMaybe<ModelIntInput>
    deletePersonalDataAfter?: InputMaybe<ModelIntInput>
    denyRecurringBookingOverThePlanningHorizon?: InputMaybe<ModelBooleanInput>
    disableOnboardingQuiz?: InputMaybe<ModelBooleanInput>
    disableProfileEditing?: InputMaybe<ModelBooleanInput>
    emailForMonthlyReport?: InputMaybe<ModelStringInput>
    enableMinDaysInOffice?: InputMaybe<ModelBooleanInput>
    homeIsDefault?: InputMaybe<ModelBooleanInput>
    horizonOfPlanningInDays?: InputMaybe<ModelIntInput>
    horizonOfPlanningTimeAmount?: InputMaybe<ModelIntInput>
    horizonOfPlanningTimeUnit?: InputMaybe<ModelHorizonOfPlanningTimeUnitInput>
    hourOfAttendanceNotifications?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelStringInput>
    isPersonalSeatAvailableToday?: InputMaybe<ModelBooleanInput>
    isTeamSeatAvailableToday?: InputMaybe<ModelBooleanInput>
    maxDaysInOffice?: InputMaybe<ModelIntInput>
    minDaysInOffice?: InputMaybe<ModelIntInput>
    minDaysInOfficeTimeUnit?: InputMaybe<ModelMinDaysInOfficeTimeUnitInput>
    not?: InputMaybe<ModelRulesConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelRulesConditionInput>>>
    startTimeOfNotifications?: InputMaybe<ModelIntInput>
    turnOffCheckInForPersonalSeats?: InputMaybe<ModelBooleanInput>
    turnOnAttendanceNotifications?: InputMaybe<ModelBooleanInput>
    turnOnCheckIn?: InputMaybe<ModelBooleanInput>
    turnOnDeletingData?: InputMaybe<ModelBooleanInput>
    turnOnExperimentalFeatures?: InputMaybe<ModelBooleanInput>
    turnOnMonthlyReport?: InputMaybe<ModelBooleanInput>
    updateEmployeeDataFromSource?: InputMaybe<ModelBooleanInput>
    updatedAt?: InputMaybe<ModelStringInput>
    workingDays?: InputMaybe<ModelStringInput>
}

export type ModelRulesConnection = {
    __typename?: 'ModelRulesConnection'
    items: Array<Maybe<Rules>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelRulesFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelRulesFilterInput>>>
    autoAssignWaitingList?: InputMaybe<ModelBooleanInput>
    blockReservationOnNonWorkingDays?: InputMaybe<ModelBooleanInput>
    checkInCancellingTime?: InputMaybe<ModelIntInput>
    checkInWaitingTime?: InputMaybe<ModelIntInput>
    createdAt?: InputMaybe<ModelStringInput>
    dayOfAttendanceNotifications?: InputMaybe<ModelIntInput>
    defaultShareAttendance?: InputMaybe<ModelShareAttendanceInput>
    deleteDataAfter?: InputMaybe<ModelIntInput>
    deletePersonalDataAfter?: InputMaybe<ModelIntInput>
    denyRecurringBookingOverThePlanningHorizon?: InputMaybe<ModelBooleanInput>
    disableOnboardingQuiz?: InputMaybe<ModelBooleanInput>
    disableProfileEditing?: InputMaybe<ModelBooleanInput>
    emailForMonthlyReport?: InputMaybe<ModelStringInput>
    enableMinDaysInOffice?: InputMaybe<ModelBooleanInput>
    homeIsDefault?: InputMaybe<ModelBooleanInput>
    horizonOfPlanningInDays?: InputMaybe<ModelIntInput>
    horizonOfPlanningTimeAmount?: InputMaybe<ModelIntInput>
    horizonOfPlanningTimeUnit?: InputMaybe<ModelHorizonOfPlanningTimeUnitInput>
    hourOfAttendanceNotifications?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    isPersonalSeatAvailableToday?: InputMaybe<ModelBooleanInput>
    isTeamSeatAvailableToday?: InputMaybe<ModelBooleanInput>
    maxDaysInOffice?: InputMaybe<ModelIntInput>
    minDaysInOffice?: InputMaybe<ModelIntInput>
    minDaysInOfficeTimeUnit?: InputMaybe<ModelMinDaysInOfficeTimeUnitInput>
    not?: InputMaybe<ModelRulesFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelRulesFilterInput>>>
    startTimeOfNotifications?: InputMaybe<ModelIntInput>
    turnOffCheckInForPersonalSeats?: InputMaybe<ModelBooleanInput>
    turnOnAttendanceNotifications?: InputMaybe<ModelBooleanInput>
    turnOnCheckIn?: InputMaybe<ModelBooleanInput>
    turnOnDeletingData?: InputMaybe<ModelBooleanInput>
    turnOnExperimentalFeatures?: InputMaybe<ModelBooleanInput>
    turnOnMonthlyReport?: InputMaybe<ModelBooleanInput>
    updateEmployeeDataFromSource?: InputMaybe<ModelBooleanInput>
    updatedAt?: InputMaybe<ModelStringInput>
    workingDays?: InputMaybe<ModelStringInput>
}

export type ModelSsoTypeInput = {
    eq?: InputMaybe<SsoType>
    ne?: InputMaybe<SsoType>
}

export type ModelScopeInput = {
    eq?: InputMaybe<Scope>
    ne?: InputMaybe<Scope>
}

export type ModelShareAttendanceInput = {
    eq?: InputMaybe<ShareAttendance>
    ne?: InputMaybe<ShareAttendance>
}

export type ModelSizeInput = {
    between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    eq?: InputMaybe<Scalars['Int']['input']>
    ge?: InputMaybe<Scalars['Int']['input']>
    gt?: InputMaybe<Scalars['Int']['input']>
    le?: InputMaybe<Scalars['Int']['input']>
    lt?: InputMaybe<Scalars['Int']['input']>
    ne?: InputMaybe<Scalars['Int']['input']>
}

export type ModelSlackOAuthStateConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSlackOAuthStateConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelSlackOAuthStateConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelSlackOAuthStateConditionInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSlackOAuthStateConnection = {
    __typename?: 'ModelSlackOAuthStateConnection'
    items: Array<Maybe<SlackOAuthState>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSlackOAuthStateFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSlackOAuthStateFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSlackOAuthStateFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelSlackOAuthStateFilterInput>>>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSlackOnboradingMessageConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSlackOnboradingMessageConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSlackOnboradingMessageConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelSlackOnboradingMessageConditionInput>>>
    slackMessageID?: InputMaybe<ModelStringInput>
    slackOnboradingMessageCompanyId?: InputMaybe<ModelIdInput>
    slackOnboradingMessageEmployeeId?: InputMaybe<ModelIdInput>
    state?: InputMaybe<ModelSlackOnboradingMessageStateInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSlackOnboradingMessageConnection = {
    __typename?: 'ModelSlackOnboradingMessageConnection'
    items: Array<Maybe<SlackOnboradingMessage>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSlackOnboradingMessageFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSlackOnboradingMessageFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSlackOnboradingMessageFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelSlackOnboradingMessageFilterInput>>>
    slackMessageID?: InputMaybe<ModelStringInput>
    slackOnboradingMessageCompanyId?: InputMaybe<ModelIdInput>
    slackOnboradingMessageEmployeeId?: InputMaybe<ModelIdInput>
    state?: InputMaybe<ModelSlackOnboradingMessageStateInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSlackOnboradingMessageStateInput = {
    eq?: InputMaybe<SlackOnboradingMessageState>
    ne?: InputMaybe<SlackOnboradingMessageState>
}

export enum ModelSortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyInput>
    le?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyInput>
}

export type ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyInput = {
    createdAt?: InputMaybe<Scalars['String']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
}

export type ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyInput>>>
    eq?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyInput>
    le?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyInput>
}

export type ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
}

export type ModelSpaceCapacityDayAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSpaceCapacityDayAggregationConditionInput>>>
    availableSeatsSum?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSpaceCapacityDayAggregationConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceCapacityDayAggregationConditionInput>>>
    spaceCapacityDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    spaceCapacityDayAggregationEmployeeId?: InputMaybe<ModelIdInput>
    spaceCapacityDayAggregationSpaceId?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    ttl?: InputMaybe<ModelIntInput>
    type?: InputMaybe<ModelSpaceCapacityDayAggregationTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    waitingListItemsSum?: InputMaybe<ModelIntInput>
}

export type ModelSpaceCapacityDayAggregationConnection = {
    __typename?: 'ModelSpaceCapacityDayAggregationConnection'
    items: Array<Maybe<SpaceCapacityDayAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSpaceCapacityDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSpaceCapacityDayAggregationFilterInput>>>
    availableSeatsSum?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSpaceCapacityDayAggregationFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceCapacityDayAggregationFilterInput>>>
    spaceCapacityDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    spaceCapacityDayAggregationEmployeeId?: InputMaybe<ModelIdInput>
    spaceCapacityDayAggregationSpaceId?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    ttl?: InputMaybe<ModelIntInput>
    type?: InputMaybe<ModelSpaceCapacityDayAggregationTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    waitingListItemsSum?: InputMaybe<ModelIntInput>
}

export type ModelSpaceCapacityDayAggregationTypeInput = {
    eq?: InputMaybe<SpaceCapacityDayAggregationType>
    ne?: InputMaybe<SpaceCapacityDayAggregationType>
}

export type ModelSpaceConditionInput = {
    address?: InputMaybe<ModelStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelSpaceConditionInput>>>
    availableParkingSlots?: InputMaybe<ModelIntInput>
    availableParkingSlotsByPeriod?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    floorPlanFilePath?: InputMaybe<ModelStringInput>
    isAvailableToEmployees?: InputMaybe<ModelBooleanInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelSpaceConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceConditionInput>>>
    order?: InputMaybe<ModelIntInput>
    spaceCompanyId?: InputMaybe<ModelIdInput>
    spaceOfficeId?: InputMaybe<ModelIdInput>
    spaceRowID?: InputMaybe<ModelIdInput>
    suggestedSeatID?: InputMaybe<ModelIdInput>
    timeZone?: InputMaybe<ModelStringInput>
    totalParkingSlots?: InputMaybe<ModelIntInput>
    totalRooms?: InputMaybe<ModelIntInput>
    totalSeats?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    workingHoursFrom?: InputMaybe<ModelStringInput>
    workingHoursTo?: InputMaybe<ModelStringInput>
}

export type ModelSpaceConnection = {
    __typename?: 'ModelSpaceConnection'
    items: Array<Maybe<Space>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyInput>>>
    eq?: InputMaybe<ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyInput>
    le?: InputMaybe<ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyInput>
}

export type ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
}

export type ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyInput>>>
    eq?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyInput>
    le?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyInput>
}

export type ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    le?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput>
}

export type ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelSpaceDayAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSpaceDayAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelSpaceDayAggregationConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceDayAggregationConditionInput>>>
    spaceBookingsSum?: InputMaybe<ModelIntInput>
    spaceCapacityLast?: InputMaybe<ModelIntInput>
    spaceDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelSpaceDayAggregationConnection = {
    __typename?: 'ModelSpaceDayAggregationConnection'
    items: Array<Maybe<SpaceDayAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSpaceDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSpaceDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    homeBookingsSum?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSpaceDayAggregationFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceDayAggregationFilterInput>>>
    spaceBookingsSum?: InputMaybe<ModelIntInput>
    spaceCapacityLast?: InputMaybe<ModelIntInput>
    spaceDayAggregationCompanyId?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelSpaceFilterInput = {
    address?: InputMaybe<ModelStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelSpaceFilterInput>>>
    availableParkingSlots?: InputMaybe<ModelIntInput>
    availableParkingSlotsByPeriod?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    floorPlanFilePath?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    isAvailableToEmployees?: InputMaybe<ModelBooleanInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelSpaceFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceFilterInput>>>
    order?: InputMaybe<ModelIntInput>
    spaceCompanyId?: InputMaybe<ModelIdInput>
    spaceOfficeId?: InputMaybe<ModelIdInput>
    spaceRowID?: InputMaybe<ModelIdInput>
    suggestedSeatID?: InputMaybe<ModelIdInput>
    timeZone?: InputMaybe<ModelStringInput>
    totalParkingSlots?: InputMaybe<ModelIntInput>
    totalRooms?: InputMaybe<ModelIntInput>
    totalSeats?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
    workingHoursFrom?: InputMaybe<ModelStringInput>
    workingHoursTo?: InputMaybe<ModelStringInput>
}

export type ModelSpaceRowConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSpaceRowConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelSpaceRowConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceRowConditionInput>>>
    order?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSpaceRowConnection = {
    __typename?: 'ModelSpaceRowConnection'
    items: Array<Maybe<SpaceRow>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSpaceRowFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSpaceRowFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSpaceRowFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceRowFilterInput>>>
    order?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<
        Array<InputMaybe<ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>>
    >
    eq?: InputMaybe<ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyInput>>>
    eq?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyInput>
    le?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyInput>
}

export type ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyInput = {
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>>>
    eq?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    ge?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    gt?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    le?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
    lt?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput>
}

export type ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyInput = {
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type ModelSpaceWeekAggregationConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSpaceWeekAggregationConditionInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeesLast?: InputMaybe<ModelIntInput>
    not?: InputMaybe<ModelSpaceWeekAggregationConditionInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceWeekAggregationConditionInput>>>
    percentageOfUtilization?: InputMaybe<ModelIntInput>
    spaceBookingsSum?: InputMaybe<ModelIntInput>
    spaceCapacityMax?: InputMaybe<ModelIntInput>
    spaceID?: InputMaybe<ModelIdInput>
    spaceWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelSpaceWeekAggregationConnection = {
    __typename?: 'ModelSpaceWeekAggregationConnection'
    items: Array<Maybe<SpaceWeekAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSpaceWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSpaceWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelIntInput>
    capacityInHours?: InputMaybe<ModelIntInput>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    employeesLast?: InputMaybe<ModelIntInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSpaceWeekAggregationFilterInput>
    officeID?: InputMaybe<ModelIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSpaceWeekAggregationFilterInput>>>
    percentageOfUtilization?: InputMaybe<ModelIntInput>
    spaceBookingsSum?: InputMaybe<ModelIntInput>
    spaceCapacityMax?: InputMaybe<ModelIntInput>
    spaceID?: InputMaybe<ModelIdInput>
    spaceWeekAggregationCompanyId?: InputMaybe<ModelIdInput>
    type?: InputMaybe<ModelAggregationByReservableTypeInput>
    updatedAt?: InputMaybe<ModelStringInput>
    weekNumber?: InputMaybe<ModelIntInput>
    weekNumberString?: InputMaybe<ModelStringInput>
    year?: InputMaybe<ModelIntInput>
}

export type ModelStringInput = {
    attributeExists?: InputMaybe<Scalars['Boolean']['input']>
    attributeType?: InputMaybe<ModelAttributeTypes>
    beginsWith?: InputMaybe<Scalars['String']['input']>
    between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contains?: InputMaybe<Scalars['String']['input']>
    eq?: InputMaybe<Scalars['String']['input']>
    ge?: InputMaybe<Scalars['String']['input']>
    gt?: InputMaybe<Scalars['String']['input']>
    le?: InputMaybe<Scalars['String']['input']>
    lt?: InputMaybe<Scalars['String']['input']>
    ne?: InputMaybe<Scalars['String']['input']>
    notContains?: InputMaybe<Scalars['String']['input']>
    size?: InputMaybe<ModelSizeInput>
}

export type ModelStringKeyConditionInput = {
    beginsWith?: InputMaybe<Scalars['String']['input']>
    between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    eq?: InputMaybe<Scalars['String']['input']>
    ge?: InputMaybe<Scalars['String']['input']>
    gt?: InputMaybe<Scalars['String']['input']>
    le?: InputMaybe<Scalars['String']['input']>
    lt?: InputMaybe<Scalars['String']['input']>
}

export type ModelSubscriptionAddressFilterInput = {
    address?: InputMaybe<ModelSubscriptionStringInput>
    addressCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionAddressFilterInput>>>
    city?: InputMaybe<ModelSubscriptionStringInput>
    companyID?: InputMaybe<ModelStringInput>
    country?: InputMaybe<ModelSubscriptionStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    lat?: InputMaybe<ModelSubscriptionFloatInput>
    long?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionAddressFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    zipcode?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionBookingCancellationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingCancellationFilterInput>>>
    bookingCancellationBookingId?: InputMaybe<ModelSubscriptionIdInput>
    bookingCancellationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    bookingCancellationEmployeeId?: InputMaybe<ModelSubscriptionIdInput>
    bookingID?: InputMaybe<ModelSubscriptionIdInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingCancellationFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    removeRepeatable?: InputMaybe<ModelSubscriptionBooleanInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionBookingEquipmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingEquipmentFilterInput>>>
    calendarEventID?: InputMaybe<ModelSubscriptionIdInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    endTime?: InputMaybe<ModelSubscriptionStringInput>
    equipmentID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isAllDay?: InputMaybe<ModelSubscriptionBooleanInput>
    isPrivate?: InputMaybe<ModelSubscriptionBooleanInput>
    isoWeekNumber?: InputMaybe<ModelSubscriptionIntInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingEquipmentFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    startTime?: InputMaybe<ModelSubscriptionStringInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionBookingFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingFilterInput>>>
    bookingBookingRequestId?: InputMaybe<ModelSubscriptionIdInput>
    bookingCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    bookingEmployeeId?: InputMaybe<ModelSubscriptionIdInput>
    bookingRequestID?: InputMaybe<ModelSubscriptionIdInput>
    bookingReservableId?: InputMaybe<ModelSubscriptionIdInput>
    bookingSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    bookingSubType?: InputMaybe<ModelSubscriptionStringInput>
    bookingTeamEventRequestId?: InputMaybe<ModelSubscriptionIdInput>
    bookingType?: InputMaybe<ModelSubscriptionStringInput>
    checkInStatus?: InputMaybe<ModelSubscriptionStringInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    dayOfWeek?: InputMaybe<ModelSubscriptionStringInput>
    description?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    endBookingTime?: InputMaybe<ModelSubscriptionStringInput>
    endTime?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isBlocked?: InputMaybe<ModelSubscriptionBooleanInput>
    isFullDay?: InputMaybe<ModelSubscriptionBooleanInput>
    isTeamEvent?: InputMaybe<ModelSubscriptionBooleanInput>
    isoWeekNumber?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    slackCheckInMessageId?: InputMaybe<ModelSubscriptionStringInput>
    source?: InputMaybe<ModelSubscriptionStringInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    startBookingTime?: InputMaybe<ModelSubscriptionStringInput>
    startTime?: InputMaybe<ModelSubscriptionStringInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    teamEventRequestID?: InputMaybe<ModelSubscriptionIdInput>
    title?: InputMaybe<ModelSubscriptionStringInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionBookingRequestFilterInput = {
    addParkingSlot?: InputMaybe<ModelSubscriptionBooleanInput>
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingRequestFilterInput>>>
    bookingRequestCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    bookingRequestEmployeeId?: InputMaybe<ModelSubscriptionIdInput>
    bookingRequestPreviousBookingRequestId?: InputMaybe<ModelSubscriptionIdInput>
    bookingRequestReservableId?: InputMaybe<ModelSubscriptionIdInput>
    bookingRequestSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    bookingSubType?: InputMaybe<ModelSubscriptionStringInput>
    bookingType?: InputMaybe<ModelSubscriptionStringInput>
    checkInStatus?: InputMaybe<ModelSubscriptionStringInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    dates?: InputMaybe<ModelSubscriptionStringInput>
    daysOfWeek?: InputMaybe<ModelSubscriptionStringInput>
    effectiveFrom?: InputMaybe<ModelSubscriptionStringInput>
    effectiveTo?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    endTime?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isFullDay?: InputMaybe<ModelSubscriptionBooleanInput>
    isTeamEvent?: InputMaybe<ModelSubscriptionBooleanInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingRequestFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    parkingSlotID?: InputMaybe<ModelSubscriptionIdInput>
    previousBookingRequestID?: InputMaybe<ModelSubscriptionIdInput>
    repeatType?: InputMaybe<ModelSubscriptionStringInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    source?: InputMaybe<ModelSubscriptionStringInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    startTime?: InputMaybe<ModelSubscriptionStringInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionBookingReservableFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingReservableFilterInput>>>
    bookingID?: InputMaybe<ModelSubscriptionIdInput>
    bookingReservableCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    checkInStatus?: InputMaybe<ModelSubscriptionStringInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    dayOfWeek?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    endBookingTime?: InputMaybe<ModelSubscriptionStringInput>
    endTime?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionBookingReservableFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    startBookingTime?: InputMaybe<ModelSubscriptionStringInput>
    startTime?: InputMaybe<ModelSubscriptionStringInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionBooleanInput = {
    eq?: InputMaybe<Scalars['Boolean']['input']>
    ne?: InputMaybe<Scalars['Boolean']['input']>
}

export type ModelSubscriptionCalendarEventEmployeeFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCalendarEventEmployeeFilterInput>>>
    calendarEventID?: InputMaybe<ModelSubscriptionIdInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    employeeFullName?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isOwner?: InputMaybe<ModelSubscriptionBooleanInput>
    isoWeekNumber?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCalendarEventEmployeeFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionCalendarEventRequestFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCalendarEventRequestFilterInput>>>
    calendarEventRequestCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    calendarEventRequestPreviousCalendarEventRequestId?: InputMaybe<ModelSubscriptionIdInput>
    calendarEventRequestReservableId?: InputMaybe<ModelSubscriptionIdInput>
    calendarEventRequestSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    cateringID?: InputMaybe<ModelSubscriptionIdInput>
    checkInStatus?: InputMaybe<ModelSubscriptionStringInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    dates?: InputMaybe<ModelSubscriptionStringInput>
    daysOfWeek?: InputMaybe<ModelSubscriptionStringInput>
    description?: InputMaybe<ModelSubscriptionStringInput>
    effectiveFrom?: InputMaybe<ModelSubscriptionStringInput>
    effectiveTo?: InputMaybe<ModelSubscriptionStringInput>
    employeesIDs?: InputMaybe<ModelSubscriptionIdInput>
    endTime?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isAllDay?: InputMaybe<ModelSubscriptionBooleanInput>
    isOnlineMeeting?: InputMaybe<ModelSubscriptionBooleanInput>
    isPrivate?: InputMaybe<ModelSubscriptionBooleanInput>
    onlineMeetingUrl?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCalendarEventRequestFilterInput>>>
    organiserEmployeeID?: InputMaybe<ModelSubscriptionIdInput>
    owner?: InputMaybe<ModelStringInput>
    previousCalendarEventRequestID?: InputMaybe<ModelSubscriptionIdInput>
    repeatType?: InputMaybe<ModelSubscriptionStringInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    source?: InputMaybe<ModelSubscriptionStringInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    startTime?: InputMaybe<ModelSubscriptionStringInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    title?: InputMaybe<ModelSubscriptionStringInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    useRelatedReservable?: InputMaybe<ModelSubscriptionBooleanInput>
}

export type ModelSubscriptionCalendarEventsFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCalendarEventsFilterInput>>>
    calendarEventRequestID?: InputMaybe<ModelSubscriptionIdInput>
    canCurrentUserEdit?: InputMaybe<ModelSubscriptionBooleanInput>
    cateringID?: InputMaybe<ModelSubscriptionIdInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    dayOfMonth?: InputMaybe<ModelSubscriptionIntInput>
    daysOfWeek?: InputMaybe<ModelSubscriptionStringInput>
    description?: InputMaybe<ModelSubscriptionStringInput>
    employeesIDs?: InputMaybe<ModelSubscriptionIdInput>
    endTime?: InputMaybe<ModelSubscriptionStringInput>
    firstDayOfWeek?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    interval?: InputMaybe<ModelSubscriptionIntInput>
    isAllDay?: InputMaybe<ModelSubscriptionBooleanInput>
    isOnlineMeeting?: InputMaybe<ModelSubscriptionBooleanInput>
    isPrivate?: InputMaybe<ModelSubscriptionBooleanInput>
    month?: InputMaybe<ModelSubscriptionIntInput>
    numberOfOccurrences?: InputMaybe<ModelSubscriptionIntInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    onlineMeetingUrl?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCalendarEventsFilterInput>>>
    organiserEmployeeID?: InputMaybe<ModelSubscriptionIdInput>
    owner?: InputMaybe<ModelStringInput>
    parentEventExternalId?: InputMaybe<ModelSubscriptionStringInput>
    patternType?: InputMaybe<ModelSubscriptionStringInput>
    rangeEndDate?: InputMaybe<ModelSubscriptionStringInput>
    rangeStartDate?: InputMaybe<ModelSubscriptionStringInput>
    rangeType?: InputMaybe<ModelSubscriptionStringInput>
    recurrenceTimeZone?: InputMaybe<ModelSubscriptionStringInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    source?: InputMaybe<ModelSubscriptionStringInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    startTime?: InputMaybe<ModelSubscriptionStringInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    title?: InputMaybe<ModelSubscriptionStringInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    useRelatedReservable?: InputMaybe<ModelSubscriptionBooleanInput>
    weekIndex?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionCateringFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCateringFilterInput>>>
    cateringCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    email?: InputMaybe<ModelSubscriptionStringInput>
    enabled?: InputMaybe<ModelSubscriptionBooleanInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCateringFilterInput>>>
    title?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionCompanyDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCompanyDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyDayAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    employeesInOffice?: InputMaybe<ModelSubscriptionIntInput>
    homeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    officeCapacityLast?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCompanyDayAggregationFilterInput>>>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionCompanyFilterInput = {
    address?: InputMaybe<ModelSubscriptionStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCompanyFilterInput>>>
    calendarIntegration?: InputMaybe<ModelSubscriptionStringInput>
    city?: InputMaybe<ModelSubscriptionStringInput>
    companyLogo?: InputMaybe<ModelSubscriptionStringInput>
    companyRulesId?: InputMaybe<ModelSubscriptionIdInput>
    country?: InputMaybe<ModelSubscriptionStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelStringInput>
    microsoftTenantId?: InputMaybe<ModelSubscriptionStringInput>
    numberOfEmployees?: InputMaybe<ModelSubscriptionIntInput>
    numberOfOffices?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCompanyFilterInput>>>
    phoneNumber?: InputMaybe<ModelSubscriptionStringInput>
    ssoIntegration?: InputMaybe<ModelSubscriptionStringInput>
    tariff?: InputMaybe<ModelSubscriptionStringInput>
    title?: InputMaybe<ModelSubscriptionStringInput>
    trialExpiresAt?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    userProvisioningIntegration?: InputMaybe<ModelSubscriptionStringInput>
    zipcode?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionCompanyWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCompanyWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    companyWeekAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    employeesInOffice?: InputMaybe<ModelSubscriptionIntInput>
    employeesLast?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    officeCapacityMax?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCompanyWeekAggregationFilterInput>>>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionCustomRoleFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCustomRoleFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    description?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCustomRoleFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionDepartmentDayAggregationEmployeesFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentDayAggregationEmployeesFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    departmentDayAggregationID?: InputMaybe<ModelSubscriptionIdInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentDayAggregationEmployeesFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionDepartmentDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentDayAggregationFilterInput>>>
    averageDaysInOffice?: InputMaybe<ModelSubscriptionFloatInput>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    departmentDayAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    departmentID?: InputMaybe<ModelSubscriptionIdInput>
    homeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    notAvailableBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    officeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentDayAggregationFilterInput>>>
    teamSize?: InputMaybe<ModelSubscriptionIntInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionDepartmentLastMonthAttendanceFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentLastMonthAttendanceFilterInput>>>
    attendanceScore?: InputMaybe<ModelSubscriptionIntInput>
    attendanceScoreString?: InputMaybe<ModelSubscriptionStringInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    departmentID?: InputMaybe<ModelSubscriptionIdInput>
    departmentLastMonthAttendanceCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    departmentLastMonthAttendanceDepartmentId?: InputMaybe<ModelSubscriptionIdInput>
    departmentNameLowerCase?: InputMaybe<ModelSubscriptionStringInput>
    employeesLast?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    lastDateTogether?: InputMaybe<ModelSubscriptionStringInput>
    lastDateTogetherForSorting?: InputMaybe<ModelSubscriptionStringInput>
    monthlyAttendance?: InputMaybe<ModelSubscriptionFloatInput>
    monthlyAttendanceString?: InputMaybe<ModelSubscriptionStringInput>
    officeDaysScore?: InputMaybe<ModelSubscriptionIntInput>
    officeDaysScoreString?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentLastMonthAttendanceFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weeklyAttendance?: InputMaybe<ModelSubscriptionFloatInput>
    weeklyAttendanceString?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionDepartmentRulesFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentRulesFilterInput>>>
    blockReservationOnNonOfficeDays?: InputMaybe<ModelSubscriptionBooleanInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    departmentID?: InputMaybe<ModelSubscriptionIdInput>
    enableMinDaysInOffice?: InputMaybe<ModelSubscriptionBooleanInput>
    horizonOfPlanningInDays?: InputMaybe<ModelSubscriptionIntInput>
    horizonOfPlanningTimeAmount?: InputMaybe<ModelSubscriptionIntInput>
    horizonOfPlanningTimeUnit?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    maxDaysInOffice?: InputMaybe<ModelSubscriptionIntInput>
    minDaysInOffice?: InputMaybe<ModelSubscriptionIntInput>
    minDaysInOfficeTimeUnit?: InputMaybe<ModelSubscriptionStringInput>
    officeDays?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentRulesFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionDepartmentWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentWeekAggregationFilterInput>>>
    averageDaysInOffice?: InputMaybe<ModelSubscriptionFloatInput>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    departmentID?: InputMaybe<ModelSubscriptionIdInput>
    departmentWeekAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    employeesInOffice?: InputMaybe<ModelSubscriptionIntInput>
    employeesLast?: InputMaybe<ModelSubscriptionIntInput>
    homeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    notAvailableBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    officeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionDepartmentWeekAggregationFilterInput>>>
    teamSize?: InputMaybe<ModelSubscriptionIntInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionEmployeeDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionEmployeeDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    employeeDayAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    employeesLast?: InputMaybe<ModelSubscriptionIntInput>
    homeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    notAvailableBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    officeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionEmployeeDayAggregationFilterInput>>>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionEmployeeLastMonthAttendanceFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionEmployeeLastMonthAttendanceFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    departmentID?: InputMaybe<ModelSubscriptionIdInput>
    departmentIDs?: InputMaybe<ModelSubscriptionIdInput>
    departmentIDsString?: InputMaybe<ModelSubscriptionStringInput>
    departmentNamesString?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    employeeLastMonthAttendanceCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    employeeLastMonthAttendanceDepartmentId?: InputMaybe<ModelSubscriptionIdInput>
    employeeLastMonthAttendanceEmployeeId?: InputMaybe<ModelSubscriptionIdInput>
    employeeNameLowerCase?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    lastDateInOffice?: InputMaybe<ModelSubscriptionStringInput>
    lastDateInOfficeForSorting?: InputMaybe<ModelSubscriptionStringInput>
    monthlyAttendance?: InputMaybe<ModelSubscriptionFloatInput>
    monthlyAttendanceString?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionEmployeeLastMonthAttendanceFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weeklyAttendance?: InputMaybe<ModelSubscriptionFloatInput>
    weeklyAttendanceString?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionEmployeeToDepartmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionEmployeeToDepartmentFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    departmentID?: InputMaybe<ModelSubscriptionIdInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    employeeToDepartmentCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionEmployeeToDepartmentFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionEmployeeWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionEmployeeWeekAggregationFilterInput>>>
    averageDaysInOffice?: InputMaybe<ModelSubscriptionFloatInput>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    employeeWeekAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    employeesInOffice?: InputMaybe<ModelSubscriptionIntInput>
    employeesLast?: InputMaybe<ModelSubscriptionIntInput>
    homeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    notAvailableBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    officeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionEmployeeWeekAggregationFilterInput>>>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionEquipmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionEquipmentFilterInput>>>
    canBookedSeparately?: InputMaybe<ModelSubscriptionBooleanInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    description?: InputMaybe<ModelSubscriptionStringInput>
    email?: InputMaybe<ModelSubscriptionStringInput>
    enabled?: InputMaybe<ModelSubscriptionBooleanInput>
    equipmentCategoryID?: InputMaybe<ModelSubscriptionIdInput>
    equipmentCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    equipmentEquipmentCategoryId?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionEquipmentFilterInput>>>
    quantity?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionEquipmentImageFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionEquipmentImageFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    equipmentID?: InputMaybe<ModelSubscriptionIdInput>
    equipmentImageCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isMain?: InputMaybe<ModelSubscriptionBooleanInput>
    location?: InputMaybe<ModelSubscriptionStringInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionEquipmentImageFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionFloatInput = {
    between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
    eq?: InputMaybe<Scalars['Float']['input']>
    ge?: InputMaybe<Scalars['Float']['input']>
    gt?: InputMaybe<Scalars['Float']['input']>
    in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
    le?: InputMaybe<Scalars['Float']['input']>
    lt?: InputMaybe<Scalars['Float']['input']>
    ne?: InputMaybe<Scalars['Float']['input']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
}

export type ModelSubscriptionGoogleImportedBookingsFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionGoogleImportedBookingsFilterInput>>>
    calendarEventID?: InputMaybe<ModelSubscriptionIdInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    googleID?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionGoogleImportedBookingsFilterInput>>>
    updated?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionGoogleImportedWorkingLocationsFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionGoogleImportedWorkingLocationsFilterInput>>>
    bookingID?: InputMaybe<ModelSubscriptionIdInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    googleID?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionGoogleImportedWorkingLocationsFilterInput>>>
    updated?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionIdInput = {
    beginsWith?: InputMaybe<Scalars['ID']['input']>
    between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    contains?: InputMaybe<Scalars['ID']['input']>
    eq?: InputMaybe<Scalars['ID']['input']>
    ge?: InputMaybe<Scalars['ID']['input']>
    gt?: InputMaybe<Scalars['ID']['input']>
    in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    le?: InputMaybe<Scalars['ID']['input']>
    lt?: InputMaybe<Scalars['ID']['input']>
    ne?: InputMaybe<Scalars['ID']['input']>
    notContains?: InputMaybe<Scalars['ID']['input']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type ModelSubscriptionIntInput = {
    between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    eq?: InputMaybe<Scalars['Int']['input']>
    ge?: InputMaybe<Scalars['Int']['input']>
    gt?: InputMaybe<Scalars['Int']['input']>
    in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
    le?: InputMaybe<Scalars['Int']['input']>
    lt?: InputMaybe<Scalars['Int']['input']>
    ne?: InputMaybe<Scalars['Int']['input']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export type ModelSubscriptionIntegrationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionIntegrationFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    group?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    integrationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionIntegrationFilterInput>>>
    order?: InputMaybe<ModelSubscriptionIntInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionMenuItemFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionMenuItemFilterInput>>>
    cateringID?: InputMaybe<ModelSubscriptionIdInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    description?: InputMaybe<ModelSubscriptionStringInput>
    enabled?: InputMaybe<ModelSubscriptionBooleanInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    menuItemCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionMenuItemFilterInput>>>
    title?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionMicrosoftSubscriptionFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionMicrosoftSubscriptionFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isFreeTrial?: InputMaybe<ModelSubscriptionBooleanInput>
    microsoftSubscriptionCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    offerId?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionMicrosoftSubscriptionFilterInput>>>
    ownerEmail?: InputMaybe<ModelSubscriptionStringInput>
    ownerID?: InputMaybe<ModelSubscriptionIdInput>
    ownerName?: InputMaybe<ModelSubscriptionStringInput>
    planId?: InputMaybe<ModelSubscriptionStringInput>
    quantity?: InputMaybe<ModelSubscriptionIntInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    tenantId?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionNotificationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionNotificationFilterInput>>>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionNotificationFilterInput>>>
    placeholders?: InputMaybe<ModelSubscriptionStringInput>
    sentAt?: InputMaybe<ModelSubscriptionStringInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionOfficeBuilderRequestFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeBuilderRequestFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeBuilderRequestFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionOfficeDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    homeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    officeCapacityLast?: InputMaybe<ModelSubscriptionIntInput>
    officeDayAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    officeDayAggregationOfficeId?: InputMaybe<ModelSubscriptionIdInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeDayAggregationFilterInput>>>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionOfficeFilterInput = {
    address?: InputMaybe<ModelSubscriptionStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    externalOfficeId?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    officeAddressId?: InputMaybe<ModelSubscriptionIdInput>
    officeCateringId?: InputMaybe<ModelSubscriptionIdInput>
    officeCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeFilterInput>>>
    order?: InputMaybe<ModelSubscriptionIntInput>
    phoneNumbers?: InputMaybe<ModelSubscriptionStringInput>
    placesBlocked?: InputMaybe<ModelSubscriptionIntInput>
    placesFixed?: InputMaybe<ModelSubscriptionIntInput>
    placesFlex?: InputMaybe<ModelSubscriptionIntInput>
    placesTotal?: InputMaybe<ModelSubscriptionIntInput>
    properties?: InputMaybe<ModelSubscriptionStringInput>
    shortDescription?: InputMaybe<ModelSubscriptionStringInput>
    timeZone?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    workingFrom?: InputMaybe<ModelSubscriptionStringInput>
    workingHours?: InputMaybe<ModelSubscriptionStringInput>
    workingTo?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionOfficeImageFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeImageFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    location?: InputMaybe<ModelSubscriptionStringInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    officeImageCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    officeImageOfficeId?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeImageFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionOfficeWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    employeesLast?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    officeCapacityMax?: InputMaybe<ModelSubscriptionIntInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    officeWeekAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    officeWeekAggregationOfficeId?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionOfficeWeekAggregationFilterInput>>>
    percentageOfUtilization?: InputMaybe<ModelSubscriptionIntInput>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionPermissionFilterInput = {
    allowedActions?: InputMaybe<ModelSubscriptionStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPermissionFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPermissionFilterInput>>>
    resourceType?: InputMaybe<ModelSubscriptionStringInput>
    roleID?: InputMaybe<ModelSubscriptionIdInput>
    scope?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionPersonioImportedAbsencesFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPersonioImportedAbsencesFilterInput>>>
    bookingRequestID?: InputMaybe<ModelSubscriptionIdInput>
    bookingSubType?: InputMaybe<ModelSubscriptionStringInput>
    bookingType?: InputMaybe<ModelSubscriptionStringInput>
    companyID?: InputMaybe<ModelSubscriptionIdInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    effectiveFrom?: InputMaybe<ModelSubscriptionStringInput>
    effectiveTo?: InputMaybe<ModelSubscriptionStringInput>
    employeeEmail?: InputMaybe<ModelSubscriptionStringInput>
    halfDayEnd?: InputMaybe<ModelSubscriptionBooleanInput>
    halfDayStart?: InputMaybe<ModelSubscriptionBooleanInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPersonioImportedAbsencesFilterInput>>>
    personioID?: InputMaybe<ModelSubscriptionIntInput>
    personioImportedAbsencesBookingRequestId?: InputMaybe<ModelSubscriptionIdInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionPersonioImportedBookingsFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPersonioImportedBookingsFilterInput>>>
    bookingID?: InputMaybe<ModelSubscriptionIdInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    effectiveFrom?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPersonioImportedBookingsFilterInput>>>
    personioID?: InputMaybe<ModelSubscriptionStringInput>
    personioImportedBookingsBookingId?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionPositionFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionPositionFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionPositionFilterInput>>>
    positionCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionReservableFilterInput = {
    adminID?: InputMaybe<ModelSubscriptionIdInput>
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableFilterInput>>>
    availabilityByDatesAndTime?: InputMaybe<ModelSubscriptionBooleanInput>
    availabilityPreviewByDates?: InputMaybe<ModelSubscriptionStringInput>
    availabilityType?: InputMaybe<ModelSubscriptionStringInput>
    color?: InputMaybe<ModelSubscriptionStringInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    departmentID?: InputMaybe<ModelSubscriptionIdInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    externalRoomID?: InputMaybe<ModelSubscriptionStringInput>
    height?: InputMaybe<ModelSubscriptionFloatInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isDefault?: InputMaybe<ModelSubscriptionStringInput>
    maxAmountOfPeople?: InputMaybe<ModelSubscriptionIntInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableFilterInput>>>
    relatedReservableID?: InputMaybe<ModelSubscriptionIdInput>
    reservableCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    reservableDepartmentId?: InputMaybe<ModelSubscriptionIdInput>
    reservableEmployeeId?: InputMaybe<ModelSubscriptionIdInput>
    reservableSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    width?: InputMaybe<ModelSubscriptionFloatInput>
    x?: InputMaybe<ModelSubscriptionFloatInput>
    y?: InputMaybe<ModelSubscriptionFloatInput>
    zonePoints?: InputMaybe<ModelSubscriptionFloatInput>
}

export type ModelSubscriptionReservableImageFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableImageFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isMain?: InputMaybe<ModelSubscriptionBooleanInput>
    location?: InputMaybe<ModelSubscriptionStringInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableImageFilterInput>>>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    reservableImageCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    reservableImageReservableId?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionReservableTagFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableTagFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableTagFilterInput>>>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    reservableTagCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    reservableTagOfficeId?: InputMaybe<ModelSubscriptionIdInput>
    reservableTagSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    reservableType?: InputMaybe<ModelSubscriptionStringInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    tagID?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionReservableToDepartmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableToDepartmentFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    departmentID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableToDepartmentFilterInput>>>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    reservableToDepartmentCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    reservableToDepartmentDepartmentId?: InputMaybe<ModelSubscriptionIdInput>
    reservableToDepartmentReservableId?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionReservableToEmployeeFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableToEmployeeFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionReservableToEmployeeFilterInput>>>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionRoomDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionRoomDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionRoomDayAggregationFilterInput>>>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    roomBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    roomCapacityLast?: InputMaybe<ModelSubscriptionIntInput>
    roomDayAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    roomNameLowerCase?: InputMaybe<ModelSubscriptionStringInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    visitors?: InputMaybe<ModelSubscriptionIntInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionRoomWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionRoomWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionRoomWeekAggregationFilterInput>>>
    percentageOfUtilization?: InputMaybe<ModelSubscriptionIntInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    roomBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    roomCapacityMax?: InputMaybe<ModelSubscriptionIntInput>
    roomNameLowerCase?: InputMaybe<ModelSubscriptionStringInput>
    roomWeekAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    visitors?: InputMaybe<ModelSubscriptionIntInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionRulesFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionRulesFilterInput>>>
    autoAssignWaitingList?: InputMaybe<ModelSubscriptionBooleanInput>
    blockReservationOnNonWorkingDays?: InputMaybe<ModelSubscriptionBooleanInput>
    checkInCancellingTime?: InputMaybe<ModelSubscriptionIntInput>
    checkInWaitingTime?: InputMaybe<ModelSubscriptionIntInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    dayOfAttendanceNotifications?: InputMaybe<ModelSubscriptionIntInput>
    defaultShareAttendance?: InputMaybe<ModelSubscriptionStringInput>
    deleteDataAfter?: InputMaybe<ModelSubscriptionIntInput>
    deletePersonalDataAfter?: InputMaybe<ModelSubscriptionIntInput>
    denyRecurringBookingOverThePlanningHorizon?: InputMaybe<ModelSubscriptionBooleanInput>
    disableOnboardingQuiz?: InputMaybe<ModelSubscriptionBooleanInput>
    disableProfileEditing?: InputMaybe<ModelSubscriptionBooleanInput>
    emailForMonthlyReport?: InputMaybe<ModelSubscriptionStringInput>
    enableMinDaysInOffice?: InputMaybe<ModelSubscriptionBooleanInput>
    homeIsDefault?: InputMaybe<ModelSubscriptionBooleanInput>
    horizonOfPlanningInDays?: InputMaybe<ModelSubscriptionIntInput>
    horizonOfPlanningTimeAmount?: InputMaybe<ModelSubscriptionIntInput>
    horizonOfPlanningTimeUnit?: InputMaybe<ModelSubscriptionStringInput>
    hourOfAttendanceNotifications?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelStringInput>
    isPersonalSeatAvailableToday?: InputMaybe<ModelSubscriptionBooleanInput>
    isTeamSeatAvailableToday?: InputMaybe<ModelSubscriptionBooleanInput>
    maxDaysInOffice?: InputMaybe<ModelSubscriptionIntInput>
    minDaysInOffice?: InputMaybe<ModelSubscriptionIntInput>
    minDaysInOfficeTimeUnit?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionRulesFilterInput>>>
    startTimeOfNotifications?: InputMaybe<ModelSubscriptionIntInput>
    turnOffCheckInForPersonalSeats?: InputMaybe<ModelSubscriptionBooleanInput>
    turnOnAttendanceNotifications?: InputMaybe<ModelSubscriptionBooleanInput>
    turnOnCheckIn?: InputMaybe<ModelSubscriptionBooleanInput>
    turnOnDeletingData?: InputMaybe<ModelSubscriptionBooleanInput>
    turnOnExperimentalFeatures?: InputMaybe<ModelSubscriptionBooleanInput>
    turnOnMonthlyReport?: InputMaybe<ModelSubscriptionBooleanInput>
    updateEmployeeDataFromSource?: InputMaybe<ModelSubscriptionBooleanInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    workingDays?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionSlackOAuthStateFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSlackOAuthStateFilterInput>>>
    companyID?: InputMaybe<ModelSubscriptionIdInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSlackOAuthStateFilterInput>>>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionSlackOnboradingMessageFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSlackOnboradingMessageFilterInput>>>
    companyID?: InputMaybe<ModelSubscriptionIdInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSlackOnboradingMessageFilterInput>>>
    slackMessageID?: InputMaybe<ModelSubscriptionStringInput>
    slackOnboradingMessageCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    slackOnboradingMessageEmployeeId?: InputMaybe<ModelSubscriptionIdInput>
    state?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionSpaceCapacityDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceCapacityDayAggregationFilterInput>>>
    availableSeatsSum?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceCapacityDayAggregationFilterInput>>>
    spaceCapacityDayAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    spaceCapacityDayAggregationEmployeeId?: InputMaybe<ModelSubscriptionIdInput>
    spaceCapacityDayAggregationSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    waitingListItemsSum?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionSpaceDayAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceDayAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    homeBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceDayAggregationFilterInput>>>
    spaceBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    spaceCapacityLast?: InputMaybe<ModelSubscriptionIntInput>
    spaceDayAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionSpaceFilterInput = {
    address?: InputMaybe<ModelSubscriptionStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceFilterInput>>>
    availableParkingSlots?: InputMaybe<ModelSubscriptionIntInput>
    availableParkingSlotsByPeriod?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    floorPlanFilePath?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    isAvailableToEmployees?: InputMaybe<ModelSubscriptionBooleanInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceFilterInput>>>
    order?: InputMaybe<ModelSubscriptionIntInput>
    spaceCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    spaceOfficeId?: InputMaybe<ModelSubscriptionIdInput>
    spaceRowID?: InputMaybe<ModelSubscriptionIdInput>
    suggestedSeatID?: InputMaybe<ModelSubscriptionIdInput>
    timeZone?: InputMaybe<ModelSubscriptionStringInput>
    totalParkingSlots?: InputMaybe<ModelSubscriptionIntInput>
    totalRooms?: InputMaybe<ModelSubscriptionIntInput>
    totalSeats?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    workingHoursFrom?: InputMaybe<ModelSubscriptionStringInput>
    workingHoursTo?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionSpaceRowFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceRowFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceRowFilterInput>>>
    order?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionSpaceWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<ModelSubscriptionIntInput>
    capacityInHours?: InputMaybe<ModelSubscriptionIntInput>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    employeesLast?: InputMaybe<ModelSubscriptionIntInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    officeID?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSpaceWeekAggregationFilterInput>>>
    percentageOfUtilization?: InputMaybe<ModelSubscriptionIntInput>
    spaceBookingsSum?: InputMaybe<ModelSubscriptionIntInput>
    spaceCapacityMax?: InputMaybe<ModelSubscriptionIntInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    spaceWeekAggregationCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    type?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
    weekNumber?: InputMaybe<ModelSubscriptionIntInput>
    weekNumberString?: InputMaybe<ModelSubscriptionStringInput>
    year?: InputMaybe<ModelSubscriptionIntInput>
}

export type ModelSubscriptionStringInput = {
    beginsWith?: InputMaybe<Scalars['String']['input']>
    between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    contains?: InputMaybe<Scalars['String']['input']>
    eq?: InputMaybe<Scalars['String']['input']>
    ge?: InputMaybe<Scalars['String']['input']>
    gt?: InputMaybe<Scalars['String']['input']>
    in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    le?: InputMaybe<Scalars['String']['input']>
    lt?: InputMaybe<Scalars['String']['input']>
    ne?: InputMaybe<Scalars['String']['input']>
    notContains?: InputMaybe<Scalars['String']['input']>
    notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ModelSubscriptionSuggestedSeatFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSuggestedSeatFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    employeeID?: InputMaybe<ModelSubscriptionIdInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSuggestedSeatFilterInput>>>
    reason?: InputMaybe<ModelSubscriptionStringInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    suggestedSeatCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    suggestedSeatEmployeeId?: InputMaybe<ModelSubscriptionIdInput>
    suggestedSeatReservableId?: InputMaybe<ModelSubscriptionIdInput>
    suggestedSeatSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionSyncUserEventFilterInput = {
    action?: InputMaybe<ModelSubscriptionStringInput>
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionSyncUserEventFilterInput>>>
    companyID?: InputMaybe<ModelSubscriptionIdInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    department?: InputMaybe<ModelSubscriptionStringInput>
    email?: InputMaybe<ModelSubscriptionStringInput>
    externalId?: InputMaybe<ModelSubscriptionStringInput>
    firstname?: InputMaybe<ModelSubscriptionStringInput>
    hasInvitationEnabled?: InputMaybe<ModelSubscriptionBooleanInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    lastname?: InputMaybe<ModelSubscriptionStringInput>
    office?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionSyncUserEventFilterInput>>>
    position?: InputMaybe<ModelSubscriptionStringInput>
    profilePicS3key?: InputMaybe<ModelSubscriptionStringInput>
    shouldEmailInvitation?: InputMaybe<ModelSubscriptionBooleanInput>
    source?: InputMaybe<ModelSubscriptionStringInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionTagFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionTagFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    name?: InputMaybe<ModelSubscriptionStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionTagFilterInput>>>
    reservableType?: InputMaybe<ModelSubscriptionStringInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    tagCompanyId?: InputMaybe<ModelSubscriptionIdInput>
    tagSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSubscriptionTeamEventRequestFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSubscriptionTeamEventRequestFilterInput>>>
    companyID?: InputMaybe<ModelStringInput>
    createdAt?: InputMaybe<ModelSubscriptionStringInput>
    date?: InputMaybe<ModelSubscriptionStringInput>
    dates?: InputMaybe<ModelSubscriptionStringInput>
    daysOfWeek?: InputMaybe<ModelSubscriptionStringInput>
    description?: InputMaybe<ModelSubscriptionStringInput>
    employeeIDs?: InputMaybe<ModelSubscriptionIdInput>
    endTime?: InputMaybe<ModelSubscriptionStringInput>
    id?: InputMaybe<ModelSubscriptionIdInput>
    or?: InputMaybe<Array<InputMaybe<ModelSubscriptionTeamEventRequestFilterInput>>>
    owner?: InputMaybe<ModelStringInput>
    repeatType?: InputMaybe<ModelSubscriptionStringInput>
    reservableID?: InputMaybe<ModelSubscriptionIdInput>
    spaceID?: InputMaybe<ModelSubscriptionIdInput>
    startTime?: InputMaybe<ModelSubscriptionStringInput>
    status?: InputMaybe<ModelSubscriptionStringInput>
    teamEventRequestSpaceId?: InputMaybe<ModelSubscriptionIdInput>
    title?: InputMaybe<ModelSubscriptionStringInput>
    ttl?: InputMaybe<ModelSubscriptionIntInput>
    updatedAt?: InputMaybe<ModelSubscriptionStringInput>
}

export type ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyConditionInput = {
    beginsWith?: InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyInput>
    between?: InputMaybe<Array<InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyInput>>>
    eq?: InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyInput>
    ge?: InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyInput>
    gt?: InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyInput>
    le?: InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyInput>
    lt?: InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyInput>
}

export type ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyInput = {
    date?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
}

export type ModelSuggestedSeatConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSuggestedSeatConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSuggestedSeatConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelSuggestedSeatConditionInput>>>
    reason?: InputMaybe<ModelSuggestedSeatReasonInput>
    reservableID?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    suggestedSeatCompanyId?: InputMaybe<ModelIdInput>
    suggestedSeatEmployeeId?: InputMaybe<ModelIdInput>
    suggestedSeatReservableId?: InputMaybe<ModelIdInput>
    suggestedSeatSpaceId?: InputMaybe<ModelIdInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSuggestedSeatConnection = {
    __typename?: 'ModelSuggestedSeatConnection'
    items: Array<Maybe<SuggestedSeat>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSuggestedSeatFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelSuggestedSeatFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    employeeID?: InputMaybe<ModelIdInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelSuggestedSeatFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelSuggestedSeatFilterInput>>>
    reason?: InputMaybe<ModelSuggestedSeatReasonInput>
    reservableID?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    suggestedSeatCompanyId?: InputMaybe<ModelIdInput>
    suggestedSeatEmployeeId?: InputMaybe<ModelIdInput>
    suggestedSeatReservableId?: InputMaybe<ModelIdInput>
    suggestedSeatSpaceId?: InputMaybe<ModelIdInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSuggestedSeatReasonInput = {
    eq?: InputMaybe<SuggestedSeatReason>
    ne?: InputMaybe<SuggestedSeatReason>
}

export type ModelSyncSourceInput = {
    eq?: InputMaybe<SyncSource>
    ne?: InputMaybe<SyncSource>
}

export type ModelSyncUserEventActionInput = {
    eq?: InputMaybe<SyncUserEventAction>
    ne?: InputMaybe<SyncUserEventAction>
}

export type ModelSyncUserEventConditionInput = {
    action?: InputMaybe<ModelSyncUserEventActionInput>
    and?: InputMaybe<Array<InputMaybe<ModelSyncUserEventConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    department?: InputMaybe<ModelStringInput>
    email?: InputMaybe<ModelStringInput>
    externalId?: InputMaybe<ModelStringInput>
    firstname?: InputMaybe<ModelStringInput>
    hasInvitationEnabled?: InputMaybe<ModelBooleanInput>
    lastname?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelSyncUserEventConditionInput>
    office?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSyncUserEventConditionInput>>>
    position?: InputMaybe<ModelStringInput>
    profilePicS3key?: InputMaybe<ModelStringInput>
    shouldEmailInvitation?: InputMaybe<ModelBooleanInput>
    source?: InputMaybe<ModelSyncSourceInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelSyncUserEventConnection = {
    __typename?: 'ModelSyncUserEventConnection'
    items: Array<Maybe<SyncUserEvent>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelSyncUserEventFilterInput = {
    action?: InputMaybe<ModelSyncUserEventActionInput>
    and?: InputMaybe<Array<InputMaybe<ModelSyncUserEventFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    department?: InputMaybe<ModelStringInput>
    email?: InputMaybe<ModelStringInput>
    externalId?: InputMaybe<ModelStringInput>
    firstname?: InputMaybe<ModelStringInput>
    hasInvitationEnabled?: InputMaybe<ModelBooleanInput>
    id?: InputMaybe<ModelIdInput>
    lastname?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelSyncUserEventFilterInput>
    office?: InputMaybe<ModelStringInput>
    or?: InputMaybe<Array<InputMaybe<ModelSyncUserEventFilterInput>>>
    position?: InputMaybe<ModelStringInput>
    profilePicS3key?: InputMaybe<ModelStringInput>
    shouldEmailInvitation?: InputMaybe<ModelBooleanInput>
    source?: InputMaybe<ModelSyncSourceInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelTagConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelTagConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelTagConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelTagConditionInput>>>
    reservableType?: InputMaybe<ModelReservableTypeInput>
    spaceID?: InputMaybe<ModelIdInput>
    tagCompanyId?: InputMaybe<ModelIdInput>
    tagSpaceId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelTagConnection = {
    __typename?: 'ModelTagConnection'
    items: Array<Maybe<Tag>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelTagFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelTagFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    name?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelTagFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelTagFilterInput>>>
    reservableType?: InputMaybe<ModelReservableTypeInput>
    spaceID?: InputMaybe<ModelIdInput>
    tagCompanyId?: InputMaybe<ModelIdInput>
    tagSpaceId?: InputMaybe<ModelIdInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelTariffInput = {
    eq?: InputMaybe<Tariff>
    ne?: InputMaybe<Tariff>
}

export type ModelTeamEventRequestConditionInput = {
    and?: InputMaybe<Array<InputMaybe<ModelTeamEventRequestConditionInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    dates?: InputMaybe<ModelStringInput>
    daysOfWeek?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    employeeIDs?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    not?: InputMaybe<ModelTeamEventRequestConditionInput>
    or?: InputMaybe<Array<InputMaybe<ModelTeamEventRequestConditionInput>>>
    owner?: InputMaybe<ModelIdInput>
    repeatType?: InputMaybe<ModelRepeatTypeInput>
    reservableID?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelTeamEventRequestStatusInput>
    teamEventRequestSpaceId?: InputMaybe<ModelIdInput>
    title?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelTeamEventRequestConnection = {
    __typename?: 'ModelTeamEventRequestConnection'
    items: Array<Maybe<TeamEventRequest>>
    nextToken?: Maybe<Scalars['String']['output']>
}

export type ModelTeamEventRequestFilterInput = {
    and?: InputMaybe<Array<InputMaybe<ModelTeamEventRequestFilterInput>>>
    companyID?: InputMaybe<ModelIdInput>
    createdAt?: InputMaybe<ModelStringInput>
    date?: InputMaybe<ModelStringInput>
    dates?: InputMaybe<ModelStringInput>
    daysOfWeek?: InputMaybe<ModelStringInput>
    description?: InputMaybe<ModelStringInput>
    employeeIDs?: InputMaybe<ModelIdInput>
    endTime?: InputMaybe<ModelStringInput>
    id?: InputMaybe<ModelIdInput>
    not?: InputMaybe<ModelTeamEventRequestFilterInput>
    or?: InputMaybe<Array<InputMaybe<ModelTeamEventRequestFilterInput>>>
    owner?: InputMaybe<ModelIdInput>
    repeatType?: InputMaybe<ModelRepeatTypeInput>
    reservableID?: InputMaybe<ModelIdInput>
    spaceID?: InputMaybe<ModelIdInput>
    startTime?: InputMaybe<ModelStringInput>
    status?: InputMaybe<ModelTeamEventRequestStatusInput>
    teamEventRequestSpaceId?: InputMaybe<ModelIdInput>
    title?: InputMaybe<ModelStringInput>
    ttl?: InputMaybe<ModelIntInput>
    updatedAt?: InputMaybe<ModelStringInput>
}

export type ModelTeamEventRequestStatusInput = {
    eq?: InputMaybe<TeamEventRequestStatus>
    ne?: InputMaybe<TeamEventRequestStatus>
}

export type ModelUserLocaleInput = {
    eq?: InputMaybe<UserLocale>
    ne?: InputMaybe<UserLocale>
}

export type Money = {
    __typename?: 'Money'
    amount: Scalars['String']['output']
    currency: Scalars['String']['output']
}

export type MsTeamsIntegration = {
    __typename?: 'MsTeamsIntegration'
    companyId: Scalars['String']['output']
    tenantId: Scalars['String']['output']
}

export type Mutation = {
    __typename?: 'Mutation'
    cancelBooking: Scalars['ID']['output']
    createAddress?: Maybe<Address>
    createBooking?: Maybe<Booking>
    createBookingCancellation?: Maybe<BookingCancellation>
    createBookingEquipment?: Maybe<BookingEquipment>
    createBookingRequest?: Maybe<BookingRequest>
    createBookingReservable?: Maybe<BookingReservable>
    createCalendarEventEmployee?: Maybe<CalendarEventEmployee>
    createCalendarEventRequest?: Maybe<CalendarEventRequest>
    createCalendarEvents?: Maybe<CalendarEvents>
    createCatering?: Maybe<Catering>
    createCompany?: Maybe<Company>
    createCompanyDayAggregation?: Maybe<CompanyDayAggregation>
    createCompanyWeekAggregation?: Maybe<CompanyWeekAggregation>
    createCustomRole?: Maybe<CustomRole>
    createDepartment?: Maybe<Department>
    createDepartmentDayAggregation?: Maybe<DepartmentDayAggregation>
    createDepartmentDayAggregationEmployees?: Maybe<DepartmentDayAggregationEmployees>
    createDepartmentLastMonthAttendance?: Maybe<DepartmentLastMonthAttendance>
    createDepartmentRules?: Maybe<DepartmentRules>
    createDepartmentWeekAggregation?: Maybe<DepartmentWeekAggregation>
    createEmployee?: Maybe<Employee>
    createEmployeeDayAggregation?: Maybe<EmployeeDayAggregation>
    createEmployeeLastMonthAttendance?: Maybe<EmployeeLastMonthAttendance>
    createEmployeeToDepartment?: Maybe<EmployeeToDepartment>
    createEmployeeWeekAggregation?: Maybe<EmployeeWeekAggregation>
    createEquipment?: Maybe<Equipment>
    createEquipmentImage?: Maybe<EquipmentImage>
    createGoogleImportedBookings?: Maybe<GoogleImportedBookings>
    createGoogleImportedWorkingLocations?: Maybe<GoogleImportedWorkingLocations>
    createIntegration?: Maybe<Integration>
    createMenuItem?: Maybe<MenuItem>
    createMicrosoftSubscription?: Maybe<MicrosoftSubscription>
    createNotification?: Maybe<Notification>
    createOffice?: Maybe<Office>
    createOfficeBuilderRequest?: Maybe<OfficeBuilderRequest>
    createOfficeDayAggregation?: Maybe<OfficeDayAggregation>
    createOfficeImage?: Maybe<OfficeImage>
    createOfficeWeekAggregation?: Maybe<OfficeWeekAggregation>
    createOrUpdateSlackIntegration: SlackIntegration
    createPermission?: Maybe<Permission>
    createPersonioImportedAbsences?: Maybe<PersonioImportedAbsences>
    createPersonioImportedBookings?: Maybe<PersonioImportedBookings>
    createPosition?: Maybe<Position>
    createReservable?: Maybe<Reservable>
    createReservableImage?: Maybe<ReservableImage>
    createReservableTag?: Maybe<ReservableTag>
    createReservableToDepartment?: Maybe<ReservableToDepartment>
    createReservableToEmployee?: Maybe<ReservableToEmployee>
    createRoomDayAggregation?: Maybe<RoomDayAggregation>
    createRoomWeekAggregation?: Maybe<RoomWeekAggregation>
    createRules?: Maybe<Rules>
    createSlackOAuthState?: Maybe<SlackOAuthState>
    createSlackOnboradingMessage?: Maybe<SlackOnboradingMessage>
    createSpace?: Maybe<Space>
    createSpaceCapacityDayAggregation?: Maybe<SpaceCapacityDayAggregation>
    createSpaceDayAggregation?: Maybe<SpaceDayAggregation>
    createSpaceRow?: Maybe<SpaceRow>
    createSpaceWeekAggregation?: Maybe<SpaceWeekAggregation>
    createSuggestedSeat?: Maybe<SuggestedSeat>
    createSyncUserEvent?: Maybe<SyncUserEvent>
    createTag?: Maybe<Tag>
    createTeamEventRequest?: Maybe<TeamEventRequest>
    customUpdateBookingRequest?: Maybe<BookingRequest>
    deleteAddress?: Maybe<Address>
    deleteBambooHRIntegration?: Maybe<Scalars['String']['output']>
    deleteBooking?: Maybe<Booking>
    deleteBookingCancellation?: Maybe<BookingCancellation>
    deleteBookingEquipment?: Maybe<BookingEquipment>
    deleteBookingRequest?: Maybe<BookingRequest>
    deleteBookingReservable?: Maybe<BookingReservable>
    deleteCalendarEventEmployee?: Maybe<CalendarEventEmployee>
    deleteCalendarEventRequest?: Maybe<CalendarEventRequest>
    deleteCalendarEvents?: Maybe<CalendarEvents>
    deleteCalendarIntegration?: Maybe<Scalars['String']['output']>
    deleteCatering?: Maybe<Catering>
    deleteCompany?: Maybe<Company>
    deleteCompanyDayAggregation?: Maybe<CompanyDayAggregation>
    deleteCompanyWeekAggregation?: Maybe<CompanyWeekAggregation>
    deleteCustomRole?: Maybe<CustomRole>
    deleteDepartment?: Maybe<Department>
    deleteDepartmentDayAggregation?: Maybe<DepartmentDayAggregation>
    deleteDepartmentDayAggregationEmployees?: Maybe<DepartmentDayAggregationEmployees>
    deleteDepartmentLastMonthAttendance?: Maybe<DepartmentLastMonthAttendance>
    deleteDepartmentRules?: Maybe<DepartmentRules>
    deleteDepartmentWeekAggregation?: Maybe<DepartmentWeekAggregation>
    deleteEmployee?: Maybe<Employee>
    deleteEmployeeDayAggregation?: Maybe<EmployeeDayAggregation>
    deleteEmployeeLastMonthAttendance?: Maybe<EmployeeLastMonthAttendance>
    deleteEmployeeToDepartment?: Maybe<EmployeeToDepartment>
    deleteEmployeeWeekAggregation?: Maybe<EmployeeWeekAggregation>
    deleteEmployees?: Maybe<Array<EmployeeMutationResult>>
    deleteEquipment?: Maybe<Equipment>
    deleteEquipmentImage?: Maybe<EquipmentImage>
    deleteGoogleCalendarIntegration?: Maybe<Scalars['Boolean']['output']>
    deleteGoogleImportedBookings?: Maybe<GoogleImportedBookings>
    deleteGoogleImportedWorkingLocations?: Maybe<GoogleImportedWorkingLocations>
    deleteIntegration?: Maybe<Integration>
    deleteMenuItem?: Maybe<MenuItem>
    deleteMicrosoftSubscription?: Maybe<MicrosoftSubscription>
    deleteMsTeamsIntegration?: Maybe<Scalars['Boolean']['output']>
    deleteNotification?: Maybe<Notification>
    deleteOffice?: Maybe<Office>
    deleteOfficeBuilderRequest?: Maybe<OfficeBuilderRequest>
    deleteOfficeDayAggregation?: Maybe<OfficeDayAggregation>
    deleteOfficeImage?: Maybe<OfficeImage>
    deleteOfficeWeekAggregation?: Maybe<OfficeWeekAggregation>
    deleteOidcIntegration?: Maybe<Scalars['Boolean']['output']>
    deletePermission?: Maybe<Permission>
    deletePersonioDepartmentMapping?: Maybe<Array<Maybe<PersonioDepartmentMapping>>>
    deletePersonioImportedAbsences?: Maybe<PersonioImportedAbsences>
    deletePersonioImportedBookings?: Maybe<PersonioImportedBookings>
    deletePersonioPositionMapping?: Maybe<Array<Maybe<PersonioPositionMapping>>>
    deletePosition?: Maybe<Position>
    deleteReservable?: Maybe<Reservable>
    deleteReservableImage?: Maybe<ReservableImage>
    deleteReservableTag?: Maybe<ReservableTag>
    deleteReservableToDepartment?: Maybe<ReservableToDepartment>
    deleteReservableToEmployee?: Maybe<ReservableToEmployee>
    deleteRoomDayAggregation?: Maybe<RoomDayAggregation>
    deleteRoomMapping?: Maybe<Scalars['String']['output']>
    deleteRoomWeekAggregation?: Maybe<RoomWeekAggregation>
    deleteSSOIntegration?: Maybe<Scalars['Boolean']['output']>
    deleteSamlIntegration?: Maybe<Scalars['Boolean']['output']>
    deleteScimIntegration?: Maybe<Scalars['Boolean']['output']>
    deleteSlackIntegration: SlackIntegration
    deleteSlackOAuthState?: Maybe<SlackOAuthState>
    deleteSlackOnboradingMessage?: Maybe<SlackOnboradingMessage>
    deleteSpace?: Maybe<Space>
    deleteSpaceCapacityDayAggregation?: Maybe<SpaceCapacityDayAggregation>
    deleteSpaceDayAggregation?: Maybe<SpaceDayAggregation>
    deleteSpaceRow?: Maybe<SpaceRow>
    deleteSpaceWeekAggregation?: Maybe<SpaceWeekAggregation>
    deleteSuggestedSeat?: Maybe<SuggestedSeat>
    deleteTag?: Maybe<Tag>
    deleteTeamEventRequest?: Maybe<TeamEventRequest>
    deleteUserProvisioningIntegration?: Maybe<Scalars['Boolean']['output']>
    disablePersonioIntegration: PersonioIntegration
    forceSyncPersonioIntegration: PersonioIntegration
    initialisePersonioIntegration: PersonioIntegration
    initiateBambooHRIntegration: BambooHrIntegration
    initiateCalendarIntegration: CalendarIntegration
    initiateOidcIntegration: OidcIntegration
    initiateSamlIntegration?: Maybe<SamlIntegration>
    initiateScimIntegration: ScimIntegration
    insertPersonioDepartmentMapping?: Maybe<Array<Maybe<PersonioDepartmentMapping>>>
    insertPersonioPositionMapping?: Maybe<Array<Maybe<PersonioPositionMapping>>>
    inviteEmployees?: Maybe<Array<EmployeeMutationResult>>
    logPersonioIntegrationExecutionResult: PersonioIntegration
    mapReservableToExternalRoom?: Maybe<Scalars['String']['output']>
    mapReservableToGoogleRoom?: Maybe<Scalars['String']['output']>
    setSSOIntegration: SsoIntegration
    setUserProvisioningIntegration: UserProvisioningIntegration
    startTrial?: Maybe<Scalars['String']['output']>
    syncUserSynchronously?: Maybe<SyncUserEvent>
    toggleInvitationForBambooHRIntegration?: Maybe<Scalars['String']['output']>
    updateAddress?: Maybe<Address>
    updateBillingSubscription: SubscribedBillingOutput
    updateBillingTrial: TrialBillingOutput
    updateBooking?: Maybe<Booking>
    updateBookingCancellation?: Maybe<BookingCancellation>
    updateBookingEquipment?: Maybe<BookingEquipment>
    updateBookingRequest?: Maybe<BookingRequest>
    updateBookingReservable?: Maybe<BookingReservable>
    updateCalendarEventEmployee?: Maybe<CalendarEventEmployee>
    updateCalendarEventRequest?: Maybe<CalendarEventRequest>
    updateCalendarEvents?: Maybe<CalendarEvents>
    updateCalendarIntegration: CalendarIntegration
    updateCatering?: Maybe<Catering>
    updateCompany?: Maybe<Company>
    updateCompanyDayAggregation?: Maybe<CompanyDayAggregation>
    updateCompanyWeekAggregation?: Maybe<CompanyWeekAggregation>
    updateCustomRole?: Maybe<CustomRole>
    updateDepartment?: Maybe<Department>
    updateDepartmentDayAggregation?: Maybe<DepartmentDayAggregation>
    updateDepartmentDayAggregationEmployees?: Maybe<DepartmentDayAggregationEmployees>
    updateDepartmentLastMonthAttendance?: Maybe<DepartmentLastMonthAttendance>
    updateDepartmentRules?: Maybe<DepartmentRules>
    updateDepartmentWeekAggregation?: Maybe<DepartmentWeekAggregation>
    updateEmployee?: Maybe<Employee>
    updateEmployeeDayAggregation?: Maybe<EmployeeDayAggregation>
    updateEmployeeLastMonthAttendance?: Maybe<EmployeeLastMonthAttendance>
    updateEmployeeToDepartment?: Maybe<EmployeeToDepartment>
    updateEmployeeWeekAggregation?: Maybe<EmployeeWeekAggregation>
    updateEquipment?: Maybe<Equipment>
    updateEquipmentImage?: Maybe<EquipmentImage>
    updateGoogleImportedBookings?: Maybe<GoogleImportedBookings>
    updateGoogleImportedWorkingLocations?: Maybe<GoogleImportedWorkingLocations>
    updateIntegration?: Maybe<Integration>
    updateMSCalendarIntegration?: Maybe<Array<Maybe<MsCalendarIntegration>>>
    updateMenuItem?: Maybe<MenuItem>
    updateMicrosoftSubscription?: Maybe<MicrosoftSubscription>
    updateNotification?: Maybe<Notification>
    updateOffice?: Maybe<Office>
    updateOfficeBuilderRequest?: Maybe<OfficeBuilderRequest>
    updateOfficeDayAggregation?: Maybe<OfficeDayAggregation>
    updateOfficeImage?: Maybe<OfficeImage>
    updateOfficeWeekAggregation?: Maybe<OfficeWeekAggregation>
    updateOidcIntegration: OidcIntegration
    updatePermission?: Maybe<Permission>
    updatePersonioImportedAbsences?: Maybe<PersonioImportedAbsences>
    updatePersonioImportedBookings?: Maybe<PersonioImportedBookings>
    updatePersonioIntegration: PersonioIntegration
    updatePosition?: Maybe<Position>
    updateReservable?: Maybe<Reservable>
    updateReservableImage?: Maybe<ReservableImage>
    updateReservableTag?: Maybe<ReservableTag>
    updateReservableToDepartment?: Maybe<ReservableToDepartment>
    updateReservableToEmployee?: Maybe<ReservableToEmployee>
    updateRoomDayAggregation?: Maybe<RoomDayAggregation>
    updateRoomWeekAggregation?: Maybe<RoomWeekAggregation>
    updateRules?: Maybe<Rules>
    updateSamlIntegration: SamlIntegration
    updateSlackIntegration: SlackIntegration
    updateSlackOAuthState?: Maybe<SlackOAuthState>
    updateSlackOnboradingMessage?: Maybe<SlackOnboradingMessage>
    updateSpace?: Maybe<Space>
    updateSpaceCapacityDayAggregation?: Maybe<SpaceCapacityDayAggregation>
    updateSpaceDayAggregation?: Maybe<SpaceDayAggregation>
    updateSpaceRow?: Maybe<SpaceRow>
    updateSpaceWeekAggregation?: Maybe<SpaceWeekAggregation>
    updateSuggestedSeat?: Maybe<SuggestedSeat>
    updateTag?: Maybe<Tag>
    updateTeamEventRequest?: Maybe<TeamEventRequest>
    updatedBambooHrIntegration?: Maybe<BambooHrIntegration>
    updatedTariff: CompanyTariffUpdated
}

export type MutationCancelBookingArgs = {
    bookingID: Scalars['ID']['input']
}

export type MutationCreateAddressArgs = {
    condition?: InputMaybe<ModelAddressConditionInput>
    input: CreateAddressInput
}

export type MutationCreateBookingArgs = {
    condition?: InputMaybe<ModelBookingConditionInput>
    input: CreateBookingInput
}

export type MutationCreateBookingCancellationArgs = {
    condition?: InputMaybe<ModelBookingCancellationConditionInput>
    input: CreateBookingCancellationInput
}

export type MutationCreateBookingEquipmentArgs = {
    condition?: InputMaybe<ModelBookingEquipmentConditionInput>
    input: CreateBookingEquipmentInput
}

export type MutationCreateBookingRequestArgs = {
    condition?: InputMaybe<ModelBookingRequestConditionInput>
    input: CreateBookingRequestInput
}

export type MutationCreateBookingReservableArgs = {
    condition?: InputMaybe<ModelBookingReservableConditionInput>
    input: CreateBookingReservableInput
}

export type MutationCreateCalendarEventEmployeeArgs = {
    condition?: InputMaybe<ModelCalendarEventEmployeeConditionInput>
    input: CreateCalendarEventEmployeeInput
}

export type MutationCreateCalendarEventRequestArgs = {
    condition?: InputMaybe<ModelCalendarEventRequestConditionInput>
    input: CreateCalendarEventRequestInput
}

export type MutationCreateCalendarEventsArgs = {
    condition?: InputMaybe<ModelCalendarEventsConditionInput>
    input: CreateCalendarEventsInput
}

export type MutationCreateCateringArgs = {
    condition?: InputMaybe<ModelCateringConditionInput>
    input: CreateCateringInput
}

export type MutationCreateCompanyArgs = {
    condition?: InputMaybe<ModelCompanyConditionInput>
    input: CreateCompanyInput
}

export type MutationCreateCompanyDayAggregationArgs = {
    condition?: InputMaybe<ModelCompanyDayAggregationConditionInput>
    input: CreateCompanyDayAggregationInput
}

export type MutationCreateCompanyWeekAggregationArgs = {
    condition?: InputMaybe<ModelCompanyWeekAggregationConditionInput>
    input: CreateCompanyWeekAggregationInput
}

export type MutationCreateCustomRoleArgs = {
    condition?: InputMaybe<ModelCustomRoleConditionInput>
    input: CreateCustomRoleInput
}

export type MutationCreateDepartmentArgs = {
    input: CreateDepartmentInput
}

export type MutationCreateDepartmentDayAggregationArgs = {
    condition?: InputMaybe<ModelDepartmentDayAggregationConditionInput>
    input: CreateDepartmentDayAggregationInput
}

export type MutationCreateDepartmentDayAggregationEmployeesArgs = {
    condition?: InputMaybe<ModelDepartmentDayAggregationEmployeesConditionInput>
    input: CreateDepartmentDayAggregationEmployeesInput
}

export type MutationCreateDepartmentLastMonthAttendanceArgs = {
    condition?: InputMaybe<ModelDepartmentLastMonthAttendanceConditionInput>
    input: CreateDepartmentLastMonthAttendanceInput
}

export type MutationCreateDepartmentRulesArgs = {
    condition?: InputMaybe<ModelDepartmentRulesConditionInput>
    input: CreateDepartmentRulesInput
}

export type MutationCreateDepartmentWeekAggregationArgs = {
    condition?: InputMaybe<ModelDepartmentWeekAggregationConditionInput>
    input: CreateDepartmentWeekAggregationInput
}

export type MutationCreateEmployeeArgs = {
    input: CreateEmployeeInput
}

export type MutationCreateEmployeeDayAggregationArgs = {
    condition?: InputMaybe<ModelEmployeeDayAggregationConditionInput>
    input: CreateEmployeeDayAggregationInput
}

export type MutationCreateEmployeeLastMonthAttendanceArgs = {
    condition?: InputMaybe<ModelEmployeeLastMonthAttendanceConditionInput>
    input: CreateEmployeeLastMonthAttendanceInput
}

export type MutationCreateEmployeeToDepartmentArgs = {
    condition?: InputMaybe<ModelEmployeeToDepartmentConditionInput>
    input: CreateEmployeeToDepartmentInput
}

export type MutationCreateEmployeeWeekAggregationArgs = {
    condition?: InputMaybe<ModelEmployeeWeekAggregationConditionInput>
    input: CreateEmployeeWeekAggregationInput
}

export type MutationCreateEquipmentArgs = {
    condition?: InputMaybe<ModelEquipmentConditionInput>
    input: CreateEquipmentInput
}

export type MutationCreateEquipmentImageArgs = {
    condition?: InputMaybe<ModelEquipmentImageConditionInput>
    input: CreateEquipmentImageInput
}

export type MutationCreateGoogleImportedBookingsArgs = {
    condition?: InputMaybe<ModelGoogleImportedBookingsConditionInput>
    input: CreateGoogleImportedBookingsInput
}

export type MutationCreateGoogleImportedWorkingLocationsArgs = {
    condition?: InputMaybe<ModelGoogleImportedWorkingLocationsConditionInput>
    input: CreateGoogleImportedWorkingLocationsInput
}

export type MutationCreateIntegrationArgs = {
    condition?: InputMaybe<ModelIntegrationConditionInput>
    input: CreateIntegrationInput
}

export type MutationCreateMenuItemArgs = {
    condition?: InputMaybe<ModelMenuItemConditionInput>
    input: CreateMenuItemInput
}

export type MutationCreateMicrosoftSubscriptionArgs = {
    condition?: InputMaybe<ModelMicrosoftSubscriptionConditionInput>
    input: CreateMicrosoftSubscriptionInput
}

export type MutationCreateNotificationArgs = {
    condition?: InputMaybe<ModelNotificationConditionInput>
    input: CreateNotificationInput
}

export type MutationCreateOfficeArgs = {
    condition?: InputMaybe<ModelOfficeConditionInput>
    input: CreateOfficeInput
}

export type MutationCreateOfficeBuilderRequestArgs = {
    condition?: InputMaybe<ModelOfficeBuilderRequestConditionInput>
    input: CreateOfficeBuilderRequestInput
}

export type MutationCreateOfficeDayAggregationArgs = {
    condition?: InputMaybe<ModelOfficeDayAggregationConditionInput>
    input: CreateOfficeDayAggregationInput
}

export type MutationCreateOfficeImageArgs = {
    condition?: InputMaybe<ModelOfficeImageConditionInput>
    input: CreateOfficeImageInput
}

export type MutationCreateOfficeWeekAggregationArgs = {
    condition?: InputMaybe<ModelOfficeWeekAggregationConditionInput>
    input: CreateOfficeWeekAggregationInput
}

export type MutationCreateOrUpdateSlackIntegrationArgs = {
    input?: InputMaybe<SlackIntegrationInput>
}

export type MutationCreatePermissionArgs = {
    condition?: InputMaybe<ModelPermissionConditionInput>
    input: CreatePermissionInput
}

export type MutationCreatePersonioImportedAbsencesArgs = {
    condition?: InputMaybe<ModelPersonioImportedAbsencesConditionInput>
    input: CreatePersonioImportedAbsencesInput
}

export type MutationCreatePersonioImportedBookingsArgs = {
    condition?: InputMaybe<ModelPersonioImportedBookingsConditionInput>
    input: CreatePersonioImportedBookingsInput
}

export type MutationCreatePositionArgs = {
    input: CreatePositionInput
}

export type MutationCreateReservableArgs = {
    condition?: InputMaybe<ModelReservableConditionInput>
    input: CreateReservableInput
}

export type MutationCreateReservableImageArgs = {
    condition?: InputMaybe<ModelReservableImageConditionInput>
    input: CreateReservableImageInput
}

export type MutationCreateReservableTagArgs = {
    condition?: InputMaybe<ModelReservableTagConditionInput>
    input: CreateReservableTagInput
}

export type MutationCreateReservableToDepartmentArgs = {
    condition?: InputMaybe<ModelReservableToDepartmentConditionInput>
    input: CreateReservableToDepartmentInput
}

export type MutationCreateReservableToEmployeeArgs = {
    condition?: InputMaybe<ModelReservableToEmployeeConditionInput>
    input: CreateReservableToEmployeeInput
}

export type MutationCreateRoomDayAggregationArgs = {
    condition?: InputMaybe<ModelRoomDayAggregationConditionInput>
    input: CreateRoomDayAggregationInput
}

export type MutationCreateRoomWeekAggregationArgs = {
    condition?: InputMaybe<ModelRoomWeekAggregationConditionInput>
    input: CreateRoomWeekAggregationInput
}

export type MutationCreateRulesArgs = {
    condition?: InputMaybe<ModelRulesConditionInput>
    input: CreateRulesInput
}

export type MutationCreateSlackOAuthStateArgs = {
    condition?: InputMaybe<ModelSlackOAuthStateConditionInput>
    input: CreateSlackOAuthStateInput
}

export type MutationCreateSlackOnboradingMessageArgs = {
    condition?: InputMaybe<ModelSlackOnboradingMessageConditionInput>
    input: CreateSlackOnboradingMessageInput
}

export type MutationCreateSpaceArgs = {
    condition?: InputMaybe<ModelSpaceConditionInput>
    input: CreateSpaceInput
}

export type MutationCreateSpaceCapacityDayAggregationArgs = {
    condition?: InputMaybe<ModelSpaceCapacityDayAggregationConditionInput>
    input: CreateSpaceCapacityDayAggregationInput
}

export type MutationCreateSpaceDayAggregationArgs = {
    condition?: InputMaybe<ModelSpaceDayAggregationConditionInput>
    input: CreateSpaceDayAggregationInput
}

export type MutationCreateSpaceRowArgs = {
    condition?: InputMaybe<ModelSpaceRowConditionInput>
    input: CreateSpaceRowInput
}

export type MutationCreateSpaceWeekAggregationArgs = {
    condition?: InputMaybe<ModelSpaceWeekAggregationConditionInput>
    input: CreateSpaceWeekAggregationInput
}

export type MutationCreateSuggestedSeatArgs = {
    condition?: InputMaybe<ModelSuggestedSeatConditionInput>
    input: CreateSuggestedSeatInput
}

export type MutationCreateSyncUserEventArgs = {
    condition?: InputMaybe<ModelSyncUserEventConditionInput>
    input: CreateSyncUserEventInput
}

export type MutationCreateTagArgs = {
    condition?: InputMaybe<ModelTagConditionInput>
    input: CreateTagInput
}

export type MutationCreateTeamEventRequestArgs = {
    condition?: InputMaybe<ModelTeamEventRequestConditionInput>
    input: CreateTeamEventRequestInput
}

export type MutationCustomUpdateBookingRequestArgs = {
    input: UpdateBookingRequestInput
}

export type MutationDeleteAddressArgs = {
    condition?: InputMaybe<ModelAddressConditionInput>
    input: DeleteAddressInput
}

export type MutationDeleteBookingArgs = {
    condition?: InputMaybe<ModelBookingConditionInput>
    input: DeleteBookingInput
}

export type MutationDeleteBookingCancellationArgs = {
    condition?: InputMaybe<ModelBookingCancellationConditionInput>
    input: DeleteBookingCancellationInput
}

export type MutationDeleteBookingEquipmentArgs = {
    condition?: InputMaybe<ModelBookingEquipmentConditionInput>
    input: DeleteBookingEquipmentInput
}

export type MutationDeleteBookingRequestArgs = {
    condition?: InputMaybe<ModelBookingRequestConditionInput>
    input: DeleteBookingRequestInput
}

export type MutationDeleteBookingReservableArgs = {
    condition?: InputMaybe<ModelBookingReservableConditionInput>
    input: DeleteBookingReservableInput
}

export type MutationDeleteCalendarEventEmployeeArgs = {
    condition?: InputMaybe<ModelCalendarEventEmployeeConditionInput>
    input: DeleteCalendarEventEmployeeInput
}

export type MutationDeleteCalendarEventRequestArgs = {
    condition?: InputMaybe<ModelCalendarEventRequestConditionInput>
    input: DeleteCalendarEventRequestInput
}

export type MutationDeleteCalendarEventsArgs = {
    condition?: InputMaybe<ModelCalendarEventsConditionInput>
    input: DeleteCalendarEventsInput
}

export type MutationDeleteCalendarIntegrationArgs = {
    id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationDeleteCateringArgs = {
    condition?: InputMaybe<ModelCateringConditionInput>
    input: DeleteCateringInput
}

export type MutationDeleteCompanyArgs = {
    condition?: InputMaybe<ModelCompanyConditionInput>
    input: DeleteCompanyInput
}

export type MutationDeleteCompanyDayAggregationArgs = {
    condition?: InputMaybe<ModelCompanyDayAggregationConditionInput>
    input: DeleteCompanyDayAggregationInput
}

export type MutationDeleteCompanyWeekAggregationArgs = {
    condition?: InputMaybe<ModelCompanyWeekAggregationConditionInput>
    input: DeleteCompanyWeekAggregationInput
}

export type MutationDeleteCustomRoleArgs = {
    condition?: InputMaybe<ModelCustomRoleConditionInput>
    input: DeleteCustomRoleInput
}

export type MutationDeleteDepartmentArgs = {
    input: DeleteDepartmentInput
}

export type MutationDeleteDepartmentDayAggregationArgs = {
    condition?: InputMaybe<ModelDepartmentDayAggregationConditionInput>
    input: DeleteDepartmentDayAggregationInput
}

export type MutationDeleteDepartmentDayAggregationEmployeesArgs = {
    condition?: InputMaybe<ModelDepartmentDayAggregationEmployeesConditionInput>
    input: DeleteDepartmentDayAggregationEmployeesInput
}

export type MutationDeleteDepartmentLastMonthAttendanceArgs = {
    condition?: InputMaybe<ModelDepartmentLastMonthAttendanceConditionInput>
    input: DeleteDepartmentLastMonthAttendanceInput
}

export type MutationDeleteDepartmentRulesArgs = {
    condition?: InputMaybe<ModelDepartmentRulesConditionInput>
    input: DeleteDepartmentRulesInput
}

export type MutationDeleteDepartmentWeekAggregationArgs = {
    condition?: InputMaybe<ModelDepartmentWeekAggregationConditionInput>
    input: DeleteDepartmentWeekAggregationInput
}

export type MutationDeleteEmployeeArgs = {
    input: DeleteEmployeeInput
}

export type MutationDeleteEmployeeDayAggregationArgs = {
    condition?: InputMaybe<ModelEmployeeDayAggregationConditionInput>
    input: DeleteEmployeeDayAggregationInput
}

export type MutationDeleteEmployeeLastMonthAttendanceArgs = {
    condition?: InputMaybe<ModelEmployeeLastMonthAttendanceConditionInput>
    input: DeleteEmployeeLastMonthAttendanceInput
}

export type MutationDeleteEmployeeToDepartmentArgs = {
    condition?: InputMaybe<ModelEmployeeToDepartmentConditionInput>
    input: DeleteEmployeeToDepartmentInput
}

export type MutationDeleteEmployeeWeekAggregationArgs = {
    condition?: InputMaybe<ModelEmployeeWeekAggregationConditionInput>
    input: DeleteEmployeeWeekAggregationInput
}

export type MutationDeleteEmployeesArgs = {
    employeeIDs: Array<Scalars['ID']['input']>
}

export type MutationDeleteEquipmentArgs = {
    condition?: InputMaybe<ModelEquipmentConditionInput>
    input: DeleteEquipmentInput
}

export type MutationDeleteEquipmentImageArgs = {
    condition?: InputMaybe<ModelEquipmentImageConditionInput>
    input: DeleteEquipmentImageInput
}

export type MutationDeleteGoogleImportedBookingsArgs = {
    condition?: InputMaybe<ModelGoogleImportedBookingsConditionInput>
    input: DeleteGoogleImportedBookingsInput
}

export type MutationDeleteGoogleImportedWorkingLocationsArgs = {
    condition?: InputMaybe<ModelGoogleImportedWorkingLocationsConditionInput>
    input: DeleteGoogleImportedWorkingLocationsInput
}

export type MutationDeleteIntegrationArgs = {
    condition?: InputMaybe<ModelIntegrationConditionInput>
    input: DeleteIntegrationInput
}

export type MutationDeleteMenuItemArgs = {
    condition?: InputMaybe<ModelMenuItemConditionInput>
    input: DeleteMenuItemInput
}

export type MutationDeleteMicrosoftSubscriptionArgs = {
    condition?: InputMaybe<ModelMicrosoftSubscriptionConditionInput>
    input: DeleteMicrosoftSubscriptionInput
}

export type MutationDeleteMsTeamsIntegrationArgs = {
    id: Scalars['ID']['input']
}

export type MutationDeleteNotificationArgs = {
    condition?: InputMaybe<ModelNotificationConditionInput>
    input: DeleteNotificationInput
}

export type MutationDeleteOfficeArgs = {
    condition?: InputMaybe<ModelOfficeConditionInput>
    input: DeleteOfficeInput
}

export type MutationDeleteOfficeBuilderRequestArgs = {
    condition?: InputMaybe<ModelOfficeBuilderRequestConditionInput>
    input: DeleteOfficeBuilderRequestInput
}

export type MutationDeleteOfficeDayAggregationArgs = {
    condition?: InputMaybe<ModelOfficeDayAggregationConditionInput>
    input: DeleteOfficeDayAggregationInput
}

export type MutationDeleteOfficeImageArgs = {
    condition?: InputMaybe<ModelOfficeImageConditionInput>
    input: DeleteOfficeImageInput
}

export type MutationDeleteOfficeWeekAggregationArgs = {
    condition?: InputMaybe<ModelOfficeWeekAggregationConditionInput>
    input: DeleteOfficeWeekAggregationInput
}

export type MutationDeleteOidcIntegrationArgs = {
    id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationDeletePermissionArgs = {
    condition?: InputMaybe<ModelPermissionConditionInput>
    input: DeletePermissionInput
}

export type MutationDeletePersonioDepartmentMappingArgs = {
    input: Scalars['ID']['input']
}

export type MutationDeletePersonioImportedAbsencesArgs = {
    condition?: InputMaybe<ModelPersonioImportedAbsencesConditionInput>
    input: DeletePersonioImportedAbsencesInput
}

export type MutationDeletePersonioImportedBookingsArgs = {
    condition?: InputMaybe<ModelPersonioImportedBookingsConditionInput>
    input: DeletePersonioImportedBookingsInput
}

export type MutationDeletePersonioPositionMappingArgs = {
    input: Scalars['ID']['input']
}

export type MutationDeletePositionArgs = {
    condition?: InputMaybe<ModelPositionConditionInput>
    input: DeletePositionInput
}

export type MutationDeleteReservableArgs = {
    condition?: InputMaybe<ModelReservableConditionInput>
    input: DeleteReservableInput
}

export type MutationDeleteReservableImageArgs = {
    condition?: InputMaybe<ModelReservableImageConditionInput>
    input: DeleteReservableImageInput
}

export type MutationDeleteReservableTagArgs = {
    condition?: InputMaybe<ModelReservableTagConditionInput>
    input: DeleteReservableTagInput
}

export type MutationDeleteReservableToDepartmentArgs = {
    condition?: InputMaybe<ModelReservableToDepartmentConditionInput>
    input: DeleteReservableToDepartmentInput
}

export type MutationDeleteReservableToEmployeeArgs = {
    condition?: InputMaybe<ModelReservableToEmployeeConditionInput>
    input: DeleteReservableToEmployeeInput
}

export type MutationDeleteRoomDayAggregationArgs = {
    condition?: InputMaybe<ModelRoomDayAggregationConditionInput>
    input: DeleteRoomDayAggregationInput
}

export type MutationDeleteRoomMappingArgs = {
    reservableRoomId: Scalars['String']['input']
}

export type MutationDeleteRoomWeekAggregationArgs = {
    condition?: InputMaybe<ModelRoomWeekAggregationConditionInput>
    input: DeleteRoomWeekAggregationInput
}

export type MutationDeleteSamlIntegrationArgs = {
    id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationDeleteScimIntegrationArgs = {
    id?: InputMaybe<Scalars['ID']['input']>
}

export type MutationDeleteSlackOAuthStateArgs = {
    condition?: InputMaybe<ModelSlackOAuthStateConditionInput>
    input: DeleteSlackOAuthStateInput
}

export type MutationDeleteSlackOnboradingMessageArgs = {
    condition?: InputMaybe<ModelSlackOnboradingMessageConditionInput>
    input: DeleteSlackOnboradingMessageInput
}

export type MutationDeleteSpaceArgs = {
    condition?: InputMaybe<ModelSpaceConditionInput>
    input: DeleteSpaceInput
}

export type MutationDeleteSpaceCapacityDayAggregationArgs = {
    condition?: InputMaybe<ModelSpaceCapacityDayAggregationConditionInput>
    input: DeleteSpaceCapacityDayAggregationInput
}

export type MutationDeleteSpaceDayAggregationArgs = {
    condition?: InputMaybe<ModelSpaceDayAggregationConditionInput>
    input: DeleteSpaceDayAggregationInput
}

export type MutationDeleteSpaceRowArgs = {
    condition?: InputMaybe<ModelSpaceRowConditionInput>
    input: DeleteSpaceRowInput
}

export type MutationDeleteSpaceWeekAggregationArgs = {
    condition?: InputMaybe<ModelSpaceWeekAggregationConditionInput>
    input: DeleteSpaceWeekAggregationInput
}

export type MutationDeleteSuggestedSeatArgs = {
    condition?: InputMaybe<ModelSuggestedSeatConditionInput>
    input: DeleteSuggestedSeatInput
}

export type MutationDeleteTagArgs = {
    condition?: InputMaybe<ModelTagConditionInput>
    input: DeleteTagInput
}

export type MutationDeleteTeamEventRequestArgs = {
    condition?: InputMaybe<ModelTeamEventRequestConditionInput>
    input: DeleteTeamEventRequestInput
}

export type MutationInitialisePersonioIntegrationArgs = {
    input?: InputMaybe<PersonioIntegrationInput>
}

export type MutationInitiateBambooHrIntegrationArgs = {
    input: BambooHrIntegrationInput
}

export type MutationInitiateOidcIntegrationArgs = {
    input: OidcIntegrationInput
}

export type MutationInitiateSamlIntegrationArgs = {
    input: SamlIntegrationInput
}

export type MutationInitiateScimIntegrationArgs = {
    input?: InputMaybe<ScimIntegrationInput>
}

export type MutationInsertPersonioDepartmentMappingArgs = {
    input?: InputMaybe<PersonioDepartmentMappingEntry>
}

export type MutationInsertPersonioPositionMappingArgs = {
    input?: InputMaybe<PersonioPositionMappingEntry>
}

export type MutationInviteEmployeesArgs = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    employeeIDs: Array<Scalars['ID']['input']>
    shouldEmailInvitation?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationLogPersonioIntegrationExecutionResultArgs = {
    input: PersonioIntegrationExecutionResultInput
}

export type MutationMapReservableToExternalRoomArgs = {
    input: MapReservableToExternalRoomRequest
}

export type MutationMapReservableToGoogleRoomArgs = {
    input: MapReservableToGoogleRoomRequest
}

export type MutationSetSsoIntegrationArgs = {
    input: SsoIntegrationInput
}

export type MutationSetUserProvisioningIntegrationArgs = {
    input: UserProvisioningIntegrationInput
}

export type MutationStartTrialArgs = {
    subscriptionPlanId: Scalars['String']['input']
}

export type MutationSyncUserSynchronouslyArgs = {
    input: SynchronousSyncUserEventInput
}

export type MutationUpdateAddressArgs = {
    condition?: InputMaybe<ModelAddressConditionInput>
    input: UpdateAddressInput
}

export type MutationUpdateBillingSubscriptionArgs = {
    input: BillingSubscriptionInput
}

export type MutationUpdateBillingTrialArgs = {
    input: BillingTrialInput
}

export type MutationUpdateBookingArgs = {
    condition?: InputMaybe<ModelBookingConditionInput>
    input: UpdateBookingInput
}

export type MutationUpdateBookingCancellationArgs = {
    condition?: InputMaybe<ModelBookingCancellationConditionInput>
    input: UpdateBookingCancellationInput
}

export type MutationUpdateBookingEquipmentArgs = {
    condition?: InputMaybe<ModelBookingEquipmentConditionInput>
    input: UpdateBookingEquipmentInput
}

export type MutationUpdateBookingRequestArgs = {
    condition?: InputMaybe<ModelBookingRequestConditionInput>
    input: UpdateBookingRequestInput
}

export type MutationUpdateBookingReservableArgs = {
    condition?: InputMaybe<ModelBookingReservableConditionInput>
    input: UpdateBookingReservableInput
}

export type MutationUpdateCalendarEventEmployeeArgs = {
    condition?: InputMaybe<ModelCalendarEventEmployeeConditionInput>
    input: UpdateCalendarEventEmployeeInput
}

export type MutationUpdateCalendarEventRequestArgs = {
    condition?: InputMaybe<ModelCalendarEventRequestConditionInput>
    input: UpdateCalendarEventRequestInput
}

export type MutationUpdateCalendarEventsArgs = {
    condition?: InputMaybe<ModelCalendarEventsConditionInput>
    input: UpdateCalendarEventsInput
}

export type MutationUpdateCalendarIntegrationArgs = {
    input: UpdateGoogleCalendarIntegration
}

export type MutationUpdateCateringArgs = {
    condition?: InputMaybe<ModelCateringConditionInput>
    input: UpdateCateringInput
}

export type MutationUpdateCompanyArgs = {
    condition?: InputMaybe<ModelCompanyConditionInput>
    input: UpdateCompanyInput
}

export type MutationUpdateCompanyDayAggregationArgs = {
    condition?: InputMaybe<ModelCompanyDayAggregationConditionInput>
    input: UpdateCompanyDayAggregationInput
}

export type MutationUpdateCompanyWeekAggregationArgs = {
    condition?: InputMaybe<ModelCompanyWeekAggregationConditionInput>
    input: UpdateCompanyWeekAggregationInput
}

export type MutationUpdateCustomRoleArgs = {
    condition?: InputMaybe<ModelCustomRoleConditionInput>
    input: UpdateCustomRoleInput
}

export type MutationUpdateDepartmentArgs = {
    input: UpdateDepartmentInput
}

export type MutationUpdateDepartmentDayAggregationArgs = {
    condition?: InputMaybe<ModelDepartmentDayAggregationConditionInput>
    input: UpdateDepartmentDayAggregationInput
}

export type MutationUpdateDepartmentDayAggregationEmployeesArgs = {
    condition?: InputMaybe<ModelDepartmentDayAggregationEmployeesConditionInput>
    input: UpdateDepartmentDayAggregationEmployeesInput
}

export type MutationUpdateDepartmentLastMonthAttendanceArgs = {
    condition?: InputMaybe<ModelDepartmentLastMonthAttendanceConditionInput>
    input: UpdateDepartmentLastMonthAttendanceInput
}

export type MutationUpdateDepartmentRulesArgs = {
    condition?: InputMaybe<ModelDepartmentRulesConditionInput>
    input: UpdateDepartmentRulesInput
}

export type MutationUpdateDepartmentWeekAggregationArgs = {
    condition?: InputMaybe<ModelDepartmentWeekAggregationConditionInput>
    input: UpdateDepartmentWeekAggregationInput
}

export type MutationUpdateEmployeeArgs = {
    input: UpdateEmployeeInput
}

export type MutationUpdateEmployeeDayAggregationArgs = {
    condition?: InputMaybe<ModelEmployeeDayAggregationConditionInput>
    input: UpdateEmployeeDayAggregationInput
}

export type MutationUpdateEmployeeLastMonthAttendanceArgs = {
    condition?: InputMaybe<ModelEmployeeLastMonthAttendanceConditionInput>
    input: UpdateEmployeeLastMonthAttendanceInput
}

export type MutationUpdateEmployeeToDepartmentArgs = {
    condition?: InputMaybe<ModelEmployeeToDepartmentConditionInput>
    input: UpdateEmployeeToDepartmentInput
}

export type MutationUpdateEmployeeWeekAggregationArgs = {
    condition?: InputMaybe<ModelEmployeeWeekAggregationConditionInput>
    input: UpdateEmployeeWeekAggregationInput
}

export type MutationUpdateEquipmentArgs = {
    condition?: InputMaybe<ModelEquipmentConditionInput>
    input: UpdateEquipmentInput
}

export type MutationUpdateEquipmentImageArgs = {
    condition?: InputMaybe<ModelEquipmentImageConditionInput>
    input: UpdateEquipmentImageInput
}

export type MutationUpdateGoogleImportedBookingsArgs = {
    condition?: InputMaybe<ModelGoogleImportedBookingsConditionInput>
    input: UpdateGoogleImportedBookingsInput
}

export type MutationUpdateGoogleImportedWorkingLocationsArgs = {
    condition?: InputMaybe<ModelGoogleImportedWorkingLocationsConditionInput>
    input: UpdateGoogleImportedWorkingLocationsInput
}

export type MutationUpdateIntegrationArgs = {
    condition?: InputMaybe<ModelIntegrationConditionInput>
    input: UpdateIntegrationInput
}

export type MutationUpdateMsCalendarIntegrationArgs = {
    input: UpdateMicrosoftCalendarIntegration
}

export type MutationUpdateMenuItemArgs = {
    condition?: InputMaybe<ModelMenuItemConditionInput>
    input: UpdateMenuItemInput
}

export type MutationUpdateMicrosoftSubscriptionArgs = {
    condition?: InputMaybe<ModelMicrosoftSubscriptionConditionInput>
    input: UpdateMicrosoftSubscriptionInput
}

export type MutationUpdateNotificationArgs = {
    condition?: InputMaybe<ModelNotificationConditionInput>
    input: UpdateNotificationInput
}

export type MutationUpdateOfficeArgs = {
    condition?: InputMaybe<ModelOfficeConditionInput>
    input: UpdateOfficeInput
}

export type MutationUpdateOfficeBuilderRequestArgs = {
    condition?: InputMaybe<ModelOfficeBuilderRequestConditionInput>
    input: UpdateOfficeBuilderRequestInput
}

export type MutationUpdateOfficeDayAggregationArgs = {
    condition?: InputMaybe<ModelOfficeDayAggregationConditionInput>
    input: UpdateOfficeDayAggregationInput
}

export type MutationUpdateOfficeImageArgs = {
    condition?: InputMaybe<ModelOfficeImageConditionInput>
    input: UpdateOfficeImageInput
}

export type MutationUpdateOfficeWeekAggregationArgs = {
    condition?: InputMaybe<ModelOfficeWeekAggregationConditionInput>
    input: UpdateOfficeWeekAggregationInput
}

export type MutationUpdateOidcIntegrationArgs = {
    input: OidcIntegrationInput
}

export type MutationUpdatePermissionArgs = {
    condition?: InputMaybe<ModelPermissionConditionInput>
    input: UpdatePermissionInput
}

export type MutationUpdatePersonioImportedAbsencesArgs = {
    condition?: InputMaybe<ModelPersonioImportedAbsencesConditionInput>
    input: UpdatePersonioImportedAbsencesInput
}

export type MutationUpdatePersonioImportedBookingsArgs = {
    condition?: InputMaybe<ModelPersonioImportedBookingsConditionInput>
    input: UpdatePersonioImportedBookingsInput
}

export type MutationUpdatePersonioIntegrationArgs = {
    input?: InputMaybe<UpdatePersonioIntegrationInput>
}

export type MutationUpdatePositionArgs = {
    input: UpdatePositionInput
}

export type MutationUpdateReservableArgs = {
    condition?: InputMaybe<ModelReservableConditionInput>
    input: UpdateReservableInput
}

export type MutationUpdateReservableImageArgs = {
    condition?: InputMaybe<ModelReservableImageConditionInput>
    input: UpdateReservableImageInput
}

export type MutationUpdateReservableTagArgs = {
    condition?: InputMaybe<ModelReservableTagConditionInput>
    input: UpdateReservableTagInput
}

export type MutationUpdateReservableToDepartmentArgs = {
    condition?: InputMaybe<ModelReservableToDepartmentConditionInput>
    input: UpdateReservableToDepartmentInput
}

export type MutationUpdateReservableToEmployeeArgs = {
    condition?: InputMaybe<ModelReservableToEmployeeConditionInput>
    input: UpdateReservableToEmployeeInput
}

export type MutationUpdateRoomDayAggregationArgs = {
    condition?: InputMaybe<ModelRoomDayAggregationConditionInput>
    input: UpdateRoomDayAggregationInput
}

export type MutationUpdateRoomWeekAggregationArgs = {
    condition?: InputMaybe<ModelRoomWeekAggregationConditionInput>
    input: UpdateRoomWeekAggregationInput
}

export type MutationUpdateRulesArgs = {
    condition?: InputMaybe<ModelRulesConditionInput>
    input: UpdateRulesInput
}

export type MutationUpdateSamlIntegrationArgs = {
    input: SamlIntegrationInput
}

export type MutationUpdateSlackIntegrationArgs = {
    input?: InputMaybe<SlackIntegrationUpdateInput>
}

export type MutationUpdateSlackOAuthStateArgs = {
    condition?: InputMaybe<ModelSlackOAuthStateConditionInput>
    input: UpdateSlackOAuthStateInput
}

export type MutationUpdateSlackOnboradingMessageArgs = {
    condition?: InputMaybe<ModelSlackOnboradingMessageConditionInput>
    input: UpdateSlackOnboradingMessageInput
}

export type MutationUpdateSpaceArgs = {
    condition?: InputMaybe<ModelSpaceConditionInput>
    input: UpdateSpaceInput
}

export type MutationUpdateSpaceCapacityDayAggregationArgs = {
    condition?: InputMaybe<ModelSpaceCapacityDayAggregationConditionInput>
    input: UpdateSpaceCapacityDayAggregationInput
}

export type MutationUpdateSpaceDayAggregationArgs = {
    condition?: InputMaybe<ModelSpaceDayAggregationConditionInput>
    input: UpdateSpaceDayAggregationInput
}

export type MutationUpdateSpaceRowArgs = {
    condition?: InputMaybe<ModelSpaceRowConditionInput>
    input: UpdateSpaceRowInput
}

export type MutationUpdateSpaceWeekAggregationArgs = {
    condition?: InputMaybe<ModelSpaceWeekAggregationConditionInput>
    input: UpdateSpaceWeekAggregationInput
}

export type MutationUpdateSuggestedSeatArgs = {
    condition?: InputMaybe<ModelSuggestedSeatConditionInput>
    input: UpdateSuggestedSeatInput
}

export type MutationUpdateTagArgs = {
    condition?: InputMaybe<ModelTagConditionInput>
    input: UpdateTagInput
}

export type MutationUpdateTeamEventRequestArgs = {
    condition?: InputMaybe<ModelTeamEventRequestConditionInput>
    input: UpdateTeamEventRequestInput
}

export type MutationUpdatedBambooHrIntegrationArgs = {
    input: UpdatedBambooHrIntegrationInput
}

export type MutationUpdatedTariffArgs = {
    input: TariffUpdatedInput
}

export type Notification = {
    __typename?: 'Notification'
    createdAt: Scalars['AWSDateTime']['output']
    employeeID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    placeholders?: Maybe<Scalars['AWSJSON']['output']>
    sentAt?: Maybe<Scalars['AWSDateTime']['output']>
    status: NotificationStatus
    type: NotificationType
    updatedAt: Scalars['AWSDateTime']['output']
}

export enum NotificationChannel {
    DISABLED = 'DISABLED',
    EMAIL = 'EMAIL',
    MS_TEAMS = 'MS_TEAMS',
    SLACK = 'SLACK',
}

export enum NotificationStatus {
    CANCELED = 'CANCELED',
    SCHEDULED = 'SCHEDULED',
    SENDING = 'SENDING',
    SENT = 'SENT',
}

export enum NotificationType {
    BOOKING_CHANGES = 'BOOKING_CHANGES',
    CANCELED_BOOKING = 'CANCELED_BOOKING',
    CHANGED_SEAT = 'CHANGED_SEAT',
    NEXT_WEEK_SCHEDULE_EMAIL = 'NEXT_WEEK_SCHEDULE_EMAIL',
}

export type Office = {
    __typename?: 'Office'
    Address?: Maybe<Address>
    Catering?: Maybe<Catering>
    Company?: Maybe<Company>
    Employees?: Maybe<ModelEmployeeConnection>
    Equipments?: Maybe<ModelEquipmentConnection>
    OfficeImages?: Maybe<ModelOfficeImageConnection>
    SpaceRows?: Maybe<ModelSpaceRowConnection>
    Spaces?: Maybe<ModelSpaceConnection>
    WeekAggregations?: Maybe<ModelOfficeWeekAggregationConnection>
    address?: Maybe<Scalars['String']['output']>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    externalOfficeId?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    officeAddressId?: Maybe<Scalars['ID']['output']>
    officeCateringId?: Maybe<Scalars['ID']['output']>
    officeCompanyId?: Maybe<Scalars['ID']['output']>
    order?: Maybe<Scalars['Int']['output']>
    phoneNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    placesBlocked?: Maybe<Scalars['Int']['output']>
    placesFixed?: Maybe<Scalars['Int']['output']>
    placesFlex?: Maybe<Scalars['Int']['output']>
    placesTotal?: Maybe<Scalars['Int']['output']>
    properties?: Maybe<Scalars['AWSJSON']['output']>
    shortDescription?: Maybe<Scalars['String']['output']>
    timeZone?: Maybe<Scalars['String']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
    workingFrom?: Maybe<Scalars['AWSTime']['output']>
    workingHours?: Maybe<Scalars['AWSJSON']['output']>
    workingTo?: Maybe<Scalars['AWSTime']['output']>
}

export type OfficeEmployeesArgs = {
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type OfficeEquipmentsArgs = {
    filter?: InputMaybe<ModelEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type OfficeOfficeImagesArgs = {
    filter?: InputMaybe<ModelOfficeImageFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type OfficeSpaceRowsArgs = {
    filter?: InputMaybe<ModelSpaceRowFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<ModelIntKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type OfficeSpacesArgs = {
    filter?: InputMaybe<ModelSpaceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type OfficeWeekAggregationsArgs = {
    filter?: InputMaybe<ModelOfficeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumber?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type OfficeBuilderOffice = {
    __typename?: 'OfficeBuilderOffice'
    address?: Maybe<Scalars['String']['output']>
    externalOfficeId?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    spacesRows: Array<OfficeBuilderSpacesRow>
    timeZone?: Maybe<Scalars['String']['output']>
    workingFrom?: Maybe<Scalars['AWSTime']['output']>
    workingTo?: Maybe<Scalars['AWSTime']['output']>
}

export type OfficeBuilderOfficeInput = {
    address?: InputMaybe<Scalars['String']['input']>
    externalOfficeId?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
    spacesRows: Array<OfficeBuilderSpacesRowInput>
    timeZone?: InputMaybe<Scalars['String']['input']>
    workingFrom?: InputMaybe<Scalars['AWSTime']['input']>
    workingTo?: InputMaybe<Scalars['AWSTime']['input']>
}

export type OfficeBuilderRequest = {
    __typename?: 'OfficeBuilderRequest'
    Company?: Maybe<Company>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    id: Scalars['ID']['output']
    offices: Array<OfficeBuilderOffice>
    owner?: Maybe<Scalars['ID']['output']>
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
}

export type OfficeBuilderSpace = {
    __typename?: 'OfficeBuilderSpace'
    floorPlanFilePath?: Maybe<Scalars['String']['output']>
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
}

export type OfficeBuilderSpaceInput = {
    floorPlanFilePath?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    name: Scalars['String']['input']
}

export type OfficeBuilderSpacesRow = {
    __typename?: 'OfficeBuilderSpacesRow'
    id: Scalars['ID']['output']
    spaces: Array<OfficeBuilderSpace>
}

export type OfficeBuilderSpacesRowInput = {
    id: Scalars['ID']['input']
    spaces: Array<OfficeBuilderSpaceInput>
}

export type OfficeDayAggregation = {
    __typename?: 'OfficeDayAggregation'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    bookedHours?: Maybe<Scalars['Int']['output']>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date: Scalars['AWSDate']['output']
    homeBookingsSum: Scalars['Int']['output']
    id: Scalars['ID']['output']
    officeBookingsSum: Scalars['Int']['output']
    officeCapacityLast: Scalars['Int']['output']
    officeDayAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    officeDayAggregationOfficeId?: Maybe<Scalars['ID']['output']>
    officeID: Scalars['ID']['output']
    type?: Maybe<AggregationByReservableType>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type OfficeImage = {
    __typename?: 'OfficeImage'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    location?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    officeID: Scalars['ID']['output']
    officeImageCompanyId?: Maybe<Scalars['ID']['output']>
    officeImageOfficeId?: Maybe<Scalars['ID']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type OfficeWeekAggregation = {
    __typename?: 'OfficeWeekAggregation'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    bookedHours?: Maybe<Scalars['Int']['output']>
    bookingsDistribution: Array<DashboardWeekAggregationBookingsDistribution>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dayAggregations?: Maybe<ModelOfficeDayAggregationConnection>
    employeesLast: Scalars['Int']['output']
    id: Scalars['ID']['output']
    officeBookingsSum: Scalars['Int']['output']
    officeCapacityMax: Scalars['Int']['output']
    officeID: Scalars['ID']['output']
    officeWeekAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    officeWeekAggregationOfficeId?: Maybe<Scalars['ID']['output']>
    percentageOfUtilization?: Maybe<Scalars['Int']['output']>
    type?: Maybe<AggregationByReservableType>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type OfficeWeekAggregationDayAggregationsArgs = {
    filter?: InputMaybe<ModelOfficeDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type OidcIntegration = {
    __typename?: 'OidcIntegration'
    clientId: Scalars['String']['output']
    clientSecret: Scalars['String']['output']
    companyId: Scalars['String']['output']
    domains: Array<Scalars['String']['output']>
    id?: Maybe<Scalars['ID']['output']>
    issuer: Scalars['String']['output']
    source?: Maybe<SsoSource>
}

export type OidcIntegrationInput = {
    clientId: Scalars['String']['input']
    clientSecret: Scalars['String']['input']
    domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    id?: InputMaybe<Scalars['ID']['input']>
    issuer: Scalars['String']['input']
    source?: InputMaybe<SsoSource>
}

export type PaylinkUrlForCheckout = {
    __typename?: 'PaylinkUrlForCheckout'
    url: Scalars['String']['output']
}

export type Permission = {
    __typename?: 'Permission'
    Company?: Maybe<Company>
    Role?: Maybe<CustomRole>
    allowedActions: Array<ActionType>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    resourceType: ResourceType
    roleID: Scalars['ID']['output']
    scope?: Maybe<Scope>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type PersonioDepartmentMapping = {
    __typename?: 'PersonioDepartmentMapping'
    companyID: Scalars['ID']['output']
    departmentID?: Maybe<Scalars['ID']['output']>
    id: Scalars['ID']['output']
    personioDepartmentID?: Maybe<Scalars['ID']['output']>
}

export type PersonioDepartmentMappingEntry = {
    companyID: Scalars['ID']['input']
    departmentID?: InputMaybe<Scalars['ID']['input']>
    personioDepartmentID?: InputMaybe<Scalars['ID']['input']>
}

export type PersonioDepartmentName = {
    __typename?: 'PersonioDepartmentName'
    personioDepartmentID: Scalars['ID']['output']
    personioDepartmentName: Scalars['String']['output']
}

export type PersonioImportedAbsences = {
    __typename?: 'PersonioImportedAbsences'
    BookingRequest?: Maybe<BookingRequest>
    bookingRequestID: Scalars['ID']['output']
    bookingSubType?: Maybe<BookingSubType>
    bookingType?: Maybe<BookingType>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    effectiveFrom: Scalars['AWSDate']['output']
    effectiveTo: Scalars['AWSDate']['output']
    employeeEmail: Scalars['String']['output']
    halfDayEnd?: Maybe<Scalars['Boolean']['output']>
    halfDayStart?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    personioID: Scalars['Int']['output']
    personioImportedAbsencesBookingRequestId?: Maybe<Scalars['ID']['output']>
    reservableID?: Maybe<Scalars['ID']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type PersonioImportedBookings = {
    __typename?: 'PersonioImportedBookings'
    Booking?: Maybe<Booking>
    bookingID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    effectiveFrom: Scalars['AWSDate']['output']
    id: Scalars['ID']['output']
    personioID: Scalars['String']['output']
    personioImportedBookingsBookingId?: Maybe<Scalars['ID']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type PersonioIntegration = {
    __typename?: 'PersonioIntegration'
    clientID: Scalars['String']['output']
    clientSecret: Scalars['String']['output']
    companyID: Scalars['ID']['output']
    employeeEmails?: Maybe<Array<Maybe<Scalars['String']['output']>>>
    error?: Maybe<Scalars['String']['output']>
    hasInvitationEnabled: Scalars['Boolean']['output']
    hasSyncBookingsToPersonioEnabled: Scalars['Boolean']['output']
    lastSyncedAt?: Maybe<Scalars['AWSDateTime']['output']>
    nextSyncAt?: Maybe<Scalars['AWSDateTime']['output']>
    personioCompanyID?: Maybe<Scalars['Int']['output']>
    status?: Maybe<PersonioIntegrationStatus>
}

export type PersonioIntegrationExecutionResultInput = {
    companyID: Scalars['ID']['input']
    employeeEmails: Array<InputMaybe<Scalars['String']['input']>>
    error?: InputMaybe<Scalars['String']['input']>
    lastSyncedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    nextSyncAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<PersonioIntegrationStatus>
}

export type PersonioIntegrationInput = {
    clientID: Scalars['String']['input']
    clientSecret: Scalars['String']['input']
    hasInvitationEnabled?: InputMaybe<Scalars['Boolean']['input']>
    hasSyncBookingsToPersonioEnabled?: InputMaybe<Scalars['Boolean']['input']>
    personioCompanyID?: InputMaybe<Scalars['Int']['input']>
}

export enum PersonioIntegrationStatus {
    DISABLED = 'DISABLED',
    ERROR = 'ERROR',
    SYNCED = 'SYNCED',
    SYNCING = 'SYNCING',
}

export type PersonioPositionMapping = {
    __typename?: 'PersonioPositionMapping'
    companyID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    personioPositionID: Scalars['ID']['output']
    positionID: Scalars['ID']['output']
}

export type PersonioPositionMappingEntry = {
    companyID: Scalars['ID']['input']
    personioPositionID: Scalars['ID']['input']
    positionID: Scalars['ID']['input']
}

export type PersonioPositionName = {
    __typename?: 'PersonioPositionName'
    personioPositionID: Scalars['ID']['output']
    personioPositionName: Scalars['String']['output']
}

export type Position = {
    __typename?: 'Position'
    Company?: Maybe<Company>
    Employees?: Maybe<ModelEmployeeConnection>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    positionCompanyId?: Maybe<Scalars['ID']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type PositionEmployeesArgs = {
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type Query = {
    __typename?: 'Query'
    bookingsByCompany?: Maybe<ModelBookingConnection>
    employeeByEmail?: Maybe<ModelEmployeeConnection>
    employeeByEmailForSearch?: Maybe<ModelEmployeeConnection>
    getAddress?: Maybe<Address>
    getAllRoomMappingsNew?: Maybe<Array<Maybe<GetRoomMappingsResponse>>>
    getBambooHRIntegration?: Maybe<BambooHrIntegration>
    getBooking?: Maybe<Booking>
    getBookingCancellation?: Maybe<BookingCancellation>
    getBookingEquipment?: Maybe<BookingEquipment>
    getBookingRequest?: Maybe<BookingRequest>
    getBookingReservable?: Maybe<BookingReservable>
    getCalendarEventEmployee?: Maybe<CalendarEventEmployee>
    getCalendarEventRequest?: Maybe<CalendarEventRequest>
    getCalendarEvents?: Maybe<CalendarEvents>
    getCatering?: Maybe<Catering>
    getCompany?: Maybe<Company>
    getCompanyDayAggregation?: Maybe<CompanyDayAggregation>
    getCompanyWeekAggregation?: Maybe<CompanyWeekAggregation>
    getContactTracingCsv: Scalars['String']['output']
    getCustomRole?: Maybe<CustomRole>
    getDemoAccount?: Maybe<DemoAccount>
    getDepartment?: Maybe<Department>
    getDepartmentDayAggregation?: Maybe<DepartmentDayAggregation>
    getDepartmentDayAggregationEmployees?: Maybe<DepartmentDayAggregationEmployees>
    getDepartmentLastMonthAttendance?: Maybe<DepartmentLastMonthAttendance>
    getDepartmentRules?: Maybe<DepartmentRules>
    getDepartmentWeekAggregation?: Maybe<DepartmentWeekAggregation>
    getEmployee?: Maybe<Employee>
    getEmployeeDayAggregation?: Maybe<EmployeeDayAggregation>
    getEmployeeLastMonthAttendance?: Maybe<EmployeeLastMonthAttendance>
    getEmployeeToDepartment?: Maybe<EmployeeToDepartment>
    getEmployeeWeekAggregation?: Maybe<EmployeeWeekAggregation>
    getEquipment?: Maybe<Equipment>
    getEquipmentCategory?: Maybe<EquipmentCategory>
    getEquipmentImage?: Maybe<EquipmentImage>
    getGoogleCalendarIntegration: GetGoogleCalendarIntegration
    getGoogleImportedBookings?: Maybe<GoogleImportedBookings>
    getGoogleImportedWorkingLocations?: Maybe<GoogleImportedWorkingLocations>
    getGoogleRoomMappings?: Maybe<Array<Maybe<GetRoomMappingsResponse>>>
    getIntegration?: Maybe<Integration>
    getMenuItem?: Maybe<MenuItem>
    getMicrosoftCalendarIntegration?: Maybe<Array<Maybe<GetMicrosoftCalendarIntegration>>>
    getMicrosoftSubscription?: Maybe<MicrosoftSubscription>
    getMsTeamsIntegration?: Maybe<Array<Maybe<MsTeamsIntegration>>>
    getNotification?: Maybe<Notification>
    getOffice?: Maybe<Office>
    getOfficeBuilderRequest?: Maybe<OfficeBuilderRequest>
    getOfficeDayAggregation?: Maybe<OfficeDayAggregation>
    getOfficeImage?: Maybe<OfficeImage>
    getOfficeWeekAggregation?: Maybe<OfficeWeekAggregation>
    getOidcIntegration?: Maybe<Array<Maybe<OidcIntegration>>>
    getPaylinkUrlForCheckout: PaylinkUrlForCheckout
    getPermission?: Maybe<Permission>
    getPersonioImportedAbsences?: Maybe<PersonioImportedAbsences>
    getPersonioImportedBookings?: Maybe<PersonioImportedBookings>
    getPersonioIntegration?: Maybe<PersonioIntegration>
    getPosition?: Maybe<Position>
    getReservable?: Maybe<Reservable>
    getReservableImage?: Maybe<ReservableImage>
    getReservableTag?: Maybe<ReservableTag>
    getReservableToDepartment?: Maybe<ReservableToDepartment>
    getReservableToEmployee?: Maybe<ReservableToEmployee>
    getRoomDayAggregation?: Maybe<RoomDayAggregation>
    getRoomWeekAggregation?: Maybe<RoomWeekAggregation>
    getRules?: Maybe<Rules>
    getS3SignedLink: Scalars['String']['output']
    getSamlIntegration?: Maybe<Array<Maybe<SamlIntegration>>>
    getScimIntegration?: Maybe<ScimIntegration>
    getSeatsBySpace: Array<Reservable>
    getSlackIntegration?: Maybe<SlackIntegration>
    getSlackIntegrationInstallUrl: Scalars['String']['output']
    getSlackOAuthState?: Maybe<SlackOAuthState>
    getSlackOnboradingMessage?: Maybe<SlackOnboradingMessage>
    getSpace?: Maybe<Space>
    getSpaceCapacityDayAggregation?: Maybe<SpaceCapacityDayAggregation>
    getSpaceDayAggregation?: Maybe<SpaceDayAggregation>
    getSpaceRow?: Maybe<SpaceRow>
    getSpaceTagByNameAndCompanyName?: Maybe<ModelTagConnection>
    getSpaceWeekAggregation?: Maybe<SpaceWeekAggregation>
    getSubscriptionsForCompany: SubscribedBillingItems
    getSuggestedSeat?: Maybe<SuggestedSeat>
    getSyncUserEvent?: Maybe<SyncUserEvent>
    getTag?: Maybe<Tag>
    getTeamEventRequest?: Maybe<TeamEventRequest>
    listAddresses?: Maybe<ModelAddressConnection>
    listBookingCancellations?: Maybe<ModelBookingCancellationConnection>
    listBookingEquipments?: Maybe<ModelBookingEquipmentConnection>
    listBookingEquipmentsByCalendarEvent?: Maybe<ModelBookingEquipmentConnection>
    listBookingEquipmentsByEquipment?: Maybe<ModelBookingEquipmentConnection>
    listBookingEquipmentsByEquipmentAndStartTime?: Maybe<ModelBookingEquipmentConnection>
    listBookingEquipmentsByOfficeAndDate?: Maybe<ModelBookingEquipmentConnection>
    listBookingEquipmentsByOfficeAndStartTime?: Maybe<ModelBookingEquipmentConnection>
    listBookingEquipmentsByOfficeAndYearAndWeek?: Maybe<ModelBookingEquipmentConnection>
    listBookingRequests?: Maybe<ModelBookingRequestConnection>
    listBookingRequestsByEmployeeAndEffectiveFrom?: Maybe<ModelBookingRequestConnection>
    listBookingRequestsByStatusAndEffectiveFrom?: Maybe<ModelBookingRequestConnection>
    listBookingReservables?: Maybe<ModelBookingReservableConnection>
    listBookingReservablesByBooking?: Maybe<ModelBookingReservableConnection>
    listBookingReservablesByReservable?: Maybe<ModelBookingReservableConnection>
    listBookingReservablesByReservableAndDateAndDayOfWeek?: Maybe<ModelBookingReservableConnection>
    listBookingReservablesByReservableAndStartTime?: Maybe<ModelBookingReservableConnection>
    listBookingReservablesByReservableAndStartTimeAndEndTime?: Maybe<ModelBookingReservableConnection>
    listBookings?: Maybe<ModelBookingConnection>
    listBookingsByBookingRequest?: Maybe<ModelBookingConnection>
    listBookingsByCompanyAndStartTime?: Maybe<ModelBookingConnection>
    listBookingsByDate?: Maybe<ModelBookingConnection>
    listBookingsByEmployeeAndStartTime?: Maybe<ModelBookingConnection>
    listBookingsByReservableAndDateAndDayOfWeek?: Maybe<ModelBookingConnection>
    listBookingsByReservableAndStartTime?: Maybe<ModelBookingConnection>
    listBookingsByTeamEventRequest?: Maybe<ModelBookingConnection>
    listCalendarEventEmployees?: Maybe<ModelCalendarEventEmployeeConnection>
    listCalendarEventEmployeesByCalendarEvent?: Maybe<ModelCalendarEventEmployeeConnection>
    listCalendarEventEmployeesByEmployeeAndDate?: Maybe<ModelCalendarEventEmployeeConnection>
    listCalendarEventEmployeesByEmployeeAndWeekAndYear?: Maybe<ModelCalendarEventEmployeeConnection>
    listCalendarEventRequests?: Maybe<ModelCalendarEventRequestConnection>
    listCalendarEventRequestsByEmployeeAndEffectiveFrom?: Maybe<ModelCalendarEventRequestConnection>
    listCalendarEventRequestsByReservable?: Maybe<ModelCalendarEventRequestConnection>
    listCalendarEventRequestsByReservableAndStartTime?: Maybe<ModelCalendarEventRequestConnection>
    listCalendarEvents?: Maybe<ModelCalendarEventsConnection>
    listCalendarEventsByCalendarEventRequest?: Maybe<ModelCalendarEventsConnection>
    listCalendarEventsByOfficeAndStartTime?: Maybe<ModelCalendarEventsConnection>
    listCalendarEventsByReservable?: Maybe<ModelCalendarEventsConnection>
    listCalendarEventsByReservableAndStartTime?: Maybe<ModelCalendarEventsConnection>
    listCaterings?: Maybe<ModelCateringConnection>
    listCompanies?: Maybe<ModelCompanyConnection>
    listCompanyDayAggregationByCompany?: Maybe<ModelCompanyDayAggregationConnection>
    listCompanyDayAggregationByCompanyAndMoment?: Maybe<ModelCompanyDayAggregationConnection>
    listCompanyDayAggregations?: Maybe<ModelCompanyDayAggregationConnection>
    listCompanyWeekAggregationByCompany?: Maybe<ModelCompanyWeekAggregationConnection>
    listCompanyWeekAggregationByCompanyAndMoment?: Maybe<ModelCompanyWeekAggregationConnection>
    listCompanyWeekAggregations?: Maybe<ModelCompanyWeekAggregationConnection>
    listCustomRoles?: Maybe<ModelCustomRoleConnection>
    listDashboardDayAggregationBySpaceAndYearAndWeekNumberAndDate?: Maybe<ModelSpaceDayAggregationConnection>
    listDashboardWeekAggregationBySpaceAndWeekNumberAndDate?: Maybe<ModelSpaceDayAggregationConnection>
    listDemoAccounts?: Maybe<ModelDemoAccountConnection>
    listDepartmentDayAggregationByCompany?: Maybe<ModelDepartmentDayAggregationConnection>
    listDepartmentDayAggregationByCompanyAndMoment?: Maybe<ModelDepartmentDayAggregationConnection>
    listDepartmentDayAggregationByDepartment?: Maybe<ModelDepartmentDayAggregationConnection>
    listDepartmentDayAggregationByDepartmentAndMoment?: Maybe<ModelDepartmentDayAggregationConnection>
    listDepartmentDayAggregationEmployees?: Maybe<ModelDepartmentDayAggregationEmployeesConnection>
    listDepartmentDayAggregations?: Maybe<ModelDepartmentDayAggregationConnection>
    listDepartmentLastMonthAttendances?: Maybe<ModelDepartmentLastMonthAttendanceConnection>
    listDepartmentLastMonthAttendancesByLastDateTogether?: Maybe<ModelDepartmentLastMonthAttendanceConnection>
    listDepartmentLastMonthAttendancesByName?: Maybe<ModelDepartmentLastMonthAttendanceConnection>
    listDepartmentLastMonthAttendancesByWeeklyAttendance?: Maybe<ModelDepartmentLastMonthAttendanceConnection>
    listDepartmentRules?: Maybe<ModelDepartmentRulesConnection>
    listDepartmentWeekAggregationByCompany?: Maybe<ModelDepartmentWeekAggregationConnection>
    listDepartmentWeekAggregationByCompanyAndMoment?: Maybe<ModelDepartmentWeekAggregationConnection>
    listDepartmentWeekAggregationByDepartmentAndMoment?: Maybe<ModelDepartmentWeekAggregationConnection>
    listDepartmentWeekAggregationByDepartmentAndWeekNumber?: Maybe<ModelDepartmentWeekAggregationConnection>
    listDepartmentWeekAggregations?: Maybe<ModelDepartmentWeekAggregationConnection>
    listDepartments?: Maybe<ModelDepartmentConnection>
    listDepartmentsByCompanyAndName?: Maybe<ModelDepartmentConnection>
    listEmployeeDayAggregationByCompany?: Maybe<ModelEmployeeDayAggregationConnection>
    listEmployeeDayAggregationByCompanyAndMoment?: Maybe<ModelEmployeeDayAggregationConnection>
    listEmployeeDayAggregations?: Maybe<ModelEmployeeDayAggregationConnection>
    listEmployeeLastMonthAttendances?: Maybe<ModelEmployeeLastMonthAttendanceConnection>
    listEmployeeLastMonthAttendancesByDepartment?: Maybe<ModelEmployeeLastMonthAttendanceConnection>
    listEmployeeLastMonthAttendancesByLastDateInOffice?: Maybe<ModelEmployeeLastMonthAttendanceConnection>
    listEmployeeLastMonthAttendancesByName?: Maybe<ModelEmployeeLastMonthAttendanceConnection>
    listEmployeeLastMonthAttendancesByWeeklyAttendance?: Maybe<ModelEmployeeLastMonthAttendanceConnection>
    listEmployeeToDepartmentByDepartment?: Maybe<ModelEmployeeToDepartmentConnection>
    listEmployeeToDepartmentByEmployee?: Maybe<ModelEmployeeToDepartmentConnection>
    listEmployeeToDepartments?: Maybe<ModelEmployeeToDepartmentConnection>
    listEmployeeWeekAggregationByCompany?: Maybe<ModelEmployeeWeekAggregationConnection>
    listEmployeeWeekAggregationByCompanyAndMoment?: Maybe<ModelEmployeeWeekAggregationConnection>
    listEmployeeWeekAggregations?: Maybe<ModelEmployeeWeekAggregationConnection>
    listEmployees?: Maybe<ModelEmployeeConnection>
    listEmployeesByCognitoUserId?: Maybe<ModelEmployeeConnection>
    listEmployeesByCompanyAndEmail?: Maybe<ModelEmployeeConnection>
    listEmployeesByCompanyAndFirstname?: Maybe<ModelEmployeeConnection>
    listEmployeesByCompanyAndRole?: Maybe<ModelEmployeeConnection>
    listEmployeesByCompanyAndStatusAndFirstname?: Maybe<ModelEmployeeConnection>
    listEmployeesByDepartmentAndFirstname?: Maybe<ModelEmployeeConnection>
    listEmployeesByDepartmentAndStatusAndFirstname?: Maybe<ModelEmployeeConnection>
    listEmployeesByOffice?: Maybe<ModelEmployeeConnection>
    listEquipment?: Maybe<ModelEquipmentConnection>
    listEquipmentCategories?: Maybe<ModelEquipmentCategoryConnection>
    listEquipmentCategoriesByCompanyAndName?: Maybe<ModelEquipmentCategoryConnection>
    listEquipmentImages?: Maybe<ModelEquipmentImageConnection>
    listEquipmentsByCategoryAndName?: Maybe<ModelEquipmentConnection>
    listEquipmentsByCompany?: Maybe<ModelEquipmentCategoryConnection>
    listEquipmentsByOffice?: Maybe<ModelEquipmentConnection>
    listGenericIntegrations: Array<GenericIntegration>
    listGoogleImportedBookings?: Maybe<ModelGoogleImportedBookingsConnection>
    listGoogleImportedBookingsByCalendarEventId?: Maybe<ModelGoogleImportedBookingsConnection>
    listGoogleImportedBookingsByGoogleId?: Maybe<ModelGoogleImportedBookingsConnection>
    listGoogleImportedWorkingLocations?: Maybe<ModelGoogleImportedWorkingLocationsConnection>
    listGoogleImportedWorkingLocationsByBookingId?: Maybe<ModelGoogleImportedWorkingLocationsConnection>
    listGoogleImportedWorkingLocationsByGoogleId?: Maybe<ModelGoogleImportedWorkingLocationsConnection>
    listIntegrations?: Maybe<ModelIntegrationConnection>
    listIntegrationsByCompany?: Maybe<ModelIntegrationConnection>
    listMenuItems?: Maybe<ModelMenuItemConnection>
    listMenuItemsByCatering?: Maybe<ModelMenuItemConnection>
    listMicrosoftSubscriptions?: Maybe<ModelMicrosoftSubscriptionConnection>
    listNotificationIntegrations: Array<GenericIntegration>
    listNotifications?: Maybe<ModelNotificationConnection>
    listOfficeBuilderRequests?: Maybe<ModelOfficeBuilderRequestConnection>
    listOfficeBuilderRequestsByCompany?: Maybe<ModelOfficeBuilderRequestConnection>
    listOfficeDayAggregationByCompanyAndDate?: Maybe<ModelOfficeDayAggregationConnection>
    listOfficeDayAggregationByOfficeAndMoment?: Maybe<ModelOfficeDayAggregationConnection>
    listOfficeDayAggregations?: Maybe<ModelOfficeDayAggregationConnection>
    listOfficeImages?: Maybe<ModelOfficeImageConnection>
    listOfficeWeekAggregationByCompanyAndWeekNumber?: Maybe<ModelOfficeWeekAggregationConnection>
    listOfficeWeekAggregationByOfficeAndMoment?: Maybe<ModelOfficeWeekAggregationConnection>
    listOfficeWeekAggregationByOfficeAndWeekNumber?: Maybe<ModelOfficeWeekAggregationConnection>
    listOfficeWeekAggregationByOfficeAndYearAndWeekNumber?: Maybe<ModelOfficeWeekAggregationConnection>
    listOfficeWeekAggregations?: Maybe<ModelOfficeWeekAggregationConnection>
    listOffices?: Maybe<ModelOfficeConnection>
    listOfficesByCompanyAndName?: Maybe<ModelOfficeConnection>
    listOfficesByCompanyAndOrder?: Maybe<ModelOfficeConnection>
    listPayments?: Maybe<Array<BillingPayment>>
    listPermissions?: Maybe<ModelPermissionConnection>
    listPersonioImportedAbsences?: Maybe<ModelPersonioImportedAbsencesConnection>
    listPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFrom?: Maybe<ModelPersonioImportedAbsencesConnection>
    listPersonioImportedAbsencesByCompanyIDAndEffectiveFrom?: Maybe<ModelPersonioImportedAbsencesConnection>
    listPersonioImportedBookings?: Maybe<ModelPersonioImportedBookingsConnection>
    listPersonioImportedBookingsByPersonioId?: Maybe<ModelPersonioImportedBookingsConnection>
    listPositions?: Maybe<ModelPositionConnection>
    listPositionsByCompanyAndName?: Maybe<ModelPositionConnection>
    listReservableImages?: Maybe<ModelReservableImageConnection>
    listReservableTags?: Maybe<ModelReservableTagConnection>
    listReservableTagsByOfficeAndType?: Maybe<ModelReservableTagConnection>
    listReservableTagsByReservable?: Maybe<ModelReservableTagConnection>
    listReservableTagsBySpace?: Maybe<ModelReservableTagConnection>
    listReservableTagsByTag?: Maybe<ModelReservableTagConnection>
    listReservableToDepartments?: Maybe<ModelReservableToDepartmentConnection>
    listReservableToDepartmentsByDepartment?: Maybe<ModelReservableToDepartmentConnection>
    listReservableToDepartmentsByReservable?: Maybe<ModelReservableToDepartmentConnection>
    listReservableToEmployees?: Maybe<ModelReservableToEmployeeConnection>
    listReservables?: Maybe<ModelReservableConnection>
    listReservablesByCompany?: Maybe<ModelReservableConnection>
    listReservablesByCompanyAndIsDefault?: Maybe<ModelReservableConnection>
    listReservablesByCompanyAndType?: Maybe<ModelReservableConnection>
    listReservablesBySpaceAndType?: Maybe<ModelReservableConnection>
    listRoomDayAggregationByCompanyAndDate?: Maybe<ModelRoomDayAggregationConnection>
    listRoomDayAggregationByOfficeAndMoment?: Maybe<ModelRoomDayAggregationConnection>
    listRoomDayAggregationByReservableAndMoment?: Maybe<ModelRoomDayAggregationConnection>
    listRoomDayAggregationBySpaceAndMoment?: Maybe<ModelRoomDayAggregationConnection>
    listRoomDayAggregations?: Maybe<ModelRoomDayAggregationConnection>
    listRoomWeekAggregationByCompanyAndYearAndWeekNumber?: Maybe<ModelRoomWeekAggregationConnection>
    listRoomWeekAggregationByOfficeAndMoment?: Maybe<ModelRoomWeekAggregationConnection>
    listRoomWeekAggregationByReservableAndMoment?: Maybe<ModelRoomWeekAggregationConnection>
    listRoomWeekAggregationBySpaceAndMoment?: Maybe<ModelRoomWeekAggregationConnection>
    listRoomWeekAggregations?: Maybe<ModelRoomWeekAggregationConnection>
    listRules?: Maybe<ModelRulesConnection>
    listSlackOAuthStates?: Maybe<ModelSlackOAuthStateConnection>
    listSlackOAuthStatesByCompany?: Maybe<ModelSlackOAuthStateConnection>
    listSlackOnboradingMessages?: Maybe<ModelSlackOnboradingMessageConnection>
    listSpaceCapacityDayAggregationBySpaceAndEmployeeAndDate?: Maybe<ModelSpaceCapacityDayAggregationConnection>
    listSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAt?: Maybe<ModelSpaceCapacityDayAggregationConnection>
    listSpaceCapacityDayAggregations?: Maybe<ModelSpaceCapacityDayAggregationConnection>
    listSpaceDayAggregationByCompanyAndDate?: Maybe<ModelSpaceDayAggregationConnection>
    listSpaceDayAggregationBySpaceAndMoment?: Maybe<ModelSpaceDayAggregationConnection>
    listSpaceDayAggregations?: Maybe<ModelSpaceDayAggregationConnection>
    listSpaceRows?: Maybe<ModelSpaceRowConnection>
    listSpaceRowsByOfficeAndOrder?: Maybe<ModelSpaceRowConnection>
    listSpaceWeekAggregationByCompanyAndWeekNumber?: Maybe<ModelSpaceWeekAggregationConnection>
    listSpaceWeekAggregationByCompanyAndYearAndWeekNumber?: Maybe<ModelSpaceWeekAggregationConnection>
    listSpaceWeekAggregationByOfficeAndMoment?: Maybe<ModelSpaceWeekAggregationConnection>
    listSpaceWeekAggregationBySpaceAndMoment?: Maybe<ModelSpaceWeekAggregationConnection>
    listSpaceWeekAggregationBySpaceAndWeekNumber?: Maybe<ModelSpaceWeekAggregationConnection>
    listSpaceWeekAggregationBySpaceAndYearAndWeekNumber?: Maybe<ModelSpaceWeekAggregationConnection>
    listSpaceWeekAggregations?: Maybe<ModelSpaceWeekAggregationConnection>
    listSpaces?: Maybe<ModelSpaceConnection>
    listSpacesByCompanyAndName?: Maybe<ModelSpaceConnection>
    listSubscriptionsPlans: BillingPlans
    listSuggestedSeats?: Maybe<ModelSuggestedSeatConnection>
    listSuggestedSeatsByEmployeeAndDate?: Maybe<ModelSuggestedSeatConnection>
    listSuggestedSeatsByReservableAndDate?: Maybe<ModelSuggestedSeatConnection>
    listSuggestedSeatsBySpaceAndDate?: Maybe<ModelSuggestedSeatConnection>
    listSuggestedSeatsBySpaceAndEmployeeAndDate?: Maybe<ModelSuggestedSeatConnection>
    listSyncUserEvents?: Maybe<ModelSyncUserEventConnection>
    listTags?: Maybe<ModelTagConnection>
    listTeamEventRequests?: Maybe<ModelTeamEventRequestConnection>
    resolveEmail: ResolveEmailResult
    searchDepartmentLastMonthAttendances?: Maybe<SearchableDepartmentLastMonthAttendanceConnection>
    searchEmployeeLastMonthAttendances?: Maybe<SearchableEmployeeLastMonthAttendanceConnection>
    searchEmployees?: Maybe<SearchableEmployeeConnection>
    searchEquipment?: Maybe<SearchableEquipmentConnection>
    searchReservables?: Maybe<SearchableReservableConnection>
    searchRoomWeekAggregations?: Maybe<SearchableRoomWeekAggregationConnection>
}

export type QueryBookingsByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryEmployeeByEmailArgs = {
    email: Scalars['String']['input']
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryEmployeeByEmailForSearchArgs = {
    emailForSearch: Scalars['String']['input']
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryGetAddressArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetAllRoomMappingsNewArgs = {
    input?: InputMaybe<GetRoomMappingsRequest>
}

export type QueryGetBookingArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetBookingCancellationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetBookingEquipmentArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetBookingRequestArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetBookingReservableArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetCalendarEventEmployeeArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetCalendarEventRequestArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetCalendarEventsArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetCateringArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetCompanyArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetCompanyDayAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetCompanyWeekAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetContactTracingCsvArgs = {
    date: Scalars['AWSDate']['input']
    employeeID: Scalars['ID']['input']
}

export type QueryGetCustomRoleArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetDemoAccountArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetDepartmentArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetDepartmentDayAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetDepartmentDayAggregationEmployeesArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetDepartmentLastMonthAttendanceArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetDepartmentRulesArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetDepartmentWeekAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetEmployeeArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetEmployeeDayAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetEmployeeLastMonthAttendanceArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetEmployeeToDepartmentArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetEmployeeWeekAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetEquipmentArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetEquipmentCategoryArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetEquipmentImageArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetGoogleImportedBookingsArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetGoogleImportedWorkingLocationsArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetIntegrationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetMenuItemArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetMicrosoftSubscriptionArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetNotificationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetOfficeArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetOfficeBuilderRequestArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetOfficeDayAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetOfficeImageArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetOfficeWeekAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetPaylinkUrlForCheckoutArgs = {
    planID: Scalars['String']['input']
    returnUrl: Scalars['String']['input']
}

export type QueryGetPermissionArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetPersonioImportedAbsencesArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetPersonioImportedBookingsArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetPositionArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetReservableArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetReservableImageArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetReservableTagArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetReservableToDepartmentArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetReservableToEmployeeArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetRoomDayAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetRoomWeekAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetRulesArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetS3SignedLinkArgs = {
    operationType: S3OperationType
    path: Scalars['String']['input']
    resize?: InputMaybe<ImageResizeInput>
}

export type QueryGetSeatsBySpaceArgs = {
    daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    endTime?: InputMaybe<Scalars['AWSTime']['input']>
    repeatType?: InputMaybe<RepeatType>
    spaceID: Scalars['ID']['input']
    startDate: Scalars['AWSDate']['input']
    startTime?: InputMaybe<Scalars['AWSTime']['input']>
}

export type QueryGetSlackOAuthStateArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetSlackOnboradingMessageArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetSpaceArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetSpaceCapacityDayAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetSpaceDayAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetSpaceRowArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetSpaceTagByNameAndCompanyNameArgs = {
    filter?: InputMaybe<ModelTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    name: Scalars['String']['input']
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryGetSpaceWeekAggregationArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetSuggestedSeatArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetSyncUserEventArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetTagArgs = {
    id: Scalars['ID']['input']
}

export type QueryGetTeamEventRequestArgs = {
    id: Scalars['ID']['input']
}

export type QueryListAddressesArgs = {
    filter?: InputMaybe<ModelAddressFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListBookingCancellationsArgs = {
    filter?: InputMaybe<ModelBookingCancellationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListBookingEquipmentsArgs = {
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListBookingEquipmentsByCalendarEventArgs = {
    calendarEventID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListBookingEquipmentsByEquipmentArgs = {
    equipmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListBookingEquipmentsByEquipmentAndStartTimeArgs = {
    equipmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListBookingEquipmentsByOfficeAndDateArgs = {
    date?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListBookingEquipmentsByOfficeAndStartTimeArgs = {
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListBookingEquipmentsByOfficeAndYearAndWeekArgs = {
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearIsoWeekNumber?: InputMaybe<ModelBookingEquipmentByOfficeAndYearAndIsoWeekNumberCompositeKeyConditionInput>
}

export type QueryListBookingRequestsArgs = {
    filter?: InputMaybe<ModelBookingRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListBookingRequestsByEmployeeAndEffectiveFromArgs = {
    effectiveFrom?: InputMaybe<ModelStringKeyConditionInput>
    employeeID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListBookingRequestsByStatusAndEffectiveFromArgs = {
    effectiveFrom?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelBookingRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    status: BookingRequestStatus
}

export type QueryListBookingReservablesArgs = {
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListBookingReservablesByBookingArgs = {
    bookingID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListBookingReservablesByReservableArgs = {
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListBookingReservablesByReservableAndDateAndDayOfWeekArgs = {
    dateDayOfWeek?: InputMaybe<ModelBookingReservableByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListBookingReservablesByReservableAndStartTimeArgs = {
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListBookingReservablesByReservableAndStartTimeAndEndTimeArgs = {
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    startTimeEndTime?: InputMaybe<ModelBookingReservableByReservableAndStartTimeAndEndTimeCompositeKeyConditionInput>
}

export type QueryListBookingsArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListBookingsByBookingRequestArgs = {
    bookingRequestID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListBookingsByCompanyAndStartTimeArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListBookingsByDateArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
    year: Scalars['Int']['input']
}

export type QueryListBookingsByEmployeeAndStartTimeArgs = {
    employeeID: Scalars['ID']['input']
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListBookingsByReservableAndDateAndDayOfWeekArgs = {
    dateDayOfWeek?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListBookingsByReservableAndStartTimeArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListBookingsByTeamEventRequestArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
    teamEventRequestID: Scalars['ID']['input']
}

export type QueryListCalendarEventEmployeesArgs = {
    filter?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListCalendarEventEmployeesByCalendarEventArgs = {
    calendarEventID: Scalars['ID']['input']
    employeeFullName?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListCalendarEventEmployeesByEmployeeAndDateArgs = {
    date?: InputMaybe<ModelStringKeyConditionInput>
    employeeID: Scalars['ID']['input']
    filter?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListCalendarEventEmployeesByEmployeeAndWeekAndYearArgs = {
    employeeID: Scalars['ID']['input']
    filter?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearIsoWeekNumber?: InputMaybe<ModelCalendarEventEmployeeByEmployeeAndYearAndIsoWeekNumberCompositeKeyConditionInput>
}

export type QueryListCalendarEventRequestsArgs = {
    filter?: InputMaybe<ModelCalendarEventRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListCalendarEventRequestsByEmployeeAndEffectiveFromArgs = {
    effectiveFrom?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelCalendarEventRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    organiserEmployeeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListCalendarEventRequestsByReservableArgs = {
    filter?: InputMaybe<ModelCalendarEventRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListCalendarEventRequestsByReservableAndStartTimeArgs = {
    filter?: InputMaybe<ModelCalendarEventRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListCalendarEventsArgs = {
    filter?: InputMaybe<ModelCalendarEventsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListCalendarEventsByCalendarEventRequestArgs = {
    calendarEventRequestID: Scalars['ID']['input']
    filter?: InputMaybe<ModelCalendarEventsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListCalendarEventsByOfficeAndStartTimeArgs = {
    filter?: InputMaybe<ModelCalendarEventsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListCalendarEventsByReservableArgs = {
    filter?: InputMaybe<ModelCalendarEventsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListCalendarEventsByReservableAndStartTimeArgs = {
    filter?: InputMaybe<ModelCalendarEventsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListCateringsArgs = {
    filter?: InputMaybe<ModelCateringFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListCompaniesArgs = {
    filter?: InputMaybe<ModelCompanyFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListCompanyDayAggregationByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelCompanyDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberDate?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type QueryListCompanyDayAggregationByCompanyAndMomentArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelCompanyDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelCompanyDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListCompanyDayAggregationsArgs = {
    filter?: InputMaybe<ModelCompanyDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListCompanyWeekAggregationByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelCompanyWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumber?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type QueryListCompanyWeekAggregationByCompanyAndMomentArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelCompanyWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberString?: InputMaybe<ModelCompanyWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListCompanyWeekAggregationsArgs = {
    filter?: InputMaybe<ModelCompanyWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListCustomRolesArgs = {
    filter?: InputMaybe<ModelCustomRoleFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDashboardDayAggregationBySpaceAndYearAndWeekNumberAndDateArgs = {
    filter?: InputMaybe<ModelSpaceDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    yearWeekNumberDate?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type QueryListDashboardWeekAggregationBySpaceAndWeekNumberAndDateArgs = {
    filter?: InputMaybe<ModelSpaceDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    weekNumberDate?: InputMaybe<ModelSpaceDayAggregationBySpaceAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type QueryListDemoAccountsArgs = {
    filter?: InputMaybe<ModelDemoAccountFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDepartmentDayAggregationByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberDate?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type QueryListDepartmentDayAggregationByCompanyAndMomentArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListDepartmentDayAggregationByDepartmentArgs = {
    departmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberDate?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type QueryListDepartmentDayAggregationByDepartmentAndMomentArgs = {
    departmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelDepartmentDayAggregationByDepartmentAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListDepartmentDayAggregationEmployeesArgs = {
    filter?: InputMaybe<ModelDepartmentDayAggregationEmployeesFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDepartmentDayAggregationsArgs = {
    filter?: InputMaybe<ModelDepartmentDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDepartmentLastMonthAttendancesArgs = {
    filter?: InputMaybe<ModelDepartmentLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDepartmentLastMonthAttendancesByLastDateTogetherArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentLastMonthAttendanceFilterInput>
    lastDateTogetherForSorting?: InputMaybe<ModelStringKeyConditionInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListDepartmentLastMonthAttendancesByNameArgs = {
    companyID: Scalars['ID']['input']
    departmentNameLowerCase?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelDepartmentLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListDepartmentLastMonthAttendancesByWeeklyAttendanceArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    weeklyAttendance?: InputMaybe<ModelFloatKeyConditionInput>
}

export type QueryListDepartmentRulesArgs = {
    filter?: InputMaybe<ModelDepartmentRulesFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDepartmentWeekAggregationByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumber?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type QueryListDepartmentWeekAggregationByCompanyAndMomentArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberString?: InputMaybe<ModelDepartmentWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListDepartmentWeekAggregationByDepartmentAndMomentArgs = {
    departmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberString?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListDepartmentWeekAggregationByDepartmentAndWeekNumberArgs = {
    departmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumber?: InputMaybe<ModelDepartmentWeekAggregationByDepartmentAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type QueryListDepartmentWeekAggregationsArgs = {
    filter?: InputMaybe<ModelDepartmentWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDepartmentsArgs = {
    filter?: InputMaybe<ModelDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListDepartmentsByCompanyAndNameArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<ModelStringKeyConditionInput>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeeDayAggregationByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberDate?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type QueryListEmployeeDayAggregationByCompanyAndMomentArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelEmployeeDayAggregationByCompanyAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListEmployeeDayAggregationsArgs = {
    filter?: InputMaybe<ModelEmployeeDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListEmployeeLastMonthAttendancesArgs = {
    filter?: InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListEmployeeLastMonthAttendancesByDepartmentArgs = {
    departmentID: Scalars['ID']['input']
    employeeNameLowerCase?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeeLastMonthAttendancesByLastDateInOfficeArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>
    lastDateInOfficeForSorting?: InputMaybe<ModelStringKeyConditionInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeeLastMonthAttendancesByNameArgs = {
    companyID: Scalars['ID']['input']
    employeeNameLowerCase?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeeLastMonthAttendancesByWeeklyAttendanceArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeLastMonthAttendanceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    weeklyAttendance?: InputMaybe<ModelFloatKeyConditionInput>
}

export type QueryListEmployeeToDepartmentByDepartmentArgs = {
    departmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeeToDepartmentByEmployeeArgs = {
    employeeID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeeToDepartmentsArgs = {
    filter?: InputMaybe<ModelEmployeeToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListEmployeeWeekAggregationByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumber?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type QueryListEmployeeWeekAggregationByCompanyAndMomentArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberString?: InputMaybe<ModelEmployeeWeekAggregationByCompanyAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListEmployeeWeekAggregationsArgs = {
    filter?: InputMaybe<ModelEmployeeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListEmployeesArgs = {
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListEmployeesByCognitoUserIdArgs = {
    cognitoUserId: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeesByCompanyAndEmailArgs = {
    companyID: Scalars['ID']['input']
    email?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeesByCompanyAndFirstnameArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeFilterInput>
    firstname?: InputMaybe<ModelStringKeyConditionInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeesByCompanyAndRoleArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<ModelStringKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeesByCompanyAndStatusAndFirstnameArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    statusFirstname?: InputMaybe<ModelEmployeeByCompanyAndStatusAndFirstnameCompositeKeyConditionInput>
}

export type QueryListEmployeesByDepartmentAndFirstnameArgs = {
    departmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeFilterInput>
    firstname?: InputMaybe<ModelStringKeyConditionInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEmployeesByDepartmentAndStatusAndFirstnameArgs = {
    departmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    statusFirstname?: InputMaybe<ModelEmployeeByDepartmentAndStatusAndFirstnameCompositeKeyConditionInput>
}

export type QueryListEmployeesByOfficeArgs = {
    filter?: InputMaybe<ModelEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEquipmentArgs = {
    filter?: InputMaybe<ModelEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListEquipmentCategoriesArgs = {
    filter?: InputMaybe<ModelEquipmentCategoryFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListEquipmentCategoriesByCompanyAndNameArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEquipmentCategoryFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<ModelStringKeyConditionInput>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEquipmentImagesArgs = {
    filter?: InputMaybe<ModelEquipmentImageFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListEquipmentsByCategoryAndNameArgs = {
    equipmentCategoryID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<ModelStringKeyConditionInput>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEquipmentsByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEquipmentCategoryFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListEquipmentsByOfficeArgs = {
    filter?: InputMaybe<ModelEquipmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListGenericIntegrationsArgs = {
    type?: InputMaybe<Scalars['String']['input']>
}

export type QueryListGoogleImportedBookingsArgs = {
    filter?: InputMaybe<ModelGoogleImportedBookingsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListGoogleImportedBookingsByCalendarEventIdArgs = {
    calendarEventID: Scalars['ID']['input']
    filter?: InputMaybe<ModelGoogleImportedBookingsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListGoogleImportedBookingsByGoogleIdArgs = {
    filter?: InputMaybe<ModelGoogleImportedBookingsFilterInput>
    googleID: Scalars['String']['input']
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListGoogleImportedWorkingLocationsArgs = {
    filter?: InputMaybe<ModelGoogleImportedWorkingLocationsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListGoogleImportedWorkingLocationsByBookingIdArgs = {
    bookingID: Scalars['ID']['input']
    filter?: InputMaybe<ModelGoogleImportedWorkingLocationsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListGoogleImportedWorkingLocationsByGoogleIdArgs = {
    filter?: InputMaybe<ModelGoogleImportedWorkingLocationsFilterInput>
    googleID: Scalars['String']['input']
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListIntegrationsArgs = {
    filter?: InputMaybe<ModelIntegrationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListIntegrationsByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelIntegrationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<ModelIntKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListMenuItemsArgs = {
    filter?: InputMaybe<ModelMenuItemFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListMenuItemsByCateringArgs = {
    cateringID: Scalars['ID']['input']
    filter?: InputMaybe<ModelMenuItemFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListMicrosoftSubscriptionsArgs = {
    filter?: InputMaybe<ModelMicrosoftSubscriptionFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListNotificationsArgs = {
    filter?: InputMaybe<ModelNotificationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListOfficeBuilderRequestsArgs = {
    filter?: InputMaybe<ModelOfficeBuilderRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListOfficeBuilderRequestsByCompanyArgs = {
    companyID: Scalars['ID']['input']
    createdAt?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelOfficeBuilderRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListOfficeDayAggregationByCompanyAndDateArgs = {
    companyID: Scalars['ID']['input']
    date?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelOfficeDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListOfficeDayAggregationByOfficeAndMomentArgs = {
    filter?: InputMaybe<ModelOfficeDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListOfficeDayAggregationsArgs = {
    filter?: InputMaybe<ModelOfficeDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListOfficeImagesArgs = {
    filter?: InputMaybe<ModelOfficeImageFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListOfficeWeekAggregationByCompanyAndWeekNumberArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelOfficeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    weekNumber?: InputMaybe<ModelIntKeyConditionInput>
}

export type QueryListOfficeWeekAggregationByOfficeAndMomentArgs = {
    filter?: InputMaybe<ModelOfficeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberString?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListOfficeWeekAggregationByOfficeAndWeekNumberArgs = {
    filter?: InputMaybe<ModelOfficeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    weekNumber?: InputMaybe<ModelIntKeyConditionInput>
}

export type QueryListOfficeWeekAggregationByOfficeAndYearAndWeekNumberArgs = {
    filter?: InputMaybe<ModelOfficeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumber?: InputMaybe<ModelOfficeWeekAggregationByOfficeAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type QueryListOfficeWeekAggregationsArgs = {
    filter?: InputMaybe<ModelOfficeWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListOfficesArgs = {
    filter?: InputMaybe<ModelOfficeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListOfficesByCompanyAndNameArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelOfficeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<ModelStringKeyConditionInput>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListOfficesByCompanyAndOrderArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelOfficeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<ModelIntKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListPaymentsArgs = {
    subscriptionId: Scalars['String']['input']
}

export type QueryListPermissionsArgs = {
    filter?: InputMaybe<ModelPermissionFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListPersonioImportedAbsencesArgs = {
    filter?: InputMaybe<ModelPersonioImportedAbsencesFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromArgs = {
    companyID: Scalars['ID']['input']
    employeeEmailEffectiveFrom?: InputMaybe<ModelPersonioImportedAbsencesByCompanyAndEmployeeEmailAndEffectiveFromCompositeKeyConditionInput>
    filter?: InputMaybe<ModelPersonioImportedAbsencesFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListPersonioImportedAbsencesByCompanyIdAndEffectiveFromArgs = {
    companyID: Scalars['ID']['input']
    effectiveFrom?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelPersonioImportedAbsencesFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListPersonioImportedBookingsArgs = {
    filter?: InputMaybe<ModelPersonioImportedBookingsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListPersonioImportedBookingsByPersonioIdArgs = {
    filter?: InputMaybe<ModelPersonioImportedBookingsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    personioID: Scalars['String']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListPositionsArgs = {
    filter?: InputMaybe<ModelPositionFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListPositionsByCompanyAndNameArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelPositionFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<ModelStringKeyConditionInput>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListReservableImagesArgs = {
    filter?: InputMaybe<ModelReservableImageFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListReservableTagsArgs = {
    filter?: InputMaybe<ModelReservableTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListReservableTagsByOfficeAndTypeArgs = {
    filter?: InputMaybe<ModelReservableTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    reservableType?: InputMaybe<ModelStringKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListReservableTagsByReservableArgs = {
    filter?: InputMaybe<ModelReservableTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    tagID?: InputMaybe<ModelIdKeyConditionInput>
}

export type QueryListReservableTagsBySpaceArgs = {
    filter?: InputMaybe<ModelReservableTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
}

export type QueryListReservableTagsByTagArgs = {
    filter?: InputMaybe<ModelReservableTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID?: InputMaybe<ModelIdKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
    tagID: Scalars['ID']['input']
}

export type QueryListReservableToDepartmentsArgs = {
    filter?: InputMaybe<ModelReservableToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListReservableToDepartmentsByDepartmentArgs = {
    departmentID: Scalars['ID']['input']
    filter?: InputMaybe<ModelReservableToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListReservableToDepartmentsByReservableArgs = {
    filter?: InputMaybe<ModelReservableToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListReservableToEmployeesArgs = {
    filter?: InputMaybe<ModelReservableToEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListReservablesArgs = {
    filter?: InputMaybe<ModelReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListReservablesByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListReservablesByCompanyAndIsDefaultArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelReservableFilterInput>
    isDefault?: InputMaybe<ModelStringKeyConditionInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListReservablesByCompanyAndTypeArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    type?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListReservablesBySpaceAndTypeArgs = {
    filter?: InputMaybe<ModelReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    type?: InputMaybe<ModelStringKeyConditionInput>
}

export type QueryListRoomDayAggregationByCompanyAndDateArgs = {
    companyID: Scalars['ID']['input']
    date?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelRoomDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListRoomDayAggregationByOfficeAndMomentArgs = {
    filter?: InputMaybe<ModelRoomDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelRoomDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListRoomDayAggregationByReservableAndMomentArgs = {
    filter?: InputMaybe<ModelRoomDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelRoomDayAggregationByReservableAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListRoomDayAggregationBySpaceAndMomentArgs = {
    filter?: InputMaybe<ModelRoomDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    yearWeekNumberStringDate?: InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListRoomDayAggregationsArgs = {
    filter?: InputMaybe<ModelRoomDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListRoomWeekAggregationByCompanyAndYearAndWeekNumberArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelRoomWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumber?: InputMaybe<ModelRoomWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type QueryListRoomWeekAggregationByOfficeAndMomentArgs = {
    filter?: InputMaybe<ModelRoomWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberString?: InputMaybe<ModelRoomWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListRoomWeekAggregationByReservableAndMomentArgs = {
    filter?: InputMaybe<ModelRoomWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberString?: InputMaybe<ModelRoomWeekAggregationByReservableAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListRoomWeekAggregationBySpaceAndMomentArgs = {
    filter?: InputMaybe<ModelRoomWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    yearWeekNumberString?: InputMaybe<ModelRoomWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListRoomWeekAggregationsArgs = {
    filter?: InputMaybe<ModelRoomWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListRulesArgs = {
    filter?: InputMaybe<ModelRulesFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSlackOAuthStatesArgs = {
    filter?: InputMaybe<ModelSlackOAuthStateFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSlackOAuthStatesByCompanyArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelSlackOAuthStateFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListSlackOnboradingMessagesArgs = {
    filter?: InputMaybe<ModelSlackOnboradingMessageFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateArgs = {
    employeeIDDate?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateCompositeKeyConditionInput>
    filter?: InputMaybe<ModelSpaceCapacityDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
}

export type QueryListSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtArgs = {
    employeeIDDateCreatedAt?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyConditionInput>
    filter?: InputMaybe<ModelSpaceCapacityDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
}

export type QueryListSpaceCapacityDayAggregationsArgs = {
    filter?: InputMaybe<ModelSpaceCapacityDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSpaceDayAggregationByCompanyAndDateArgs = {
    companyID: Scalars['ID']['input']
    date?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelSpaceDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListSpaceDayAggregationBySpaceAndMomentArgs = {
    filter?: InputMaybe<ModelSpaceDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    yearWeekNumberStringDate?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type QueryListSpaceDayAggregationsArgs = {
    filter?: InputMaybe<ModelSpaceDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSpaceRowsArgs = {
    filter?: InputMaybe<ModelSpaceRowFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSpaceRowsByOfficeAndOrderArgs = {
    filter?: InputMaybe<ModelSpaceRowFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    order?: InputMaybe<ModelIntKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListSpaceWeekAggregationByCompanyAndWeekNumberArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelSpaceWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    weekNumber?: InputMaybe<ModelIntKeyConditionInput>
}

export type QueryListSpaceWeekAggregationByCompanyAndYearAndWeekNumberArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelSpaceWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumber?: InputMaybe<ModelSpaceWeekAggregationByCompanyAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type QueryListSpaceWeekAggregationByOfficeAndMomentArgs = {
    filter?: InputMaybe<ModelSpaceWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    officeID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberString?: InputMaybe<ModelSpaceWeekAggregationByOfficeAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListSpaceWeekAggregationBySpaceAndMomentArgs = {
    filter?: InputMaybe<ModelSpaceWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    yearWeekNumberString?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberStringCompositeKeyConditionInput>
}

export type QueryListSpaceWeekAggregationBySpaceAndWeekNumberArgs = {
    filter?: InputMaybe<ModelSpaceWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    weekNumber?: InputMaybe<ModelIntKeyConditionInput>
}

export type QueryListSpaceWeekAggregationBySpaceAndYearAndWeekNumberArgs = {
    filter?: InputMaybe<ModelSpaceWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
    yearWeekNumber?: InputMaybe<ModelSpaceWeekAggregationBySpaceAndYearAndWeekNumberCompositeKeyConditionInput>
}

export type QueryListSpaceWeekAggregationsArgs = {
    filter?: InputMaybe<ModelSpaceWeekAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSpacesArgs = {
    filter?: InputMaybe<ModelSpaceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSpacesByCompanyAndNameArgs = {
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelSpaceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<ModelStringKeyConditionInput>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListSuggestedSeatsArgs = {
    filter?: InputMaybe<ModelSuggestedSeatFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListSuggestedSeatsByEmployeeAndDateArgs = {
    date?: InputMaybe<ModelStringKeyConditionInput>
    employeeID: Scalars['ID']['input']
    filter?: InputMaybe<ModelSuggestedSeatFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListSuggestedSeatsByReservableAndDateArgs = {
    date?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelSuggestedSeatFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID: Scalars['ID']['input']
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type QueryListSuggestedSeatsBySpaceAndDateArgs = {
    date?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelSuggestedSeatFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
}

export type QueryListSuggestedSeatsBySpaceAndEmployeeAndDateArgs = {
    employeeIDDate?: InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyConditionInput>
    filter?: InputMaybe<ModelSuggestedSeatFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    spaceID: Scalars['ID']['input']
}

export type QueryListSyncUserEventsArgs = {
    filter?: InputMaybe<ModelSyncUserEventFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListTagsArgs = {
    filter?: InputMaybe<ModelTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryListTeamEventRequestsArgs = {
    filter?: InputMaybe<ModelTeamEventRequestFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}

export type QueryResolveEmailArgs = {
    email: Scalars['String']['input']
}

export type QuerySearchDepartmentLastMonthAttendancesArgs = {
    aggregates?: InputMaybe<Array<InputMaybe<SearchableDepartmentLastMonthAttendanceAggregationInput>>>
    filter?: InputMaybe<SearchableDepartmentLastMonthAttendanceFilterInput>
    from?: InputMaybe<Scalars['Int']['input']>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sort?: InputMaybe<Array<InputMaybe<SearchableDepartmentLastMonthAttendanceSortInput>>>
}

export type QuerySearchEmployeeLastMonthAttendancesArgs = {
    aggregates?: InputMaybe<Array<InputMaybe<SearchableEmployeeLastMonthAttendanceAggregationInput>>>
    filter?: InputMaybe<SearchableEmployeeLastMonthAttendanceFilterInput>
    from?: InputMaybe<Scalars['Int']['input']>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sort?: InputMaybe<Array<InputMaybe<SearchableEmployeeLastMonthAttendanceSortInput>>>
}

export type QuerySearchEmployeesArgs = {
    aggregates?: InputMaybe<Array<InputMaybe<SearchableEmployeeAggregationInput>>>
    filter?: InputMaybe<SearchableEmployeeFilterInput>
    from?: InputMaybe<Scalars['Int']['input']>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sort?: InputMaybe<Array<InputMaybe<SearchableEmployeeSortInput>>>
}

export type QuerySearchEquipmentArgs = {
    aggregates?: InputMaybe<Array<InputMaybe<SearchableEquipmentAggregationInput>>>
    filter?: InputMaybe<SearchableEquipmentFilterInput>
    from?: InputMaybe<Scalars['Int']['input']>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sort?: InputMaybe<Array<InputMaybe<SearchableEquipmentSortInput>>>
}

export type QuerySearchReservablesArgs = {
    aggregates?: InputMaybe<Array<InputMaybe<SearchableReservableAggregationInput>>>
    filter?: InputMaybe<SearchableReservableFilterInput>
    from?: InputMaybe<Scalars['Int']['input']>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sort?: InputMaybe<Array<InputMaybe<SearchableReservableSortInput>>>
}

export type QuerySearchRoomWeekAggregationsArgs = {
    aggregates?: InputMaybe<Array<InputMaybe<SearchableRoomWeekAggregationAggregationInput>>>
    filter?: InputMaybe<SearchableRoomWeekAggregationFilterInput>
    from?: InputMaybe<Scalars['Int']['input']>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sort?: InputMaybe<Array<InputMaybe<SearchableRoomWeekAggregationSortInput>>>
}

export enum RecurrencePatternType {
    ABSOLUTE_MONTHLY = 'ABSOLUTE_MONTHLY',
    ABSOLUTE_YEARLY = 'ABSOLUTE_YEARLY',
    DAILY = 'DAILY',
    RELATIVE_MONTHLY = 'RELATIVE_MONTHLY',
    RELATIVE_YEARLY = 'RELATIVE_YEARLY',
    WEEKLY = 'WEEKLY',
}

export enum RecurrenceRangeType {
    END_DATE = 'END_DATE',
    NO_END_DATE = 'NO_END_DATE',
    NUMBERED = 'NUMBERED',
}

export enum RepeatType {
    CUSTOM = 'CUSTOM',
    NEVER = 'NEVER',
    WEEKLY = 'WEEKLY',
}

export type Reservable = {
    __typename?: 'Reservable'
    BookingReservablesByCreatedAt?: Maybe<ModelBookingReservableConnection>
    Bookings?: Maybe<ModelBookingConnection>
    BookingsByCreatedAt?: Maybe<ModelBookingConnection>
    BookingsByDateAndDayOfWeek?: Maybe<ModelBookingConnection>
    CalendarEvents?: Maybe<ModelCalendarEventsConnection>
    Company?: Maybe<Company>
    Department?: Maybe<Department>
    Employee?: Maybe<Employee>
    RelatedReservable?: Maybe<Reservable>
    ReservableImages?: Maybe<ModelReservableImageConnection>
    ReservableToDepartments?: Maybe<ModelReservableToDepartmentConnection>
    ReservableToEmployees?: Maybe<ModelReservableToEmployeeConnection>
    Space?: Maybe<Space>
    Tags?: Maybe<ModelReservableTagConnection>
    admin?: Maybe<Employee>
    adminID?: Maybe<Scalars['ID']['output']>
    availabilityByDatesAndTime?: Maybe<Scalars['Boolean']['output']>
    availabilityPreviewByDates?: Maybe<Array<AvailabilityItemPreviewElement>>
    availabilityType: AvailabilityType
    bookingReservables?: Maybe<ModelBookingReservableConnection>
    color?: Maybe<Scalars['String']['output']>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    departmentID?: Maybe<Scalars['ID']['output']>
    employeeID?: Maybe<Scalars['ID']['output']>
    externalRoomID?: Maybe<Scalars['String']['output']>
    height?: Maybe<Scalars['Float']['output']>
    id: Scalars['ID']['output']
    isDefault?: Maybe<Scalars['String']['output']>
    maxAmountOfPeople?: Maybe<Scalars['Int']['output']>
    name?: Maybe<Scalars['String']['output']>
    relatedReservableID?: Maybe<Scalars['ID']['output']>
    reservableCompanyId?: Maybe<Scalars['ID']['output']>
    reservableDepartmentId?: Maybe<Scalars['ID']['output']>
    reservableEmployeeId?: Maybe<Scalars['ID']['output']>
    reservableSpaceId?: Maybe<Scalars['ID']['output']>
    spaceID?: Maybe<Scalars['ID']['output']>
    type: ReservableType
    updatedAt: Scalars['AWSDateTime']['output']
    width?: Maybe<Scalars['Float']['output']>
    x?: Maybe<Scalars['Float']['output']>
    y?: Maybe<Scalars['Float']['output']>
    zonePoints?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
}

export type ReservableBookingReservablesByCreatedAtArgs = {
    createdAt?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type ReservableBookingsArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type ReservableBookingsByCreatedAtArgs = {
    createdAt?: InputMaybe<ModelStringKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type ReservableBookingsByDateAndDayOfWeekArgs = {
    dateDayOfWeek?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type ReservableCalendarEventsArgs = {
    filter?: InputMaybe<ModelCalendarEventsFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type ReservableReservableImagesArgs = {
    filter?: InputMaybe<ModelReservableImageFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type ReservableReservableToDepartmentsArgs = {
    departmentID?: InputMaybe<ModelIdKeyConditionInput>
    filter?: InputMaybe<ModelReservableToDepartmentFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type ReservableReservableToEmployeesArgs = {
    filter?: InputMaybe<ModelReservableToEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type ReservableTagsArgs = {
    filter?: InputMaybe<ModelReservableTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    tagID?: InputMaybe<ModelIdKeyConditionInput>
}

export type ReservableAvailabilityByDatesAndTimeArgs = {
    bookingRepeatType?: InputMaybe<RepeatType>
    dates?: InputMaybe<Array<InputMaybe<Scalars['AWSDate']['input']>>>
    daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    employees?: InputMaybe<Array<InputMaybe<EmployeesWithDepartments>>>
    endTime?: InputMaybe<Scalars['String']['input']>
    startTime?: InputMaybe<Scalars['String']['input']>
}

export type ReservableAvailabilityPreviewByDatesArgs = {
    dates?: InputMaybe<Array<InputMaybe<Scalars['AWSDate']['input']>>>
}

export type ReservableBookingReservablesArgs = {
    filter?: InputMaybe<ModelBookingReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export type ReservableImage = {
    __typename?: 'ReservableImage'
    Company?: Maybe<Company>
    Reservable?: Maybe<Reservable>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    isMain?: Maybe<Scalars['Boolean']['output']>
    location?: Maybe<Scalars['String']['output']>
    name?: Maybe<Scalars['String']['output']>
    reservableID: Scalars['ID']['output']
    reservableImageCompanyId?: Maybe<Scalars['ID']['output']>
    reservableImageReservableId?: Maybe<Scalars['ID']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type ReservableTag = {
    __typename?: 'ReservableTag'
    Company: Company
    Office?: Maybe<Office>
    Reservable?: Maybe<Reservable>
    Space?: Maybe<Space>
    Tag?: Maybe<Tag>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    officeID?: Maybe<Scalars['ID']['output']>
    reservableID: Scalars['ID']['output']
    reservableTagCompanyId: Scalars['ID']['output']
    reservableTagOfficeId?: Maybe<Scalars['ID']['output']>
    reservableTagSpaceId?: Maybe<Scalars['ID']['output']>
    reservableType?: Maybe<ReservableType>
    spaceID?: Maybe<Scalars['ID']['output']>
    tagID: Scalars['ID']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export type ReservableToDepartment = {
    __typename?: 'ReservableToDepartment'
    Company: Company
    Department?: Maybe<Department>
    Reservable?: Maybe<Reservable>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    departmentID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    reservableID: Scalars['ID']['output']
    reservableToDepartmentCompanyId: Scalars['ID']['output']
    reservableToDepartmentDepartmentId?: Maybe<Scalars['ID']['output']>
    reservableToDepartmentReservableId?: Maybe<Scalars['ID']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type ReservableToEmployee = {
    __typename?: 'ReservableToEmployee'
    companyID?: Maybe<Scalars['String']['output']>
    createdAt: Scalars['AWSDateTime']['output']
    employee: Employee
    employeeID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    reservable: Reservable
    reservableID: Scalars['ID']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export enum ReservableType {
    AWAY = 'AWAY',
    BUSINESS_TRIP = 'BUSINESS_TRIP',
    HOME = 'HOME',
    PARKING = 'PARKING',
    ROOM = 'ROOM',
    SEAT = 'SEAT',
    SICK_LEAVE = 'SICK_LEAVE',
    VACATION = 'VACATION',
    ZONE = 'ZONE',
}

export enum ResizeFit {
    contain = 'contain',
    cover = 'cover',
    fill = 'fill',
    inside = 'inside',
    outside = 'outside',
}

export type ResolveEmailResult = {
    __typename?: 'ResolveEmailResult'
    loginUrl?: Maybe<Scalars['String']['output']>
}

export enum ResourceType {
    COMPANY = 'COMPANY',
    DASHBOARD = 'DASHBOARD',
    DEPARTMENT = 'DEPARTMENT',
    DEPARTMENT_RULES = 'DEPARTMENT_RULES',
    EMPLOYEE = 'EMPLOYEE',
    OFFICE = 'OFFICE',
    POSITION = 'POSITION',
}

export enum Role {
    EMPLOYEE = 'EMPLOYEE',
    MANAGER = 'MANAGER',
    OWNER = 'OWNER',
    TEAM_LEAD = 'TEAM_LEAD',
}

export type RoomDayAggregation = {
    __typename?: 'RoomDayAggregation'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    Reservable?: Maybe<Reservable>
    Space?: Maybe<Space>
    bookedHours?: Maybe<Scalars['Int']['output']>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dataByHour: Array<RoomDayAggregationBookingsByHoursDistribution>
    date: Scalars['AWSDate']['output']
    id: Scalars['ID']['output']
    officeID: Scalars['ID']['output']
    reservableID: Scalars['ID']['output']
    roomBookingsSum: Scalars['Int']['output']
    roomCapacityLast: Scalars['Int']['output']
    roomDayAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    roomNameLowerCase: Scalars['String']['output']
    spaceID: Scalars['ID']['output']
    updatedAt: Scalars['AWSDateTime']['output']
    visitors?: Maybe<Scalars['Int']['output']>
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type RoomDayAggregationBookingsByHoursDistribution = {
    __typename?: 'RoomDayAggregationBookingsByHoursDistribution'
    capacity: Scalars['Int']['output']
    hour: Scalars['Int']['output']
    members: Scalars['Int']['output']
}

export type RoomDayAggregationBookingsByHoursDistributionInput = {
    capacity: Scalars['Int']['input']
    hour: Scalars['Int']['input']
    members: Scalars['Int']['input']
}

export type RoomMappingResponse = {
    __typename?: 'RoomMappingResponse'
    reservableRoomId?: Maybe<Scalars['String']['output']>
    room?: Maybe<GoogleRoom>
}

export type RoomWeekAggregation = {
    __typename?: 'RoomWeekAggregation'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    Reservable?: Maybe<Reservable>
    Space?: Maybe<Space>
    bookedHours?: Maybe<Scalars['Int']['output']>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dayAggregations?: Maybe<ModelRoomDayAggregationConnection>
    id: Scalars['ID']['output']
    officeID?: Maybe<Scalars['ID']['output']>
    percentageOfUtilization?: Maybe<Scalars['Int']['output']>
    reservableID: Scalars['ID']['output']
    roomBookingsSum: Scalars['Int']['output']
    roomCapacityMax: Scalars['Int']['output']
    roomNameLowerCase: Scalars['String']['output']
    roomWeekAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    spaceID: Scalars['ID']['output']
    updatedAt: Scalars['AWSDateTime']['output']
    visitors?: Maybe<Scalars['Int']['output']>
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type RoomWeekAggregationDayAggregationsArgs = {
    filter?: InputMaybe<ModelRoomDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberStringDate?: InputMaybe<ModelRoomDayAggregationBySpaceAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput>
}

export type Rules = {
    __typename?: 'Rules'
    autoAssignWaitingList: Scalars['Boolean']['output']
    blockReservationOnNonWorkingDays?: Maybe<Scalars['Boolean']['output']>
    checkInCancellingTime?: Maybe<Scalars['Int']['output']>
    checkInWaitingTime?: Maybe<Scalars['Int']['output']>
    createdAt: Scalars['AWSDateTime']['output']
    dayOfAttendanceNotifications?: Maybe<Scalars['Int']['output']>
    defaultShareAttendance?: Maybe<ShareAttendance>
    deleteDataAfter?: Maybe<Scalars['Int']['output']>
    deletePersonalDataAfter?: Maybe<Scalars['Int']['output']>
    denyRecurringBookingOverThePlanningHorizon?: Maybe<Scalars['Boolean']['output']>
    disableOnboardingQuiz: Scalars['Boolean']['output']
    disableProfileEditing?: Maybe<Scalars['Boolean']['output']>
    emailForMonthlyReport?: Maybe<Scalars['String']['output']>
    enableMinDaysInOffice?: Maybe<Scalars['Boolean']['output']>
    homeIsDefault: Scalars['Boolean']['output']
    horizonOfPlanningInDays: Scalars['Int']['output']
    horizonOfPlanningTimeAmount?: Maybe<Scalars['Int']['output']>
    horizonOfPlanningTimeUnit?: Maybe<HorizonOfPlanningTimeUnit>
    hourOfAttendanceNotifications?: Maybe<Scalars['Int']['output']>
    id: Scalars['ID']['output']
    isPersonalSeatAvailableToday?: Maybe<Scalars['Boolean']['output']>
    isTeamSeatAvailableToday?: Maybe<Scalars['Boolean']['output']>
    maxDaysInOffice?: Maybe<Scalars['Int']['output']>
    minDaysInOffice?: Maybe<Scalars['Int']['output']>
    minDaysInOfficeTimeUnit?: Maybe<MinDaysInOfficeTimeUnit>
    startTimeOfNotifications?: Maybe<Scalars['Int']['output']>
    turnOffCheckInForPersonalSeats?: Maybe<Scalars['Boolean']['output']>
    turnOnAttendanceNotifications?: Maybe<Scalars['Boolean']['output']>
    turnOnCheckIn?: Maybe<Scalars['Boolean']['output']>
    turnOnDeletingData?: Maybe<Scalars['Boolean']['output']>
    turnOnExperimentalFeatures?: Maybe<Scalars['Boolean']['output']>
    turnOnMonthlyReport?: Maybe<Scalars['Boolean']['output']>
    updateEmployeeDataFromSource?: Maybe<Scalars['Boolean']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
    workingDays?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export enum S3OperationType {
    getObject = 'getObject',
    putObject = 'putObject',
}

export type SsoIntegration = {
    __typename?: 'SSOIntegration'
    companyId: Scalars['String']['output']
    source?: Maybe<SsoSource>
    type: SsoType
}

export type SsoIntegrationInput = {
    source?: InputMaybe<SsoSource>
    type: SsoType
}

export enum SsoSource {
    AD = 'AD',
    CUSTOM = 'CUSTOM',
    GOOGLE = 'GOOGLE',
    OKTA = 'OKTA',
    ONELOGIN = 'ONELOGIN',
}

export enum SsoType {
    OIDC = 'OIDC',
    SAML = 'SAML',
}

export type SamlIntegration = {
    __typename?: 'SamlIntegration'
    certificate: Scalars['String']['output']
    companyId: Scalars['String']['output']
    domains: Array<Scalars['String']['output']>
    id?: Maybe<Scalars['ID']['output']>
    source?: Maybe<SsoSource>
    ssoUrl: Scalars['String']['output']
}

export type SamlIntegrationInput = {
    certificate: Scalars['String']['input']
    domains: Array<Scalars['String']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    source?: InputMaybe<SsoSource>
    ssoUrl: Scalars['String']['input']
}

export type ScimIntegration = {
    __typename?: 'ScimIntegration'
    source?: Maybe<Source>
    token?: Maybe<Scalars['String']['output']>
}

export type ScimIntegrationInput = {
    source?: InputMaybe<Source>
}

export enum Scope {
    GLOBAL = 'GLOBAL',
    ORGANIZATION = 'ORGANIZATION',
    TEAM = 'TEAM',
}

export type SearchableAggregateBucketResult = {
    __typename?: 'SearchableAggregateBucketResult'
    buckets?: Maybe<Array<Maybe<SearchableAggregateBucketResultItem>>>
}

export type SearchableAggregateBucketResultItem = {
    __typename?: 'SearchableAggregateBucketResultItem'
    doc_count: Scalars['Int']['output']
    key: Scalars['String']['output']
}

export type SearchableAggregateGenericResult = SearchableAggregateBucketResult | SearchableAggregateScalarResult

export type SearchableAggregateResult = {
    __typename?: 'SearchableAggregateResult'
    name: Scalars['String']['output']
    result?: Maybe<SearchableAggregateGenericResult>
}

export type SearchableAggregateScalarResult = {
    __typename?: 'SearchableAggregateScalarResult'
    value: Scalars['Float']['output']
}

export enum SearchableAggregateType {
    avg = 'avg',
    cardinality = 'cardinality',
    max = 'max',
    min = 'min',
    sum = 'sum',
    terms = 'terms',
}

export type SearchableBooleanFilterInput = {
    eq?: InputMaybe<Scalars['Boolean']['input']>
    ne?: InputMaybe<Scalars['Boolean']['input']>
}

export enum SearchableDepartmentLastMonthAttendanceAggregateField {
    attendanceScore = 'attendanceScore',
    attendanceScoreString = 'attendanceScoreString',
    companyID = 'companyID',
    createdAt = 'createdAt',
    departmentID = 'departmentID',
    departmentLastMonthAttendanceCompanyId = 'departmentLastMonthAttendanceCompanyId',
    departmentLastMonthAttendanceDepartmentId = 'departmentLastMonthAttendanceDepartmentId',
    departmentNameLowerCase = 'departmentNameLowerCase',
    employeesLast = 'employeesLast',
    id = 'id',
    lastDateTogether = 'lastDateTogether',
    lastDateTogetherForSorting = 'lastDateTogetherForSorting',
    monthlyAttendance = 'monthlyAttendance',
    monthlyAttendanceString = 'monthlyAttendanceString',
    officeDaysScore = 'officeDaysScore',
    officeDaysScoreString = 'officeDaysScoreString',
    updatedAt = 'updatedAt',
    weeklyAttendance = 'weeklyAttendance',
    weeklyAttendanceString = 'weeklyAttendanceString',
}

export type SearchableDepartmentLastMonthAttendanceAggregationInput = {
    field: SearchableDepartmentLastMonthAttendanceAggregateField
    name: Scalars['String']['input']
    type: SearchableAggregateType
}

export type SearchableDepartmentLastMonthAttendanceConnection = {
    __typename?: 'SearchableDepartmentLastMonthAttendanceConnection'
    aggregateItems: Array<Maybe<SearchableAggregateResult>>
    items: Array<Maybe<DepartmentLastMonthAttendance>>
    nextToken?: Maybe<Scalars['String']['output']>
    total?: Maybe<Scalars['Int']['output']>
}

export type SearchableDepartmentLastMonthAttendanceFilterInput = {
    and?: InputMaybe<Array<InputMaybe<SearchableDepartmentLastMonthAttendanceFilterInput>>>
    attendanceScore?: InputMaybe<SearchableIntFilterInput>
    attendanceScoreString?: InputMaybe<SearchableStringFilterInput>
    companyID?: InputMaybe<SearchableIdFilterInput>
    createdAt?: InputMaybe<SearchableStringFilterInput>
    departmentID?: InputMaybe<SearchableIdFilterInput>
    departmentLastMonthAttendanceCompanyId?: InputMaybe<SearchableIdFilterInput>
    departmentLastMonthAttendanceDepartmentId?: InputMaybe<SearchableIdFilterInput>
    departmentNameLowerCase?: InputMaybe<SearchableStringFilterInput>
    employeesLast?: InputMaybe<SearchableIntFilterInput>
    id?: InputMaybe<SearchableIdFilterInput>
    lastDateTogether?: InputMaybe<SearchableStringFilterInput>
    lastDateTogetherForSorting?: InputMaybe<SearchableStringFilterInput>
    monthlyAttendance?: InputMaybe<SearchableFloatFilterInput>
    monthlyAttendanceString?: InputMaybe<SearchableStringFilterInput>
    not?: InputMaybe<SearchableDepartmentLastMonthAttendanceFilterInput>
    officeDaysScore?: InputMaybe<SearchableIntFilterInput>
    officeDaysScoreString?: InputMaybe<SearchableStringFilterInput>
    or?: InputMaybe<Array<InputMaybe<SearchableDepartmentLastMonthAttendanceFilterInput>>>
    updatedAt?: InputMaybe<SearchableStringFilterInput>
    weeklyAttendance?: InputMaybe<SearchableFloatFilterInput>
    weeklyAttendanceString?: InputMaybe<SearchableStringFilterInput>
}

export type SearchableDepartmentLastMonthAttendanceSortInput = {
    direction?: InputMaybe<SearchableSortDirection>
    field?: InputMaybe<SearchableDepartmentLastMonthAttendanceSortableFields>
}

export enum SearchableDepartmentLastMonthAttendanceSortableFields {
    attendanceScore = 'attendanceScore',
    attendanceScoreString = 'attendanceScoreString',
    companyID = 'companyID',
    createdAt = 'createdAt',
    departmentID = 'departmentID',
    departmentLastMonthAttendanceCompanyId = 'departmentLastMonthAttendanceCompanyId',
    departmentLastMonthAttendanceDepartmentId = 'departmentLastMonthAttendanceDepartmentId',
    departmentNameLowerCase = 'departmentNameLowerCase',
    employeesLast = 'employeesLast',
    id = 'id',
    lastDateTogether = 'lastDateTogether',
    lastDateTogetherForSorting = 'lastDateTogetherForSorting',
    monthlyAttendance = 'monthlyAttendance',
    monthlyAttendanceString = 'monthlyAttendanceString',
    officeDaysScore = 'officeDaysScore',
    officeDaysScoreString = 'officeDaysScoreString',
    updatedAt = 'updatedAt',
    weeklyAttendance = 'weeklyAttendance',
    weeklyAttendanceString = 'weeklyAttendanceString',
}

export enum SearchableEmployeeAggregateField {
    active = 'active',
    birthday = 'birthday',
    buddyID = 'buddyID',
    cognitoUserId = 'cognitoUserId',
    companyID = 'companyID',
    createdAt = 'createdAt',
    defaultPlan = 'defaultPlan',
    demoAccountID = 'demoAccountID',
    departmentID = 'departmentID',
    departmentIDs = 'departmentIDs',
    departmentIDsString = 'departmentIDsString',
    departmentsString = 'departmentsString',
    email = 'email',
    emailForSearch = 'emailForSearch',
    employeeCompanyId = 'employeeCompanyId',
    employeeDemoAccountId = 'employeeDemoAccountId',
    employeeFavouriteOfficeId = 'employeeFavouriteOfficeId',
    employeeOfficeId = 'employeeOfficeId',
    employeePositionId = 'employeePositionId',
    externalID = 'externalID',
    favouriteColleagueIDs = 'favouriteColleagueIDs',
    favouriteOfficeID = 'favouriteOfficeID',
    favouriteReservableID = 'favouriteReservableID',
    firstname = 'firstname',
    fullName = 'fullName',
    fullNameLowerCase = 'fullNameLowerCase',
    id = 'id',
    isSeatingInOffice = 'isSeatingInOffice',
    lastname = 'lastname',
    locale = 'locale',
    notificationChannels = 'notificationChannels',
    officeID = 'officeID',
    photo = 'photo',
    positionID = 'positionID',
    preferredScheduleID = 'preferredScheduleID',
    role = 'role',
    roleID = 'roleID',
    roleString = 'roleString',
    shareAttendance = 'shareAttendance',
    source = 'source',
    status = 'status',
    statusString = 'statusString',
    turnOnExperimentalFeatures = 'turnOnExperimentalFeatures',
    updatedAt = 'updatedAt',
}

export type SearchableEmployeeAggregationInput = {
    field: SearchableEmployeeAggregateField
    name: Scalars['String']['input']
    type: SearchableAggregateType
}

export type SearchableEmployeeConnection = {
    __typename?: 'SearchableEmployeeConnection'
    aggregateItems: Array<Maybe<SearchableAggregateResult>>
    items: Array<Maybe<Employee>>
    nextToken?: Maybe<Scalars['String']['output']>
    total?: Maybe<Scalars['Int']['output']>
}

export type SearchableEmployeeFilterInput = {
    active?: InputMaybe<SearchableBooleanFilterInput>
    and?: InputMaybe<Array<InputMaybe<SearchableEmployeeFilterInput>>>
    birthday?: InputMaybe<SearchableStringFilterInput>
    buddyID?: InputMaybe<SearchableIdFilterInput>
    cognitoUserId?: InputMaybe<SearchableIdFilterInput>
    companyID?: InputMaybe<SearchableIdFilterInput>
    createdAt?: InputMaybe<SearchableStringFilterInput>
    defaultPlan?: InputMaybe<SearchableStringFilterInput>
    demoAccountID?: InputMaybe<SearchableIdFilterInput>
    departmentID?: InputMaybe<SearchableIdFilterInput>
    departmentIDs?: InputMaybe<SearchableIdFilterInput>
    departmentIDsString?: InputMaybe<SearchableStringFilterInput>
    departmentsString?: InputMaybe<SearchableStringFilterInput>
    email?: InputMaybe<SearchableStringFilterInput>
    emailForSearch?: InputMaybe<SearchableStringFilterInput>
    employeeCompanyId?: InputMaybe<SearchableIdFilterInput>
    employeeDemoAccountId?: InputMaybe<SearchableIdFilterInput>
    employeeFavouriteOfficeId?: InputMaybe<SearchableIdFilterInput>
    employeeOfficeId?: InputMaybe<SearchableIdFilterInput>
    employeePositionId?: InputMaybe<SearchableIdFilterInput>
    externalID?: InputMaybe<SearchableStringFilterInput>
    favouriteColleagueIDs?: InputMaybe<SearchableIdFilterInput>
    favouriteOfficeID?: InputMaybe<SearchableIdFilterInput>
    favouriteReservableID?: InputMaybe<SearchableIdFilterInput>
    firstname?: InputMaybe<SearchableStringFilterInput>
    fullName?: InputMaybe<SearchableStringFilterInput>
    fullNameLowerCase?: InputMaybe<SearchableStringFilterInput>
    id?: InputMaybe<SearchableIdFilterInput>
    isSeatingInOffice?: InputMaybe<SearchableBooleanFilterInput>
    lastname?: InputMaybe<SearchableStringFilterInput>
    locale?: InputMaybe<SearchableStringFilterInput>
    not?: InputMaybe<SearchableEmployeeFilterInput>
    notificationChannels?: InputMaybe<SearchableStringFilterInput>
    officeID?: InputMaybe<SearchableIdFilterInput>
    or?: InputMaybe<Array<InputMaybe<SearchableEmployeeFilterInput>>>
    photo?: InputMaybe<SearchableStringFilterInput>
    positionID?: InputMaybe<SearchableIdFilterInput>
    preferredScheduleID?: InputMaybe<SearchableIdFilterInput>
    role?: InputMaybe<SearchableStringFilterInput>
    roleID?: InputMaybe<SearchableIdFilterInput>
    roleString?: InputMaybe<SearchableStringFilterInput>
    shareAttendance?: InputMaybe<SearchableStringFilterInput>
    source?: InputMaybe<SearchableStringFilterInput>
    status?: InputMaybe<SearchableStringFilterInput>
    statusString?: InputMaybe<SearchableStringFilterInput>
    turnOnExperimentalFeatures?: InputMaybe<SearchableBooleanFilterInput>
    updatedAt?: InputMaybe<SearchableStringFilterInput>
}

export enum SearchableEmployeeLastMonthAttendanceAggregateField {
    companyID = 'companyID',
    createdAt = 'createdAt',
    departmentID = 'departmentID',
    departmentIDs = 'departmentIDs',
    departmentIDsString = 'departmentIDsString',
    departmentNamesString = 'departmentNamesString',
    employeeID = 'employeeID',
    employeeLastMonthAttendanceCompanyId = 'employeeLastMonthAttendanceCompanyId',
    employeeLastMonthAttendanceDepartmentId = 'employeeLastMonthAttendanceDepartmentId',
    employeeLastMonthAttendanceEmployeeId = 'employeeLastMonthAttendanceEmployeeId',
    employeeNameLowerCase = 'employeeNameLowerCase',
    id = 'id',
    lastDateInOffice = 'lastDateInOffice',
    lastDateInOfficeForSorting = 'lastDateInOfficeForSorting',
    monthlyAttendance = 'monthlyAttendance',
    monthlyAttendanceString = 'monthlyAttendanceString',
    updatedAt = 'updatedAt',
    weeklyAttendance = 'weeklyAttendance',
    weeklyAttendanceString = 'weeklyAttendanceString',
}

export type SearchableEmployeeLastMonthAttendanceAggregationInput = {
    field: SearchableEmployeeLastMonthAttendanceAggregateField
    name: Scalars['String']['input']
    type: SearchableAggregateType
}

export type SearchableEmployeeLastMonthAttendanceConnection = {
    __typename?: 'SearchableEmployeeLastMonthAttendanceConnection'
    aggregateItems: Array<Maybe<SearchableAggregateResult>>
    items: Array<Maybe<EmployeeLastMonthAttendance>>
    nextToken?: Maybe<Scalars['String']['output']>
    total?: Maybe<Scalars['Int']['output']>
}

export type SearchableEmployeeLastMonthAttendanceFilterInput = {
    and?: InputMaybe<Array<InputMaybe<SearchableEmployeeLastMonthAttendanceFilterInput>>>
    companyID?: InputMaybe<SearchableIdFilterInput>
    createdAt?: InputMaybe<SearchableStringFilterInput>
    departmentID?: InputMaybe<SearchableIdFilterInput>
    departmentIDs?: InputMaybe<SearchableIdFilterInput>
    departmentIDsString?: InputMaybe<SearchableStringFilterInput>
    departmentNamesString?: InputMaybe<SearchableStringFilterInput>
    employeeID?: InputMaybe<SearchableIdFilterInput>
    employeeLastMonthAttendanceCompanyId?: InputMaybe<SearchableIdFilterInput>
    employeeLastMonthAttendanceDepartmentId?: InputMaybe<SearchableIdFilterInput>
    employeeLastMonthAttendanceEmployeeId?: InputMaybe<SearchableIdFilterInput>
    employeeNameLowerCase?: InputMaybe<SearchableStringFilterInput>
    id?: InputMaybe<SearchableIdFilterInput>
    lastDateInOffice?: InputMaybe<SearchableStringFilterInput>
    lastDateInOfficeForSorting?: InputMaybe<SearchableStringFilterInput>
    monthlyAttendance?: InputMaybe<SearchableFloatFilterInput>
    monthlyAttendanceString?: InputMaybe<SearchableStringFilterInput>
    not?: InputMaybe<SearchableEmployeeLastMonthAttendanceFilterInput>
    or?: InputMaybe<Array<InputMaybe<SearchableEmployeeLastMonthAttendanceFilterInput>>>
    updatedAt?: InputMaybe<SearchableStringFilterInput>
    weeklyAttendance?: InputMaybe<SearchableFloatFilterInput>
    weeklyAttendanceString?: InputMaybe<SearchableStringFilterInput>
}

export type SearchableEmployeeLastMonthAttendanceSortInput = {
    direction?: InputMaybe<SearchableSortDirection>
    field?: InputMaybe<SearchableEmployeeLastMonthAttendanceSortableFields>
}

export enum SearchableEmployeeLastMonthAttendanceSortableFields {
    companyID = 'companyID',
    createdAt = 'createdAt',
    departmentID = 'departmentID',
    departmentIDs = 'departmentIDs',
    departmentIDsString = 'departmentIDsString',
    departmentNamesString = 'departmentNamesString',
    employeeID = 'employeeID',
    employeeLastMonthAttendanceCompanyId = 'employeeLastMonthAttendanceCompanyId',
    employeeLastMonthAttendanceDepartmentId = 'employeeLastMonthAttendanceDepartmentId',
    employeeLastMonthAttendanceEmployeeId = 'employeeLastMonthAttendanceEmployeeId',
    employeeNameLowerCase = 'employeeNameLowerCase',
    id = 'id',
    lastDateInOffice = 'lastDateInOffice',
    lastDateInOfficeForSorting = 'lastDateInOfficeForSorting',
    monthlyAttendance = 'monthlyAttendance',
    monthlyAttendanceString = 'monthlyAttendanceString',
    updatedAt = 'updatedAt',
    weeklyAttendance = 'weeklyAttendance',
    weeklyAttendanceString = 'weeklyAttendanceString',
}

export type SearchableEmployeeSortInput = {
    direction?: InputMaybe<SearchableSortDirection>
    field?: InputMaybe<SearchableEmployeeSortableFields>
}

export enum SearchableEmployeeSortableFields {
    active = 'active',
    birthday = 'birthday',
    buddyID = 'buddyID',
    cognitoUserId = 'cognitoUserId',
    companyID = 'companyID',
    createdAt = 'createdAt',
    defaultPlan = 'defaultPlan',
    demoAccountID = 'demoAccountID',
    departmentID = 'departmentID',
    departmentIDs = 'departmentIDs',
    departmentIDsString = 'departmentIDsString',
    departmentsString = 'departmentsString',
    email = 'email',
    emailForSearch = 'emailForSearch',
    employeeCompanyId = 'employeeCompanyId',
    employeeDemoAccountId = 'employeeDemoAccountId',
    employeeFavouriteOfficeId = 'employeeFavouriteOfficeId',
    employeeOfficeId = 'employeeOfficeId',
    employeePositionId = 'employeePositionId',
    externalID = 'externalID',
    favouriteColleagueIDs = 'favouriteColleagueIDs',
    favouriteOfficeID = 'favouriteOfficeID',
    favouriteReservableID = 'favouriteReservableID',
    firstname = 'firstname',
    fullName = 'fullName',
    fullNameLowerCase = 'fullNameLowerCase',
    id = 'id',
    isSeatingInOffice = 'isSeatingInOffice',
    lastname = 'lastname',
    officeID = 'officeID',
    photo = 'photo',
    positionID = 'positionID',
    preferredScheduleID = 'preferredScheduleID',
    roleID = 'roleID',
    roleString = 'roleString',
    statusString = 'statusString',
    turnOnExperimentalFeatures = 'turnOnExperimentalFeatures',
    updatedAt = 'updatedAt',
}

export enum SearchableEquipmentAggregateField {
    canBookedSeparately = 'canBookedSeparately',
    companyID = 'companyID',
    createdAt = 'createdAt',
    description = 'description',
    email = 'email',
    enabled = 'enabled',
    equipmentCategoryID = 'equipmentCategoryID',
    equipmentCompanyId = 'equipmentCompanyId',
    equipmentEquipmentCategoryId = 'equipmentEquipmentCategoryId',
    id = 'id',
    name = 'name',
    officeID = 'officeID',
    quantity = 'quantity',
    updatedAt = 'updatedAt',
}

export type SearchableEquipmentAggregationInput = {
    field: SearchableEquipmentAggregateField
    name: Scalars['String']['input']
    type: SearchableAggregateType
}

export type SearchableEquipmentConnection = {
    __typename?: 'SearchableEquipmentConnection'
    aggregateItems: Array<Maybe<SearchableAggregateResult>>
    items: Array<Maybe<Equipment>>
    nextToken?: Maybe<Scalars['String']['output']>
    total?: Maybe<Scalars['Int']['output']>
}

export type SearchableEquipmentFilterInput = {
    and?: InputMaybe<Array<InputMaybe<SearchableEquipmentFilterInput>>>
    canBookedSeparately?: InputMaybe<SearchableBooleanFilterInput>
    companyID?: InputMaybe<SearchableIdFilterInput>
    createdAt?: InputMaybe<SearchableStringFilterInput>
    description?: InputMaybe<SearchableStringFilterInput>
    email?: InputMaybe<SearchableStringFilterInput>
    enabled?: InputMaybe<SearchableBooleanFilterInput>
    equipmentCategoryID?: InputMaybe<SearchableIdFilterInput>
    equipmentCompanyId?: InputMaybe<SearchableIdFilterInput>
    equipmentEquipmentCategoryId?: InputMaybe<SearchableIdFilterInput>
    id?: InputMaybe<SearchableIdFilterInput>
    name?: InputMaybe<SearchableStringFilterInput>
    not?: InputMaybe<SearchableEquipmentFilterInput>
    officeID?: InputMaybe<SearchableIdFilterInput>
    or?: InputMaybe<Array<InputMaybe<SearchableEquipmentFilterInput>>>
    quantity?: InputMaybe<SearchableIntFilterInput>
    updatedAt?: InputMaybe<SearchableStringFilterInput>
}

export type SearchableEquipmentSortInput = {
    direction?: InputMaybe<SearchableSortDirection>
    field?: InputMaybe<SearchableEquipmentSortableFields>
}

export enum SearchableEquipmentSortableFields {
    canBookedSeparately = 'canBookedSeparately',
    companyID = 'companyID',
    createdAt = 'createdAt',
    description = 'description',
    email = 'email',
    enabled = 'enabled',
    equipmentCategoryID = 'equipmentCategoryID',
    equipmentCompanyId = 'equipmentCompanyId',
    equipmentEquipmentCategoryId = 'equipmentEquipmentCategoryId',
    id = 'id',
    name = 'name',
    officeID = 'officeID',
    quantity = 'quantity',
    updatedAt = 'updatedAt',
}

export type SearchableFloatFilterInput = {
    eq?: InputMaybe<Scalars['Float']['input']>
    gt?: InputMaybe<Scalars['Float']['input']>
    gte?: InputMaybe<Scalars['Float']['input']>
    lt?: InputMaybe<Scalars['Float']['input']>
    lte?: InputMaybe<Scalars['Float']['input']>
    ne?: InputMaybe<Scalars['Float']['input']>
    range?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
}

export type SearchableIdFilterInput = {
    eq?: InputMaybe<Scalars['ID']['input']>
    exists?: InputMaybe<Scalars['Boolean']['input']>
    gt?: InputMaybe<Scalars['ID']['input']>
    gte?: InputMaybe<Scalars['ID']['input']>
    lt?: InputMaybe<Scalars['ID']['input']>
    lte?: InputMaybe<Scalars['ID']['input']>
    match?: InputMaybe<Scalars['ID']['input']>
    matchPhrase?: InputMaybe<Scalars['ID']['input']>
    matchPhrasePrefix?: InputMaybe<Scalars['ID']['input']>
    multiMatch?: InputMaybe<Scalars['ID']['input']>
    ne?: InputMaybe<Scalars['ID']['input']>
    range?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    regexp?: InputMaybe<Scalars['ID']['input']>
    wildcard?: InputMaybe<Scalars['ID']['input']>
}

export type SearchableIntFilterInput = {
    eq?: InputMaybe<Scalars['Int']['input']>
    gt?: InputMaybe<Scalars['Int']['input']>
    gte?: InputMaybe<Scalars['Int']['input']>
    lt?: InputMaybe<Scalars['Int']['input']>
    lte?: InputMaybe<Scalars['Int']['input']>
    ne?: InputMaybe<Scalars['Int']['input']>
    range?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export enum SearchableReservableAggregateField {
    adminID = 'adminID',
    availabilityByDatesAndTime = 'availabilityByDatesAndTime',
    availabilityPreviewByDates = 'availabilityPreviewByDates',
    availabilityType = 'availabilityType',
    color = 'color',
    companyID = 'companyID',
    createdAt = 'createdAt',
    departmentID = 'departmentID',
    employeeID = 'employeeID',
    externalRoomID = 'externalRoomID',
    height = 'height',
    id = 'id',
    isDefault = 'isDefault',
    maxAmountOfPeople = 'maxAmountOfPeople',
    name = 'name',
    relatedReservableID = 'relatedReservableID',
    reservableCompanyId = 'reservableCompanyId',
    reservableDepartmentId = 'reservableDepartmentId',
    reservableEmployeeId = 'reservableEmployeeId',
    reservableSpaceId = 'reservableSpaceId',
    spaceID = 'spaceID',
    type = 'type',
    updatedAt = 'updatedAt',
    width = 'width',
    x = 'x',
    y = 'y',
    zonePoints = 'zonePoints',
}

export type SearchableReservableAggregationInput = {
    field: SearchableReservableAggregateField
    name: Scalars['String']['input']
    type: SearchableAggregateType
}

export type SearchableReservableConnection = {
    __typename?: 'SearchableReservableConnection'
    aggregateItems: Array<Maybe<SearchableAggregateResult>>
    items: Array<Maybe<Reservable>>
    nextToken?: Maybe<Scalars['String']['output']>
    total?: Maybe<Scalars['Int']['output']>
}

export type SearchableReservableFilterInput = {
    adminID?: InputMaybe<SearchableIdFilterInput>
    and?: InputMaybe<Array<InputMaybe<SearchableReservableFilterInput>>>
    availabilityByDatesAndTime?: InputMaybe<SearchableBooleanFilterInput>
    availabilityPreviewByDates?: InputMaybe<SearchableStringFilterInput>
    availabilityType?: InputMaybe<SearchableStringFilterInput>
    color?: InputMaybe<SearchableStringFilterInput>
    companyID?: InputMaybe<SearchableIdFilterInput>
    createdAt?: InputMaybe<SearchableStringFilterInput>
    departmentID?: InputMaybe<SearchableIdFilterInput>
    employeeID?: InputMaybe<SearchableIdFilterInput>
    externalRoomID?: InputMaybe<SearchableStringFilterInput>
    height?: InputMaybe<SearchableFloatFilterInput>
    id?: InputMaybe<SearchableIdFilterInput>
    isDefault?: InputMaybe<SearchableStringFilterInput>
    maxAmountOfPeople?: InputMaybe<SearchableIntFilterInput>
    name?: InputMaybe<SearchableStringFilterInput>
    not?: InputMaybe<SearchableReservableFilterInput>
    or?: InputMaybe<Array<InputMaybe<SearchableReservableFilterInput>>>
    relatedReservableID?: InputMaybe<SearchableIdFilterInput>
    reservableCompanyId?: InputMaybe<SearchableIdFilterInput>
    reservableDepartmentId?: InputMaybe<SearchableIdFilterInput>
    reservableEmployeeId?: InputMaybe<SearchableIdFilterInput>
    reservableSpaceId?: InputMaybe<SearchableIdFilterInput>
    spaceID?: InputMaybe<SearchableIdFilterInput>
    type?: InputMaybe<SearchableStringFilterInput>
    updatedAt?: InputMaybe<SearchableStringFilterInput>
    width?: InputMaybe<SearchableFloatFilterInput>
    x?: InputMaybe<SearchableFloatFilterInput>
    y?: InputMaybe<SearchableFloatFilterInput>
    zonePoints?: InputMaybe<SearchableFloatFilterInput>
}

export type SearchableReservableSortInput = {
    direction?: InputMaybe<SearchableSortDirection>
    field?: InputMaybe<SearchableReservableSortableFields>
}

export enum SearchableReservableSortableFields {
    adminID = 'adminID',
    availabilityByDatesAndTime = 'availabilityByDatesAndTime',
    color = 'color',
    companyID = 'companyID',
    createdAt = 'createdAt',
    departmentID = 'departmentID',
    employeeID = 'employeeID',
    externalRoomID = 'externalRoomID',
    height = 'height',
    id = 'id',
    isDefault = 'isDefault',
    maxAmountOfPeople = 'maxAmountOfPeople',
    name = 'name',
    relatedReservableID = 'relatedReservableID',
    reservableCompanyId = 'reservableCompanyId',
    reservableDepartmentId = 'reservableDepartmentId',
    reservableEmployeeId = 'reservableEmployeeId',
    reservableSpaceId = 'reservableSpaceId',
    spaceID = 'spaceID',
    updatedAt = 'updatedAt',
    width = 'width',
    x = 'x',
    y = 'y',
    zonePoints = 'zonePoints',
}

export enum SearchableRoomWeekAggregationAggregateField {
    bookedHours = 'bookedHours',
    capacityInHours = 'capacityInHours',
    companyID = 'companyID',
    createdAt = 'createdAt',
    id = 'id',
    officeID = 'officeID',
    percentageOfUtilization = 'percentageOfUtilization',
    reservableID = 'reservableID',
    roomBookingsSum = 'roomBookingsSum',
    roomCapacityMax = 'roomCapacityMax',
    roomNameLowerCase = 'roomNameLowerCase',
    roomWeekAggregationCompanyId = 'roomWeekAggregationCompanyId',
    spaceID = 'spaceID',
    updatedAt = 'updatedAt',
    visitors = 'visitors',
    weekNumber = 'weekNumber',
    weekNumberString = 'weekNumberString',
    year = 'year',
}

export type SearchableRoomWeekAggregationAggregationInput = {
    field: SearchableRoomWeekAggregationAggregateField
    name: Scalars['String']['input']
    type: SearchableAggregateType
}

export type SearchableRoomWeekAggregationConnection = {
    __typename?: 'SearchableRoomWeekAggregationConnection'
    aggregateItems: Array<Maybe<SearchableAggregateResult>>
    items: Array<Maybe<RoomWeekAggregation>>
    nextToken?: Maybe<Scalars['String']['output']>
    total?: Maybe<Scalars['Int']['output']>
}

export type SearchableRoomWeekAggregationFilterInput = {
    and?: InputMaybe<Array<InputMaybe<SearchableRoomWeekAggregationFilterInput>>>
    bookedHours?: InputMaybe<SearchableIntFilterInput>
    capacityInHours?: InputMaybe<SearchableIntFilterInput>
    companyID?: InputMaybe<SearchableIdFilterInput>
    createdAt?: InputMaybe<SearchableStringFilterInput>
    id?: InputMaybe<SearchableIdFilterInput>
    not?: InputMaybe<SearchableRoomWeekAggregationFilterInput>
    officeID?: InputMaybe<SearchableIdFilterInput>
    or?: InputMaybe<Array<InputMaybe<SearchableRoomWeekAggregationFilterInput>>>
    percentageOfUtilization?: InputMaybe<SearchableIntFilterInput>
    reservableID?: InputMaybe<SearchableIdFilterInput>
    roomBookingsSum?: InputMaybe<SearchableIntFilterInput>
    roomCapacityMax?: InputMaybe<SearchableIntFilterInput>
    roomNameLowerCase?: InputMaybe<SearchableStringFilterInput>
    roomWeekAggregationCompanyId?: InputMaybe<SearchableIdFilterInput>
    spaceID?: InputMaybe<SearchableIdFilterInput>
    updatedAt?: InputMaybe<SearchableStringFilterInput>
    visitors?: InputMaybe<SearchableIntFilterInput>
    weekNumber?: InputMaybe<SearchableIntFilterInput>
    weekNumberString?: InputMaybe<SearchableStringFilterInput>
    year?: InputMaybe<SearchableIntFilterInput>
}

export type SearchableRoomWeekAggregationSortInput = {
    direction?: InputMaybe<SearchableSortDirection>
    field?: InputMaybe<SearchableRoomWeekAggregationSortableFields>
}

export enum SearchableRoomWeekAggregationSortableFields {
    bookedHours = 'bookedHours',
    capacityInHours = 'capacityInHours',
    companyID = 'companyID',
    createdAt = 'createdAt',
    id = 'id',
    officeID = 'officeID',
    percentageOfUtilization = 'percentageOfUtilization',
    reservableID = 'reservableID',
    roomBookingsSum = 'roomBookingsSum',
    roomCapacityMax = 'roomCapacityMax',
    roomNameLowerCase = 'roomNameLowerCase',
    roomWeekAggregationCompanyId = 'roomWeekAggregationCompanyId',
    spaceID = 'spaceID',
    updatedAt = 'updatedAt',
    visitors = 'visitors',
    weekNumber = 'weekNumber',
    weekNumberString = 'weekNumberString',
    year = 'year',
}

export enum SearchableSortDirection {
    asc = 'asc',
    desc = 'desc',
}

export type SearchableStringFilterInput = {
    eq?: InputMaybe<Scalars['String']['input']>
    exists?: InputMaybe<Scalars['Boolean']['input']>
    gt?: InputMaybe<Scalars['String']['input']>
    gte?: InputMaybe<Scalars['String']['input']>
    lt?: InputMaybe<Scalars['String']['input']>
    lte?: InputMaybe<Scalars['String']['input']>
    match?: InputMaybe<Scalars['String']['input']>
    matchPhrase?: InputMaybe<Scalars['String']['input']>
    matchPhrasePrefix?: InputMaybe<Scalars['String']['input']>
    multiMatch?: InputMaybe<Scalars['String']['input']>
    ne?: InputMaybe<Scalars['String']['input']>
    range?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    regexp?: InputMaybe<Scalars['String']['input']>
    wildcard?: InputMaybe<Scalars['String']['input']>
}

export enum ShareAttendance {
    ADMIN = 'ADMIN',
    ALL = 'ALL',
    NONE = 'NONE',
    TEAM = 'TEAM',
}

export type SlackIntegration = {
    __typename?: 'SlackIntegration'
    companyID: Scalars['ID']['output']
    enableCheckInFeature: Scalars['Boolean']['output']
    enableSendingDaySchedule?: Maybe<Scalars['Boolean']['output']>
    sendDayScheduleAt?: Maybe<Scalars['AWSTime']['output']>
}

export type SlackIntegrationInput = {
    code: Scalars['String']['input']
    state: Scalars['String']['input']
}

export type SlackIntegrationUpdateInput = {
    enableCheckInFeature: Scalars['Boolean']['input']
    enableSendingDaySchedule?: InputMaybe<Scalars['Boolean']['input']>
    sendDayScheduleAt?: InputMaybe<Scalars['AWSTime']['input']>
}

export type SlackOAuthState = {
    __typename?: 'SlackOAuthState'
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    updatedAt: Scalars['AWSDateTime']['output']
}

export type SlackOnboradingMessage = {
    __typename?: 'SlackOnboradingMessage'
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    employeeID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    slackMessageID: Scalars['String']['output']
    slackOnboradingMessageCompanyId?: Maybe<Scalars['ID']['output']>
    slackOnboradingMessageEmployeeId?: Maybe<Scalars['ID']['output']>
    state: SlackOnboradingMessageState
    updatedAt?: Maybe<Scalars['AWSDateTime']['output']>
}

export enum SlackOnboradingMessageState {
    BOOKING = 'BOOKING',
    DONE = 'DONE',
    INTRO = 'INTRO',
    NOTIFICATIONS = 'NOTIFICATIONS',
    TEAM_SCHEDULE = 'TEAM_SCHEDULE',
}

export enum Source {
    AD = 'AD',
    CUSTOM = 'CUSTOM',
    GOOGLE = 'GOOGLE',
}

export type Space = {
    __typename?: 'Space'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    Reservables?: Maybe<ModelReservableConnection>
    SpaceCapacityDayAggregation?: Maybe<ModelSpaceCapacityDayAggregationConnection>
    SuggestedSeats?: Maybe<ModelSuggestedSeatConnection>
    address?: Maybe<Scalars['String']['output']>
    availableParkingSlots?: Maybe<Scalars['Int']['output']>
    availableParkingSlotsByPeriod?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    floorPlanFilePath?: Maybe<Scalars['String']['output']>
    getCapacity?: Maybe<SpaceCapacity>
    id: Scalars['ID']['output']
    isAvailableToEmployees?: Maybe<Scalars['Boolean']['output']>
    name?: Maybe<Scalars['String']['output']>
    officeID: Scalars['ID']['output']
    order?: Maybe<Scalars['Int']['output']>
    spaceCompanyId?: Maybe<Scalars['ID']['output']>
    spaceOfficeId?: Maybe<Scalars['ID']['output']>
    spaceRowID?: Maybe<Scalars['ID']['output']>
    suggestedSeatID?: Maybe<Scalars['ID']['output']>
    timeZone?: Maybe<Scalars['String']['output']>
    totalParkingSlots?: Maybe<Scalars['Int']['output']>
    totalRooms?: Maybe<Scalars['Int']['output']>
    totalSeats?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
    workingHoursFrom?: Maybe<Scalars['AWSTime']['output']>
    workingHoursTo?: Maybe<Scalars['AWSTime']['output']>
}

export type SpaceReservablesArgs = {
    filter?: InputMaybe<ModelReservableFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type SpaceSpaceCapacityDayAggregationArgs = {
    employeeIDDateCreatedAt?: InputMaybe<ModelSpaceCapacityDayAggregationBySpaceAndEmployeeAndDateAndCreatedAtCompositeKeyConditionInput>
    filter?: InputMaybe<ModelSpaceCapacityDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type SpaceSuggestedSeatsArgs = {
    employeeIDDate?: InputMaybe<ModelSuggestedSeatBySpaceAndEmployeeAndDateCompositeKeyConditionInput>
    filter?: InputMaybe<ModelSuggestedSeatFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type SpaceAvailableParkingSlotsArgs = {
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
}

export type SpaceAvailableParkingSlotsByPeriodArgs = {
    employeeID?: InputMaybe<Scalars['ID']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
    startTime?: InputMaybe<Scalars['String']['input']>
}

export type SpaceGetCapacityArgs = {
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
}

export type SpaceIsAvailableToEmployeesArgs = {
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SpaceSuggestedSeatIdArgs = {
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
}

export type SpaceCapacity = {
    __typename?: 'SpaceCapacity'
    availableSeatsSum: Scalars['Int']['output']
    type: SpaceCapacityDayAggregationType
    waitingListItemsSum: Scalars['Int']['output']
}

export type SpaceCapacityDayAggregation = {
    __typename?: 'SpaceCapacityDayAggregation'
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    Space?: Maybe<Space>
    availableSeatsSum: Scalars['Int']['output']
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date: Scalars['AWSDate']['output']
    employeeID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    spaceCapacityDayAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    spaceCapacityDayAggregationEmployeeId?: Maybe<Scalars['ID']['output']>
    spaceCapacityDayAggregationSpaceId?: Maybe<Scalars['ID']['output']>
    spaceID: Scalars['ID']['output']
    ttl?: Maybe<Scalars['Int']['output']>
    type: SpaceCapacityDayAggregationType
    updatedAt: Scalars['AWSDateTime']['output']
    waitingListItemsSum: Scalars['Int']['output']
}

export enum SpaceCapacityDayAggregationType {
    FULL = 'FULL',
    HAS_AVAILABLE_SEATS = 'HAS_AVAILABLE_SEATS',
    HAS_WAITING_LIST = 'HAS_WAITING_LIST',
    IN_WAITING_LIST = 'IN_WAITING_LIST',
    SEATING = 'SEATING',
}

export type SpaceCapacityInput = {
    availableSeatsSum: Scalars['Int']['input']
    type: SpaceCapacityDayAggregationType
    waitingListItemsSum: Scalars['Int']['input']
}

export type SpaceDayAggregation = {
    __typename?: 'SpaceDayAggregation'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    Space?: Maybe<Space>
    bookedHours?: Maybe<Scalars['Int']['output']>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dataByHour: Array<SpaceDayAggregationBookingsByHoursDistribution>
    date: Scalars['AWSDate']['output']
    homeBookingsSum: Scalars['Int']['output']
    id: Scalars['ID']['output']
    officeID?: Maybe<Scalars['ID']['output']>
    spaceBookingsSum: Scalars['Int']['output']
    spaceCapacityLast: Scalars['Int']['output']
    spaceDayAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    spaceID: Scalars['ID']['output']
    type?: Maybe<AggregationByReservableType>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type SpaceDayAggregationBookingsByHoursDistribution = {
    __typename?: 'SpaceDayAggregationBookingsByHoursDistribution'
    bookedSeats: Scalars['Int']['output']
    capacity: Scalars['Int']['output']
    hour: Scalars['Int']['output']
}

export type SpaceDayAggregationBookingsByHoursDistributionInput = {
    bookedSeats: Scalars['Int']['input']
    capacity: Scalars['Int']['input']
    hour: Scalars['Int']['input']
}

export type SpaceRow = {
    __typename?: 'SpaceRow'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    Spaces?: Maybe<ModelSpaceConnection>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    officeID: Scalars['ID']['output']
    order?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type SpaceRowSpacesArgs = {
    filter?: InputMaybe<ModelSpaceFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    order?: InputMaybe<ModelIntKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export type SpaceWeekAggregation = {
    __typename?: 'SpaceWeekAggregation'
    Company?: Maybe<Company>
    Office?: Maybe<Office>
    Space?: Maybe<Space>
    bookedHours?: Maybe<Scalars['Int']['output']>
    bookingsDistribution: Array<DashboardWeekAggregationBookingsDistribution>
    capacityInHours?: Maybe<Scalars['Int']['output']>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    dayAggregations?: Maybe<ModelSpaceDayAggregationConnection>
    employeesLast: Scalars['Int']['output']
    id: Scalars['ID']['output']
    officeID?: Maybe<Scalars['ID']['output']>
    percentageOfUtilization?: Maybe<Scalars['Int']['output']>
    spaceBookingsSum: Scalars['Int']['output']
    spaceCapacityMax: Scalars['Int']['output']
    spaceID: Scalars['ID']['output']
    spaceWeekAggregationCompanyId?: Maybe<Scalars['ID']['output']>
    type?: Maybe<AggregationByReservableType>
    updatedAt: Scalars['AWSDateTime']['output']
    weekNumber: Scalars['Int']['output']
    weekNumberString?: Maybe<Scalars['String']['output']>
    year: Scalars['Int']['output']
}

export type SpaceWeekAggregationDayAggregationsArgs = {
    filter?: InputMaybe<ModelSpaceDayAggregationFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    yearWeekNumberDate?: InputMaybe<ModelSpaceDayAggregationBySpaceAndYearAndWeekNumberAndDateCompositeKeyConditionInput>
}

export type SubscribedBilling = BillingSubscription & {
    __typename?: 'SubscribedBilling'
    cancelUrl: Scalars['String']['output']
    companyId: Scalars['String']['output']
    discount?: Maybe<Discount>
    expiresAt: Scalars['AWSDateTime']['output']
    quantity: Scalars['Int']['output']
    status: BillingSubscriptionStatus
    subscribedAt: Scalars['AWSDateTime']['output']
    subscriptionId: Scalars['String']['output']
    subscriptionPlanId: Scalars['String']['output']
    updateUrl: Scalars['String']['output']
}

export type SubscribedBillingItems = {
    __typename?: 'SubscribedBillingItems'
    items?: Maybe<Array<Maybe<SubscribedBilling>>>
}

export type SubscribedBillingOutput = {
    __typename?: 'SubscribedBillingOutput'
    cancelUrl: Scalars['String']['output']
    companyId: Scalars['String']['output']
    discount?: Maybe<Discount>
    expiresAt: Scalars['AWSDateTime']['output']
    quantity: Scalars['Int']['output']
    status: BillingSubscriptionStatus
    subscribedAt: Scalars['AWSDateTime']['output']
    subscriptionId: Scalars['String']['output']
    subscriptionPlanId: Scalars['String']['output']
    updateUrl: Scalars['String']['output']
}

export type Subscription = {
    __typename?: 'Subscription'
    onCompanyTariffUpdate?: Maybe<CompanyTariffUpdated>
    onCreateAddress?: Maybe<Address>
    onCreateBooking?: Maybe<Booking>
    onCreateBookingByDate?: Maybe<Booking>
    onCreateBookingByEmployeeID?: Maybe<Booking>
    onCreateBookingByReservable?: Maybe<Booking>
    onCreateBookingBySpace?: Maybe<Booking>
    onCreateBookingBySpaceAndDate?: Maybe<Booking>
    onCreateBookingCancellation?: Maybe<BookingCancellation>
    onCreateBookingEquipment?: Maybe<BookingEquipment>
    onCreateBookingEquipmentByCompanyAndDate?: Maybe<BookingEquipment>
    onCreateBookingEquipmentByCompanyAndWeekAndYear?: Maybe<BookingEquipment>
    onCreateBookingRequest?: Maybe<BookingRequest>
    onCreateBookingReservable?: Maybe<BookingReservable>
    onCreateCalendarEventByCompany?: Maybe<CalendarEvents>
    onCreateCalendarEventByCompanyAndDate?: Maybe<CalendarEvents>
    onCreateCalendarEventByReservable?: Maybe<CalendarEvents>
    onCreateCalendarEventBySpace?: Maybe<CalendarEvents>
    onCreateCalendarEventEmployee?: Maybe<CalendarEventEmployee>
    onCreateCalendarEventEmployeeByEmployee?: Maybe<CalendarEventEmployee>
    onCreateCalendarEventEmployeeByEmployeeAndWeek?: Maybe<CalendarEventEmployee>
    onCreateCalendarEventRequest?: Maybe<CalendarEventRequest>
    onCreateCalendarEvents?: Maybe<CalendarEvents>
    onCreateCatering?: Maybe<Catering>
    onCreateCompany?: Maybe<Company>
    onCreateCompanyDayAggregation?: Maybe<CompanyDayAggregation>
    onCreateCompanyWeekAggregation?: Maybe<CompanyWeekAggregation>
    onCreateCustomRole?: Maybe<CustomRole>
    onCreateDepartment?: Maybe<Department>
    onCreateDepartmentDayAggregation?: Maybe<DepartmentDayAggregation>
    onCreateDepartmentDayAggregationByWeek?: Maybe<DepartmentDayAggregation>
    onCreateDepartmentDayAggregationEmployees?: Maybe<DepartmentDayAggregationEmployees>
    onCreateDepartmentLastMonthAttendance?: Maybe<DepartmentLastMonthAttendance>
    onCreateDepartmentRules?: Maybe<DepartmentRules>
    onCreateDepartmentWeekAggregation?: Maybe<DepartmentWeekAggregation>
    onCreateEmployee?: Maybe<Employee>
    onCreateEmployeeDayAggregation?: Maybe<EmployeeDayAggregation>
    onCreateEmployeeLastMonthAttendance?: Maybe<EmployeeLastMonthAttendance>
    onCreateEmployeeToDepartment?: Maybe<EmployeeToDepartment>
    onCreateEmployeeWeekAggregation?: Maybe<EmployeeWeekAggregation>
    onCreateEquipment?: Maybe<Equipment>
    onCreateEquipmentImage?: Maybe<EquipmentImage>
    onCreateGoogleImportedBookings?: Maybe<GoogleImportedBookings>
    onCreateGoogleImportedWorkingLocations?: Maybe<GoogleImportedWorkingLocations>
    onCreateIntegration?: Maybe<Integration>
    onCreateMenuItem?: Maybe<MenuItem>
    onCreateMicrosoftSubscription?: Maybe<MicrosoftSubscription>
    onCreateNotification?: Maybe<Notification>
    onCreateOffice?: Maybe<Office>
    onCreateOfficeBuilderRequest?: Maybe<OfficeBuilderRequest>
    onCreateOfficeDayAggregation?: Maybe<OfficeDayAggregation>
    onCreateOfficeDayAggregationByWeekAndOffice?: Maybe<OfficeDayAggregation>
    onCreateOfficeImage?: Maybe<OfficeImage>
    onCreateOfficeWeekAggregation?: Maybe<OfficeWeekAggregation>
    onCreatePermission?: Maybe<Permission>
    onCreatePersonioImportedAbsences?: Maybe<PersonioImportedAbsences>
    onCreatePersonioImportedBookings?: Maybe<PersonioImportedBookings>
    onCreatePosition?: Maybe<Position>
    onCreateReservable?: Maybe<Reservable>
    onCreateReservableImage?: Maybe<ReservableImage>
    onCreateReservableTag?: Maybe<ReservableTag>
    onCreateReservableToDepartment?: Maybe<ReservableToDepartment>
    onCreateReservableToEmployee?: Maybe<ReservableToEmployee>
    onCreateRoomDayAggregation?: Maybe<RoomDayAggregation>
    onCreateRoomWeekAggregation?: Maybe<RoomWeekAggregation>
    onCreateRules?: Maybe<Rules>
    onCreateSlackOAuthState?: Maybe<SlackOAuthState>
    onCreateSlackOnboradingMessage?: Maybe<SlackOnboradingMessage>
    onCreateSpace?: Maybe<Space>
    onCreateSpaceCapacityDayAggregation?: Maybe<SpaceCapacityDayAggregation>
    onCreateSpaceCapacityDayAggregationCustom?: Maybe<SpaceCapacityDayAggregation>
    onCreateSpaceDayAggregation?: Maybe<SpaceDayAggregation>
    onCreateSpaceDayAggregationBySpace?: Maybe<SpaceDayAggregation>
    onCreateSpaceRow?: Maybe<SpaceRow>
    onCreateSpaceWeekAggregation?: Maybe<SpaceWeekAggregation>
    onCreateSuggestedSeat?: Maybe<SuggestedSeat>
    onCreateSyncUserEvent?: Maybe<SyncUserEvent>
    onCreateTag?: Maybe<Tag>
    onCreateTeamEventRequest?: Maybe<TeamEventRequest>
    onDeleteAddress?: Maybe<Address>
    onDeleteBooking?: Maybe<Booking>
    onDeleteBookingByDate?: Maybe<Booking>
    onDeleteBookingByEmployeeID?: Maybe<Booking>
    onDeleteBookingByReservable?: Maybe<Booking>
    onDeleteBookingBySpace?: Maybe<Booking>
    onDeleteBookingBySpaceAndDate?: Maybe<Booking>
    onDeleteBookingCancellation?: Maybe<BookingCancellation>
    onDeleteBookingEquipment?: Maybe<BookingEquipment>
    onDeleteBookingEquipmentByCompanyAndDate?: Maybe<BookingEquipment>
    onDeleteBookingEquipmentByCompanyAndWeekAndYear?: Maybe<BookingEquipment>
    onDeleteBookingRequest?: Maybe<BookingRequest>
    onDeleteBookingReservable?: Maybe<BookingReservable>
    onDeleteBookingReservableByCompany?: Maybe<BookingReservable>
    onDeleteCalendarEventByCompany?: Maybe<CalendarEvents>
    onDeleteCalendarEventByCompanyAndDate?: Maybe<CalendarEvents>
    onDeleteCalendarEventByReservable?: Maybe<CalendarEvents>
    onDeleteCalendarEventBySpace?: Maybe<CalendarEvents>
    onDeleteCalendarEventEmployee?: Maybe<CalendarEventEmployee>
    onDeleteCalendarEventEmployeeByEmployee?: Maybe<CalendarEventEmployee>
    onDeleteCalendarEventEmployeeByEmployeeAndWeek?: Maybe<CalendarEventEmployee>
    onDeleteCalendarEventRequest?: Maybe<CalendarEventRequest>
    onDeleteCalendarEvents?: Maybe<CalendarEvents>
    onDeleteCatering?: Maybe<Catering>
    onDeleteCompany?: Maybe<Company>
    onDeleteCompanyDayAggregation?: Maybe<CompanyDayAggregation>
    onDeleteCompanyWeekAggregation?: Maybe<CompanyWeekAggregation>
    onDeleteCustomRole?: Maybe<CustomRole>
    onDeleteDepartment?: Maybe<Department>
    onDeleteDepartmentDayAggregation?: Maybe<DepartmentDayAggregation>
    onDeleteDepartmentDayAggregationEmployees?: Maybe<DepartmentDayAggregationEmployees>
    onDeleteDepartmentLastMonthAttendance?: Maybe<DepartmentLastMonthAttendance>
    onDeleteDepartmentRules?: Maybe<DepartmentRules>
    onDeleteDepartmentWeekAggregation?: Maybe<DepartmentWeekAggregation>
    onDeleteEmployee?: Maybe<Employee>
    onDeleteEmployeeDayAggregation?: Maybe<EmployeeDayAggregation>
    onDeleteEmployeeLastMonthAttendance?: Maybe<EmployeeLastMonthAttendance>
    onDeleteEmployeeToDepartment?: Maybe<EmployeeToDepartment>
    onDeleteEmployeeWeekAggregation?: Maybe<EmployeeWeekAggregation>
    onDeleteEquipment?: Maybe<Equipment>
    onDeleteEquipmentImage?: Maybe<EquipmentImage>
    onDeleteGoogleImportedBookings?: Maybe<GoogleImportedBookings>
    onDeleteGoogleImportedWorkingLocations?: Maybe<GoogleImportedWorkingLocations>
    onDeleteIntegration?: Maybe<Integration>
    onDeleteMenuItem?: Maybe<MenuItem>
    onDeleteMicrosoftSubscription?: Maybe<MicrosoftSubscription>
    onDeleteNotification?: Maybe<Notification>
    onDeleteOffice?: Maybe<Office>
    onDeleteOfficeBuilderRequest?: Maybe<OfficeBuilderRequest>
    onDeleteOfficeDayAggregation?: Maybe<OfficeDayAggregation>
    onDeleteOfficeImage?: Maybe<OfficeImage>
    onDeleteOfficeWeekAggregation?: Maybe<OfficeWeekAggregation>
    onDeletePermission?: Maybe<Permission>
    onDeletePersonioImportedAbsences?: Maybe<PersonioImportedAbsences>
    onDeletePersonioImportedBookings?: Maybe<PersonioImportedBookings>
    onDeletePosition?: Maybe<Position>
    onDeleteReservable?: Maybe<Reservable>
    onDeleteReservableImage?: Maybe<ReservableImage>
    onDeleteReservableTag?: Maybe<ReservableTag>
    onDeleteReservableToDepartment?: Maybe<ReservableToDepartment>
    onDeleteReservableToEmployee?: Maybe<ReservableToEmployee>
    onDeleteRoomDayAggregation?: Maybe<RoomDayAggregation>
    onDeleteRoomWeekAggregation?: Maybe<RoomWeekAggregation>
    onDeleteSlackOAuthState?: Maybe<SlackOAuthState>
    onDeleteSlackOnboradingMessage?: Maybe<SlackOnboradingMessage>
    onDeleteSpace?: Maybe<Space>
    onDeleteSpaceCapacityDayAggregation?: Maybe<SpaceCapacityDayAggregation>
    onDeleteSpaceDayAggregation?: Maybe<SpaceDayAggregation>
    onDeleteSpaceRow?: Maybe<SpaceRow>
    onDeleteSpaceWeekAggregation?: Maybe<SpaceWeekAggregation>
    onDeleteSuggestedSeat?: Maybe<SuggestedSeat>
    onDeleteTag?: Maybe<Tag>
    onDeleteTeamEventRequest?: Maybe<TeamEventRequest>
    onLogPersonioIntegrationExecutionResult?: Maybe<PersonioIntegration>
    onUpdateAddress?: Maybe<Address>
    onUpdateBillingSubscription?: Maybe<SubscribedBillingOutput>
    onUpdateBillingTrial?: Maybe<TrialBillingOutput>
    onUpdateBooking?: Maybe<Booking>
    onUpdateBookingByDate?: Maybe<Booking>
    onUpdateBookingByEmployeeID?: Maybe<Booking>
    onUpdateBookingByReservable?: Maybe<Booking>
    onUpdateBookingBySpace?: Maybe<Booking>
    onUpdateBookingBySpaceAndDate?: Maybe<Booking>
    onUpdateBookingCancellation?: Maybe<BookingCancellation>
    onUpdateBookingEquipment?: Maybe<BookingEquipment>
    onUpdateBookingEquipmentByCompanyAndDate?: Maybe<BookingEquipment>
    onUpdateBookingEquipmentByCompanyAndWeekAndYear?: Maybe<BookingEquipment>
    onUpdateBookingRequest?: Maybe<BookingRequest>
    onUpdateBookingReservable?: Maybe<BookingReservable>
    onUpdateCalendarEventByCompany?: Maybe<CalendarEvents>
    onUpdateCalendarEventByCompanyAndDate?: Maybe<CalendarEvents>
    onUpdateCalendarEventByReservable?: Maybe<CalendarEvents>
    onUpdateCalendarEventBySpace?: Maybe<CalendarEvents>
    onUpdateCalendarEventEmployee?: Maybe<CalendarEventEmployee>
    onUpdateCalendarEventEmployeeByEmployee?: Maybe<CalendarEventEmployee>
    onUpdateCalendarEventEmployeeByEmployeeAndWeek?: Maybe<CalendarEventEmployee>
    onUpdateCalendarEventRequest?: Maybe<CalendarEventRequest>
    onUpdateCalendarEvents?: Maybe<CalendarEvents>
    onUpdateCatering?: Maybe<Catering>
    onUpdateCompany?: Maybe<Company>
    onUpdateCompanyDayAggregation?: Maybe<CompanyDayAggregation>
    onUpdateCompanyWeekAggregation?: Maybe<CompanyWeekAggregation>
    onUpdateCustomRole?: Maybe<CustomRole>
    onUpdateDepartment?: Maybe<Department>
    onUpdateDepartmentDayAggregation?: Maybe<DepartmentDayAggregation>
    onUpdateDepartmentDayAggregationByWeek?: Maybe<DepartmentDayAggregation>
    onUpdateDepartmentDayAggregationEmployees?: Maybe<DepartmentDayAggregationEmployees>
    onUpdateDepartmentLastMonthAttendance?: Maybe<DepartmentLastMonthAttendance>
    onUpdateDepartmentRules?: Maybe<DepartmentRules>
    onUpdateDepartmentWeekAggregation?: Maybe<DepartmentWeekAggregation>
    onUpdateEmployee?: Maybe<Employee>
    onUpdateEmployeeById?: Maybe<Employee>
    onUpdateEmployeeDayAggregation?: Maybe<EmployeeDayAggregation>
    onUpdateEmployeeLastMonthAttendance?: Maybe<EmployeeLastMonthAttendance>
    onUpdateEmployeeToDepartment?: Maybe<EmployeeToDepartment>
    onUpdateEmployeeWeekAggregation?: Maybe<EmployeeWeekAggregation>
    onUpdateEquipment?: Maybe<Equipment>
    onUpdateEquipmentImage?: Maybe<EquipmentImage>
    onUpdateGoogleImportedBookings?: Maybe<GoogleImportedBookings>
    onUpdateGoogleImportedWorkingLocations?: Maybe<GoogleImportedWorkingLocations>
    onUpdateIntegration?: Maybe<Integration>
    onUpdateMenuItem?: Maybe<MenuItem>
    onUpdateMicrosoftSubscription?: Maybe<MicrosoftSubscription>
    onUpdateNotification?: Maybe<Notification>
    onUpdateOffice?: Maybe<Office>
    onUpdateOfficeBuilderRequest?: Maybe<OfficeBuilderRequest>
    onUpdateOfficeDayAggregation?: Maybe<OfficeDayAggregation>
    onUpdateOfficeDayAggregationByWeekAndOffice?: Maybe<OfficeDayAggregation>
    onUpdateOfficeImage?: Maybe<OfficeImage>
    onUpdateOfficeWeekAggregation?: Maybe<OfficeWeekAggregation>
    onUpdatePermission?: Maybe<Permission>
    onUpdatePersonioImportedAbsences?: Maybe<PersonioImportedAbsences>
    onUpdatePersonioImportedBookings?: Maybe<PersonioImportedBookings>
    onUpdatePosition?: Maybe<Position>
    onUpdateReservable?: Maybe<Reservable>
    onUpdateReservableImage?: Maybe<ReservableImage>
    onUpdateReservableTag?: Maybe<ReservableTag>
    onUpdateReservableToDepartment?: Maybe<ReservableToDepartment>
    onUpdateReservableToEmployee?: Maybe<ReservableToEmployee>
    onUpdateRoomDayAggregation?: Maybe<RoomDayAggregation>
    onUpdateRoomWeekAggregation?: Maybe<RoomWeekAggregation>
    onUpdateRules?: Maybe<Rules>
    onUpdateSlackOAuthState?: Maybe<SlackOAuthState>
    onUpdateSlackOnboradingMessage?: Maybe<SlackOnboradingMessage>
    onUpdateSpace?: Maybe<Space>
    onUpdateSpaceCapacityDayAggregation?: Maybe<SpaceCapacityDayAggregation>
    onUpdateSpaceDayAggregation?: Maybe<SpaceDayAggregation>
    onUpdateSpaceDayAggregationBySpace?: Maybe<SpaceDayAggregation>
    onUpdateSpaceRow?: Maybe<SpaceRow>
    onUpdateSpaceWeekAggregation?: Maybe<SpaceWeekAggregation>
    onUpdateSuggestedSeat?: Maybe<SuggestedSeat>
    onUpdateTag?: Maybe<Tag>
    onUpdateTeamEventRequest?: Maybe<TeamEventRequest>
    onUpdatedBambooHRIntegration?: Maybe<BambooHrIntegration>
}

export type SubscriptionOnCompanyTariffUpdateArgs = {
    companyId: Scalars['String']['input']
}

export type SubscriptionOnCreateAddressArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionAddressFilterInput>
}

export type SubscriptionOnCreateBookingArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateBookingByDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnCreateBookingByEmployeeIdArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
    weekNumber?: InputMaybe<Scalars['Int']['input']>
}

export type SubscriptionOnCreateBookingByReservableArgs = {
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}

export type SubscriptionOnCreateBookingBySpaceArgs = {
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnCreateBookingBySpaceAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnCreateBookingCancellationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingCancellationFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateBookingEquipmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingEquipmentFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateBookingEquipmentByCompanyAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnCreateBookingEquipmentByCompanyAndWeekAndYearArgs = {
    companyID: Scalars['String']['input']
    isoWeekNumber: Scalars['Int']['input']
    year: Scalars['Int']['input']
}

export type SubscriptionOnCreateBookingRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateBookingReservableArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingReservableFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateCalendarEventByCompanyArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnCreateCalendarEventByCompanyAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnCreateCalendarEventByReservableArgs = {
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}

export type SubscriptionOnCreateCalendarEventBySpaceArgs = {
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnCreateCalendarEventEmployeeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventEmployeeFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateCalendarEventEmployeeByEmployeeArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
}

export type SubscriptionOnCreateCalendarEventEmployeeByEmployeeAndWeekArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
}

export type SubscriptionOnCreateCalendarEventRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateCalendarEventsArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventsFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateCateringArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCateringFilterInput>
}

export type SubscriptionOnCreateCompanyArgs = {
    filter?: InputMaybe<ModelSubscriptionCompanyFilterInput>
    id?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateCompanyDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCompanyDayAggregationFilterInput>
}

export type SubscriptionOnCreateCompanyWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCompanyWeekAggregationFilterInput>
}

export type SubscriptionOnCreateCustomRoleArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCustomRoleFilterInput>
}

export type SubscriptionOnCreateDepartmentArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnCreateDepartmentDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentDayAggregationFilterInput>
}

export type SubscriptionOnCreateDepartmentDayAggregationByWeekArgs = {
    companyID: Scalars['String']['input']
    weekNumber: Scalars['Int']['input']
}

export type SubscriptionOnCreateDepartmentDayAggregationEmployeesArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentDayAggregationEmployeesFilterInput>
}

export type SubscriptionOnCreateDepartmentLastMonthAttendanceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentLastMonthAttendanceFilterInput>
}

export type SubscriptionOnCreateDepartmentRulesArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentRulesFilterInput>
}

export type SubscriptionOnCreateDepartmentWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentWeekAggregationFilterInput>
}

export type SubscriptionOnCreateEmployeeArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnCreateEmployeeDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeDayAggregationFilterInput>
}

export type SubscriptionOnCreateEmployeeLastMonthAttendanceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeLastMonthAttendanceFilterInput>
}

export type SubscriptionOnCreateEmployeeToDepartmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeToDepartmentFilterInput>
}

export type SubscriptionOnCreateEmployeeWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeWeekAggregationFilterInput>
}

export type SubscriptionOnCreateEquipmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEquipmentFilterInput>
}

export type SubscriptionOnCreateEquipmentImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEquipmentImageFilterInput>
}

export type SubscriptionOnCreateGoogleImportedBookingsArgs = {
    filter?: InputMaybe<ModelSubscriptionGoogleImportedBookingsFilterInput>
}

export type SubscriptionOnCreateGoogleImportedWorkingLocationsArgs = {
    filter?: InputMaybe<ModelSubscriptionGoogleImportedWorkingLocationsFilterInput>
}

export type SubscriptionOnCreateIntegrationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionIntegrationFilterInput>
}

export type SubscriptionOnCreateMenuItemArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionMenuItemFilterInput>
}

export type SubscriptionOnCreateMicrosoftSubscriptionArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionMicrosoftSubscriptionFilterInput>
}

export type SubscriptionOnCreateNotificationArgs = {
    employeeID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionNotificationFilterInput>
}

export type SubscriptionOnCreateOfficeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeFilterInput>
}

export type SubscriptionOnCreateOfficeBuilderRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeBuilderRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateOfficeDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeDayAggregationFilterInput>
}

export type SubscriptionOnCreateOfficeDayAggregationByWeekAndOfficeArgs = {
    companyID: Scalars['String']['input']
    officeID: Scalars['String']['input']
    weekNumber: Scalars['Int']['input']
}

export type SubscriptionOnCreateOfficeImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeImageFilterInput>
}

export type SubscriptionOnCreateOfficeWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeWeekAggregationFilterInput>
}

export type SubscriptionOnCreatePermissionArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionPermissionFilterInput>
}

export type SubscriptionOnCreatePersonioImportedAbsencesArgs = {
    filter?: InputMaybe<ModelSubscriptionPersonioImportedAbsencesFilterInput>
}

export type SubscriptionOnCreatePersonioImportedBookingsArgs = {
    filter?: InputMaybe<ModelSubscriptionPersonioImportedBookingsFilterInput>
}

export type SubscriptionOnCreatePositionArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnCreateReservableArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableFilterInput>
}

export type SubscriptionOnCreateReservableImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableImageFilterInput>
}

export type SubscriptionOnCreateReservableTagArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableTagFilterInput>
}

export type SubscriptionOnCreateReservableToDepartmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableToDepartmentFilterInput>
}

export type SubscriptionOnCreateReservableToEmployeeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableToEmployeeFilterInput>
}

export type SubscriptionOnCreateRoomDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionRoomDayAggregationFilterInput>
}

export type SubscriptionOnCreateRoomWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionRoomWeekAggregationFilterInput>
}

export type SubscriptionOnCreateRulesArgs = {
    filter?: InputMaybe<ModelSubscriptionRulesFilterInput>
    id?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnCreateSlackOAuthStateArgs = {
    filter?: InputMaybe<ModelSubscriptionSlackOAuthStateFilterInput>
}

export type SubscriptionOnCreateSlackOnboradingMessageArgs = {
    filter?: InputMaybe<ModelSubscriptionSlackOnboradingMessageFilterInput>
}

export type SubscriptionOnCreateSpaceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceFilterInput>
}

export type SubscriptionOnCreateSpaceCapacityDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceCapacityDayAggregationFilterInput>
}

export type SubscriptionOnCreateSpaceCapacityDayAggregationCustomArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
    employeeID: Scalars['String']['input']
}

export type SubscriptionOnCreateSpaceDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceDayAggregationFilterInput>
}

export type SubscriptionOnCreateSpaceDayAggregationBySpaceArgs = {
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnCreateSpaceRowArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceRowFilterInput>
}

export type SubscriptionOnCreateSpaceWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceWeekAggregationFilterInput>
}

export type SubscriptionOnCreateSuggestedSeatArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSuggestedSeatFilterInput>
}

export type SubscriptionOnCreateSyncUserEventArgs = {
    filter?: InputMaybe<ModelSubscriptionSyncUserEventFilterInput>
}

export type SubscriptionOnCreateTagArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionTagFilterInput>
}

export type SubscriptionOnCreateTeamEventRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionTeamEventRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteAddressArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionAddressFilterInput>
}

export type SubscriptionOnDeleteBookingArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteBookingByDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnDeleteBookingByEmployeeIdArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
    weekNumber?: InputMaybe<Scalars['Int']['input']>
}

export type SubscriptionOnDeleteBookingByReservableArgs = {
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}

export type SubscriptionOnDeleteBookingBySpaceArgs = {
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnDeleteBookingBySpaceAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnDeleteBookingCancellationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingCancellationFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteBookingEquipmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingEquipmentFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteBookingEquipmentByCompanyAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnDeleteBookingEquipmentByCompanyAndWeekAndYearArgs = {
    companyID: Scalars['String']['input']
    isoWeekNumber: Scalars['Int']['input']
    year: Scalars['Int']['input']
}

export type SubscriptionOnDeleteBookingRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteBookingReservableArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingReservableFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteBookingReservableByCompanyArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnDeleteCalendarEventByCompanyArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnDeleteCalendarEventByCompanyAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnDeleteCalendarEventByReservableArgs = {
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}

export type SubscriptionOnDeleteCalendarEventBySpaceArgs = {
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnDeleteCalendarEventEmployeeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventEmployeeFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteCalendarEventEmployeeByEmployeeArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
}

export type SubscriptionOnDeleteCalendarEventEmployeeByEmployeeAndWeekArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
}

export type SubscriptionOnDeleteCalendarEventRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteCalendarEventsArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventsFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteCateringArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCateringFilterInput>
}

export type SubscriptionOnDeleteCompanyArgs = {
    filter?: InputMaybe<ModelSubscriptionCompanyFilterInput>
    id?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteCompanyDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCompanyDayAggregationFilterInput>
}

export type SubscriptionOnDeleteCompanyWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCompanyWeekAggregationFilterInput>
}

export type SubscriptionOnDeleteCustomRoleArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCustomRoleFilterInput>
}

export type SubscriptionOnDeleteDepartmentArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnDeleteDepartmentDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentDayAggregationFilterInput>
}

export type SubscriptionOnDeleteDepartmentDayAggregationEmployeesArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentDayAggregationEmployeesFilterInput>
}

export type SubscriptionOnDeleteDepartmentLastMonthAttendanceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentLastMonthAttendanceFilterInput>
}

export type SubscriptionOnDeleteDepartmentRulesArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentRulesFilterInput>
}

export type SubscriptionOnDeleteDepartmentWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentWeekAggregationFilterInput>
}

export type SubscriptionOnDeleteEmployeeArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnDeleteEmployeeDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeDayAggregationFilterInput>
}

export type SubscriptionOnDeleteEmployeeLastMonthAttendanceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeLastMonthAttendanceFilterInput>
}

export type SubscriptionOnDeleteEmployeeToDepartmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeToDepartmentFilterInput>
}

export type SubscriptionOnDeleteEmployeeWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeWeekAggregationFilterInput>
}

export type SubscriptionOnDeleteEquipmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEquipmentFilterInput>
}

export type SubscriptionOnDeleteEquipmentImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEquipmentImageFilterInput>
}

export type SubscriptionOnDeleteGoogleImportedBookingsArgs = {
    filter?: InputMaybe<ModelSubscriptionGoogleImportedBookingsFilterInput>
}

export type SubscriptionOnDeleteGoogleImportedWorkingLocationsArgs = {
    filter?: InputMaybe<ModelSubscriptionGoogleImportedWorkingLocationsFilterInput>
}

export type SubscriptionOnDeleteIntegrationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionIntegrationFilterInput>
}

export type SubscriptionOnDeleteMenuItemArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionMenuItemFilterInput>
}

export type SubscriptionOnDeleteMicrosoftSubscriptionArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionMicrosoftSubscriptionFilterInput>
}

export type SubscriptionOnDeleteNotificationArgs = {
    employeeID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionNotificationFilterInput>
}

export type SubscriptionOnDeleteOfficeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeFilterInput>
}

export type SubscriptionOnDeleteOfficeBuilderRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeBuilderRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnDeleteOfficeDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeDayAggregationFilterInput>
}

export type SubscriptionOnDeleteOfficeImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeImageFilterInput>
}

export type SubscriptionOnDeleteOfficeWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeWeekAggregationFilterInput>
}

export type SubscriptionOnDeletePermissionArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionPermissionFilterInput>
}

export type SubscriptionOnDeletePersonioImportedAbsencesArgs = {
    filter?: InputMaybe<ModelSubscriptionPersonioImportedAbsencesFilterInput>
}

export type SubscriptionOnDeletePersonioImportedBookingsArgs = {
    filter?: InputMaybe<ModelSubscriptionPersonioImportedBookingsFilterInput>
}

export type SubscriptionOnDeletePositionArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionPositionFilterInput>
}

export type SubscriptionOnDeleteReservableArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableFilterInput>
}

export type SubscriptionOnDeleteReservableImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableImageFilterInput>
}

export type SubscriptionOnDeleteReservableTagArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableTagFilterInput>
}

export type SubscriptionOnDeleteReservableToDepartmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableToDepartmentFilterInput>
}

export type SubscriptionOnDeleteReservableToEmployeeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableToEmployeeFilterInput>
}

export type SubscriptionOnDeleteRoomDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionRoomDayAggregationFilterInput>
}

export type SubscriptionOnDeleteRoomWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionRoomWeekAggregationFilterInput>
}

export type SubscriptionOnDeleteSlackOAuthStateArgs = {
    filter?: InputMaybe<ModelSubscriptionSlackOAuthStateFilterInput>
}

export type SubscriptionOnDeleteSlackOnboradingMessageArgs = {
    filter?: InputMaybe<ModelSubscriptionSlackOnboradingMessageFilterInput>
}

export type SubscriptionOnDeleteSpaceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceFilterInput>
}

export type SubscriptionOnDeleteSpaceCapacityDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceCapacityDayAggregationFilterInput>
}

export type SubscriptionOnDeleteSpaceDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceDayAggregationFilterInput>
}

export type SubscriptionOnDeleteSpaceRowArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceRowFilterInput>
}

export type SubscriptionOnDeleteSpaceWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceWeekAggregationFilterInput>
}

export type SubscriptionOnDeleteSuggestedSeatArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSuggestedSeatFilterInput>
}

export type SubscriptionOnDeleteTagArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionTagFilterInput>
}

export type SubscriptionOnDeleteTeamEventRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionTeamEventRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnLogPersonioIntegrationExecutionResultArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnUpdateAddressArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionAddressFilterInput>
}

export type SubscriptionOnUpdateBillingSubscriptionArgs = {
    companyId: Scalars['String']['input']
}

export type SubscriptionOnUpdateBillingTrialArgs = {
    companyId: Scalars['String']['input']
}

export type SubscriptionOnUpdateBookingArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateBookingByDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnUpdateBookingByEmployeeIdArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
    weekNumber?: InputMaybe<Scalars['Int']['input']>
}

export type SubscriptionOnUpdateBookingByReservableArgs = {
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}

export type SubscriptionOnUpdateBookingBySpaceArgs = {
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnUpdateBookingBySpaceAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnUpdateBookingCancellationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingCancellationFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateBookingEquipmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingEquipmentFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateBookingEquipmentByCompanyAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnUpdateBookingEquipmentByCompanyAndWeekAndYearArgs = {
    companyID: Scalars['String']['input']
    isoWeekNumber: Scalars['Int']['input']
    year: Scalars['Int']['input']
}

export type SubscriptionOnUpdateBookingRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateBookingReservableArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionBookingReservableFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateCalendarEventByCompanyArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnUpdateCalendarEventByCompanyAndDateArgs = {
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}

export type SubscriptionOnUpdateCalendarEventByReservableArgs = {
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}

export type SubscriptionOnUpdateCalendarEventBySpaceArgs = {
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnUpdateCalendarEventEmployeeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventEmployeeFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateCalendarEventEmployeeByEmployeeArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
}

export type SubscriptionOnUpdateCalendarEventEmployeeByEmployeeAndWeekArgs = {
    companyID: Scalars['String']['input']
    employeeID: Scalars['String']['input']
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
}

export type SubscriptionOnUpdateCalendarEventRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateCalendarEventsArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCalendarEventsFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateCateringArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCateringFilterInput>
}

export type SubscriptionOnUpdateCompanyArgs = {
    filter?: InputMaybe<ModelSubscriptionCompanyFilterInput>
    id?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateCompanyDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCompanyDayAggregationFilterInput>
}

export type SubscriptionOnUpdateCompanyWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCompanyWeekAggregationFilterInput>
}

export type SubscriptionOnUpdateCustomRoleArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionCustomRoleFilterInput>
}

export type SubscriptionOnUpdateDepartmentArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnUpdateDepartmentDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentDayAggregationFilterInput>
}

export type SubscriptionOnUpdateDepartmentDayAggregationByWeekArgs = {
    companyID: Scalars['String']['input']
    weekNumber: Scalars['Int']['input']
}

export type SubscriptionOnUpdateDepartmentDayAggregationEmployeesArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentDayAggregationEmployeesFilterInput>
}

export type SubscriptionOnUpdateDepartmentLastMonthAttendanceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentLastMonthAttendanceFilterInput>
}

export type SubscriptionOnUpdateDepartmentRulesArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentRulesFilterInput>
}

export type SubscriptionOnUpdateDepartmentWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionDepartmentWeekAggregationFilterInput>
}

export type SubscriptionOnUpdateEmployeeArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnUpdateEmployeeByIdArgs = {
    companyID: Scalars['String']['input']
    id: Scalars['String']['input']
}

export type SubscriptionOnUpdateEmployeeDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeDayAggregationFilterInput>
}

export type SubscriptionOnUpdateEmployeeLastMonthAttendanceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeLastMonthAttendanceFilterInput>
}

export type SubscriptionOnUpdateEmployeeToDepartmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeToDepartmentFilterInput>
}

export type SubscriptionOnUpdateEmployeeWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEmployeeWeekAggregationFilterInput>
}

export type SubscriptionOnUpdateEquipmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEquipmentFilterInput>
}

export type SubscriptionOnUpdateEquipmentImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionEquipmentImageFilterInput>
}

export type SubscriptionOnUpdateGoogleImportedBookingsArgs = {
    filter?: InputMaybe<ModelSubscriptionGoogleImportedBookingsFilterInput>
}

export type SubscriptionOnUpdateGoogleImportedWorkingLocationsArgs = {
    filter?: InputMaybe<ModelSubscriptionGoogleImportedWorkingLocationsFilterInput>
}

export type SubscriptionOnUpdateIntegrationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionIntegrationFilterInput>
}

export type SubscriptionOnUpdateMenuItemArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionMenuItemFilterInput>
}

export type SubscriptionOnUpdateMicrosoftSubscriptionArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionMicrosoftSubscriptionFilterInput>
}

export type SubscriptionOnUpdateNotificationArgs = {
    employeeID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionNotificationFilterInput>
}

export type SubscriptionOnUpdateOfficeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeFilterInput>
}

export type SubscriptionOnUpdateOfficeBuilderRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeBuilderRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateOfficeDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeDayAggregationFilterInput>
}

export type SubscriptionOnUpdateOfficeDayAggregationByWeekAndOfficeArgs = {
    companyID: Scalars['String']['input']
    officeID: Scalars['String']['input']
    weekNumber: Scalars['Int']['input']
}

export type SubscriptionOnUpdateOfficeImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeImageFilterInput>
}

export type SubscriptionOnUpdateOfficeWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionOfficeWeekAggregationFilterInput>
}

export type SubscriptionOnUpdatePermissionArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionPermissionFilterInput>
}

export type SubscriptionOnUpdatePersonioImportedAbsencesArgs = {
    filter?: InputMaybe<ModelSubscriptionPersonioImportedAbsencesFilterInput>
}

export type SubscriptionOnUpdatePersonioImportedBookingsArgs = {
    filter?: InputMaybe<ModelSubscriptionPersonioImportedBookingsFilterInput>
}

export type SubscriptionOnUpdatePositionArgs = {
    companyID: Scalars['String']['input']
}

export type SubscriptionOnUpdateReservableArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableFilterInput>
}

export type SubscriptionOnUpdateReservableImageArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableImageFilterInput>
}

export type SubscriptionOnUpdateReservableTagArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableTagFilterInput>
}

export type SubscriptionOnUpdateReservableToDepartmentArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableToDepartmentFilterInput>
}

export type SubscriptionOnUpdateReservableToEmployeeArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionReservableToEmployeeFilterInput>
}

export type SubscriptionOnUpdateRoomDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionRoomDayAggregationFilterInput>
}

export type SubscriptionOnUpdateRoomWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionRoomWeekAggregationFilterInput>
}

export type SubscriptionOnUpdateRulesArgs = {
    filter?: InputMaybe<ModelSubscriptionRulesFilterInput>
    id?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdateSlackOAuthStateArgs = {
    filter?: InputMaybe<ModelSubscriptionSlackOAuthStateFilterInput>
}

export type SubscriptionOnUpdateSlackOnboradingMessageArgs = {
    filter?: InputMaybe<ModelSubscriptionSlackOnboradingMessageFilterInput>
}

export type SubscriptionOnUpdateSpaceArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceFilterInput>
}

export type SubscriptionOnUpdateSpaceCapacityDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceCapacityDayAggregationFilterInput>
}

export type SubscriptionOnUpdateSpaceDayAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceDayAggregationFilterInput>
}

export type SubscriptionOnUpdateSpaceDayAggregationBySpaceArgs = {
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}

export type SubscriptionOnUpdateSpaceRowArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceRowFilterInput>
}

export type SubscriptionOnUpdateSpaceWeekAggregationArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSpaceWeekAggregationFilterInput>
}

export type SubscriptionOnUpdateSuggestedSeatArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionSuggestedSeatFilterInput>
}

export type SubscriptionOnUpdateTagArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionTagFilterInput>
}

export type SubscriptionOnUpdateTeamEventRequestArgs = {
    companyID?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelSubscriptionTeamEventRequestFilterInput>
    owner?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionOnUpdatedBambooHrIntegrationArgs = {
    companyId: Scalars['String']['input']
}

export type SuggestedSeat = {
    __typename?: 'SuggestedSeat'
    Company?: Maybe<Company>
    Employee?: Maybe<Employee>
    Reservable?: Maybe<Reservable>
    Space?: Maybe<Space>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    date: Scalars['AWSDate']['output']
    employeeID: Scalars['ID']['output']
    id: Scalars['ID']['output']
    reason: SuggestedSeatReason
    reservableID: Scalars['ID']['output']
    spaceID?: Maybe<Scalars['ID']['output']>
    suggestedSeatCompanyId?: Maybe<Scalars['ID']['output']>
    suggestedSeatEmployeeId?: Maybe<Scalars['ID']['output']>
    suggestedSeatReservableId?: Maybe<Scalars['ID']['output']>
    suggestedSeatSpaceId?: Maybe<Scalars['ID']['output']>
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export enum SuggestedSeatReason {
    ANY = 'ANY',
    CLOSEST = 'CLOSEST',
    DEPARTMENT = 'DEPARTMENT',
    FAVOURITE = 'FAVOURITE',
    HISTORY = 'HISTORY',
    OFFICE = 'OFFICE',
    PERSONAL = 'PERSONAL',
}

export enum SyncSource {
    BAMBOOHR = 'BAMBOOHR',
    PERSONIO = 'PERSONIO',
    SCIM = 'SCIM',
    SCIM_AD = 'SCIM_AD',
    SCIM_GOOGLE = 'SCIM_GOOGLE',
    SSO_OIDC = 'SSO_OIDC',
    SSO_SAML = 'SSO_SAML',
    UNDEFINED = 'UNDEFINED',
}

export enum SyncStatus {
    FAILED = 'FAILED',
    FIRST_TIME_SYNC_FAILED = 'FIRST_TIME_SYNC_FAILED',
    NO_SYNC_STARTED = 'NO_SYNC_STARTED',
    SUCCESS = 'SUCCESS',
    SYNCING = 'SYNCING',
}

export type SyncUserEvent = {
    __typename?: 'SyncUserEvent'
    action?: Maybe<SyncUserEventAction>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    department?: Maybe<Scalars['String']['output']>
    email: Scalars['String']['output']
    externalId?: Maybe<Scalars['String']['output']>
    firstname: Scalars['String']['output']
    hasInvitationEnabled?: Maybe<Scalars['Boolean']['output']>
    id: Scalars['ID']['output']
    lastname: Scalars['String']['output']
    office?: Maybe<Scalars['String']['output']>
    position?: Maybe<Scalars['String']['output']>
    profilePicS3key?: Maybe<Scalars['String']['output']>
    shouldEmailInvitation?: Maybe<Scalars['Boolean']['output']>
    source?: Maybe<SyncSource>
    updatedAt: Scalars['AWSDateTime']['output']
}

export enum SyncUserEventAction {
    REMOVE = 'REMOVE',
    UPSERT = 'UPSERT',
}

export type SynchronousSyncUserEventInput = {
    action?: InputMaybe<SyncUserEventAction>
    companyID: Scalars['ID']['input']
    department?: InputMaybe<Scalars['String']['input']>
    email: Scalars['String']['input']
    externalId?: InputMaybe<Scalars['String']['input']>
    firstname: Scalars['String']['input']
    hasInvitationEnabled?: InputMaybe<Scalars['Boolean']['input']>
    lastname: Scalars['String']['input']
    office?: InputMaybe<Scalars['String']['input']>
    position?: InputMaybe<Scalars['String']['input']>
    profilePicS3key?: InputMaybe<Scalars['String']['input']>
    role?: InputMaybe<Role>
    shouldEmailInvitation?: InputMaybe<Scalars['Boolean']['input']>
    source?: InputMaybe<SyncSource>
}

export type Tag = {
    __typename?: 'Tag'
    Company: Company
    Reservables?: Maybe<ModelReservableTagConnection>
    Space?: Maybe<Space>
    companyID: Scalars['ID']['output']
    createdAt: Scalars['AWSDateTime']['output']
    id: Scalars['ID']['output']
    name: Scalars['String']['output']
    reservableType?: Maybe<ReservableType>
    spaceID?: Maybe<Scalars['ID']['output']>
    tagCompanyId: Scalars['ID']['output']
    tagSpaceId?: Maybe<Scalars['ID']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type TagReservablesArgs = {
    filter?: InputMaybe<ModelReservableTagFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    reservableID?: InputMaybe<ModelIdKeyConditionInput>
    sortDirection?: InputMaybe<ModelSortDirection>
}

export enum Tariff {
    FREE = 'FREE',
    STANDARD = 'STANDARD',
    SUBSCRIPTION_EXPIRED = 'SUBSCRIPTION_EXPIRED',
    TRIAL = 'TRIAL',
    TRIAL_EXPIRED = 'TRIAL_EXPIRED',
}

export type TariffUpdatedInput = {
    companyId: Scalars['ID']['input']
    tariff: Tariff
}

export type TeamEventRequest = {
    __typename?: 'TeamEventRequest'
    Bookings?: Maybe<ModelBookingConnection>
    Reservable?: Maybe<Reservable>
    Space?: Maybe<Space>
    companyID: Scalars['ID']['output']
    createdAt?: Maybe<Scalars['AWSDateTime']['output']>
    date: Scalars['AWSDate']['output']
    dates?: Maybe<Array<Scalars['AWSDate']['output']>>
    daysOfWeek?: Maybe<Array<Scalars['String']['output']>>
    description?: Maybe<Scalars['String']['output']>
    employeeIDs: Array<Scalars['ID']['output']>
    endTime?: Maybe<Scalars['AWSDateTime']['output']>
    id: Scalars['ID']['output']
    owner?: Maybe<Scalars['ID']['output']>
    repeatType?: Maybe<RepeatType>
    reservableID?: Maybe<Scalars['ID']['output']>
    spaceID: Scalars['ID']['output']
    startTime?: Maybe<Scalars['AWSDateTime']['output']>
    status?: Maybe<TeamEventRequestStatus>
    teamEventRequestSpaceId?: Maybe<Scalars['ID']['output']>
    title: Scalars['String']['output']
    ttl?: Maybe<Scalars['Int']['output']>
    updatedAt: Scalars['AWSDateTime']['output']
}

export type TeamEventRequestBookingsArgs = {
    filter?: InputMaybe<ModelBookingFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortDirection?: InputMaybe<ModelSortDirection>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
}

export enum TeamEventRequestStatus {
    CREATED = 'CREATED',
    ERROR = 'ERROR',
}

export type TrialBilling = BillingSubscription & {
    __typename?: 'TrialBilling'
    companyId: Scalars['String']['output']
    expiresAt: Scalars['AWSDateTime']['output']
    status: BillingSubscriptionStatus
    subscriptionPlanId: Scalars['String']['output']
}

export type TrialBillingOutput = {
    __typename?: 'TrialBillingOutput'
    companyId: Scalars['String']['output']
    expiresAt: Scalars['AWSDateTime']['output']
    status: BillingSubscriptionStatus
    subscriptionPlanId: Scalars['String']['output']
}

export type UpdateAddressInput = {
    address?: InputMaybe<Scalars['String']['input']>
    addressCompanyId?: InputMaybe<Scalars['ID']['input']>
    city?: InputMaybe<Scalars['String']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    lat?: InputMaybe<Scalars['Float']['input']>
    long?: InputMaybe<Scalars['String']['input']>
    zipcode?: InputMaybe<Scalars['String']['input']>
}

export type UpdateBookingCancellationInput = {
    bookingCancellationBookingId?: InputMaybe<Scalars['ID']['input']>
    bookingCancellationCompanyId?: InputMaybe<Scalars['ID']['input']>
    bookingCancellationEmployeeId?: InputMaybe<Scalars['ID']['input']>
    bookingID?: InputMaybe<Scalars['ID']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    owner?: InputMaybe<Scalars['ID']['input']>
    removeRepeatable?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateBookingEquipmentInput = {
    calendarEventID?: InputMaybe<Scalars['ID']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    endTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    equipmentID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    isAllDay?: InputMaybe<Scalars['Boolean']['input']>
    isPrivate?: InputMaybe<Scalars['Boolean']['input']>
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<BookingEquipmentStatus>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateBookingInput = {
    bookingBookingRequestId?: InputMaybe<Scalars['ID']['input']>
    bookingCompanyId?: InputMaybe<Scalars['ID']['input']>
    bookingEmployeeId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestID?: InputMaybe<Scalars['ID']['input']>
    bookingReservableId?: InputMaybe<Scalars['ID']['input']>
    bookingSpaceId?: InputMaybe<Scalars['ID']['input']>
    bookingSubType?: InputMaybe<BookingSubType>
    bookingTeamEventRequestId?: InputMaybe<Scalars['ID']['input']>
    bookingType?: InputMaybe<BookingType>
    checkInStatus?: InputMaybe<BookingCheckInStatus>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    dayOfWeek?: InputMaybe<Scalars['String']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    endBookingTime?: InputMaybe<Scalars['AWSTime']['input']>
    endTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    id: Scalars['ID']['input']
    isBlocked?: InputMaybe<Scalars['Boolean']['input']>
    isFullDay?: InputMaybe<Scalars['Boolean']['input']>
    isTeamEvent?: InputMaybe<Scalars['Boolean']['input']>
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    slackCheckInMessageId?: InputMaybe<Scalars['String']['input']>
    source?: InputMaybe<BookingSource>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startBookingTime?: InputMaybe<Scalars['AWSTime']['input']>
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<BookingStatus>
    teamEventRequestID?: InputMaybe<Scalars['ID']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateBookingRequestInput = {
    addParkingSlot?: InputMaybe<Scalars['Boolean']['input']>
    bookingRequestCompanyId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestEmployeeId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestPreviousBookingRequestId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestReservableId?: InputMaybe<Scalars['ID']['input']>
    bookingRequestSpaceId?: InputMaybe<Scalars['ID']['input']>
    bookingSubType?: InputMaybe<BookingSubType>
    bookingType?: InputMaybe<BookingType>
    checkInStatus?: InputMaybe<BookingCheckInStatus>
    companyID?: InputMaybe<Scalars['ID']['input']>
    dates?: InputMaybe<Array<Scalars['AWSDate']['input']>>
    daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>
    effectiveFrom?: InputMaybe<Scalars['AWSDate']['input']>
    effectiveTo?: InputMaybe<Scalars['AWSDate']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    endTime?: InputMaybe<Scalars['AWSTime']['input']>
    id?: InputMaybe<Scalars['ID']['input']>
    isFullDay?: InputMaybe<Scalars['Boolean']['input']>
    isTeamEvent?: InputMaybe<Scalars['Boolean']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    parkingSlotID?: InputMaybe<Scalars['ID']['input']>
    previousBookingRequestID?: InputMaybe<Scalars['ID']['input']>
    repeatType?: InputMaybe<RepeatType>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    source?: InputMaybe<BookingSource>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startTime?: InputMaybe<Scalars['AWSTime']['input']>
    status?: InputMaybe<BookingRequestStatus>
    updateMode?: InputMaybe<UpdateMode>
    updatedBookingID?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateBookingReservableInput = {
    bookingID?: InputMaybe<Scalars['ID']['input']>
    bookingReservableCompanyId?: InputMaybe<Scalars['ID']['input']>
    checkInStatus?: InputMaybe<BookingCheckInStatus>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    dayOfWeek?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    endBookingTime?: InputMaybe<Scalars['AWSTime']['input']>
    endTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    id: Scalars['ID']['input']
    reservableID?: InputMaybe<Scalars['ID']['input']>
    startBookingTime?: InputMaybe<Scalars['AWSTime']['input']>
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateCalendarEventEmployeeInput = {
    calendarEventID?: InputMaybe<Scalars['ID']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    employeeFullName?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    isOwner?: InputMaybe<Scalars['Boolean']['input']>
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateCalendarEventRequestInput = {
    calendarEventRequestCompanyId?: InputMaybe<Scalars['ID']['input']>
    calendarEventRequestPreviousCalendarEventRequestId?: InputMaybe<Scalars['ID']['input']>
    calendarEventRequestReservableId?: InputMaybe<Scalars['ID']['input']>
    calendarEventRequestSpaceId?: InputMaybe<Scalars['ID']['input']>
    cateringID?: InputMaybe<Scalars['ID']['input']>
    checkInStatus?: InputMaybe<CalendarEventCheckInStatus>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    dates?: InputMaybe<Array<Scalars['AWSDate']['input']>>
    daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>
    description?: InputMaybe<Scalars['String']['input']>
    effectiveFrom?: InputMaybe<Scalars['AWSDate']['input']>
    effectiveTo?: InputMaybe<Scalars['AWSDate']['input']>
    employeesIDs?: InputMaybe<Array<Scalars['ID']['input']>>
    endTime?: InputMaybe<Scalars['AWSTime']['input']>
    equipmentItems?: InputMaybe<Array<EquipmentItemModelInput>>
    id: Scalars['ID']['input']
    isAllDay?: InputMaybe<Scalars['Boolean']['input']>
    isOnlineMeeting?: InputMaybe<Scalars['Boolean']['input']>
    isPrivate?: InputMaybe<Scalars['Boolean']['input']>
    menuItems?: InputMaybe<Array<MenuItemModelInput>>
    onlineMeetingUrl?: InputMaybe<Scalars['String']['input']>
    organiserEmployeeID?: InputMaybe<Scalars['ID']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    previousCalendarEventRequestID?: InputMaybe<Scalars['ID']['input']>
    repeatType?: InputMaybe<RepeatType>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    source?: InputMaybe<CalendarEventSource>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startTime?: InputMaybe<Scalars['AWSTime']['input']>
    status?: InputMaybe<CalendarEventRequestStatus>
    title?: InputMaybe<Scalars['String']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    useRelatedReservable?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateCalendarEventsInput = {
    calendarEventRequestID?: InputMaybe<Scalars['ID']['input']>
    canCurrentUserEdit?: InputMaybe<Scalars['Boolean']['input']>
    cateringID?: InputMaybe<Scalars['ID']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['String']['input']>
    dayOfMonth?: InputMaybe<Scalars['Int']['input']>
    daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    description?: InputMaybe<Scalars['String']['input']>
    employeesIDs?: InputMaybe<Array<Scalars['ID']['input']>>
    endTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    equipmentItems?: InputMaybe<Array<EquipmentItemModelInput>>
    firstDayOfWeek?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    interval?: InputMaybe<Scalars['Int']['input']>
    isAllDay?: InputMaybe<Scalars['Boolean']['input']>
    isOnlineMeeting?: InputMaybe<Scalars['Boolean']['input']>
    isPrivate?: InputMaybe<Scalars['Boolean']['input']>
    menuItems?: InputMaybe<Array<MenuItemModelInput>>
    month?: InputMaybe<Scalars['Int']['input']>
    numberOfOccurrences?: InputMaybe<Scalars['Int']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    onlineMeetingUrl?: InputMaybe<Scalars['String']['input']>
    organiserEmployeeID?: InputMaybe<Scalars['ID']['input']>
    owner?: InputMaybe<Scalars['ID']['input']>
    parentEventExternalId?: InputMaybe<Scalars['String']['input']>
    patternType?: InputMaybe<RecurrencePatternType>
    rangeEndDate?: InputMaybe<Scalars['AWSDate']['input']>
    rangeStartDate?: InputMaybe<Scalars['AWSDate']['input']>
    rangeType?: InputMaybe<RecurrenceRangeType>
    recurrenceTimeZone?: InputMaybe<Scalars['String']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    source?: InputMaybe<CalendarEventSource>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<CalendarEventStatus>
    title?: InputMaybe<Scalars['String']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    useRelatedReservable?: InputMaybe<Scalars['Boolean']['input']>
    weekIndex?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCateringInput = {
    cateringCompanyId?: InputMaybe<Scalars['ID']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    id: Scalars['ID']['input']
    officeID?: InputMaybe<Scalars['ID']['input']>
    title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCompanyDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeesInOffice?: InputMaybe<Scalars['Int']['input']>
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeCapacityLast?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateCompanyInput = {
    address?: InputMaybe<Scalars['String']['input']>
    calendarIntegration?: InputMaybe<CalendarType>
    city?: InputMaybe<Scalars['String']['input']>
    companyLogo?: InputMaybe<Scalars['String']['input']>
    companyRulesId?: InputMaybe<Scalars['ID']['input']>
    country?: InputMaybe<Scalars['String']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    id: Scalars['ID']['input']
    microsoftTenantId?: InputMaybe<Scalars['String']['input']>
    numberOfEmployees?: InputMaybe<Scalars['Int']['input']>
    numberOfOffices?: InputMaybe<Scalars['Int']['input']>
    phoneNumber?: InputMaybe<Scalars['String']['input']>
    ssoIntegration?: InputMaybe<SsoType>
    tariff?: InputMaybe<Tariff>
    title?: InputMaybe<Scalars['String']['input']>
    trialExpiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    userProvisioningIntegration?: InputMaybe<SyncSource>
    zipcode?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCompanyWeekAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsDistribution?: InputMaybe<Array<DashboardWeekAggregationBookingsDistributionInput>>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    companyWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeesInOffice?: InputMaybe<Scalars['Int']['input']>
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeCapacityMax?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateCustomRoleInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateDepartmentDayAggregationEmployeesInput = {
    departmentDayAggregationID?: InputMaybe<Scalars['ID']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
}

export type UpdateDepartmentDayAggregationInput = {
    averageDaysInOffice?: InputMaybe<Scalars['Float']['input']>
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    departmentDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    notAvailableBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    teamSize?: InputMaybe<Scalars['Int']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateDepartmentInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    departmentCompanyId?: InputMaybe<Scalars['ID']['input']>
    departmentTeamLeadId?: InputMaybe<Scalars['ID']['input']>
    employeeIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    id: Scalars['ID']['input']
    name?: InputMaybe<Scalars['String']['input']>
    size?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateDepartmentLastMonthAttendanceInput = {
    attendanceScore?: InputMaybe<Scalars['Int']['input']>
    attendanceScoreString?: InputMaybe<Scalars['String']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    departmentLastMonthAttendanceCompanyId?: InputMaybe<Scalars['ID']['input']>
    departmentLastMonthAttendanceDepartmentId?: InputMaybe<Scalars['ID']['input']>
    departmentNameLowerCase?: InputMaybe<Scalars['String']['input']>
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    lastDateTogether?: InputMaybe<Scalars['AWSDate']['input']>
    lastDateTogetherForSorting?: InputMaybe<Scalars['AWSDate']['input']>
    monthlyAttendance?: InputMaybe<Scalars['Float']['input']>
    monthlyAttendanceString?: InputMaybe<Scalars['String']['input']>
    officeDaysScore?: InputMaybe<Scalars['Int']['input']>
    officeDaysScoreString?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weeklyAttendance?: InputMaybe<Scalars['Float']['input']>
    weeklyAttendanceString?: InputMaybe<Scalars['String']['input']>
}

export type UpdateDepartmentRulesInput = {
    blockReservationOnNonOfficeDays?: InputMaybe<Scalars['Boolean']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    enableMinDaysInOffice?: InputMaybe<Scalars['Boolean']['input']>
    horizonOfPlanningInDays?: InputMaybe<Scalars['Int']['input']>
    horizonOfPlanningTimeAmount?: InputMaybe<Scalars['Int']['input']>
    horizonOfPlanningTimeUnit?: InputMaybe<HorizonOfPlanningTimeUnit>
    id: Scalars['ID']['input']
    maxDaysInOffice?: InputMaybe<Scalars['Int']['input']>
    minDaysInOffice?: InputMaybe<Scalars['Int']['input']>
    minDaysInOfficeTimeUnit?: InputMaybe<MinDaysInOfficeTimeUnit>
    officeDays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type UpdateDepartmentWeekAggregationInput = {
    averageDaysInOffice?: InputMaybe<Scalars['Float']['input']>
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsDistribution?: InputMaybe<Array<DashboardWeekAggregationBookingsDistributionInput>>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    departmentWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeesInOffice?: InputMaybe<Scalars['Int']['input']>
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    notAvailableBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    teamSize?: InputMaybe<Scalars['Int']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateEmployeeDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsByHoursDistribution?: InputMaybe<Array<EmployeeAggregationAttendanceByHoursDistributionInput>>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeeDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    notAvailableBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateEmployeeInput = {
    active?: InputMaybe<Scalars['Boolean']['input']>
    birthday?: InputMaybe<Scalars['AWSDate']['input']>
    buddyID?: InputMaybe<Scalars['ID']['input']>
    cognitoUserId?: InputMaybe<Scalars['ID']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    defaultPlan?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    demoAccountID?: InputMaybe<Scalars['ID']['input']>
    departmentIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    departmentIDsString?: InputMaybe<Scalars['String']['input']>
    email?: InputMaybe<Scalars['AWSEmail']['input']>
    emailForSearch?: InputMaybe<Scalars['String']['input']>
    employeeBuddyID?: InputMaybe<Scalars['ID']['input']>
    employeeCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeeDemoAccountId?: InputMaybe<Scalars['ID']['input']>
    employeeDepartmentIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    employeeFavouriteColleagueIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    employeeFavouriteReservableID?: InputMaybe<Scalars['ID']['input']>
    employeeOfficeId?: InputMaybe<Scalars['ID']['input']>
    employeePositionId?: InputMaybe<Scalars['ID']['input']>
    externalID?: InputMaybe<Scalars['String']['input']>
    favouriteColleagueIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    favouriteOfficeID?: InputMaybe<Scalars['ID']['input']>
    favouriteReservableID?: InputMaybe<Scalars['ID']['input']>
    firstname?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    lastname?: InputMaybe<Scalars['String']['input']>
    locale?: InputMaybe<UserLocale>
    notificationChannels?: InputMaybe<Array<InputMaybe<NotificationChannel>>>
    officeID?: InputMaybe<Scalars['ID']['input']>
    photo?: InputMaybe<Scalars['String']['input']>
    positionID?: InputMaybe<Scalars['ID']['input']>
    role?: InputMaybe<Role>
    shareAttendance?: InputMaybe<ShareAttendance>
    status?: InputMaybe<EmployeeStatus>
    turnOnExperimentalFeatures?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateEmployeeLastMonthAttendanceInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    departmentIDs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
    departmentIDsString?: InputMaybe<Scalars['String']['input']>
    departmentNamesString?: InputMaybe<Scalars['String']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    employeeLastMonthAttendanceCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeeLastMonthAttendanceDepartmentId?: InputMaybe<Scalars['ID']['input']>
    employeeLastMonthAttendanceEmployeeId?: InputMaybe<Scalars['ID']['input']>
    employeeNameLowerCase?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    lastDateInOffice?: InputMaybe<Scalars['AWSDate']['input']>
    lastDateInOfficeForSorting?: InputMaybe<Scalars['AWSDate']['input']>
    monthlyAttendance?: InputMaybe<Scalars['Float']['input']>
    monthlyAttendanceString?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    weeklyAttendance?: InputMaybe<Scalars['Float']['input']>
    weeklyAttendanceString?: InputMaybe<Scalars['String']['input']>
}

export type UpdateEmployeeToDepartmentInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    employeeToDepartmentCompanyId?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
}

export type UpdateEmployeeWeekAggregationInput = {
    averageDaysInOffice?: InputMaybe<Scalars['Float']['input']>
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsByHoursDistribution?: InputMaybe<Array<EmployeeAggregationAttendanceByHoursDistributionInput>>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeeWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    employeesInOffice?: InputMaybe<Scalars['Int']['input']>
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    notAvailableBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateEquipmentImageInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    equipmentID?: InputMaybe<Scalars['ID']['input']>
    equipmentImageCompanyId?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    isMain?: InputMaybe<Scalars['Boolean']['input']>
    location?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateEquipmentInput = {
    canBookedSeparately?: InputMaybe<Scalars['Boolean']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    equipmentCategoryID?: InputMaybe<Scalars['ID']['input']>
    equipmentCompanyId?: InputMaybe<Scalars['ID']['input']>
    equipmentEquipmentCategoryId?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    name?: InputMaybe<Scalars['String']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    quantity?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateGoogleCalendarIntegration = {
    authType?: InputMaybe<Scalars['String']['input']>
    enableWorkingLocation?: InputMaybe<Scalars['Boolean']['input']>
    serviceEmail?: InputMaybe<Scalars['String']['input']>
}

export type UpdateGoogleImportedBookingsInput = {
    calendarEventID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    googleID?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    updated?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type UpdateGoogleImportedWorkingLocationsInput = {
    bookingID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    googleID?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    updated?: InputMaybe<Scalars['String']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type UpdateIntegrationInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    group?: InputMaybe<IntegrationGroup>
    id: Scalars['ID']['input']
    integrationCompanyId?: InputMaybe<Scalars['ID']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    status?: InputMaybe<IntegrationStatus>
    type?: InputMaybe<IntegrationType>
}

export type UpdateMenuItemInput = {
    cateringID?: InputMaybe<Scalars['ID']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    description?: InputMaybe<Scalars['String']['input']>
    enabled?: InputMaybe<Scalars['Boolean']['input']>
    id: Scalars['ID']['input']
    menuItemCompanyId?: InputMaybe<Scalars['ID']['input']>
    title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateMicrosoftCalendarIntegration = {
    companyId?: InputMaybe<Scalars['String']['input']>
    enableAbsenceSyncing?: InputMaybe<Scalars['Boolean']['input']>
    enableStatusSyncing?: InputMaybe<Scalars['Boolean']['input']>
    id: Scalars['ID']['input']
}

export type UpdateMicrosoftSubscriptionInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    id: Scalars['ID']['input']
    isFreeTrial?: InputMaybe<Scalars['Boolean']['input']>
    microsoftSubscriptionCompanyId?: InputMaybe<Scalars['ID']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    offerId?: InputMaybe<Scalars['String']['input']>
    ownerEmail?: InputMaybe<Scalars['String']['input']>
    ownerID?: InputMaybe<Scalars['ID']['input']>
    ownerName?: InputMaybe<Scalars['String']['input']>
    planId?: InputMaybe<Scalars['String']['input']>
    quantity?: InputMaybe<Scalars['Int']['input']>
    status?: InputMaybe<MicrosoftSubscriptionStatus>
    tenantId?: InputMaybe<Scalars['String']['input']>
}

export enum UpdateMode {
    CHANGE_ONCE = 'CHANGE_ONCE',
    CHANGE_SERIES = 'CHANGE_SERIES',
}

export type UpdateNotificationInput = {
    employeeID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    placeholders?: InputMaybe<Scalars['AWSJSON']['input']>
    sentAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<NotificationStatus>
    type?: InputMaybe<NotificationType>
}

export type UpdateOfficeBuilderRequestInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    id: Scalars['ID']['input']
    offices?: InputMaybe<Array<OfficeBuilderOfficeInput>>
    owner?: InputMaybe<Scalars['ID']['input']>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type UpdateOfficeDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeCapacityLast?: InputMaybe<Scalars['Int']['input']>
    officeDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    officeDayAggregationOfficeId?: InputMaybe<Scalars['ID']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    type?: InputMaybe<AggregationByReservableType>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateOfficeImageInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    location?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    officeImageCompanyId?: InputMaybe<Scalars['ID']['input']>
    officeImageOfficeId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateOfficeInput = {
    address?: InputMaybe<Scalars['String']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    externalOfficeId?: InputMaybe<Scalars['String']['input']>
    id: Scalars['ID']['input']
    name?: InputMaybe<Scalars['String']['input']>
    officeAddressId?: InputMaybe<Scalars['ID']['input']>
    officeCateringId?: InputMaybe<Scalars['ID']['input']>
    officeCompanyId?: InputMaybe<Scalars['ID']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    phoneNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
    placesBlocked?: InputMaybe<Scalars['Int']['input']>
    placesFixed?: InputMaybe<Scalars['Int']['input']>
    placesFlex?: InputMaybe<Scalars['Int']['input']>
    placesTotal?: InputMaybe<Scalars['Int']['input']>
    properties?: InputMaybe<Scalars['AWSJSON']['input']>
    shortDescription?: InputMaybe<Scalars['String']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    workingFrom?: InputMaybe<Scalars['AWSTime']['input']>
    workingHours?: InputMaybe<Scalars['AWSJSON']['input']>
    workingTo?: InputMaybe<Scalars['AWSTime']['input']>
}

export type UpdateOfficeWeekAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsDistribution?: InputMaybe<Array<DashboardWeekAggregationBookingsDistributionInput>>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    officeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    officeCapacityMax?: InputMaybe<Scalars['Int']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    officeWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    officeWeekAggregationOfficeId?: InputMaybe<Scalars['ID']['input']>
    percentageOfUtilization?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdatePermissionInput = {
    allowedActions?: InputMaybe<Array<ActionType>>
    companyID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    resourceType?: InputMaybe<ResourceType>
    roleID?: InputMaybe<Scalars['ID']['input']>
    scope?: InputMaybe<Scope>
}

export type UpdatePersonioImportedAbsencesInput = {
    bookingRequestID?: InputMaybe<Scalars['ID']['input']>
    bookingSubType?: InputMaybe<BookingSubType>
    bookingType?: InputMaybe<BookingType>
    companyID?: InputMaybe<Scalars['ID']['input']>
    effectiveFrom?: InputMaybe<Scalars['AWSDate']['input']>
    effectiveTo?: InputMaybe<Scalars['AWSDate']['input']>
    employeeEmail?: InputMaybe<Scalars['String']['input']>
    halfDayEnd?: InputMaybe<Scalars['Boolean']['input']>
    halfDayStart?: InputMaybe<Scalars['Boolean']['input']>
    id: Scalars['ID']['input']
    personioID?: InputMaybe<Scalars['Int']['input']>
    personioImportedAbsencesBookingRequestId?: InputMaybe<Scalars['ID']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
}

export type UpdatePersonioImportedBookingsInput = {
    bookingID?: InputMaybe<Scalars['ID']['input']>
    effectiveFrom?: InputMaybe<Scalars['AWSDate']['input']>
    id: Scalars['ID']['input']
    personioID?: InputMaybe<Scalars['String']['input']>
    personioImportedBookingsBookingId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdatePersonioIntegrationInput = {
    hasInvitationEnabled?: InputMaybe<Scalars['Boolean']['input']>
    hasSyncBookingsToPersonioEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdatePositionInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    name?: InputMaybe<Scalars['String']['input']>
    positionCompanyId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateReservableImageInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    isMain?: InputMaybe<Scalars['Boolean']['input']>
    location?: InputMaybe<Scalars['String']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    reservableImageCompanyId?: InputMaybe<Scalars['ID']['input']>
    reservableImageReservableId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateReservableInput = {
    adminID?: InputMaybe<Scalars['ID']['input']>
    availabilityByDatesAndTime?: InputMaybe<Scalars['Boolean']['input']>
    availabilityPreviewByDates?: InputMaybe<Array<AvailabilityItemPreviewElement>>
    availabilityType?: InputMaybe<AvailabilityType>
    color?: InputMaybe<Scalars['String']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    externalRoomID?: InputMaybe<Scalars['String']['input']>
    height?: InputMaybe<Scalars['Float']['input']>
    id: Scalars['ID']['input']
    isDefault?: InputMaybe<Scalars['String']['input']>
    maxAmountOfPeople?: InputMaybe<Scalars['Int']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    relatedReservableID?: InputMaybe<Scalars['ID']['input']>
    reservableCompanyId?: InputMaybe<Scalars['ID']['input']>
    reservableDepartmentId?: InputMaybe<Scalars['ID']['input']>
    reservableEmployeeId?: InputMaybe<Scalars['ID']['input']>
    reservableSpaceId?: InputMaybe<Scalars['ID']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    type?: InputMaybe<ReservableType>
    width?: InputMaybe<Scalars['Float']['input']>
    x?: InputMaybe<Scalars['Float']['input']>
    y?: InputMaybe<Scalars['Float']['input']>
    zonePoints?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
}

export type UpdateReservableTagInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    officeID?: InputMaybe<Scalars['ID']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    reservableTagCompanyId?: InputMaybe<Scalars['ID']['input']>
    reservableTagOfficeId?: InputMaybe<Scalars['ID']['input']>
    reservableTagSpaceId?: InputMaybe<Scalars['ID']['input']>
    reservableType?: InputMaybe<ReservableType>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    tagID?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateReservableToDepartmentInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    departmentID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    reservableID?: InputMaybe<Scalars['ID']['input']>
    reservableToDepartmentCompanyId?: InputMaybe<Scalars['ID']['input']>
    reservableToDepartmentDepartmentId?: InputMaybe<Scalars['ID']['input']>
    reservableToDepartmentReservableId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateReservableToEmployeeInput = {
    employeeID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    reservableID?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateRoomDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    dataByHour?: InputMaybe<Array<RoomDayAggregationBookingsByHoursDistributionInput>>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    id: Scalars['ID']['input']
    officeID?: InputMaybe<Scalars['ID']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    roomBookingsSum?: InputMaybe<Scalars['Int']['input']>
    roomCapacityLast?: InputMaybe<Scalars['Int']['input']>
    roomDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    roomNameLowerCase?: InputMaybe<Scalars['String']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    visitors?: InputMaybe<Scalars['Int']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateRoomWeekAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    id: Scalars['ID']['input']
    officeID?: InputMaybe<Scalars['ID']['input']>
    percentageOfUtilization?: InputMaybe<Scalars['Int']['input']>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    roomBookingsSum?: InputMaybe<Scalars['Int']['input']>
    roomCapacityMax?: InputMaybe<Scalars['Int']['input']>
    roomNameLowerCase?: InputMaybe<Scalars['String']['input']>
    roomWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    visitors?: InputMaybe<Scalars['Int']['input']>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateRulesInput = {
    autoAssignWaitingList?: InputMaybe<Scalars['Boolean']['input']>
    blockReservationOnNonWorkingDays?: InputMaybe<Scalars['Boolean']['input']>
    checkInCancellingTime?: InputMaybe<Scalars['Int']['input']>
    checkInWaitingTime?: InputMaybe<Scalars['Int']['input']>
    dayOfAttendanceNotifications?: InputMaybe<Scalars['Int']['input']>
    defaultShareAttendance?: InputMaybe<ShareAttendance>
    deleteDataAfter?: InputMaybe<Scalars['Int']['input']>
    deletePersonalDataAfter?: InputMaybe<Scalars['Int']['input']>
    denyRecurringBookingOverThePlanningHorizon?: InputMaybe<Scalars['Boolean']['input']>
    disableOnboardingQuiz?: InputMaybe<Scalars['Boolean']['input']>
    disableProfileEditing?: InputMaybe<Scalars['Boolean']['input']>
    emailForMonthlyReport?: InputMaybe<Scalars['String']['input']>
    enableMinDaysInOffice?: InputMaybe<Scalars['Boolean']['input']>
    homeIsDefault?: InputMaybe<Scalars['Boolean']['input']>
    horizonOfPlanningInDays?: InputMaybe<Scalars['Int']['input']>
    horizonOfPlanningTimeAmount?: InputMaybe<Scalars['Int']['input']>
    horizonOfPlanningTimeUnit?: InputMaybe<HorizonOfPlanningTimeUnit>
    hourOfAttendanceNotifications?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    isPersonalSeatAvailableToday?: InputMaybe<Scalars['Boolean']['input']>
    isTeamSeatAvailableToday?: InputMaybe<Scalars['Boolean']['input']>
    maxDaysInOffice?: InputMaybe<Scalars['Int']['input']>
    minDaysInOffice?: InputMaybe<Scalars['Int']['input']>
    minDaysInOfficeTimeUnit?: InputMaybe<MinDaysInOfficeTimeUnit>
    startTimeOfNotifications?: InputMaybe<Scalars['Int']['input']>
    turnOffCheckInForPersonalSeats?: InputMaybe<Scalars['Boolean']['input']>
    turnOnAttendanceNotifications?: InputMaybe<Scalars['Boolean']['input']>
    turnOnCheckIn?: InputMaybe<Scalars['Boolean']['input']>
    turnOnDeletingData?: InputMaybe<Scalars['Boolean']['input']>
    turnOnExperimentalFeatures?: InputMaybe<Scalars['Boolean']['input']>
    turnOnMonthlyReport?: InputMaybe<Scalars['Boolean']['input']>
    updateEmployeeDataFromSource?: InputMaybe<Scalars['Boolean']['input']>
    workingDays?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type UpdateSlackOAuthStateInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
}

export type UpdateSlackOnboradingMessageInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    slackMessageID?: InputMaybe<Scalars['String']['input']>
    slackOnboradingMessageCompanyId?: InputMaybe<Scalars['ID']['input']>
    slackOnboradingMessageEmployeeId?: InputMaybe<Scalars['ID']['input']>
    state?: InputMaybe<SlackOnboradingMessageState>
    updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type UpdateSpaceCapacityDayAggregationInput = {
    availableSeatsSum?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    spaceCapacityDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    spaceCapacityDayAggregationEmployeeId?: InputMaybe<Scalars['ID']['input']>
    spaceCapacityDayAggregationSpaceId?: InputMaybe<Scalars['ID']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<SpaceCapacityDayAggregationType>
    waitingListItemsSum?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateSpaceDayAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    dataByHour?: InputMaybe<Array<SpaceDayAggregationBookingsByHoursDistributionInput>>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    homeBookingsSum?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    officeID?: InputMaybe<Scalars['ID']['input']>
    spaceBookingsSum?: InputMaybe<Scalars['Int']['input']>
    spaceCapacityLast?: InputMaybe<Scalars['Int']['input']>
    spaceDayAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    type?: InputMaybe<AggregationByReservableType>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateSpaceInput = {
    address?: InputMaybe<Scalars['String']['input']>
    availableParkingSlots?: InputMaybe<Scalars['Int']['input']>
    availableParkingSlotsByPeriod?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    floorPlanFilePath?: InputMaybe<Scalars['String']['input']>
    getCapacity?: InputMaybe<SpaceCapacityInput>
    id: Scalars['ID']['input']
    isAvailableToEmployees?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
    spaceCompanyId?: InputMaybe<Scalars['ID']['input']>
    spaceOfficeId?: InputMaybe<Scalars['ID']['input']>
    spaceRowID?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatID?: InputMaybe<Scalars['ID']['input']>
    timeZone?: InputMaybe<Scalars['String']['input']>
    totalParkingSlots?: InputMaybe<Scalars['Int']['input']>
    totalRooms?: InputMaybe<Scalars['Int']['input']>
    totalSeats?: InputMaybe<Scalars['Int']['input']>
    workingHoursFrom?: InputMaybe<Scalars['AWSTime']['input']>
    workingHoursTo?: InputMaybe<Scalars['AWSTime']['input']>
}

export type UpdateSpaceRowInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    officeID?: InputMaybe<Scalars['ID']['input']>
    order?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateSpaceWeekAggregationInput = {
    bookedHours?: InputMaybe<Scalars['Int']['input']>
    bookingsDistribution?: InputMaybe<Array<DashboardWeekAggregationBookingsDistributionInput>>
    capacityInHours?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    employeesLast?: InputMaybe<Scalars['Int']['input']>
    id: Scalars['ID']['input']
    officeID?: InputMaybe<Scalars['ID']['input']>
    percentageOfUtilization?: InputMaybe<Scalars['Int']['input']>
    spaceBookingsSum?: InputMaybe<Scalars['Int']['input']>
    spaceCapacityMax?: InputMaybe<Scalars['Int']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    spaceWeekAggregationCompanyId?: InputMaybe<Scalars['ID']['input']>
    type?: InputMaybe<AggregationByReservableType>
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateSuggestedSeatInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    employeeID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    reason?: InputMaybe<SuggestedSeatReason>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatCompanyId?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatEmployeeId?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatReservableId?: InputMaybe<Scalars['ID']['input']>
    suggestedSeatSpaceId?: InputMaybe<Scalars['ID']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
}

export type UpdateTagInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    id: Scalars['ID']['input']
    name?: InputMaybe<Scalars['String']['input']>
    reservableType?: InputMaybe<ReservableType>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    tagCompanyId?: InputMaybe<Scalars['ID']['input']>
    tagSpaceId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateTeamEventRequestInput = {
    companyID?: InputMaybe<Scalars['ID']['input']>
    createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
    dates?: InputMaybe<Array<Scalars['AWSDate']['input']>>
    daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>
    description?: InputMaybe<Scalars['String']['input']>
    employeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>
    endTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    id: Scalars['ID']['input']
    owner?: InputMaybe<Scalars['ID']['input']>
    repeatType?: InputMaybe<RepeatType>
    reservableID?: InputMaybe<Scalars['ID']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
    startTime?: InputMaybe<Scalars['AWSDateTime']['input']>
    status?: InputMaybe<TeamEventRequestStatus>
    teamEventRequestSpaceId?: InputMaybe<Scalars['ID']['input']>
    title?: InputMaybe<Scalars['String']['input']>
    ttl?: InputMaybe<Scalars['Int']['input']>
}

export type UpdatedBambooHrIntegrationInput = {
    companyId: Scalars['String']['input']
}

export enum UserLocale {
    de = 'de',
    en = 'en',
    es = 'es',
    fr = 'fr',
}

export type UserProvisioningIntegration = {
    __typename?: 'UserProvisioningIntegration'
    companyId: Scalars['ID']['output']
    name: SyncSource
}

export type UserProvisioningIntegrationInput = {
    name: SyncSource
}

export type GetBookingByReservableIdQueryVariables = Exact<{
    reservableID: Scalars['ID']['input']
    employeeID: Scalars['ID']['input']
    startDate: Scalars['String']['input']
    spaceID: Scalars['ID']['input']
}>

export type GetBookingByReservableIdQuery = {
    __typename?: 'Query'
    listReservablesBySpaceAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{ __typename?: 'Reservable'; id: string } | null>
    } | null
    getReservable?: {
        __typename?: 'Reservable'
        Bookings?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                bookingRequestID?: string | null
                BookingRequest?: {
                    __typename?: 'BookingRequest'
                    repeatType?: RepeatType | null
                    daysOfWeek?: Array<string> | null
                    addParkingSlot?: boolean | null
                } | null
            } | null>
        } | null
    } | null
}

export type CreateBookingRequestMutationVariables = Exact<{
    input: CreateBookingRequestInput
}>

export type CreateBookingRequestMutation = {
    __typename?: 'Mutation'
    createBookingRequest?: {
        __typename?: 'BookingRequest'
        id: string
        startTime?: any | null
        endTime?: any | null
        owner?: string | null
        reservableID?: string | null
        spaceID?: string | null
        companyID: string
        employeeID: string
        addParkingSlot?: boolean | null
        parkingSlotID?: string | null
        repeatType?: RepeatType | null
        bookingType?: BookingType | null
        bookingSubType?: BookingSubType | null
        isFullDay?: boolean | null
        reservable?: {
            __typename?: 'Reservable'
            id: string
            type: ReservableType
            Space?: { __typename?: 'Space'; name?: string | null } | null
        } | null
    } | null
}

export type CreateBookingMutationVariables = Exact<{
    input: CreateBookingInput
}>

export type CreateBookingMutation = {
    __typename?: 'Mutation'
    createBooking?: {
        __typename?: 'Booking'
        id: string
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        status?: BookingStatus | null
        employeeID: string
        spaceID?: string | null
        companyID: string
        owner?: string | null
        isTeamEvent?: boolean | null
        isBlocked?: boolean | null
        reservable?: {
            __typename?: 'Reservable'
            id: string
            type: ReservableType
            Space?: { __typename?: 'Space'; name?: string | null } | null
        } | null
    } | null
}

export type ListOfficesByCompanyAndNameQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    employeeIDs?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
    date?: InputMaybe<Scalars['AWSDate']['input']>
}>

export type ListOfficesByCompanyAndNameQuery = {
    __typename?: 'Query'
    listOfficesByCompanyAndName?: {
        __typename?: 'ModelOfficeConnection'
        items: Array<{
            __typename?: 'Office'
            id: string
            name: string
            workingFrom?: any | null
            workingTo?: any | null
            SpaceRows?: {
                __typename?: 'ModelSpaceRowConnection'
                items: Array<{
                    __typename?: 'SpaceRow'
                    id: string
                    Spaces?: {
                        __typename?: 'ModelSpaceConnection'
                        items: Array<{
                            __typename?: 'Space'
                            id: string
                            name?: string | null
                            isAvailableToEmployees?: boolean | null
                            floorPlanFilePath?: string | null
                            workingHoursFrom?: any | null
                            workingHoursTo?: any | null
                            totalSeats?: number | null
                            availableParkingSlots?: number | null
                            totalRooms?: number | null
                            officeID: string
                            Office?: {
                                __typename?: 'Office'
                                id: string
                                name: string
                                timeZone?: string | null
                            } | null
                        } | null>
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetCalendarHeadDataQueryVariables = Exact<{
    id: Scalars['ID']['input']
    weekNumber: Scalars['Int']['input']
    year: Scalars['Int']['input']
}>

export type GetCalendarHeadDataQuery = {
    __typename?: 'Query'
    getEmployee?: {
        __typename?: 'Employee'
        favouriteReservableID?: string | null
        favouriteOfficeID?: string | null
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
        Company?: {
            __typename?: 'Company'
            Offices?: {
                __typename?: 'ModelOfficeConnection'
                items: Array<{ __typename?: 'Office'; id: string; name: string; address?: string | null } | null>
            } | null
        } | null
        BookingsByWeek?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                reservableID?: string | null
                isBlocked?: boolean | null
                isFullDay?: boolean | null
                bookingType?: BookingType | null
                bookingSubType?: BookingSubType | null
                isTeamEvent?: boolean | null
                Space?: {
                    __typename?: 'Space'
                    id: string
                    name?: string | null
                    floorPlanFilePath?: string | null
                    workingHoursFrom?: any | null
                    workingHoursTo?: any | null
                    officeID: string
                    Office?: { __typename?: 'Office'; id: string; name: string } | null
                } | null
                reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    type: ReservableType
                    name?: string | null
                    x?: number | null
                    y?: number | null
                    spaceID?: string | null
                } | null
                BookingRequest?: {
                    __typename?: 'BookingRequest'
                    id: string
                    repeatType?: RepeatType | null
                    addParkingSlot?: boolean | null
                    daysOfWeek?: Array<string> | null
                    ParkingSlot?: {
                        __typename?: 'Reservable'
                        name?: string | null
                        Space?: {
                            __typename?: 'Space'
                            name?: string | null
                            id: string
                            Office?: { __typename?: 'Office'; name: string; id: string } | null
                        } | null
                    } | null
                } | null
                reservables?: {
                    __typename?: 'ModelBookingReservableConnection'
                    items: Array<{
                        __typename?: 'BookingReservable'
                        id: string
                        reservable?: {
                            __typename?: 'Reservable'
                            id: string
                            name?: string | null
                            type: ReservableType
                            Space?: {
                                __typename?: 'Space'
                                id: string
                                name?: string | null
                                Office?: { __typename?: 'Office'; id: string; name: string } | null
                            } | null
                        } | null
                    } | null>
                } | null
                TeamEventRequest?: {
                    __typename?: 'TeamEventRequest'
                    id: string
                    repeatType?: RepeatType | null
                    daysOfWeek?: Array<string> | null
                } | null
            } | null>
        } | null
        CalendarEventsByWeek?: {
            __typename?: 'ModelCalendarEventEmployeeConnection'
            items: Array<{
                __typename?: 'CalendarEventEmployee'
                CalendarEvent?: {
                    __typename?: 'CalendarEvents'
                    title?: string | null
                    createdAt?: any | null
                    owner?: string | null
                    companyID: string
                    id: string
                    dayOfMonth?: number | null
                    daysOfWeek?: Array<string | null> | null
                    description?: string | null
                    endTime: any
                    startTime: any
                    status?: CalendarEventStatus | null
                    onlineMeetingUrl?: string | null
                    isPrivate?: boolean | null
                    employeesIDs: Array<string>
                    organiserEmployeeID?: string | null
                    Reservable?: {
                        __typename?: 'Reservable'
                        id: string
                        companyID: string
                        name?: string | null
                        availabilityType: AvailabilityType
                        type: ReservableType
                        x?: number | null
                        y?: number | null
                        Space?: {
                            __typename?: 'Space'
                            name?: string | null
                            companyID: string
                            id: string
                            address?: string | null
                            Office?: { __typename?: 'Office'; name: string; address?: string | null; id: string } | null
                        } | null
                    } | null
                    Employees?: {
                        __typename?: 'ModelCalendarEventEmployeeConnection'
                        items: Array<{
                            __typename?: 'CalendarEventEmployee'
                            id: string
                            companyID: string
                            employeeFullName?: string | null
                            weekNumber?: number | null
                            year?: number | null
                            createdAt?: any | null
                            owner?: string | null
                        } | null>
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type BookingFieldsForCalendarFragment = {
    __typename?: 'Booking'
    id: string
    startTime?: any | null
    endTime?: any | null
    reservableID?: string | null
    isBlocked?: boolean | null
    isFullDay?: boolean | null
    bookingType?: BookingType | null
    bookingSubType?: BookingSubType | null
    isTeamEvent?: boolean | null
    checkInStatus?: BookingCheckInStatus | null
    Employee?: {
        __typename?: 'Employee'
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
    } | null
    Space?: {
        __typename?: 'Space'
        id: string
        name?: string | null
        floorPlanFilePath?: string | null
        workingHoursFrom?: any | null
        workingHoursTo?: any | null
        officeID: string
        Office?: { __typename?: 'Office'; id: string; name: string } | null
    } | null
    reservable?: {
        __typename?: 'Reservable'
        id: string
        type: ReservableType
        x?: number | null
        y?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
    } | null
    BookingRequest?: {
        __typename?: 'BookingRequest'
        id: string
        repeatType?: RepeatType | null
        addParkingSlot?: boolean | null
        daysOfWeek?: Array<string> | null
        ParkingSlot?: {
            __typename?: 'Reservable'
            id: string
            name?: string | null
            type: ReservableType
            y?: number | null
            x?: number | null
            Space?: {
                __typename?: 'Space'
                id: string
                name?: string | null
                Office?: { __typename?: 'Office'; id: string; name: string } | null
            } | null
        } | null
    } | null
}

export type ListBookingsByEmployeeAndStartTimeQueryVariables = Exact<{
    employeeID: Scalars['ID']['input']
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
}>

export type ListBookingsByEmployeeAndStartTimeQuery = {
    __typename?: 'Query'
    listBookingsByEmployeeAndStartTime?: {
        __typename?: 'ModelBookingConnection'
        items: Array<{
            __typename?: 'Booking'
            id: string
            startTime?: any | null
            endTime?: any | null
            reservableID?: string | null
            isBlocked?: boolean | null
            isFullDay?: boolean | null
            bookingType?: BookingType | null
            bookingSubType?: BookingSubType | null
            isTeamEvent?: boolean | null
            checkInStatus?: BookingCheckInStatus | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
            Space?: {
                __typename?: 'Space'
                id: string
                name?: string | null
                floorPlanFilePath?: string | null
                workingHoursFrom?: any | null
                workingHoursTo?: any | null
                officeID: string
                Office?: { __typename?: 'Office'; id: string; name: string } | null
            } | null
            reservable?: {
                __typename?: 'Reservable'
                id: string
                type: ReservableType
                x?: number | null
                y?: number | null
                zonePoints?: Array<number | null> | null
                color?: string | null
            } | null
            BookingRequest?: {
                __typename?: 'BookingRequest'
                id: string
                repeatType?: RepeatType | null
                addParkingSlot?: boolean | null
                daysOfWeek?: Array<string> | null
                ParkingSlot?: {
                    __typename?: 'Reservable'
                    id: string
                    name?: string | null
                    type: ReservableType
                    y?: number | null
                    x?: number | null
                    Space?: {
                        __typename?: 'Space'
                        id: string
                        name?: string | null
                        Office?: { __typename?: 'Office'; id: string; name: string } | null
                    } | null
                } | null
            } | null
        } | null>
    } | null
}

export type ListReservablesWithBookingsForPreviewQueryVariables = Exact<{
    spaceID: Scalars['ID']['input']
    startDate: Scalars['String']['input']
}>

export type ListReservablesWithBookingsForPreviewQuery = {
    __typename?: 'Query'
    listReservablesBySpaceAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            name?: string | null
            x?: number | null
            y?: number | null
            type: ReservableType
            availabilityType: AvailabilityType
            maxAmountOfPeople?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
            Department?: { __typename?: 'Department'; name: string } | null
            ReservableToDepartments?: {
                __typename?: 'ModelReservableToDepartmentConnection'
                items: Array<{
                    __typename?: 'ReservableToDepartment'
                    Department?: { __typename?: 'Department'; id: string; name: string } | null
                } | null>
            } | null
            BookingsByDateAndDayOfWeek?: {
                __typename?: 'ModelBookingConnection'
                items: Array<{
                    __typename?: 'Booking'
                    id: string
                    startTime?: any | null
                    endTime?: any | null
                    isFullDay?: boolean | null
                    isBlocked?: boolean | null
                    date?: string | null
                    employeeID: string
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        photo?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        shareAttendance?: ShareAttendance | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type ListSeatsWithBookingsBySpaceAndTypeQueryVariables = Exact<{
    spaceID: Scalars['ID']['input']
    dateFilter?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
    filterByUser?: InputMaybe<ModelReservableFilterInput>
    type?: InputMaybe<ModelStringKeyConditionInput>
}>

export type ListSeatsWithBookingsBySpaceAndTypeQuery = {
    __typename?: 'Query'
    listReservablesBySpaceAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            name?: string | null
            x?: number | null
            y?: number | null
            type: ReservableType
            availabilityType: AvailabilityType
            maxAmountOfPeople?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
            Department?: { __typename?: 'Department'; name: string } | null
            Tags?: {
                __typename?: 'ModelReservableTagConnection'
                items: Array<{
                    __typename?: 'ReservableTag'
                    Tag?: { __typename?: 'Tag'; id: string; name: string } | null
                } | null>
            } | null
            ReservableToDepartments?: {
                __typename?: 'ModelReservableToDepartmentConnection'
                items: Array<{
                    __typename?: 'ReservableToDepartment'
                    Department?: { __typename?: 'Department'; id: string; name: string } | null
                } | null>
            } | null
            ReservableToEmployees?: {
                __typename?: 'ModelReservableToEmployeeConnection'
                items: Array<{
                    __typename?: 'ReservableToEmployee'
                    employee: {
                        __typename?: 'Employee'
                        id: string
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        shareAttendance?: ShareAttendance | null
                    }
                } | null>
            } | null
            Space?: {
                __typename?: 'Space'
                address?: string | null
                id: string
                name?: string | null
                workingHoursTo?: any | null
                workingHoursFrom?: any | null
                officeID: string
                Office?: { __typename?: 'Office'; id: string; name: string; address?: string | null } | null
            } | null
            BookingsByDateAndDayOfWeek?: {
                __typename?: 'ModelBookingConnection'
                items: Array<{
                    __typename?: 'Booking'
                    id: string
                    startTime?: any | null
                    endTime?: any | null
                    isFullDay?: boolean | null
                    isBlocked?: boolean | null
                    employeeID: string
                    date?: string | null
                    createdAt?: any | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        photo?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        shareAttendance?: ShareAttendance | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type ListZonesWithBookingsBySpaceAndTypeQueryVariables = Exact<{
    spaceID: Scalars['ID']['input']
    filterByUser?: InputMaybe<ModelReservableFilterInput>
    type?: InputMaybe<ModelStringKeyConditionInput>
}>

export type ListZonesWithBookingsBySpaceAndTypeQuery = {
    __typename?: 'Query'
    listReservablesBySpaceAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            name?: string | null
            x?: number | null
            y?: number | null
            type: ReservableType
            availabilityType: AvailabilityType
            maxAmountOfPeople?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
            Tags?: {
                __typename?: 'ModelReservableTagConnection'
                items: Array<{
                    __typename?: 'ReservableTag'
                    Tag?: { __typename?: 'Tag'; id: string; name: string } | null
                } | null>
            } | null
            ReservableToDepartments?: {
                __typename?: 'ModelReservableToDepartmentConnection'
                items: Array<{
                    __typename?: 'ReservableToDepartment'
                    Department?: { __typename?: 'Department'; id: string; name: string } | null
                } | null>
            } | null
            ReservableToEmployees?: {
                __typename?: 'ModelReservableToEmployeeConnection'
                items: Array<{
                    __typename?: 'ReservableToEmployee'
                    employee: {
                        __typename?: 'Employee'
                        id: string
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        shareAttendance?: ShareAttendance | null
                    }
                } | null>
            } | null
            Space?: {
                __typename?: 'Space'
                address?: string | null
                id: string
                name?: string | null
                workingHoursTo?: any | null
                workingHoursFrom?: any | null
                officeID: string
                Office?: { __typename?: 'Office'; id: string; name: string; address?: string | null } | null
            } | null
        } | null>
    } | null
}

export type ListParkingSpotsWithBookingsBySpaceQueryVariables = Exact<{
    spaceID: Scalars['ID']['input']
    filterByUser?: InputMaybe<ModelReservableFilterInput>
    type?: InputMaybe<ModelStringKeyConditionInput>
    parkingFilter?: InputMaybe<ModelBookingReservableFilterInput>
}>

export type ListParkingSpotsWithBookingsBySpaceQuery = {
    __typename?: 'Query'
    listReservablesBySpaceAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            name?: string | null
            x?: number | null
            y?: number | null
            type: ReservableType
            availabilityType: AvailabilityType
            maxAmountOfPeople?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
            Space?: {
                __typename?: 'Space'
                address?: string | null
                id: string
                name?: string | null
                workingHoursTo?: any | null
                workingHoursFrom?: any | null
                officeID: string
                Office?: { __typename?: 'Office'; id: string; name: string; address?: string | null } | null
            } | null
            Department?: { __typename?: 'Department'; name: string } | null
            Tags?: {
                __typename?: 'ModelReservableTagConnection'
                items: Array<{
                    __typename?: 'ReservableTag'
                    Tag?: { __typename?: 'Tag'; id: string; name: string } | null
                } | null>
            } | null
            ReservableToDepartments?: {
                __typename?: 'ModelReservableToDepartmentConnection'
                items: Array<{
                    __typename?: 'ReservableToDepartment'
                    Department?: { __typename?: 'Department'; id: string; name: string } | null
                } | null>
            } | null
            BookingReservablesByCreatedAt?: {
                __typename?: 'ModelBookingReservableConnection'
                items: Array<{
                    __typename?: 'BookingReservable'
                    booking?: {
                        __typename?: 'Booking'
                        startTime?: any | null
                        endTime?: any | null
                        isFullDay?: boolean | null
                        isBlocked?: boolean | null
                        employeeID: string
                        Employee?: {
                            __typename?: 'Employee'
                            id: string
                            photo?: string | null
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            departmentIDs?: Array<string | null> | null
                            departmentsString?: string | null
                            shareAttendance?: ShareAttendance | null
                        } | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type ListRoomsBySpaceIdQueryVariables = Exact<{
    id: Scalars['ID']['input']
    datesPreview?: InputMaybe<Array<InputMaybe<Scalars['AWSDate']['input']>> | InputMaybe<Scalars['AWSDate']['input']>>
    type?: InputMaybe<ModelStringKeyConditionInput>
    filterByUser?: InputMaybe<ModelReservableFilterInput>
}>

export type ListRoomsBySpaceIdQuery = {
    __typename?: 'Query'
    listReservablesBySpaceAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            name?: string | null
            x?: number | null
            y?: number | null
            type: ReservableType
            availabilityType: AvailabilityType
            availabilityPreviewByDates?: Array<AvailabilityItemPreviewElement> | null
            maxAmountOfPeople?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
            Space?: {
                __typename?: 'Space'
                address?: string | null
                id: string
                name?: string | null
                workingHoursTo?: any | null
                workingHoursFrom?: any | null
                officeID: string
                Office?: { __typename?: 'Office'; id: string; name: string; address?: string | null } | null
            } | null
            Department?: { __typename?: 'Department'; name: string } | null
            ReservableToDepartments?: {
                __typename?: 'ModelReservableToDepartmentConnection'
                items: Array<{
                    __typename?: 'ReservableToDepartment'
                    Department?: { __typename?: 'Department'; id: string; name: string } | null
                } | null>
            } | null
            Tags?: {
                __typename?: 'ModelReservableTagConnection'
                items: Array<{
                    __typename?: 'ReservableTag'
                    reservableID: string
                    Tag?: { __typename?: 'Tag'; id: string; name: string } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type ListOfficesQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    employeeID: Scalars['ID']['input']
    date?: InputMaybe<Scalars['AWSDate']['input']>
}>

export type ListOfficesQuery = {
    __typename?: 'Query'
    listOfficesByCompanyAndOrder?: {
        __typename?: 'ModelOfficeConnection'
        items: Array<{
            __typename?: 'Office'
            id: string
            workingFrom?: any | null
            workingTo?: any | null
            SpaceRows?: {
                __typename?: 'ModelSpaceRowConnection'
                items: Array<{
                    __typename?: 'SpaceRow'
                    id: string
                    Spaces?: {
                        __typename?: 'ModelSpaceConnection'
                        items: Array<{
                            __typename?: 'Space'
                            id: string
                            name?: string | null
                            isAvailableToEmployees?: boolean | null
                            floorPlanFilePath?: string | null
                            workingHoursFrom?: any | null
                            workingHoursTo?: any | null
                            totalSeats?: number | null
                            availableParkingSlots?: number | null
                            officeID: string
                            Office?: { __typename?: 'Office'; id: string; name: string } | null
                        } | null>
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetSuggestedSeatsForCalendarQueryVariables = Exact<{
    employeeID: Scalars['ID']['input']
    date: Scalars['String']['input']
}>

export type GetSuggestedSeatsForCalendarQuery = {
    __typename?: 'Query'
    getEmployee?: {
        __typename?: 'Employee'
        SuggestedSeats?: {
            __typename?: 'ModelSuggestedSeatConnection'
            items: Array<{
                __typename?: 'SuggestedSeat'
                id: string
                createdAt: any
                updatedAt: any
                spaceID?: string | null
                Reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    spaceID?: string | null
                    Space?: {
                        __typename?: 'Space'
                        id: string
                        floorPlanFilePath?: string | null
                        workingHoursFrom?: any | null
                        workingHoursTo?: any | null
                        name?: string | null
                        officeID: string
                        Office?: { __typename?: 'Office'; id: string; name: string } | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type GetTeammatesEmployeesQueryVariables = Exact<{
    filter?: InputMaybe<SearchableEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortName?: InputMaybe<SearchableSortDirection>
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
}>

export type GetTeammatesEmployeesQuery = {
    __typename?: 'Query'
    searchEmployees?: {
        __typename?: 'SearchableEmployeeConnection'
        nextToken?: string | null
        total?: number | null
        items: Array<{
            __typename?: 'Employee'
            id: string
            companyID: string
            departmentIDs?: Array<string | null> | null
            positionID?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            email?: string | null
            photo?: string | null
            status?: EmployeeStatus | null
            role?: Role | null
            Position?: { __typename?: 'Position'; id: string; name: string } | null
            BookingsByStartTime?: {
                __typename?: 'ModelBookingConnection'
                items: Array<{
                    __typename?: 'Booking'
                    id: string
                    startTime?: any | null
                    endTime?: any | null
                    reservableID?: string | null
                    isBlocked?: boolean | null
                    isFullDay?: boolean | null
                    bookingType?: BookingType | null
                    bookingSubType?: BookingSubType | null
                    isTeamEvent?: boolean | null
                    checkInStatus?: BookingCheckInStatus | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        photo?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        shareAttendance?: ShareAttendance | null
                    } | null
                    Space?: {
                        __typename?: 'Space'
                        id: string
                        name?: string | null
                        floorPlanFilePath?: string | null
                        workingHoursFrom?: any | null
                        workingHoursTo?: any | null
                        officeID: string
                        Office?: { __typename?: 'Office'; id: string; name: string } | null
                    } | null
                    reservable?: {
                        __typename?: 'Reservable'
                        id: string
                        type: ReservableType
                        x?: number | null
                        y?: number | null
                        zonePoints?: Array<number | null> | null
                        color?: string | null
                    } | null
                    BookingRequest?: {
                        __typename?: 'BookingRequest'
                        id: string
                        repeatType?: RepeatType | null
                        addParkingSlot?: boolean | null
                        daysOfWeek?: Array<string> | null
                        ParkingSlot?: {
                            __typename?: 'Reservable'
                            id: string
                            name?: string | null
                            type: ReservableType
                            y?: number | null
                            x?: number | null
                            Space?: {
                                __typename?: 'Space'
                                id: string
                                name?: string | null
                                Office?: { __typename?: 'Office'; id: string; name: string } | null
                            } | null
                        } | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetReservableWithBookingsForAvailabilityQueryVariables = Exact<{
    reservableID: Scalars['ID']['input']
    dateFilter?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
}>

export type GetReservableWithBookingsForAvailabilityQuery = {
    __typename?: 'Query'
    getReservable?: {
        __typename?: 'Reservable'
        BookingsByDateAndDayOfWeek?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                date?: string | null
                id: string
                startBookingTime?: any | null
                endBookingTime?: any | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
            } | null>
        } | null
    } | null
}

export type GetDashboardAnaliticsByOfficeAndWeekQueryVariables = Exact<{
    officeID: Scalars['ID']['input']
    weekNumberDate: ModelOfficeDayAggregationByOfficeAndYearAndWeekNumberStringAndDateCompositeKeyConditionInput
}>

export type GetDashboardAnaliticsByOfficeAndWeekQuery = {
    __typename?: 'Query'
    listOfficeDayAggregationByOfficeAndMoment?: {
        __typename?: 'ModelOfficeDayAggregationConnection'
        items: Array<{
            __typename?: 'OfficeDayAggregation'
            id: string
            bookedHours?: number | null
            capacityInHours?: number | null
            homeBookingsSum: number
            officeCapacityLast: number
            officeBookingsSum: number
            weekNumber: number
            year: number
            companyID: string
            date: any
        } | null>
    } | null
}

export type GetDashboardListDepartmentDayAggregationQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    yearWeekNumberDate: ModelDepartmentDayAggregationByCompanyAndYearAndWeekNumberAndDateCompositeKeyConditionInput
    filter?: InputMaybe<ModelDepartmentDayAggregationFilterInput>
}>

export type GetDashboardListDepartmentDayAggregationQuery = {
    __typename?: 'Query'
    listDepartmentDayAggregationByCompany?: {
        __typename?: 'ModelDepartmentDayAggregationConnection'
        items: Array<{
            __typename?: 'DepartmentDayAggregation'
            officeBookingsSum: number
            date: any
            departmentID: string
            employeesInOffice?: {
                __typename?: 'ModelDepartmentDayAggregationEmployeesConnection'
                items: Array<{
                    __typename?: 'DepartmentDayAggregationEmployees'
                    employee: {
                        __typename?: 'Employee'
                        firstname?: string | null
                        lastname?: string | null
                        id: string
                        photo?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        shareAttendance?: ShareAttendance | null
                    }
                } | null>
            } | null
        } | null>
    } | null
}

export type DepartmentAttendanceFieldsFragment = {
    __typename?: 'DepartmentLastMonthAttendance'
    id: string
    companyID: string
    weeklyAttendance: number
    lastDateTogether?: any | null
    attendanceScore?: number | null
    attendanceScoreString?: string | null
    officeDaysScore?: number | null
    officeDaysScoreString?: string | null
    Department?: {
        __typename?: 'Department'
        id: string
        name: string
        size?: number | null
        DepartmentRules?: {
            __typename?: 'DepartmentRules'
            id: string
            blockReservationOnNonOfficeDays?: boolean | null
            companyID: string
            enableMinDaysInOffice?: boolean | null
            horizonOfPlanningInDays: number
            horizonOfPlanningTimeAmount?: number | null
            horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
            maxDaysInOffice?: number | null
            officeDays?: Array<string | null> | null
            minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
            minDaysInOffice?: number | null
        } | null
        TeamLead?: {
            __typename?: 'Employee'
            id: string
            active?: boolean | null
            birthday?: any | null
            fullName?: string | null
            email?: string | null
        } | null
    } | null
}

export type EmployeeAttendanceFieldsFragment = {
    __typename?: 'EmployeeLastMonthAttendance'
    id: string
    companyID: string
    weeklyAttendance: number
    lastDateInOffice?: any | null
    Employee?: {
        __typename?: 'Employee'
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
    } | null
}

export type SearchDepartmentLastMonthAttendancesByCompanyIdQueryVariables = Exact<{
    sort?: InputMaybe<
        | Array<InputMaybe<SearchableDepartmentLastMonthAttendanceSortInput>>
        | InputMaybe<SearchableDepartmentLastMonthAttendanceSortInput>
    >
    searchText?: InputMaybe<Scalars['String']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
}>

export type SearchDepartmentLastMonthAttendancesByCompanyIdQuery = {
    __typename?: 'Query'
    searchDepartmentLastMonthAttendances?: {
        __typename?: 'SearchableDepartmentLastMonthAttendanceConnection'
        nextToken?: string | null
        items: Array<{
            __typename?: 'DepartmentLastMonthAttendance'
            id: string
            companyID: string
            weeklyAttendance: number
            lastDateTogether?: any | null
            attendanceScore?: number | null
            attendanceScoreString?: string | null
            officeDaysScore?: number | null
            officeDaysScoreString?: string | null
            Department?: {
                __typename?: 'Department'
                id: string
                name: string
                size?: number | null
                DepartmentRules?: {
                    __typename?: 'DepartmentRules'
                    id: string
                    blockReservationOnNonOfficeDays?: boolean | null
                    companyID: string
                    enableMinDaysInOffice?: boolean | null
                    horizonOfPlanningInDays: number
                    horizonOfPlanningTimeAmount?: number | null
                    horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
                    maxDaysInOffice?: number | null
                    officeDays?: Array<string | null> | null
                    minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
                    minDaysInOffice?: number | null
                } | null
                TeamLead?: {
                    __typename?: 'Employee'
                    id: string
                    active?: boolean | null
                    birthday?: any | null
                    fullName?: string | null
                    email?: string | null
                } | null
            } | null
        } | null>
    } | null
}

export type SearchEmployeeLastMonthAttendancesByCompanyIdQueryVariables = Exact<{
    sort?: InputMaybe<
        | Array<InputMaybe<SearchableEmployeeLastMonthAttendanceSortInput>>
        | InputMaybe<SearchableEmployeeLastMonthAttendanceSortInput>
    >
    searchText?: InputMaybe<Scalars['String']['input']>
    from?: InputMaybe<Scalars['Int']['input']>
    companyID?: InputMaybe<Scalars['ID']['input']>
}>

export type SearchEmployeeLastMonthAttendancesByCompanyIdQuery = {
    __typename?: 'Query'
    searchEmployeeLastMonthAttendances?: {
        __typename?: 'SearchableEmployeeLastMonthAttendanceConnection'
        nextToken?: string | null
        total?: number | null
        items: Array<{
            __typename?: 'EmployeeLastMonthAttendance'
            id: string
            companyID: string
            weeklyAttendance: number
            lastDateInOffice?: any | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
        } | null>
    } | null
}

export type RoomWeekAggregationFieldsFragment = {
    __typename?: 'RoomWeekAggregation'
    id: string
    companyID: string
    bookedHours?: number | null
    capacityInHours?: number | null
    percentageOfUtilization?: number | null
    visitors?: number | null
    weekNumberString?: string | null
    weekNumber: number
    year: number
    reservableID: string
    Reservable?: {
        __typename?: 'Reservable'
        id: string
        name?: string | null
        maxAmountOfPeople?: number | null
    } | null
}

export type SearchRoomAttendancesByOfficeQueryVariables = Exact<{
    sort?: InputMaybe<
        Array<InputMaybe<SearchableRoomWeekAggregationSortInput>> | InputMaybe<SearchableRoomWeekAggregationSortInput>
    >
    searchText?: InputMaybe<Scalars['String']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    startWeekNumber?: InputMaybe<Scalars['String']['input']>
    startYear?: InputMaybe<Scalars['Int']['input']>
    endWeekNumber?: InputMaybe<Scalars['String']['input']>
    endYear?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchRoomAttendancesByOfficeQuery = {
    __typename?: 'Query'
    searchRoomWeekAggregations?: {
        __typename?: 'SearchableRoomWeekAggregationConnection'
        total?: number | null
        nextToken?: string | null
        items: Array<{
            __typename?: 'RoomWeekAggregation'
            id: string
            companyID: string
            bookedHours?: number | null
            capacityInHours?: number | null
            percentageOfUtilization?: number | null
            visitors?: number | null
            weekNumberString?: string | null
            weekNumber: number
            year: number
            reservableID: string
            Reservable?: {
                __typename?: 'Reservable'
                id: string
                name?: string | null
                maxAmountOfPeople?: number | null
            } | null
        } | null>
    } | null
}

export type SearchRoomAttendancesByOfficeForWeekQueryVariables = Exact<{
    sort?: InputMaybe<
        Array<InputMaybe<SearchableRoomWeekAggregationSortInput>> | InputMaybe<SearchableRoomWeekAggregationSortInput>
    >
    searchText?: InputMaybe<Scalars['String']['input']>
    officeID?: InputMaybe<Scalars['ID']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    weekNumber?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchRoomAttendancesByOfficeForWeekQuery = {
    __typename?: 'Query'
    searchRoomWeekAggregations?: {
        __typename?: 'SearchableRoomWeekAggregationConnection'
        total?: number | null
        nextToken?: string | null
        items: Array<{
            __typename?: 'RoomWeekAggregation'
            id: string
            companyID: string
            bookedHours?: number | null
            capacityInHours?: number | null
            percentageOfUtilization?: number | null
            visitors?: number | null
            weekNumberString?: string | null
            weekNumber: number
            year: number
            reservableID: string
            Reservable?: {
                __typename?: 'Reservable'
                id: string
                name?: string | null
                maxAmountOfPeople?: number | null
            } | null
        } | null>
    } | null
}

export type ListDepartmentWeekAggregationByDepartmentAndMomentQueryVariables = Exact<{
    departmentID: Scalars['ID']['input']
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}>

export type ListDepartmentWeekAggregationByDepartmentAndMomentQuery = {
    __typename?: 'Query'
    listDepartmentWeekAggregationByDepartmentAndMoment?: {
        __typename?: 'ModelDepartmentWeekAggregationConnection'
        items: Array<{
            __typename?: 'DepartmentWeekAggregation'
            weekNumber: number
            year: number
            dayAggregations?: {
                __typename?: 'ModelDepartmentDayAggregationConnection'
                items: Array<{
                    __typename?: 'DepartmentDayAggregation'
                    date: any
                    officeBookingsSum: number
                    homeBookingsSum: number
                } | null>
            } | null
        } | null>
    } | null
}

export type ListDepartmentsByCompanyQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentFilterInput>
}>

export type ListDepartmentsByCompanyQuery = {
    __typename?: 'Query'
    listDepartmentsByCompanyAndName?: {
        __typename?: 'ModelDepartmentConnection'
        items: Array<{ __typename?: 'Department'; id: string; name: string } | null>
    } | null
}

export type DepartmentAttendanceFieldsForTeamLeadFragment = {
    __typename?: 'DepartmentLastMonthAttendance'
    id: string
    companyID: string
    weeklyAttendance: number
    lastDateTogether?: any | null
    Department?: { __typename?: 'Department'; id: string; name: string; size?: number | null } | null
}

export type SearchDepartmentLastMonthAttendancesQueryVariables = Exact<{
    sort?: InputMaybe<
        | Array<InputMaybe<SearchableDepartmentLastMonthAttendanceSortInput>>
        | InputMaybe<SearchableDepartmentLastMonthAttendanceSortInput>
    >
    filter?: InputMaybe<SearchableDepartmentLastMonthAttendanceFilterInput>
}>

export type SearchDepartmentLastMonthAttendancesQuery = {
    __typename?: 'Query'
    searchDepartmentLastMonthAttendances?: {
        __typename?: 'SearchableDepartmentLastMonthAttendanceConnection'
        nextToken?: string | null
        items: Array<{
            __typename?: 'DepartmentLastMonthAttendance'
            id: string
            companyID: string
            weeklyAttendance: number
            lastDateTogether?: any | null
            Department?: { __typename?: 'Department'; id: string; name: string; size?: number | null } | null
        } | null>
    } | null
}

export type SearchEmployeeLastMonthAttendancesQueryVariables = Exact<{
    sort?: InputMaybe<
        | Array<InputMaybe<SearchableEmployeeLastMonthAttendanceSortInput>>
        | InputMaybe<SearchableEmployeeLastMonthAttendanceSortInput>
    >
    from?: InputMaybe<Scalars['Int']['input']>
    filter?: InputMaybe<SearchableEmployeeLastMonthAttendanceFilterInput>
}>

export type SearchEmployeeLastMonthAttendancesQuery = {
    __typename?: 'Query'
    searchEmployeeLastMonthAttendances?: {
        __typename?: 'SearchableEmployeeLastMonthAttendanceConnection'
        nextToken?: string | null
        total?: number | null
        items: Array<{
            __typename?: 'EmployeeLastMonthAttendance'
            id: string
            companyID: string
            weeklyAttendance: number
            lastDateInOffice?: any | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
        } | null>
    } | null
}

export type ListDepartmentDayAggregationByDepartmentAndMomentQueryVariables = Exact<{
    departmentID: Scalars['ID']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    currentWeekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    currentYear?: InputMaybe<Scalars['Int']['input']>
}>

export type ListDepartmentDayAggregationByDepartmentAndMomentQuery = {
    __typename?: 'Query'
    listDepartmentWeekAggregationByDepartmentAndMoment?: {
        __typename?: 'ModelDepartmentWeekAggregationConnection'
        items: Array<{
            __typename?: 'DepartmentWeekAggregation'
            weekNumber: number
            year: number
            officeBookingsSum: number
            bookingsDistribution: Array<{
                __typename?: 'DashboardWeekAggregationBookingsDistribution'
                amountOfDays: number
                bookingsSum: number
            }>
        } | null>
    } | null
}

export type GetCalendarEmployeesQueryVariables = Exact<{
    filter?: InputMaybe<SearchableEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}>

export type GetCalendarEmployeesQuery = {
    __typename?: 'Query'
    searchEmployees?: {
        __typename?: 'SearchableEmployeeConnection'
        nextToken?: string | null
        total?: number | null
        items: Array<{
            __typename?: 'Employee'
            id: string
            companyID: string
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            positionID?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            email?: string | null
            photo?: string | null
            status?: EmployeeStatus | null
            role?: Role | null
            Position?: { __typename?: 'Position'; id: string; name: string } | null
            Office?: { __typename?: 'Office'; id: string; name: string } | null
        } | null>
    } | null
}

export type RoomBookingFragment = {
    __typename?: 'CalendarEvents'
    id: string
    startTime: any
    endTime: any
    reservableID?: string | null
    organiserEmployeeID?: string | null
    title?: string | null
    description?: string | null
    OrganiserEmployee?: {
        __typename?: 'Employee'
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
    } | null
    Employees?: {
        __typename?: 'ModelCalendarEventEmployeeConnection'
        items: Array<{
            __typename?: 'CalendarEventEmployee'
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
        } | null>
    } | null
}

export type ListOfficesWithRoomsQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<ReservableType>
}>

export type ListOfficesWithRoomsQuery = {
    __typename?: 'Query'
    listOfficesByCompanyAndOrder?: {
        __typename?: 'ModelOfficeConnection'
        items: Array<{
            __typename?: 'Office'
            id: string
            workingFrom?: any | null
            workingTo?: any | null
            name: string
            Spaces?: {
                __typename?: 'ModelSpaceConnection'
                items: Array<{
                    __typename?: 'Space'
                    id: string
                    name?: string | null
                    Reservables?: {
                        __typename?: 'ModelReservableConnection'
                        items: Array<{
                            __typename?: 'Reservable'
                            id: string
                            name?: string | null
                            maxAmountOfPeople?: number | null
                            availabilityType: AvailabilityType
                            Space?: { __typename?: 'Space'; name?: string | null } | null
                            ReservableToDepartments?: {
                                __typename?: 'ModelReservableToDepartmentConnection'
                                items: Array<{
                                    __typename?: 'ReservableToDepartment'
                                    Department?: { __typename?: 'Department'; id: string } | null
                                } | null>
                            } | null
                            CalendarEvents?: {
                                __typename?: 'ModelCalendarEventsConnection'
                                items: Array<{
                                    __typename?: 'CalendarEvents'
                                    id: string
                                    startTime: any
                                    endTime: any
                                    reservableID?: string | null
                                    organiserEmployeeID?: string | null
                                    title?: string | null
                                    description?: string | null
                                    OrganiserEmployee?: {
                                        __typename?: 'Employee'
                                        id: string
                                        photo?: string | null
                                        firstname?: string | null
                                        lastname?: string | null
                                        fullName?: string | null
                                        departmentIDs?: Array<string | null> | null
                                        departmentsString?: string | null
                                        shareAttendance?: ShareAttendance | null
                                    } | null
                                    Employees?: {
                                        __typename?: 'ModelCalendarEventEmployeeConnection'
                                        items: Array<{
                                            __typename?: 'CalendarEventEmployee'
                                            Employee?: {
                                                __typename?: 'Employee'
                                                id: string
                                                photo?: string | null
                                                firstname?: string | null
                                                lastname?: string | null
                                                fullName?: string | null
                                                departmentIDs?: Array<string | null> | null
                                                departmentsString?: string | null
                                                shareAttendance?: ShareAttendance | null
                                            } | null
                                        } | null>
                                    } | null
                                } | null>
                            } | null
                        } | null>
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetRoomByIdQueryVariables = Exact<{
    id: Scalars['ID']['input']
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
}>

export type GetRoomByIdQuery = {
    __typename?: 'Query'
    getReservable?: {
        __typename?: 'Reservable'
        id: string
        name?: string | null
        maxAmountOfPeople?: number | null
        availabilityType: AvailabilityType
        Space?: { __typename?: 'Space'; id: string; workingHoursFrom?: any | null; workingHoursTo?: any | null } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                Department?: { __typename?: 'Department'; id: string } | null
            } | null>
        } | null
        CalendarEvents?: {
            __typename?: 'ModelCalendarEventsConnection'
            items: Array<{
                __typename?: 'CalendarEvents'
                id: string
                startTime: any
                endTime: any
                reservableID?: string | null
                organiserEmployeeID?: string | null
                title?: string | null
                description?: string | null
                OrganiserEmployee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
                Employees?: {
                    __typename?: 'ModelCalendarEventEmployeeConnection'
                    items: Array<{
                        __typename?: 'CalendarEventEmployee'
                        Employee?: {
                            __typename?: 'Employee'
                            id: string
                            photo?: string | null
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            departmentIDs?: Array<string | null> | null
                            departmentsString?: string | null
                            shareAttendance?: ShareAttendance | null
                        } | null
                    } | null>
                } | null
            } | null>
        } | null
    } | null
}

export type GetRoomBookingByIdQueryVariables = Exact<{
    bookingID: Scalars['ID']['input']
}>

export type GetRoomBookingByIdQuery = {
    __typename?: 'Query'
    getCalendarEvents?: {
        __typename?: 'CalendarEvents'
        id: string
        startTime: any
        endTime: any
        reservableID?: string | null
        organiserEmployeeID?: string | null
        title?: string | null
        description?: string | null
        OrganiserEmployee?: {
            __typename?: 'Employee'
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Employees?: {
            __typename?: 'ModelCalendarEventEmployeeConnection'
            items: Array<{
                __typename?: 'CalendarEventEmployee'
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
            } | null>
        } | null
    } | null
}

export type CreateReservableImageMutationVariables = Exact<{
    input: CreateReservableImageInput
}>

export type CreateReservableImageMutation = {
    __typename?: 'Mutation'
    createReservableImage?: {
        __typename?: 'ReservableImage'
        id: string
        companyID: string
        reservableID: string
        location?: string | null
        name?: string | null
        isMain?: boolean | null
        reservableImageReservableId?: string | null
        reservableImageCompanyId?: string | null
        createdAt: any
        updatedAt: any
    } | null
}

export type DeleteReservableImageMutationVariables = Exact<{
    input: DeleteReservableImageInput
}>

export type DeleteReservableImageMutation = {
    __typename?: 'Mutation'
    deleteReservableImage?: { __typename?: 'ReservableImage'; id: string } | null
}

export type ListReservableImagesQueryVariables = Exact<{
    filter?: InputMaybe<ModelReservableImageFilterInput>
}>

export type ListReservableImagesQuery = {
    __typename?: 'Query'
    listReservableImages?: {
        __typename?: 'ModelReservableImageConnection'
        items: Array<{
            __typename?: 'ReservableImage'
            id: string
            name?: string | null
            location?: string | null
            reservableID: string
            companyID: string
            isMain?: boolean | null
            reservableImageReservableId?: string | null
            reservableImageCompanyId?: string | null
        } | null>
    } | null
}

export type SearchRoomsQueryVariables = Exact<{
    companyID?: InputMaybe<Scalars['ID']['input']>
    search?: InputMaybe<
        Array<InputMaybe<SearchableReservableFilterInput>> | InputMaybe<SearchableReservableFilterInput>
    >
    nextToken?: InputMaybe<Scalars['String']['input']>
    spaceID?: InputMaybe<Scalars['ID']['input']>
}>

export type SearchRoomsQuery = {
    __typename?: 'Query'
    searchReservables?: {
        __typename?: 'SearchableReservableConnection'
        nextToken?: string | null
        items: Array<{ __typename?: 'Reservable'; id: string; name?: string | null } | null>
    } | null
}

export type CreateTagMutationVariables = Exact<{
    input: CreateTagInput
}>

export type CreateTagMutation = {
    __typename?: 'Mutation'
    createTag?: {
        __typename?: 'Tag'
        id: string
        name: string
        companyID: string
        reservableType?: ReservableType | null
    } | null
}

export type CreateReservableTagMutationVariables = Exact<{
    input: CreateReservableTagInput
}>

export type CreateReservableTagMutation = {
    __typename?: 'Mutation'
    createReservableTag?: { __typename?: 'ReservableTag'; id: string; companyID: string; reservableID: string } | null
}

export type DeleteTagAndReservableTagMutationVariables = Exact<{
    reservableTagID: Scalars['ID']['input']
}>

export type DeleteTagAndReservableTagMutation = {
    __typename?: 'Mutation'
    deleteReservableTag?: { __typename?: 'ReservableTag'; id: string } | null
}

export type ListReservableTagsByReservableQueryVariables = Exact<{
    reservableID: Scalars['ID']['input']
    filter?: InputMaybe<ModelReservableTagFilterInput>
}>

export type ListReservableTagsByReservableQuery = {
    __typename?: 'Query'
    listReservableTagsByReservable?: {
        __typename?: 'ModelReservableTagConnection'
        items: Array<{
            __typename?: 'ReservableTag'
            id: string
            Tag?: { __typename?: 'Tag'; name: string; id: string } | null
        } | null>
    } | null
}

export type SearchTagsQueryVariables = Exact<{
    filter: ModelTagFilterInput
}>

export type SearchTagsQuery = {
    __typename?: 'Query'
    listTags?: {
        __typename?: 'ModelTagConnection'
        items: Array<{
            __typename?: 'Tag'
            id: string
            name: string
            Company: { __typename?: 'Company'; id: string }
            Reservables?: {
                __typename?: 'ModelReservableTagConnection'
                items: Array<{ __typename?: 'ReservableTag'; id: string; reservableID: string } | null>
            } | null
        } | null>
    } | null
}

export type ListReservableTagsQueryVariables = Exact<{
    filter: ModelReservableTagFilterInput
    spaceID: Scalars['ID']['input']
}>

export type ListReservableTagsQuery = {
    __typename?: 'Query'
    listReservableTagsBySpace?: {
        __typename?: 'ModelReservableTagConnection'
        items: Array<{
            __typename?: 'ReservableTag'
            id: string
            Tag?: {
                __typename?: 'Tag'
                name: string
                id: string
                companyID: string
                Reservables?: {
                    __typename?: 'ModelReservableTagConnection'
                    items: Array<{ __typename?: 'ReservableTag'; id: string; reservableID: string } | null>
                } | null
            } | null
            Company: { __typename?: 'Company'; id: string }
        } | null>
    } | null
}

export type OnCreateEquipmentBookingByCompanyAndDateSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    year: Scalars['Int']['input']
    isoWeekNumber: Scalars['Int']['input']
}>

export type OnCreateEquipmentBookingByCompanyAndDateSubscription = {
    __typename?: 'Subscription'
    onCreateBookingEquipmentByCompanyAndWeekAndYear?: {
        __typename?: 'BookingEquipment'
        id: string
        owner?: string | null
        officeID?: string | null
        companyID: string
        startTime: any
        endTime: any
        employeeID?: string | null
        equipmentID?: string | null
        Equipment?: {
            __typename?: 'Equipment'
            id: string
            name?: string | null
            description?: string | null
            quantity?: number | null
            canBookedSeparately?: boolean | null
            officeID?: string | null
            companyID: string
            enabled?: boolean | null
            EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
            EquipmentImages?: {
                __typename?: 'ModelEquipmentImageConnection'
                items: Array<{
                    __typename?: 'EquipmentImage'
                    id: string
                    name?: string | null
                    location?: string | null
                } | null>
            } | null
        } | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
    } | null
}

export type OnUpdateEquipmentBookingByCompanyAndDateSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    year: Scalars['Int']['input']
    isoWeekNumber: Scalars['Int']['input']
}>

export type OnUpdateEquipmentBookingByCompanyAndDateSubscription = {
    __typename?: 'Subscription'
    onUpdateBookingEquipmentByCompanyAndWeekAndYear?: {
        __typename?: 'BookingEquipment'
        id: string
        owner?: string | null
        officeID?: string | null
        companyID: string
        startTime: any
        endTime: any
        employeeID?: string | null
        equipmentID?: string | null
        Equipment?: {
            __typename?: 'Equipment'
            id: string
            name?: string | null
            description?: string | null
            quantity?: number | null
            canBookedSeparately?: boolean | null
            officeID?: string | null
            companyID: string
            enabled?: boolean | null
            EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
            EquipmentImages?: {
                __typename?: 'ModelEquipmentImageConnection'
                items: Array<{
                    __typename?: 'EquipmentImage'
                    id: string
                    name?: string | null
                    location?: string | null
                } | null>
            } | null
        } | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
    } | null
}

export type OnDeleteEquipmentBookingByCompanyAndDateSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    year: Scalars['Int']['input']
    isoWeekNumber: Scalars['Int']['input']
}>

export type OnDeleteEquipmentBookingByCompanyAndDateSubscription = {
    __typename?: 'Subscription'
    onDeleteBookingEquipmentByCompanyAndWeekAndYear?: {
        __typename?: 'BookingEquipment'
        id: string
        year?: number | null
        companyID: string
        isoWeekNumber?: number | null
    } | null
}

export type RoomBookingV2Fragment = {
    __typename?: 'CalendarEvents'
    id: string
    startTime: any
    endTime: any
    reservableID?: string | null
    organiserEmployeeID?: string | null
    title?: string | null
    description?: string | null
    isPrivate?: boolean | null
    onlineMeetingUrl?: string | null
    isOnlineMeeting?: boolean | null
    OrganiserEmployee?: {
        __typename?: 'Employee'
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
    } | null
    Employees?: {
        __typename?: 'ModelCalendarEventEmployeeConnection'
        items: Array<{
            __typename?: 'CalendarEventEmployee'
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
        } | null>
    } | null
}

export type GetRoomDetailsByIdQueryVariables = Exact<{
    id: Scalars['ID']['input']
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
}>

export type GetRoomDetailsByIdQuery = {
    __typename?: 'Query'
    getReservable?: {
        __typename?: 'Reservable'
        id: string
        name?: string | null
        maxAmountOfPeople?: number | null
        availabilityType: AvailabilityType
        externalRoomID?: string | null
        relatedReservableID?: string | null
        Space?: { __typename?: 'Space'; id: string; workingHoursFrom?: any | null; workingHoursTo?: any | null } | null
        RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
        ReservableImages?: {
            __typename?: 'ModelReservableImageConnection'
            items: Array<{ __typename?: 'ReservableImage'; location?: string | null } | null>
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                Department?: { __typename?: 'Department'; id: string } | null
            } | null>
        } | null
        ReservableToEmployees?: {
            __typename?: 'ModelReservableToEmployeeConnection'
            items: Array<{
                __typename?: 'ReservableToEmployee'
                employee: { __typename?: 'Employee'; id: string }
            } | null>
        } | null
        Tags?: {
            __typename?: 'ModelReservableTagConnection'
            items: Array<{
                __typename?: 'ReservableTag'
                Tag?: { __typename?: 'Tag'; id: string; name: string } | null
            } | null>
        } | null
        CalendarEvents?: {
            __typename?: 'ModelCalendarEventsConnection'
            items: Array<{
                __typename?: 'CalendarEvents'
                id: string
                startTime: any
                endTime: any
                reservableID?: string | null
                organiserEmployeeID?: string | null
                title?: string | null
                description?: string | null
                isPrivate?: boolean | null
                onlineMeetingUrl?: string | null
                isOnlineMeeting?: boolean | null
                OrganiserEmployee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
                Employees?: {
                    __typename?: 'ModelCalendarEventEmployeeConnection'
                    items: Array<{
                        __typename?: 'CalendarEventEmployee'
                        Employee?: {
                            __typename?: 'Employee'
                            id: string
                            photo?: string | null
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            departmentIDs?: Array<string | null> | null
                            departmentsString?: string | null
                            shareAttendance?: ShareAttendance | null
                        } | null
                    } | null>
                } | null
            } | null>
        } | null
    } | null
}

export type GetRoomCalendarEventByIdQueryVariables = Exact<{
    id: Scalars['ID']['input']
}>

export type GetRoomCalendarEventByIdQuery = {
    __typename?: 'Query'
    getCalendarEvents?: {
        __typename?: 'CalendarEvents'
        id: string
        startTime: any
        endTime: any
        reservableID?: string | null
        organiserEmployeeID?: string | null
        title?: string | null
        description?: string | null
        isPrivate?: boolean | null
        onlineMeetingUrl?: string | null
        isOnlineMeeting?: boolean | null
        Catering?: { __typename?: 'Catering'; title?: string | null; id: string; email: string } | null
        menuItems?: Array<{
            __typename?: 'MenuItemModel'
            id: string
            title?: string | null
            description?: string | null
            quantity?: number | null
        }> | null
        equipmentItems?: Array<{
            __typename?: 'EquipmentItemModel'
            id: string
            title?: string | null
            description?: string | null
        }> | null
        Space?: {
            __typename?: 'Space'
            id: string
            name?: string | null
            workingHoursFrom?: any | null
            workingHoursTo?: any | null
        } | null
        Reservable?: {
            __typename?: 'Reservable'
            id: string
            name?: string | null
            type: ReservableType
            maxAmountOfPeople?: number | null
            availabilityType: AvailabilityType
            externalRoomID?: string | null
            relatedReservableID?: string | null
            RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
            ReservableToDepartments?: {
                __typename?: 'ModelReservableToDepartmentConnection'
                items: Array<{
                    __typename?: 'ReservableToDepartment'
                    Department?: { __typename?: 'Department'; id: string } | null
                } | null>
            } | null
            ReservableToEmployees?: {
                __typename?: 'ModelReservableToEmployeeConnection'
                items: Array<{
                    __typename?: 'ReservableToEmployee'
                    employee: { __typename?: 'Employee'; id: string }
                } | null>
            } | null
            Tags?: {
                __typename?: 'ModelReservableTagConnection'
                items: Array<{
                    __typename?: 'ReservableTag'
                    Tag?: { __typename?: 'Tag'; id: string; name: string } | null
                } | null>
            } | null
            ReservableImages?: {
                __typename?: 'ModelReservableImageConnection'
                items: Array<{ __typename?: 'ReservableImage'; location?: string | null } | null>
            } | null
            Space?: {
                __typename?: 'Space'
                id: string
                name?: string | null
                workingHoursFrom?: any | null
                workingHoursTo?: any | null
                Office?: { __typename?: 'Office'; id: string; name: string } | null
            } | null
        } | null
        OrganiserEmployee?: {
            __typename?: 'Employee'
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Employees?: {
            __typename?: 'ModelCalendarEventEmployeeConnection'
            items: Array<{
                __typename?: 'CalendarEventEmployee'
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
            } | null>
        } | null
    } | null
}

export type OnCreateCalendarEventByCompanyAndDateSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}>

export type OnCreateCalendarEventByCompanyAndDateSubscription = {
    __typename?: 'Subscription'
    onCreateCalendarEventByCompanyAndDate?: {
        __typename?: 'CalendarEvents'
        id: string
        title?: string | null
        reservableID?: string | null
        description?: string | null
        employeesIDs: Array<string>
        startTime: any
        endTime: any
        canCurrentUserEdit?: boolean | null
        spaceID?: string | null
        companyID: string
        date?: string | null
        isOnlineMeeting?: boolean | null
        onlineMeetingUrl?: string | null
        isPrivate?: boolean | null
    } | null
}

export type OnUpdateCalendarEventByCompanyAndDateSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}>

export type OnUpdateCalendarEventByCompanyAndDateSubscription = {
    __typename?: 'Subscription'
    onUpdateCalendarEventByCompanyAndDate?: {
        __typename?: 'CalendarEvents'
        id: string
        title?: string | null
        reservableID?: string | null
        description?: string | null
        employeesIDs: Array<string>
        startTime: any
        endTime: any
        canCurrentUserEdit?: boolean | null
        spaceID?: string | null
        companyID: string
        date?: string | null
        isOnlineMeeting?: boolean | null
        onlineMeetingUrl?: string | null
        isPrivate?: boolean | null
    } | null
}

export type OnDeleteCalendarEventByCompanyAndDateSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    date: Scalars['String']['input']
}>

export type OnDeleteCalendarEventByCompanyAndDateSubscription = {
    __typename?: 'Subscription'
    onDeleteCalendarEventByCompanyAndDate?: {
        __typename?: 'CalendarEvents'
        id: string
        companyID: string
        date?: string | null
    } | null
}

export type SearchReservablesQueryVariables = Exact<{
    companyID?: InputMaybe<Scalars['ID']['input']>
    search?: InputMaybe<
        Array<InputMaybe<SearchableReservableFilterInput>> | InputMaybe<SearchableReservableFilterInput>
    >
    nextToken?: InputMaybe<Scalars['String']['input']>
}>

export type SearchReservablesQuery = {
    __typename?: 'Query'
    searchReservables?: {
        __typename?: 'SearchableReservableConnection'
        nextToken?: string | null
        items: Array<{ __typename?: 'Reservable'; id: string; name?: string | null } | null>
    } | null
}

export type GetReservableQueryVariables = Exact<{
    roomID: Scalars['ID']['input']
}>

export type GetReservableQuery = {
    __typename?: 'Query'
    getReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
}

export type SearchReservablesNewQueryVariables = Exact<{
    companyID?: InputMaybe<Scalars['ID']['input']>
    search?: InputMaybe<
        Array<InputMaybe<SearchableReservableFilterInput>> | InputMaybe<SearchableReservableFilterInput>
    >
    nextToken?: InputMaybe<Scalars['String']['input']>
}>

export type SearchReservablesNewQuery = {
    __typename?: 'Query'
    searchReservables?: {
        __typename?: 'SearchableReservableConnection'
        nextToken?: string | null
        items: Array<{ __typename?: 'Reservable'; id: string; name?: string | null } | null>
    } | null
}

export type ListGenericIntegrationsQueryVariables = Exact<{
    type: Scalars['String']['input']
}>

export type ListGenericIntegrationsQuery = {
    __typename?: 'Query'
    listGenericIntegrations: Array<{
        __typename?: 'GenericIntegration'
        type: IntegrationType
        group: IntegrationGroup
        status: IntegrationStatus
    }>
}

export type BookingFieldsForSpaceScreenFragment = {
    __typename?: 'Booking'
    id: string
    date?: string | null
    createdAt?: any | null
    startTime?: any | null
    endTime?: any | null
    reservableID?: string | null
    isBlocked?: boolean | null
    isFullDay?: boolean | null
    bookingType?: BookingType | null
    bookingSubType?: BookingSubType | null
    isTeamEvent?: boolean | null
    checkInStatus?: BookingCheckInStatus | null
    Employee?: {
        __typename?: 'Employee'
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
    } | null
    Space?: {
        __typename?: 'Space'
        id: string
        name?: string | null
        floorPlanFilePath?: string | null
        workingHoursFrom?: any | null
        workingHoursTo?: any | null
        officeID: string
        Office?: { __typename?: 'Office'; id: string; name: string } | null
    } | null
    reservable?: {
        __typename?: 'Reservable'
        id: string
        type: ReservableType
        x?: number | null
        y?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
    } | null
    BookingRequest?: {
        __typename?: 'BookingRequest'
        id: string
        repeatType?: RepeatType | null
        addParkingSlot?: boolean | null
        daysOfWeek?: Array<string> | null
        parkingSlotID?: string | null
        ParkingSlot?: { __typename?: 'Reservable'; Space?: { __typename?: 'Space'; id: string } | null } | null
    } | null
}

export type ListParkingSpotsBySpaceQueryVariables = Exact<{
    id: Scalars['ID']['input']
    parkingFilter?: InputMaybe<ModelBookingReservableFilterInput>
    type?: InputMaybe<ModelStringKeyConditionInput>
}>

export type ListParkingSpotsBySpaceQuery = {
    __typename?: 'Query'
    listReservablesBySpaceAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            name?: string | null
            x?: number | null
            y?: number | null
            type: ReservableType
            availabilityType: AvailabilityType
            maxAmountOfPeople?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
            Space?: {
                __typename?: 'Space'
                id: string
                name?: string | null
                address?: string | null
                workingHoursFrom?: any | null
                workingHoursTo?: any | null
            } | null
            Department?: { __typename?: 'Department'; id: string; name: string } | null
            ReservableToDepartments?: {
                __typename?: 'ModelReservableToDepartmentConnection'
                items: Array<{
                    __typename?: 'ReservableToDepartment'
                    Department?: { __typename?: 'Department'; id: string; name: string } | null
                } | null>
            } | null
            ReservableToEmployees?: {
                __typename?: 'ModelReservableToEmployeeConnection'
                items: Array<{
                    __typename?: 'ReservableToEmployee'
                    employee: {
                        __typename?: 'Employee'
                        id: string
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        shareAttendance?: ShareAttendance | null
                    }
                } | null>
            } | null
            Tags?: {
                __typename?: 'ModelReservableTagConnection'
                items: Array<{
                    __typename?: 'ReservableTag'
                    reservableID: string
                    Tag?: { __typename?: 'Tag'; id: string; name: string } | null
                } | null>
            } | null
            BookingReservablesByCreatedAt?: {
                __typename?: 'ModelBookingReservableConnection'
                items: Array<{
                    __typename?: 'BookingReservable'
                    booking?: {
                        __typename?: 'Booking'
                        startTime?: any | null
                        endTime?: any | null
                        isFullDay?: boolean | null
                        isBlocked?: boolean | null
                        employeeID: string
                        Employee?: {
                            __typename?: 'Employee'
                            id: string
                            photo?: string | null
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            departmentIDs?: Array<string | null> | null
                            departmentsString?: string | null
                            shareAttendance?: ShareAttendance | null
                        } | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetBookingByIdForSpaceScreenQueryVariables = Exact<{
    bookingID: Scalars['ID']['input']
}>

export type GetBookingByIdForSpaceScreenQuery = {
    __typename?: 'Query'
    getBooking?: {
        __typename?: 'Booking'
        id: string
        date?: string | null
        createdAt?: any | null
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        isBlocked?: boolean | null
        isFullDay?: boolean | null
        bookingType?: BookingType | null
        bookingSubType?: BookingSubType | null
        isTeamEvent?: boolean | null
        checkInStatus?: BookingCheckInStatus | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Space?: {
            __typename?: 'Space'
            id: string
            name?: string | null
            floorPlanFilePath?: string | null
            workingHoursFrom?: any | null
            workingHoursTo?: any | null
            officeID: string
            Office?: { __typename?: 'Office'; id: string; name: string } | null
        } | null
        reservable?: {
            __typename?: 'Reservable'
            id: string
            type: ReservableType
            x?: number | null
            y?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
        } | null
        BookingRequest?: {
            __typename?: 'BookingRequest'
            id: string
            repeatType?: RepeatType | null
            addParkingSlot?: boolean | null
            daysOfWeek?: Array<string> | null
            parkingSlotID?: string | null
            ParkingSlot?: { __typename?: 'Reservable'; Space?: { __typename?: 'Space'; id: string } | null } | null
        } | null
    } | null
}

export type GetEmployeeQueryVariables = Exact<{
    employeeId: Scalars['ID']['input']
    bookingStartTime?: InputMaybe<ModelStringKeyConditionInput>
    filterBooking?: InputMaybe<ModelBookingFilterInput>
}>

export type GetEmployeeQuery = {
    __typename?: 'Query'
    getEmployee?: {
        __typename?: 'Employee'
        BookingsByStartTime?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                date?: string | null
                createdAt?: any | null
                startTime?: any | null
                endTime?: any | null
                reservableID?: string | null
                isBlocked?: boolean | null
                isFullDay?: boolean | null
                bookingType?: BookingType | null
                bookingSubType?: BookingSubType | null
                isTeamEvent?: boolean | null
                checkInStatus?: BookingCheckInStatus | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
                Space?: {
                    __typename?: 'Space'
                    id: string
                    name?: string | null
                    floorPlanFilePath?: string | null
                    workingHoursFrom?: any | null
                    workingHoursTo?: any | null
                    officeID: string
                    Office?: { __typename?: 'Office'; id: string; name: string } | null
                } | null
                reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    type: ReservableType
                    x?: number | null
                    y?: number | null
                    zonePoints?: Array<number | null> | null
                    color?: string | null
                } | null
                BookingRequest?: {
                    __typename?: 'BookingRequest'
                    id: string
                    repeatType?: RepeatType | null
                    addParkingSlot?: boolean | null
                    daysOfWeek?: Array<string> | null
                    parkingSlotID?: string | null
                    ParkingSlot?: {
                        __typename?: 'Reservable'
                        Space?: { __typename?: 'Space'; id: string } | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type OnCreateBookingBySpaceAndDateSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
    date: Scalars['String']['input']
}>

export type OnCreateBookingBySpaceAndDateSubscription = {
    __typename?: 'Subscription'
    onCreateBookingBySpaceAndDate?: {
        __typename?: 'Booking'
        id: string
        date?: string | null
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        isBlocked?: boolean | null
        isFullDay?: boolean | null
        bookingType?: BookingType | null
        bookingSubType?: BookingSubType | null
        isTeamEvent?: boolean | null
        checkInStatus?: BookingCheckInStatus | null
        BookingRequest?: {
            __typename?: 'BookingRequest'
            Bookings?: {
                __typename?: 'ModelBookingConnection'
                items: Array<{
                    __typename?: 'Booking'
                    id: string
                    date?: string | null
                    createdAt?: any | null
                    startTime?: any | null
                    endTime?: any | null
                    reservableID?: string | null
                    isBlocked?: boolean | null
                    isFullDay?: boolean | null
                    bookingType?: BookingType | null
                    bookingSubType?: BookingSubType | null
                    isTeamEvent?: boolean | null
                    checkInStatus?: BookingCheckInStatus | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        photo?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        shareAttendance?: ShareAttendance | null
                    } | null
                    Space?: {
                        __typename?: 'Space'
                        id: string
                        name?: string | null
                        floorPlanFilePath?: string | null
                        workingHoursFrom?: any | null
                        workingHoursTo?: any | null
                        officeID: string
                        Office?: { __typename?: 'Office'; id: string; name: string } | null
                    } | null
                    reservable?: {
                        __typename?: 'Reservable'
                        id: string
                        type: ReservableType
                        x?: number | null
                        y?: number | null
                        zonePoints?: Array<number | null> | null
                        color?: string | null
                    } | null
                    BookingRequest?: {
                        __typename?: 'BookingRequest'
                        id: string
                        repeatType?: RepeatType | null
                        addParkingSlot?: boolean | null
                        daysOfWeek?: Array<string> | null
                        parkingSlotID?: string | null
                        ParkingSlot?: {
                            __typename?: 'Reservable'
                            Space?: { __typename?: 'Space'; id: string } | null
                        } | null
                    } | null
                } | null>
            } | null
        } | null
        Employee?: {
            __typename?: 'Employee'
            departmentIDs?: Array<string | null> | null
            shareAttendance?: ShareAttendance | null
            departmentsString?: string | null
            firstname?: string | null
            fullName?: string | null
            lastname?: string | null
            photo?: string | null
        } | null
        reservable?: {
            __typename?: 'Reservable'
            id: string
            type: ReservableType
            x?: number | null
            y?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
        } | null
    } | null
}

export type OnDeleteBookingBySpaceAndDateSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
    date: Scalars['String']['input']
}>

export type OnDeleteBookingBySpaceAndDateSubscription = {
    __typename?: 'Subscription'
    onDeleteBookingBySpaceAndDate?: {
        __typename?: 'Booking'
        id: string
        date?: string | null
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        isBlocked?: boolean | null
        isFullDay?: boolean | null
        bookingType?: BookingType | null
        bookingSubType?: BookingSubType | null
        isTeamEvent?: boolean | null
        checkInStatus?: BookingCheckInStatus | null
    } | null
}

export type OnDeleteBookingReservableByCompanySubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeleteBookingReservableByCompanySubscription = {
    __typename?: 'Subscription'
    onDeleteBookingReservableByCompany?: {
        __typename?: 'BookingReservable'
        id: string
        companyID: string
        date?: any | null
        reservableID: string
    } | null
}

export type GetSuggestedSeatsQueryVariables = Exact<{
    employeeID: Scalars['ID']['input']
    date: Scalars['String']['input']
}>

export type GetSuggestedSeatsQuery = {
    __typename?: 'Query'
    getEmployee?: {
        __typename?: 'Employee'
        SuggestedSeats?: {
            __typename?: 'ModelSuggestedSeatConnection'
            items: Array<{
                __typename?: 'SuggestedSeat'
                id: string
                Reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    Space?: {
                        __typename?: 'Space'
                        id: string
                        floorPlanFilePath?: string | null
                        workingHoursFrom?: any | null
                        workingHoursTo?: any | null
                        name?: string | null
                        Office?: { __typename?: 'Office'; id: string; name: string } | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type GetSpaceDayAggregationQueryVariables = Exact<{
    spaceID: Scalars['ID']['input']
    filter?: InputMaybe<ModelSpaceDayAggregationFilterInput>
}>

export type GetSpaceDayAggregationQuery = {
    __typename?: 'Query'
    listSpaceDayAggregationBySpaceAndMoment?: {
        __typename?: 'ModelSpaceDayAggregationConnection'
        items: Array<{
            __typename?: 'SpaceDayAggregation'
            spaceCapacityLast: number
            date: any
            dataByHour: Array<{
                __typename?: 'SpaceDayAggregationBookingsByHoursDistribution'
                hour: number
                bookedSeats: number
                capacity: number
            }>
        } | null>
    } | null
}

export type OnCreateSpaceDayAggregationBySpaceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}>

export type OnCreateSpaceDayAggregationBySpaceSubscription = {
    __typename?: 'Subscription'
    onCreateSpaceDayAggregationBySpace?: { __typename?: 'SpaceDayAggregation'; id: string } | null
}

export type OnUpdateSpaceDayAggregationBySpaceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}>

export type OnUpdateSpaceDayAggregationBySpaceSubscription = {
    __typename?: 'Subscription'
    onUpdateSpaceDayAggregationBySpace?: { __typename?: 'SpaceDayAggregation'; id: string } | null
}

export type OnCreateCalendarEventBySpaceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}>

export type OnCreateCalendarEventBySpaceSubscription = {
    __typename?: 'Subscription'
    onCreateCalendarEventBySpace?: { __typename?: 'CalendarEvents'; id: string } | null
}

export type OnUpdateCalendarEventBySpaceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}>

export type OnUpdateCalendarEventBySpaceSubscription = {
    __typename?: 'Subscription'
    onUpdateCalendarEventBySpace?: { __typename?: 'CalendarEvents'; id: string } | null
}

export type OnDeleteCalendarEventBySpaceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    spaceID: Scalars['String']['input']
}>

export type OnDeleteCalendarEventBySpaceSubscription = {
    __typename?: 'Subscription'
    onDeleteCalendarEventBySpace?: { __typename?: 'CalendarEvents'; id: string } | null
}

export type GetParkingSlotsQueryVariables = Exact<{
    spaceID: Scalars['ID']['input']
    employeeID?: InputMaybe<Scalars['ID']['input']>
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
    companyID: Scalars['ID']['input']
}>

export type GetParkingSlotsQuery = {
    __typename?: 'Query'
    getSpace?: { __typename?: 'Space'; availableParkingSlotsByPeriod?: number | null } | null
    listReservablesByCompanyAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            Space?: { __typename?: 'Space'; id: string; Office?: { __typename?: 'Office'; id: string } | null } | null
        } | null>
    } | null
}

export type EmployeeInfoFragment = {
    __typename?: 'Employee'
    id: string
    firstname?: string | null
    lastname?: string | null
    fullName?: string | null
    photo?: string | null
    departmentIDs?: Array<string | null> | null
    shareAttendance?: ShareAttendance | null
    Position?: { __typename?: 'Position'; name: string } | null
}

export type GetSpaceAndReservableQueryVariables = Exact<{
    reservableID: Scalars['ID']['input']
    employeeID: Scalars['ID']['input']
    startDate: Scalars['String']['input']
    dopDate: Scalars['AWSDate']['input']
    spaceID: Scalars['ID']['input']
}>

export type GetSpaceAndReservableQuery = {
    __typename?: 'Query'
    getSpace?: { __typename?: 'Space'; id: string; availableParkingSlots?: number | null } | null
    getReservable?: {
        __typename?: 'Reservable'
        type: ReservableType
        availabilityType: AvailabilityType
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        Employee?: { __typename?: 'Employee'; id: string } | null
        bookingReservables?: {
            __typename?: 'ModelBookingReservableConnection'
            items: Array<{
                __typename?: 'BookingReservable'
                id: string
                booking?: {
                    __typename?: 'Booking'
                    id: string
                    startTime?: any | null
                    endTime?: any | null
                    bookingRequestID?: string | null
                    isBlocked?: boolean | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                        departmentIDs?: Array<string | null> | null
                        shareAttendance?: ShareAttendance | null
                        Position?: { __typename?: 'Position'; name: string } | null
                    } | null
                    BookingRequest?: {
                        __typename?: 'BookingRequest'
                        id: string
                        addParkingSlot?: boolean | null
                    } | null
                } | null
            } | null>
        } | null
        Bookings?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                bookingRequestID?: string | null
                isBlocked?: boolean | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    departmentIDs?: Array<string | null> | null
                    shareAttendance?: ShareAttendance | null
                    Position?: { __typename?: 'Position'; name: string } | null
                } | null
                BookingRequest?: {
                    __typename?: 'BookingRequest'
                    id: string
                    addParkingSlot?: boolean | null
                    ParkingSlot?: {
                        __typename?: 'Reservable'
                        id: string
                        name?: string | null
                        y?: number | null
                        x?: number | null
                        employeeID?: string | null
                        Space?: {
                            __typename?: 'Space'
                            id: string
                            name?: string | null
                            address?: string | null
                        } | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type ListCalendarEventssByIdQueryVariables = Exact<{
    dateFilter?: InputMaybe<ModelCalendarEventsFilterInput>
    reservableID: Scalars['ID']['input']
}>

export type ListCalendarEventssByIdQuery = {
    __typename?: 'Query'
    getReservable?: {
        __typename?: 'Reservable'
        type: ReservableType
        availabilityType: AvailabilityType
        maxAmountOfPeople?: number | null
        externalRoomID?: string | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        Employee?: { __typename?: 'Employee'; id: string } | null
    } | null
    listCalendarEventsByReservable?: {
        __typename?: 'ModelCalendarEventsConnection'
        items: Array<{
            __typename?: 'CalendarEvents'
            id: string
            startTime: any
            endTime: any
            organiserEmployeeID?: string | null
            title?: string | null
            description?: string | null
            isOnlineMeeting?: boolean | null
            onlineMeetingUrl?: string | null
            isPrivate?: boolean | null
            employeesIDs: Array<string>
            CalendarEventRequest?: {
                __typename?: 'CalendarEventRequest'
                id: string
                repeatType?: RepeatType | null
            } | null
            OrganiserEmployee?: {
                __typename?: 'Employee'
                id: string
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                photo?: string | null
                departmentIDs?: Array<string | null> | null
                shareAttendance?: ShareAttendance | null
                Position?: { __typename?: 'Position'; name: string } | null
            } | null
            Employees?: {
                __typename?: 'ModelCalendarEventEmployeeConnection'
                items: Array<{
                    __typename?: 'CalendarEventEmployee'
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                        departmentIDs?: Array<string | null> | null
                        shareAttendance?: ShareAttendance | null
                        Position?: { __typename?: 'Position'; name: string } | null
                    } | null
                } | null>
            } | null
            Catering?: { __typename?: 'Catering'; id: string; title?: string | null; enabled: boolean } | null
            menuItems?: Array<{
                __typename?: 'MenuItemModel'
                id: string
                quantity?: number | null
                title?: string | null
                description?: string | null
            }> | null
        } | null>
    } | null
}

export type GetBookingByIdQueryVariables = Exact<{
    bookingID: Scalars['ID']['input']
}>

export type GetBookingByIdQuery = {
    __typename?: 'Query'
    getBooking?: {
        __typename?: 'Booking'
        id: string
        startTime?: any | null
        endTime?: any | null
        isFullDay?: boolean | null
        isBlocked?: boolean | null
        employeeID: string
        reservableID?: string | null
        status?: BookingStatus | null
        companyID: string
        isTeamEvent?: boolean | null
        checkInStatus?: BookingCheckInStatus | null
        startBookingTime?: any | null
        endBookingTime?: any | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            shareAttendance?: ShareAttendance | null
        } | null
    } | null
}

export type CreateBookingCancellationMutationVariables = Exact<{
    input: CreateBookingCancellationInput
}>

export type CreateBookingCancellationMutation = {
    __typename?: 'Mutation'
    createBookingCancellation?: { __typename?: 'BookingCancellation'; id: string } | null
}

export type DeleteBookingReservableMutationVariables = Exact<{
    input: DeleteBookingReservableInput
}>

export type DeleteBookingReservableMutation = {
    __typename?: 'Mutation'
    deleteBookingReservable?: { __typename?: 'BookingReservable'; id: string; companyID: string } | null
}

export type UpdateBookingRequestForParkingMutationVariables = Exact<{
    input: UpdateBookingRequestInput
}>

export type UpdateBookingRequestForParkingMutation = {
    __typename?: 'Mutation'
    updateBookingRequest?: { __typename?: 'BookingRequest'; id: string } | null
}

export type SpaceFieldsFragment = {
    __typename?: 'Space'
    id: string
    floorPlanFilePath?: string | null
    name?: string | null
    address?: string | null
    workingHoursFrom?: any | null
    workingHoursTo?: any | null
    officeID: string
    companyID: string
}

export type ReservableFieldsFragment = {
    __typename?: 'Reservable'
    id: string
    x?: number | null
    y?: number | null
    availabilityType: AvailabilityType
    type: ReservableType
    employeeID?: string | null
    departmentID?: string | null
    spaceID?: string | null
    companyID: string
    name?: string | null
    maxAmountOfPeople?: number | null
    zonePoints?: Array<number | null> | null
    color?: string | null
    relatedReservableID?: string | null
    RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
    ReservableToEmployees?: {
        __typename?: 'ModelReservableToEmployeeConnection'
        items: Array<{
            __typename?: 'ReservableToEmployee'
            id: string
            employeeID: string
            employee: {
                __typename?: 'Employee'
                id: string
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                photo?: string | null
                departmentIDs?: Array<string | null> | null
                shareAttendance?: ShareAttendance | null
            }
        } | null>
    } | null
    ReservableToDepartments?: {
        __typename?: 'ModelReservableToDepartmentConnection'
        items: Array<{
            __typename?: 'ReservableToDepartment'
            id: string
            departmentID: string
            Department?: { __typename?: 'Department'; id: string; name: string } | null
        } | null>
    } | null
    admin?: {
        __typename?: 'Employee'
        id: string
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
    } | null
}

export type ReservableImageFieldsFragment = {
    __typename?: 'ReservableImage'
    id: string
    name?: string | null
    location?: string | null
    reservableID: string
    createdAt: any
    updatedAt: any
}

export type EmployeeFieldsFragment = {
    __typename?: 'Employee'
    id: string
    email?: string | null
    firstname?: string | null
    lastname?: string | null
    fullName?: string | null
    photo?: string | null
    officeID?: string | null
    positionID?: string | null
    departmentIDs?: Array<string | null> | null
    departmentsString?: string | null
    defaultPlan?: Array<string | null> | null
    status?: EmployeeStatus | null
    companyID: string
    notificationChannels?: Array<NotificationChannel | null> | null
    createdAt?: any | null
    role?: Role | null
    locale?: UserLocale | null
    shareAttendance?: ShareAttendance | null
    favouriteColleagueIDs?: Array<string | null> | null
    buddyID?: string | null
    favouriteReservableID?: string | null
    birthday?: any | null
    turnOnExperimentalFeatures?: boolean | null
}

export type DepartmentFieldsFragment = { __typename?: 'Department'; id: string; name: string; companyID: string }

export type PositionFieldsFragment = { __typename?: 'Position'; id: string; name: string; companyID: string }

export type OfficeFieldsFragment = { __typename?: 'Office'; id: string; name: string; companyID: string }

export type BookingFieldsFragment = {
    __typename?: 'Booking'
    id: string
    startTime?: any | null
    endTime?: any | null
    reservableID?: string | null
    status?: BookingStatus | null
    employeeID: string
    companyID: string
    isTeamEvent?: boolean | null
    checkInStatus?: BookingCheckInStatus | null
    startBookingTime?: any | null
    endBookingTime?: any | null
}

export type PersonioFieldsFragment = {
    __typename?: 'PersonioIntegration'
    companyID: string
    clientID: string
    clientSecret: string
    status?: PersonioIntegrationStatus | null
    error?: string | null
    nextSyncAt?: any | null
    lastSyncedAt?: any | null
    hasInvitationEnabled: boolean
    hasSyncBookingsToPersonioEnabled: boolean
}

export type BambooHrFieldsFragment = {
    __typename?: 'BambooHRIntegration'
    companyId: string
    hasInvitationEnabled: boolean
    syncStatus: SyncStatus
    lastSyncAt?: any | null
    companyDomain: string
}

export type SlackFieldsFragment = {
    __typename?: 'SlackIntegration'
    companyID: string
    sendDayScheduleAt?: any | null
    enableCheckInFeature: boolean
    enableSendingDaySchedule?: boolean | null
}

export type SubscriptionPlanFieldsFragment = {
    __typename?: 'BillingPlan'
    id: string
    name: string
    billingType: string
    billingPeriod: string
    initialPrice: Array<{ __typename?: 'Money'; currency: string; amount: string }>
    recurringPrice: Array<{ __typename?: 'Money'; currency: string; amount: string }>
}

export type SubscribedBillingFieldsFragment = {
    __typename?: 'SubscribedBilling'
    quantity: number
    updateUrl: string
    cancelUrl: string
    subscribedAt: any
    subscriptionId: string
    discount?: { __typename?: 'Discount'; type: DiscountType; value: number } | null
}

export type TrialBillingFieldsFragment = { __typename?: 'TrialBilling'; expiresAt: any }

export type SubscriptionForCompanyFieldsFragment = {
    __typename?: 'SubscribedBilling'
    companyId: string
    status: BillingSubscriptionStatus
    subscriptionPlanId: string
    expiresAt: any
}

export type RulesFieldsFragment = {
    __typename?: 'Rules'
    id: string
    enableMinDaysInOffice?: boolean | null
    minDaysInOffice?: number | null
    minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
    maxDaysInOffice?: number | null
    homeIsDefault: boolean
    turnOnCheckIn?: boolean | null
    autoAssignWaitingList: boolean
    horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
    horizonOfPlanningTimeAmount?: number | null
    denyRecurringBookingOverThePlanningHorizon?: boolean | null
    startTimeOfNotifications?: number | null
    checkInWaitingTime?: number | null
    checkInCancellingTime?: number | null
    turnOnAttendanceNotifications?: boolean | null
    dayOfAttendanceNotifications?: number | null
    hourOfAttendanceNotifications?: number | null
    updateEmployeeDataFromSource?: boolean | null
    turnOffCheckInForPersonalSeats?: boolean | null
    isPersonalSeatAvailableToday?: boolean | null
    isTeamSeatAvailableToday?: boolean | null
    turnOnMonthlyReport?: boolean | null
    emailForMonthlyReport?: string | null
    workingDays?: Array<string | null> | null
    blockReservationOnNonWorkingDays?: boolean | null
    disableProfileEditing?: boolean | null
    defaultShareAttendance?: ShareAttendance | null
    turnOnDeletingData?: boolean | null
    deleteDataAfter?: number | null
}

export type CalendarEventFieldsFragment = {
    __typename?: 'CalendarEvents'
    id: string
    title?: string | null
    reservableID?: string | null
    description?: string | null
    employeesIDs: Array<string>
    startTime: any
    endTime: any
    canCurrentUserEdit?: boolean | null
    spaceID?: string | null
    companyID: string
    date?: string | null
    isOnlineMeeting?: boolean | null
    onlineMeetingUrl?: string | null
    isPrivate?: boolean | null
}

export type MainEmployeeFieldsFragment = {
    __typename?: 'Employee'
    id: string
    photo?: string | null
    firstname?: string | null
    lastname?: string | null
    fullName?: string | null
    departmentIDs?: Array<string | null> | null
    departmentsString?: string | null
    shareAttendance?: ShareAttendance | null
}

export type EquipmentFieldsFragment = {
    __typename?: 'Equipment'
    id: string
    name?: string | null
    description?: string | null
    quantity?: number | null
    canBookedSeparately?: boolean | null
    officeID?: string | null
    companyID: string
    enabled?: boolean | null
    EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
    EquipmentImages?: {
        __typename?: 'ModelEquipmentImageConnection'
        items: Array<{
            __typename?: 'EquipmentImage'
            id: string
            name?: string | null
            location?: string | null
        } | null>
    } | null
}

export type BookingEquipmentFieldsFragment = {
    __typename?: 'BookingEquipment'
    id: string
    owner?: string | null
    officeID?: string | null
    companyID: string
    startTime: any
    endTime: any
    employeeID?: string | null
    equipmentID?: string | null
    Equipment?: {
        __typename?: 'Equipment'
        id: string
        name?: string | null
        description?: string | null
        quantity?: number | null
        canBookedSeparately?: boolean | null
        officeID?: string | null
        companyID: string
        enabled?: boolean | null
        EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
        EquipmentImages?: {
            __typename?: 'ModelEquipmentImageConnection'
            items: Array<{
                __typename?: 'EquipmentImage'
                id: string
                name?: string | null
                location?: string | null
            } | null>
        } | null
    } | null
    Employee?: {
        __typename?: 'Employee'
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
    } | null
}

export type UpdateCompanyMutationVariables = Exact<{
    input: UpdateCompanyInput
}>

export type UpdateCompanyMutation = {
    __typename?: 'Mutation'
    updateCompany?: {
        __typename?: 'Company'
        id: string
        title?: string | null
        country?: string | null
        city?: string | null
        address?: string | null
        zipcode?: string | null
        phoneNumber?: string | null
        numberOfOffices?: number | null
        numberOfEmployees?: number | null
        companyLogo?: string | null
        tariff?: Tariff | null
        Rules?: {
            __typename?: 'Rules'
            id: string
            enableMinDaysInOffice?: boolean | null
            minDaysInOffice?: number | null
            minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
            maxDaysInOffice?: number | null
            homeIsDefault: boolean
            turnOnCheckIn?: boolean | null
            autoAssignWaitingList: boolean
            horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
            horizonOfPlanningTimeAmount?: number | null
            denyRecurringBookingOverThePlanningHorizon?: boolean | null
            startTimeOfNotifications?: number | null
            checkInWaitingTime?: number | null
            checkInCancellingTime?: number | null
            turnOnAttendanceNotifications?: boolean | null
            dayOfAttendanceNotifications?: number | null
            hourOfAttendanceNotifications?: number | null
            updateEmployeeDataFromSource?: boolean | null
            turnOffCheckInForPersonalSeats?: boolean | null
            isPersonalSeatAvailableToday?: boolean | null
            isTeamSeatAvailableToday?: boolean | null
            turnOnMonthlyReport?: boolean | null
            emailForMonthlyReport?: string | null
            workingDays?: Array<string | null> | null
            blockReservationOnNonWorkingDays?: boolean | null
            disableProfileEditing?: boolean | null
            defaultShareAttendance?: ShareAttendance | null
            turnOnDeletingData?: boolean | null
            deleteDataAfter?: number | null
        } | null
        Offices?: {
            __typename?: 'ModelOfficeConnection'
            items: Array<{ __typename?: 'Office'; id: string; name: string } | null>
        } | null
    } | null
}

export type CreateEmployeeMutationVariables = Exact<{
    input: CreateEmployeeInput
}>

export type CreateEmployeeMutation = {
    __typename?: 'Mutation'
    createEmployee?: {
        __typename?: 'Employee'
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        EmployeeToDepartment?: {
            __typename?: 'ModelEmployeeToDepartmentConnection'
            items: Array<{
                __typename?: 'EmployeeToDepartment'
                Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
            } | null>
        } | null
        Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
    } | null
}

export type UpdateEmployeeMutationVariables = Exact<{
    input: UpdateEmployeeInput
}>

export type UpdateEmployeeMutation = {
    __typename?: 'Mutation'
    updateEmployee?: {
        __typename?: 'Employee'
        cognitoUserId?: string | null
        favouriteOfficeID?: string | null
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
        EmployeeToDepartment?: {
            __typename?: 'ModelEmployeeToDepartmentConnection'
            items: Array<{
                __typename?: 'EmployeeToDepartment'
                Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
            } | null>
        } | null
        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
        Bookings?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                reservableID?: string | null
                status?: BookingStatus | null
                employeeID: string
                companyID: string
                isTeamEvent?: boolean | null
                checkInStatus?: BookingCheckInStatus | null
                startBookingTime?: any | null
                endBookingTime?: any | null
                reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    x?: number | null
                    y?: number | null
                    availabilityType: AvailabilityType
                    type: ReservableType
                    employeeID?: string | null
                    departmentID?: string | null
                    spaceID?: string | null
                    companyID: string
                    name?: string | null
                    maxAmountOfPeople?: number | null
                    zonePoints?: Array<number | null> | null
                    color?: string | null
                    relatedReservableID?: string | null
                    RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                    ReservableToEmployees?: {
                        __typename?: 'ModelReservableToEmployeeConnection'
                        items: Array<{
                            __typename?: 'ReservableToEmployee'
                            id: string
                            employeeID: string
                            employee: {
                                __typename?: 'Employee'
                                id: string
                                firstname?: string | null
                                lastname?: string | null
                                fullName?: string | null
                                photo?: string | null
                                departmentIDs?: Array<string | null> | null
                                shareAttendance?: ShareAttendance | null
                            }
                        } | null>
                    } | null
                    ReservableToDepartments?: {
                        __typename?: 'ModelReservableToDepartmentConnection'
                        items: Array<{
                            __typename?: 'ReservableToDepartment'
                            id: string
                            departmentID: string
                            Department?: { __typename?: 'Department'; id: string; name: string } | null
                        } | null>
                    } | null
                    admin?: {
                        __typename?: 'Employee'
                        id: string
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type DeleteEmployeeMutationVariables = Exact<{
    input: DeleteEmployeeInput
}>

export type DeleteEmployeeMutation = {
    __typename?: 'Mutation'
    deleteEmployee?: {
        __typename?: 'Employee'
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
        Bookings?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                reservableID?: string | null
                status?: BookingStatus | null
                employeeID: string
                companyID: string
                isTeamEvent?: boolean | null
                checkInStatus?: BookingCheckInStatus | null
                startBookingTime?: any | null
                endBookingTime?: any | null
                reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    x?: number | null
                    y?: number | null
                    availabilityType: AvailabilityType
                    type: ReservableType
                    employeeID?: string | null
                    departmentID?: string | null
                    spaceID?: string | null
                    companyID: string
                    name?: string | null
                    maxAmountOfPeople?: number | null
                    zonePoints?: Array<number | null> | null
                    color?: string | null
                    relatedReservableID?: string | null
                    RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                    ReservableToEmployees?: {
                        __typename?: 'ModelReservableToEmployeeConnection'
                        items: Array<{
                            __typename?: 'ReservableToEmployee'
                            id: string
                            employeeID: string
                            employee: {
                                __typename?: 'Employee'
                                id: string
                                firstname?: string | null
                                lastname?: string | null
                                fullName?: string | null
                                photo?: string | null
                                departmentIDs?: Array<string | null> | null
                                shareAttendance?: ShareAttendance | null
                            }
                        } | null>
                    } | null
                    ReservableToDepartments?: {
                        __typename?: 'ModelReservableToDepartmentConnection'
                        items: Array<{
                            __typename?: 'ReservableToDepartment'
                            id: string
                            departmentID: string
                            Department?: { __typename?: 'Department'; id: string; name: string } | null
                        } | null>
                    } | null
                    admin?: {
                        __typename?: 'Employee'
                        id: string
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type AllDeleteEmployeeMutationVariables = Exact<{
    employeeIDs: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type AllDeleteEmployeeMutation = {
    __typename?: 'Mutation'
    deleteEmployees?: Array<{
        __typename?: 'EmployeeMutationResult'
        error?: string | null
        employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null
    }> | null
}

export type InviteEmployeesMutationVariables = Exact<{
    employeeIDs: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type InviteEmployeesMutation = {
    __typename?: 'Mutation'
    inviteEmployees?: Array<{
        __typename?: 'EmployeeMutationResult'
        error?: string | null
        employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null
    }> | null
}

export type CreateOfficeMutationVariables = Exact<{
    input: CreateOfficeInput
}>

export type CreateOfficeMutation = {
    __typename?: 'Mutation'
    createOffice?: {
        __typename?: 'Office'
        id: string
        companyID: string
        workingFrom?: any | null
        workingTo?: any | null
        timeZone?: string | null
    } | null
}

export type UpdateOfficeMutationVariables = Exact<{
    input: UpdateOfficeInput
}>

export type UpdateOfficeMutation = {
    __typename?: 'Mutation'
    updateOffice?: {
        __typename?: 'Office'
        id: string
        name: string
        workingFrom?: any | null
        workingTo?: any | null
        address?: string | null
        companyID: string
    } | null
}

export type DeleteOfficeMutationVariables = Exact<{
    input: DeleteOfficeInput
}>

export type DeleteOfficeMutation = {
    __typename?: 'Mutation'
    deleteOffice?: { __typename?: 'Office'; id: string; companyID: string; name: string } | null
}

export type CreateDepartmentMutationVariables = Exact<{
    input: CreateDepartmentInput
}>

export type CreateDepartmentMutation = {
    __typename?: 'Mutation'
    createDepartment?: {
        __typename?: 'Department'
        id: string
        name: string
        companyID: string
        DepartmentRules?: {
            __typename?: 'DepartmentRules'
            blockReservationOnNonOfficeDays?: boolean | null
            enableMinDaysInOffice?: boolean | null
            minDaysInOffice?: number | null
            officeDays?: Array<string | null> | null
            minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
        } | null
        TeamLead?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
        EmployeeToDepartment?: {
            __typename?: 'ModelEmployeeToDepartmentConnection'
            items: Array<{
                __typename?: 'EmployeeToDepartment'
                id: string
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    email?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    officeID?: string | null
                    positionID?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    defaultPlan?: Array<string | null> | null
                    status?: EmployeeStatus | null
                    companyID: string
                    notificationChannels?: Array<NotificationChannel | null> | null
                    createdAt?: any | null
                    role?: Role | null
                    locale?: UserLocale | null
                    shareAttendance?: ShareAttendance | null
                    favouriteColleagueIDs?: Array<string | null> | null
                    buddyID?: string | null
                    favouriteReservableID?: string | null
                    birthday?: any | null
                    turnOnExperimentalFeatures?: boolean | null
                } | null
            } | null>
        } | null
    } | null
}

export type CreateDepartmentRulesMutationVariables = Exact<{
    input: CreateDepartmentRulesInput
}>

export type CreateDepartmentRulesMutation = {
    __typename?: 'Mutation'
    createDepartmentRules?: {
        __typename?: 'DepartmentRules'
        minDaysInOffice?: number | null
        enableMinDaysInOffice?: boolean | null
        departmentID: string
        id: string
        officeDays?: Array<string | null> | null
        horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
        horizonOfPlanningTimeAmount?: number | null
    } | null
}

export type UpdateDepartRulesMutationVariables = Exact<{
    input: UpdateDepartmentRulesInput
}>

export type UpdateDepartRulesMutation = {
    __typename?: 'Mutation'
    updateDepartmentRules?: {
        __typename?: 'DepartmentRules'
        minDaysInOffice?: number | null
        enableMinDaysInOffice?: boolean | null
        departmentID: string
        id: string
        officeDays?: Array<string | null> | null
        horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
        horizonOfPlanningTimeAmount?: number | null
    } | null
}

export type UpdateDepartmentMutationVariables = Exact<{
    input: UpdateDepartmentInput
}>

export type UpdateDepartmentMutation = {
    __typename?: 'Mutation'
    updateDepartment?: {
        __typename?: 'Department'
        id: string
        name: string
        companyID: string
        DepartmentRules?: {
            __typename?: 'DepartmentRules'
            blockReservationOnNonOfficeDays?: boolean | null
            enableMinDaysInOffice?: boolean | null
            minDaysInOffice?: number | null
            officeDays?: Array<string | null> | null
            minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
        } | null
        TeamLead?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
        EmployeeToDepartment?: {
            __typename?: 'ModelEmployeeToDepartmentConnection'
            items: Array<{
                __typename?: 'EmployeeToDepartment'
                id: string
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    email?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    officeID?: string | null
                    positionID?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    defaultPlan?: Array<string | null> | null
                    status?: EmployeeStatus | null
                    companyID: string
                    notificationChannels?: Array<NotificationChannel | null> | null
                    createdAt?: any | null
                    role?: Role | null
                    locale?: UserLocale | null
                    shareAttendance?: ShareAttendance | null
                    favouriteColleagueIDs?: Array<string | null> | null
                    buddyID?: string | null
                    favouriteReservableID?: string | null
                    birthday?: any | null
                    turnOnExperimentalFeatures?: boolean | null
                } | null
            } | null>
        } | null
    } | null
}

export type DeleteDepartmentMutationVariables = Exact<{
    input: DeleteDepartmentInput
}>

export type DeleteDepartmentMutation = {
    __typename?: 'Mutation'
    deleteDepartment?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
}

export type CreatePositionMutationVariables = Exact<{
    input: CreatePositionInput
}>

export type CreatePositionMutation = {
    __typename?: 'Mutation'
    createPosition?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
}

export type UpdatePositionMutationVariables = Exact<{
    input: UpdatePositionInput
}>

export type UpdatePositionMutation = {
    __typename?: 'Mutation'
    updatePosition?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
}

export type DeletePositionMutationVariables = Exact<{
    input: DeletePositionInput
}>

export type DeletePositionMutation = {
    __typename?: 'Mutation'
    deletePosition?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
}

export type CreateSpaceMutationVariables = Exact<{
    input: CreateSpaceInput
}>

export type CreateSpaceMutation = {
    __typename?: 'Mutation'
    createSpace?: {
        __typename?: 'Space'
        id: string
        floorPlanFilePath?: string | null
        name?: string | null
        address?: string | null
        workingHoursFrom?: any | null
        workingHoursTo?: any | null
        officeID: string
        companyID: string
        Office?: { __typename?: 'Office'; name: string; id: string } | null
        Reservables?: {
            __typename?: 'ModelReservableConnection'
            items: Array<{
                __typename?: 'Reservable'
                id: string
                x?: number | null
                y?: number | null
                availabilityType: AvailabilityType
                type: ReservableType
                employeeID?: string | null
                departmentID?: string | null
                spaceID?: string | null
                companyID: string
                name?: string | null
                maxAmountOfPeople?: number | null
                zonePoints?: Array<number | null> | null
                color?: string | null
                relatedReservableID?: string | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    email?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    officeID?: string | null
                    positionID?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    defaultPlan?: Array<string | null> | null
                    status?: EmployeeStatus | null
                    companyID: string
                    notificationChannels?: Array<NotificationChannel | null> | null
                    createdAt?: any | null
                    role?: Role | null
                    locale?: UserLocale | null
                    shareAttendance?: ShareAttendance | null
                    favouriteColleagueIDs?: Array<string | null> | null
                    buddyID?: string | null
                    favouriteReservableID?: string | null
                    birthday?: any | null
                    turnOnExperimentalFeatures?: boolean | null
                    Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
                } | null
                Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
                RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                ReservableToEmployees?: {
                    __typename?: 'ModelReservableToEmployeeConnection'
                    items: Array<{
                        __typename?: 'ReservableToEmployee'
                        id: string
                        employeeID: string
                        employee: {
                            __typename?: 'Employee'
                            id: string
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            photo?: string | null
                            departmentIDs?: Array<string | null> | null
                            shareAttendance?: ShareAttendance | null
                        }
                    } | null>
                } | null
                ReservableToDepartments?: {
                    __typename?: 'ModelReservableToDepartmentConnection'
                    items: Array<{
                        __typename?: 'ReservableToDepartment'
                        id: string
                        departmentID: string
                        Department?: { __typename?: 'Department'; id: string; name: string } | null
                    } | null>
                } | null
                admin?: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                } | null
            } | null>
        } | null
    } | null
}

export type UpdateSpaceMutationVariables = Exact<{
    input: UpdateSpaceInput
}>

export type UpdateSpaceMutation = {
    __typename?: 'Mutation'
    updateSpace?: {
        __typename?: 'Space'
        id: string
        floorPlanFilePath?: string | null
        name?: string | null
        address?: string | null
        workingHoursFrom?: any | null
        workingHoursTo?: any | null
        officeID: string
        companyID: string
        Office?: { __typename?: 'Office'; name: string; id: string } | null
        Reservables?: {
            __typename?: 'ModelReservableConnection'
            items: Array<{
                __typename?: 'Reservable'
                id: string
                x?: number | null
                y?: number | null
                availabilityType: AvailabilityType
                type: ReservableType
                employeeID?: string | null
                departmentID?: string | null
                spaceID?: string | null
                companyID: string
                name?: string | null
                maxAmountOfPeople?: number | null
                zonePoints?: Array<number | null> | null
                color?: string | null
                relatedReservableID?: string | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    email?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    officeID?: string | null
                    positionID?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    defaultPlan?: Array<string | null> | null
                    status?: EmployeeStatus | null
                    companyID: string
                    notificationChannels?: Array<NotificationChannel | null> | null
                    createdAt?: any | null
                    role?: Role | null
                    locale?: UserLocale | null
                    shareAttendance?: ShareAttendance | null
                    favouriteColleagueIDs?: Array<string | null> | null
                    buddyID?: string | null
                    favouriteReservableID?: string | null
                    birthday?: any | null
                    turnOnExperimentalFeatures?: boolean | null
                    Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
                } | null
                Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
                RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                ReservableToEmployees?: {
                    __typename?: 'ModelReservableToEmployeeConnection'
                    items: Array<{
                        __typename?: 'ReservableToEmployee'
                        id: string
                        employeeID: string
                        employee: {
                            __typename?: 'Employee'
                            id: string
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            photo?: string | null
                            departmentIDs?: Array<string | null> | null
                            shareAttendance?: ShareAttendance | null
                        }
                    } | null>
                } | null
                ReservableToDepartments?: {
                    __typename?: 'ModelReservableToDepartmentConnection'
                    items: Array<{
                        __typename?: 'ReservableToDepartment'
                        id: string
                        departmentID: string
                        Department?: { __typename?: 'Department'; id: string; name: string } | null
                    } | null>
                } | null
                admin?: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                } | null
            } | null>
        } | null
    } | null
}

export type CreateReservableMutationVariables = Exact<{
    input: CreateReservableInput
}>

export type CreateReservableMutation = {
    __typename?: 'Mutation'
    createReservable?: {
        __typename?: 'Reservable'
        id: string
        x?: number | null
        y?: number | null
        availabilityType: AvailabilityType
        type: ReservableType
        employeeID?: string | null
        departmentID?: string | null
        spaceID?: string | null
        companyID: string
        name?: string | null
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        relatedReservableID?: string | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null
        Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
        RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
        ReservableToEmployees?: {
            __typename?: 'ModelReservableToEmployeeConnection'
            items: Array<{
                __typename?: 'ReservableToEmployee'
                id: string
                employeeID: string
                employee: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    departmentIDs?: Array<string | null> | null
                    shareAttendance?: ShareAttendance | null
                }
            } | null>
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                id: string
                departmentID: string
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        admin?: {
            __typename?: 'Employee'
            id: string
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
        } | null
    } | null
}

export type CreateReservableToEmployeeMutationVariables = Exact<{
    input: CreateReservableToEmployeeInput
}>

export type CreateReservableToEmployeeMutation = {
    __typename?: 'Mutation'
    createReservableToEmployee?: {
        __typename?: 'ReservableToEmployee'
        id: string
        employeeID: string
        employee: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        }
    } | null
}

export type CreateReservableToDepartmentMutationVariables = Exact<{
    input: CreateReservableToDepartmentInput
}>

export type CreateReservableToDepartmentMutation = {
    __typename?: 'Mutation'
    createReservableToDepartment?: {
        __typename?: 'ReservableToDepartment'
        id: string
        departmentID: string
        Department?: { __typename?: 'Department'; id: string; name: string } | null
    } | null
}

export type DeleteReservableToEmployeeMutationVariables = Exact<{
    input: DeleteReservableToEmployeeInput
}>

export type DeleteReservableToEmployeeMutation = {
    __typename?: 'Mutation'
    deleteReservableToEmployee?: { __typename?: 'ReservableToEmployee'; id: string } | null
}

export type DeleteReservableToDepartmentMutationVariables = Exact<{
    input: DeleteReservableToDepartmentInput
}>

export type DeleteReservableToDepartmentMutation = {
    __typename?: 'Mutation'
    deleteReservableToDepartment?: { __typename?: 'ReservableToDepartment'; id: string } | null
}

export type UpdateReservableMutationVariables = Exact<{
    input: UpdateReservableInput
}>

export type UpdateReservableMutation = {
    __typename?: 'Mutation'
    updateReservable?: {
        __typename?: 'Reservable'
        id: string
        x?: number | null
        y?: number | null
        availabilityType: AvailabilityType
        type: ReservableType
        employeeID?: string | null
        departmentID?: string | null
        spaceID?: string | null
        companyID: string
        name?: string | null
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        relatedReservableID?: string | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null
        Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
        admin?: {
            __typename?: 'Employee'
            id: string
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            email?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
        RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
        ReservableToEmployees?: {
            __typename?: 'ModelReservableToEmployeeConnection'
            items: Array<{
                __typename?: 'ReservableToEmployee'
                id: string
                employeeID: string
                employee: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    departmentIDs?: Array<string | null> | null
                    shareAttendance?: ShareAttendance | null
                }
            } | null>
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                id: string
                departmentID: string
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
    } | null
}

export type DeleteReservableMutationVariables = Exact<{
    input: DeleteReservableInput
}>

export type DeleteReservableMutation = {
    __typename?: 'Mutation'
    deleteReservable?: {
        __typename?: 'Reservable'
        id: string
        x?: number | null
        y?: number | null
        availabilityType: AvailabilityType
        type: ReservableType
        employeeID?: string | null
        departmentID?: string | null
        spaceID?: string | null
        companyID: string
        name?: string | null
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        relatedReservableID?: string | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null
        Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
        RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
        ReservableToEmployees?: {
            __typename?: 'ModelReservableToEmployeeConnection'
            items: Array<{
                __typename?: 'ReservableToEmployee'
                id: string
                employeeID: string
                employee: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    departmentIDs?: Array<string | null> | null
                    shareAttendance?: ShareAttendance | null
                }
            } | null>
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                id: string
                departmentID: string
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        admin?: {
            __typename?: 'Employee'
            id: string
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
        } | null
    } | null
}

export type InitialisePersonioIntegrationMutationVariables = Exact<{
    input: PersonioIntegrationInput
}>

export type InitialisePersonioIntegrationMutation = {
    __typename?: 'Mutation'
    initialisePersonioIntegration: {
        __typename?: 'PersonioIntegration'
        companyID: string
        clientID: string
        clientSecret: string
        status?: PersonioIntegrationStatus | null
        error?: string | null
        nextSyncAt?: any | null
        lastSyncedAt?: any | null
        hasInvitationEnabled: boolean
        hasSyncBookingsToPersonioEnabled: boolean
    }
}

export type DisablePersonioIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DisablePersonioIntegrationMutation = {
    __typename?: 'Mutation'
    disablePersonioIntegration: {
        __typename?: 'PersonioIntegration'
        companyID: string
        clientID: string
        clientSecret: string
        status?: PersonioIntegrationStatus | null
        error?: string | null
        nextSyncAt?: any | null
        lastSyncedAt?: any | null
        hasInvitationEnabled: boolean
        hasSyncBookingsToPersonioEnabled: boolean
    }
}

export type ForceSyncPersonioIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type ForceSyncPersonioIntegrationMutation = {
    __typename?: 'Mutation'
    forceSyncPersonioIntegration: {
        __typename?: 'PersonioIntegration'
        companyID: string
        clientID: string
        clientSecret: string
        status?: PersonioIntegrationStatus | null
        error?: string | null
        nextSyncAt?: any | null
        lastSyncedAt?: any | null
        hasInvitationEnabled: boolean
        hasSyncBookingsToPersonioEnabled: boolean
    }
}

export type UpdatePersonioIntegrationMutationVariables = Exact<{
    input: UpdatePersonioIntegrationInput
}>

export type UpdatePersonioIntegrationMutation = {
    __typename?: 'Mutation'
    updatePersonioIntegration: {
        __typename?: 'PersonioIntegration'
        companyID: string
        clientID: string
        clientSecret: string
        status?: PersonioIntegrationStatus | null
        error?: string | null
        nextSyncAt?: any | null
        lastSyncedAt?: any | null
        hasInvitationEnabled: boolean
        hasSyncBookingsToPersonioEnabled: boolean
    }
}

export type UpdateSlackIntegrationMutationVariables = Exact<{
    input: SlackIntegrationUpdateInput
}>

export type UpdateSlackIntegrationMutation = {
    __typename?: 'Mutation'
    updateSlackIntegration: {
        __typename?: 'SlackIntegration'
        companyID: string
        sendDayScheduleAt?: any | null
        enableCheckInFeature: boolean
        enableSendingDaySchedule?: boolean | null
    }
}

export type InitiateBambooHrIntegrationMutationVariables = Exact<{
    input: BambooHrIntegrationInput
}>

export type InitiateBambooHrIntegrationMutation = {
    __typename?: 'Mutation'
    initiateBambooHRIntegration: {
        __typename?: 'BambooHRIntegration'
        companyId: string
        hasInvitationEnabled: boolean
        syncStatus: SyncStatus
        lastSyncAt?: any | null
        companyDomain: string
    }
}

export type ToggleInvitationForBambooHrIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type ToggleInvitationForBambooHrIntegrationMutation = {
    __typename?: 'Mutation'
    toggleInvitationForBambooHRIntegration?: string | null
}

export type DeleteBambooHrIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DeleteBambooHrIntegrationMutation = { __typename?: 'Mutation'; deleteBambooHRIntegration?: string | null }

export type DeleteSlackIntegrationMutationVariables = Exact<{ [key: string]: never }>

export type DeleteSlackIntegrationMutation = {
    __typename?: 'Mutation'
    deleteSlackIntegration: {
        __typename?: 'SlackIntegration'
        companyID: string
        sendDayScheduleAt?: any | null
        enableCheckInFeature: boolean
        enableSendingDaySchedule?: boolean | null
    }
}

export type StartTrialMutationVariables = Exact<{
    subscriptionPlanId: Scalars['String']['input']
}>

export type StartTrialMutation = { __typename?: 'Mutation'; startTrial?: string | null }

export type UpdateRulesMutationVariables = Exact<{
    input: UpdateRulesInput
}>

export type UpdateRulesMutation = {
    __typename?: 'Mutation'
    updateRules?: {
        __typename?: 'Rules'
        id: string
        enableMinDaysInOffice?: boolean | null
        minDaysInOffice?: number | null
        minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
        maxDaysInOffice?: number | null
        homeIsDefault: boolean
        turnOnCheckIn?: boolean | null
        autoAssignWaitingList: boolean
        horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
        horizonOfPlanningTimeAmount?: number | null
        denyRecurringBookingOverThePlanningHorizon?: boolean | null
        startTimeOfNotifications?: number | null
        checkInWaitingTime?: number | null
        checkInCancellingTime?: number | null
        turnOnAttendanceNotifications?: boolean | null
        dayOfAttendanceNotifications?: number | null
        hourOfAttendanceNotifications?: number | null
        updateEmployeeDataFromSource?: boolean | null
        turnOffCheckInForPersonalSeats?: boolean | null
        isPersonalSeatAvailableToday?: boolean | null
        isTeamSeatAvailableToday?: boolean | null
        turnOnMonthlyReport?: boolean | null
        emailForMonthlyReport?: string | null
        workingDays?: Array<string | null> | null
        blockReservationOnNonWorkingDays?: boolean | null
        disableProfileEditing?: boolean | null
        defaultShareAttendance?: ShareAttendance | null
        turnOnDeletingData?: boolean | null
        deleteDataAfter?: number | null
    } | null
}

export type CreateTeamEventRequestMutationVariables = Exact<{
    input: CreateTeamEventRequestInput
}>

export type CreateTeamEventRequestMutation = {
    __typename?: 'Mutation'
    createTeamEventRequest?: {
        __typename?: 'TeamEventRequest'
        id: string
        date: any
        title: string
        description?: string | null
        reservableID?: string | null
        spaceID: string
        companyID: string
        repeatType?: RepeatType | null
    } | null
}

export type CreateCalendarEventsMutationVariables = Exact<{
    input: CreateCalendarEventsInput
}>

export type CreateCalendarEventsMutation = {
    __typename?: 'Mutation'
    createCalendarEvents?: {
        __typename?: 'CalendarEvents'
        id: string
        title?: string | null
        reservableID?: string | null
        description?: string | null
        employeesIDs: Array<string>
        startTime: any
        endTime: any
        canCurrentUserEdit?: boolean | null
        spaceID?: string | null
        companyID: string
        date?: string | null
        isOnlineMeeting?: boolean | null
        onlineMeetingUrl?: string | null
        isPrivate?: boolean | null
    } | null
}

export type CreateCalendarEventRequestMutationVariables = Exact<{
    input: CreateCalendarEventRequestInput
}>

export type CreateCalendarEventRequestMutation = {
    __typename?: 'Mutation'
    createCalendarEventRequest?: {
        __typename?: 'CalendarEventRequest'
        id: string
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        organiserEmployeeID: string
        title?: string | null
        description?: string | null
        status?: CalendarEventRequestStatus | null
        repeatType?: RepeatType | null
        dates?: Array<any> | null
        daysOfWeek?: Array<string> | null
        OrganiserEmployee?: {
            __typename?: 'Employee'
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Employees?: {
            __typename?: 'ModelCalendarEventEmployeeConnection'
            items: Array<{
                __typename?: 'CalendarEventEmployee'
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
            } | null>
        } | null
        Catering?: { __typename?: 'Catering'; id: string; enabled: boolean; title?: string | null } | null
        menuItems?: Array<{
            __typename?: 'MenuItemModel'
            id: string
            description?: string | null
            title?: string | null
            quantity?: number | null
        }> | null
    } | null
}

export type UpdateCalendarEventsMutationVariables = Exact<{
    input: UpdateCalendarEventsInput
}>

export type UpdateCalendarEventsMutation = {
    __typename?: 'Mutation'
    updateCalendarEvents?: {
        __typename?: 'CalendarEvents'
        id: string
        title?: string | null
        reservableID?: string | null
        description?: string | null
        employeesIDs: Array<string>
        startTime: any
        endTime: any
        canCurrentUserEdit?: boolean | null
        spaceID?: string | null
        companyID: string
        date?: string | null
        isOnlineMeeting?: boolean | null
        onlineMeetingUrl?: string | null
        isPrivate?: boolean | null
    } | null
}

export type UpdateCalendarEventRequestMutationVariables = Exact<{
    input: UpdateCalendarEventRequestInput
}>

export type UpdateCalendarEventRequestMutation = {
    __typename?: 'Mutation'
    updateCalendarEventRequest?: {
        __typename?: 'CalendarEventRequest'
        id: string
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        organiserEmployeeID: string
        title?: string | null
        description?: string | null
        status?: CalendarEventRequestStatus | null
        repeatType?: RepeatType | null
        dates?: Array<any> | null
        daysOfWeek?: Array<string> | null
        OrganiserEmployee?: {
            __typename?: 'Employee'
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Employees?: {
            __typename?: 'ModelCalendarEventEmployeeConnection'
            items: Array<{
                __typename?: 'CalendarEventEmployee'
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
            } | null>
        } | null
        Catering?: { __typename?: 'Catering'; id: string; enabled: boolean; title?: string | null } | null
        menuItems?: Array<{
            __typename?: 'MenuItemModel'
            id: string
            description?: string | null
            title?: string | null
            quantity?: number | null
        }> | null
    } | null
}

export type DeleteCalendarEventsMutationVariables = Exact<{
    input: DeleteCalendarEventsInput
}>

export type DeleteCalendarEventsMutation = {
    __typename?: 'Mutation'
    deleteCalendarEvents?: { __typename?: 'CalendarEvents'; id: string; companyID: string; date?: string | null } | null
}

export type CreateOfficeBuilderMutationVariables = Exact<{
    input: CreateOfficeBuilderRequestInput
}>

export type CreateOfficeBuilderMutation = {
    __typename?: 'Mutation'
    createOfficeBuilderRequest?: { __typename?: 'OfficeBuilderRequest'; id: string; companyID: string } | null
}

export type DeleteSpaceMutationVariables = Exact<{
    input: DeleteSpaceInput
}>

export type DeleteSpaceMutation = {
    __typename?: 'Mutation'
    deleteSpace?: { __typename?: 'Space'; id: string; name?: string | null; companyID: string } | null
}

export type CreateCateringMutationVariables = Exact<{
    input: CreateCateringInput
}>

export type CreateCateringMutation = {
    __typename?: 'Mutation'
    createCatering?: {
        __typename?: 'Catering'
        id: string
        title?: string | null
        email: string
        enabled: boolean
        officeID?: string | null
        MenuItems?: {
            __typename?: 'ModelMenuItemConnection'
            items: Array<{
                __typename?: 'MenuItem'
                id: string
                enabled: boolean
                title: string
                description?: string | null
            } | null>
        } | null
    } | null
}

export type UpdateCateringMutationVariables = Exact<{
    input: UpdateCateringInput
}>

export type UpdateCateringMutation = {
    __typename?: 'Mutation'
    updateCatering?: {
        __typename?: 'Catering'
        id: string
        title?: string | null
        email: string
        enabled: boolean
        officeID?: string | null
        MenuItems?: {
            __typename?: 'ModelMenuItemConnection'
            items: Array<{
                __typename?: 'MenuItem'
                id: string
                enabled: boolean
                title: string
                description?: string | null
            } | null>
        } | null
    } | null
}

export type DeleteCateringMutationVariables = Exact<{
    input: DeleteCateringInput
}>

export type DeleteCateringMutation = {
    __typename?: 'Mutation'
    deleteCatering?: {
        __typename?: 'Catering'
        id: string
        title?: string | null
        email: string
        enabled: boolean
        MenuItems?: {
            __typename?: 'ModelMenuItemConnection'
            items: Array<{
                __typename?: 'MenuItem'
                id: string
                enabled: boolean
                title: string
                description?: string | null
            } | null>
        } | null
    } | null
}

export type CreateMenuItemMutationVariables = Exact<{
    input: CreateMenuItemInput
}>

export type CreateMenuItemMutation = {
    __typename?: 'Mutation'
    createMenuItem?: { __typename?: 'MenuItem'; id: string; title: string; description?: string | null } | null
}

export type UpdateMenuItemMutationVariables = Exact<{
    input: UpdateMenuItemInput
}>

export type UpdateMenuItemMutation = {
    __typename?: 'Mutation'
    updateMenuItem?: { __typename?: 'MenuItem'; id: string; title: string; description?: string | null } | null
}

export type DeleteMenuItemMutationVariables = Exact<{
    input: DeleteMenuItemInput
}>

export type DeleteMenuItemMutation = {
    __typename?: 'Mutation'
    deleteMenuItem?: { __typename?: 'MenuItem'; id: string; title: string; description?: string | null } | null
}

export type CreateResourceMutationVariables = Exact<{
    input: CreateEquipmentInput
}>

export type CreateResourceMutation = {
    __typename?: 'Mutation'
    createEquipment?: {
        __typename?: 'Equipment'
        id: string
        name?: string | null
        description?: string | null
        quantity?: number | null
        canBookedSeparately?: boolean | null
        officeID?: string | null
        companyID: string
        enabled?: boolean | null
        EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
        EquipmentImages?: {
            __typename?: 'ModelEquipmentImageConnection'
            items: Array<{
                __typename?: 'EquipmentImage'
                id: string
                name?: string | null
                location?: string | null
            } | null>
        } | null
    } | null
}

export type UpdateResourceMutationVariables = Exact<{
    input: UpdateEquipmentInput
}>

export type UpdateResourceMutation = {
    __typename?: 'Mutation'
    updateEquipment?: {
        __typename?: 'Equipment'
        id: string
        name?: string | null
        description?: string | null
        quantity?: number | null
        canBookedSeparately?: boolean | null
        officeID?: string | null
        companyID: string
        enabled?: boolean | null
        EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
        EquipmentImages?: {
            __typename?: 'ModelEquipmentImageConnection'
            items: Array<{
                __typename?: 'EquipmentImage'
                id: string
                name?: string | null
                location?: string | null
            } | null>
        } | null
    } | null
}

export type DeleteResourceMutationVariables = Exact<{
    input: DeleteEquipmentInput
}>

export type DeleteResourceMutation = {
    __typename?: 'Mutation'
    deleteEquipment?: {
        __typename?: 'Equipment'
        id: string
        name?: string | null
        description?: string | null
        quantity?: number | null
        canBookedSeparately?: boolean | null
        officeID?: string | null
        companyID: string
        enabled?: boolean | null
        EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
        EquipmentImages?: {
            __typename?: 'ModelEquipmentImageConnection'
            items: Array<{
                __typename?: 'EquipmentImage'
                id: string
                name?: string | null
                location?: string | null
            } | null>
        } | null
    } | null
}

export type CreateBookingEquipmentMutationVariables = Exact<{
    input: CreateBookingEquipmentInput
}>

export type CreateBookingEquipmentMutation = {
    __typename?: 'Mutation'
    createBookingEquipment?: {
        __typename?: 'BookingEquipment'
        id: string
        owner?: string | null
        officeID?: string | null
        companyID: string
        startTime: any
        endTime: any
        employeeID?: string | null
        equipmentID?: string | null
        Equipment?: {
            __typename?: 'Equipment'
            id: string
            name?: string | null
            description?: string | null
            quantity?: number | null
            canBookedSeparately?: boolean | null
            officeID?: string | null
            companyID: string
            enabled?: boolean | null
            EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
            EquipmentImages?: {
                __typename?: 'ModelEquipmentImageConnection'
                items: Array<{
                    __typename?: 'EquipmentImage'
                    id: string
                    name?: string | null
                    location?: string | null
                } | null>
            } | null
        } | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
    } | null
}

export type UpdateBookingEquipmentMutationVariables = Exact<{
    input: UpdateBookingEquipmentInput
}>

export type UpdateBookingEquipmentMutation = {
    __typename?: 'Mutation'
    updateBookingEquipment?: {
        __typename?: 'BookingEquipment'
        id: string
        owner?: string | null
        officeID?: string | null
        companyID: string
        startTime: any
        endTime: any
        employeeID?: string | null
        equipmentID?: string | null
        Equipment?: {
            __typename?: 'Equipment'
            id: string
            name?: string | null
            description?: string | null
            quantity?: number | null
            canBookedSeparately?: boolean | null
            officeID?: string | null
            companyID: string
            enabled?: boolean | null
            EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
            EquipmentImages?: {
                __typename?: 'ModelEquipmentImageConnection'
                items: Array<{
                    __typename?: 'EquipmentImage'
                    id: string
                    name?: string | null
                    location?: string | null
                } | null>
            } | null
        } | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
    } | null
}

export type DeleteBookingEquipmentMutationVariables = Exact<{
    input: DeleteBookingEquipmentInput
}>

export type DeleteBookingEquipmentMutation = {
    __typename?: 'Mutation'
    deleteBookingEquipment?: {
        __typename?: 'BookingEquipment'
        id: string
        owner?: string | null
        officeID?: string | null
        companyID: string
        startTime: any
        endTime: any
        employeeID?: string | null
        equipmentID?: string | null
        Equipment?: {
            __typename?: 'Equipment'
            id: string
            name?: string | null
            description?: string | null
            quantity?: number | null
            canBookedSeparately?: boolean | null
            officeID?: string | null
            companyID: string
            enabled?: boolean | null
            EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
            EquipmentImages?: {
                __typename?: 'ModelEquipmentImageConnection'
                items: Array<{
                    __typename?: 'EquipmentImage'
                    id: string
                    name?: string | null
                    location?: string | null
                } | null>
            } | null
        } | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
    } | null
}

export type UpdateBookingRequestMutationVariables = Exact<{
    input: UpdateBookingRequestInput
}>

export type UpdateBookingRequestMutation = {
    __typename?: 'Mutation'
    customUpdateBookingRequest?: {
        __typename?: 'BookingRequest'
        id: string
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        status?: BookingRequestStatus | null
        employeeID: string
        spaceID?: string | null
        companyID: string
        owner?: string | null
        isTeamEvent?: boolean | null
        reservable?: {
            __typename?: 'Reservable'
            id: string
            type: ReservableType
            Space?: { __typename?: 'Space'; name?: string | null } | null
        } | null
    } | null
}

export type GetCompanyAndEmployeeQueryVariables = Exact<{
    id: Scalars['ID']['input']
    employeeId: Scalars['ID']['input']
}>

export type GetCompanyAndEmployeeQuery = {
    __typename?: 'Query'
    getCompany?: {
        __typename?: 'Company'
        id: string
        title?: string | null
        country?: string | null
        city?: string | null
        address?: string | null
        zipcode?: string | null
        phoneNumber?: string | null
        numberOfOffices?: number | null
        numberOfEmployees?: number | null
        companyLogo?: string | null
        trialExpiresAt?: any | null
        tariff?: Tariff | null
        calendarIntegration?: CalendarType | null
        Rules?: {
            __typename?: 'Rules'
            id: string
            enableMinDaysInOffice?: boolean | null
            minDaysInOffice?: number | null
            minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
            maxDaysInOffice?: number | null
            homeIsDefault: boolean
            turnOnCheckIn?: boolean | null
            autoAssignWaitingList: boolean
            horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
            horizonOfPlanningTimeAmount?: number | null
            denyRecurringBookingOverThePlanningHorizon?: boolean | null
            startTimeOfNotifications?: number | null
            checkInWaitingTime?: number | null
            checkInCancellingTime?: number | null
            turnOnAttendanceNotifications?: boolean | null
            dayOfAttendanceNotifications?: number | null
            hourOfAttendanceNotifications?: number | null
            updateEmployeeDataFromSource?: boolean | null
            turnOffCheckInForPersonalSeats?: boolean | null
            isPersonalSeatAvailableToday?: boolean | null
            isTeamSeatAvailableToday?: boolean | null
            turnOnMonthlyReport?: boolean | null
            emailForMonthlyReport?: string | null
            workingDays?: Array<string | null> | null
            blockReservationOnNonWorkingDays?: boolean | null
            disableProfileEditing?: boolean | null
            defaultShareAttendance?: ShareAttendance | null
            turnOnDeletingData?: boolean | null
            deleteDataAfter?: number | null
        } | null
        Offices?: {
            __typename?: 'ModelOfficeConnection'
            items: Array<{
                __typename?: 'Office'
                id: string
                name: string
                workingFrom?: any | null
                workingTo?: any | null
            } | null>
        } | null
        CustomRoles?: {
            __typename?: 'ModelCustomRoleConnection'
            items: Array<{
                __typename?: 'CustomRole'
                id: string
                name: string
                description?: string | null
                Permissions?: {
                    __typename?: 'ModelPermissionConnection'
                    items: Array<{
                        __typename?: 'Permission'
                        id: string
                        resourceType: ResourceType
                        allowedActions: Array<ActionType>
                        scope?: Scope | null
                    } | null>
                } | null
            } | null>
        } | null
    } | null
    getEmployee?: {
        __typename?: 'Employee'
        id: string
        firstname?: string | null
        lastname?: string | null
        cognitoUserId?: string | null
        email?: string | null
        photo?: string | null
        role?: Role | null
        favouriteOfficeID?: string | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        locale?: UserLocale | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        notificationChannels?: Array<NotificationChannel | null> | null
        shareAttendance?: ShareAttendance | null
        demoAccountID?: string | null
        turnOnExperimentalFeatures?: boolean | null
        Role?: { __typename?: 'CustomRole'; id: string; name: string } | null
        Position?: { __typename?: 'Position'; id: string; name: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string } | null
    } | null
}

export type ListEmployeesQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    nextToken?: InputMaybe<Scalars['String']['input']>
}>

export type ListEmployeesQuery = {
    __typename?: 'Query'
    listEmployeesByCompanyAndEmail?: {
        __typename?: 'ModelEmployeeConnection'
        nextToken?: string | null
        items: Array<{
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null>
    } | null
}

export type GetEmployeesByCompanyAndStatusAndFirstnameQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
}>

export type GetEmployeesByCompanyAndStatusAndFirstnameQuery = {
    __typename?: 'Query'
    listEmployeesByCompanyAndFirstname?: {
        __typename?: 'ModelEmployeeConnection'
        items: Array<{
            __typename?: 'Employee'
            id: string
            firstname?: string | null
            lastname?: string | null
            photo?: string | null
        } | null>
    } | null
}

export type GetContactTracingCsvQueryVariables = Exact<{
    employeeID: Scalars['ID']['input']
    date: Scalars['AWSDate']['input']
}>

export type GetContactTracingCsvQuery = { __typename?: 'Query'; getContactTracingCsv: string }

export type GetS3LinkQueryVariables = Exact<{
    operationType: S3OperationType
    path: Scalars['String']['input']
    resize?: InputMaybe<ImageResizeInput>
}>

export type GetS3LinkQuery = { __typename?: 'Query'; getS3SignedLink: string }

export type ListReservablesByCompanyAndTypeQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    type: Scalars['String']['input']
}>

export type ListReservablesByCompanyAndTypeQuery = {
    __typename?: 'Query'
    listReservablesByCompanyAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{ __typename?: 'Reservable'; id: string; type: ReservableType } | null>
    } | null
}

export type ListSpacesQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
}>

export type ListSpacesQuery = {
    __typename?: 'Query'
    listSpacesByCompanyAndName?: {
        __typename?: 'ModelSpaceConnection'
        items: Array<{
            __typename?: 'Space'
            id: string
            floorPlanFilePath?: string | null
            name?: string | null
            address?: string | null
            workingHoursFrom?: any | null
            workingHoursTo?: any | null
            officeID: string
            companyID: string
            Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
            Reservables?: {
                __typename?: 'ModelReservableConnection'
                items: Array<{
                    __typename?: 'Reservable'
                    id: string
                    x?: number | null
                    y?: number | null
                    availabilityType: AvailabilityType
                    type: ReservableType
                    employeeID?: string | null
                    departmentID?: string | null
                    spaceID?: string | null
                    companyID: string
                    name?: string | null
                    maxAmountOfPeople?: number | null
                    zonePoints?: Array<number | null> | null
                    color?: string | null
                    relatedReservableID?: string | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        email?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                        officeID?: string | null
                        positionID?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        defaultPlan?: Array<string | null> | null
                        status?: EmployeeStatus | null
                        companyID: string
                        notificationChannels?: Array<NotificationChannel | null> | null
                        createdAt?: any | null
                        role?: Role | null
                        locale?: UserLocale | null
                        shareAttendance?: ShareAttendance | null
                        favouriteColleagueIDs?: Array<string | null> | null
                        buddyID?: string | null
                        favouriteReservableID?: string | null
                        birthday?: any | null
                        turnOnExperimentalFeatures?: boolean | null
                        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
                    } | null
                    Department?: { __typename?: 'Department'; name: string } | null
                    RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                    ReservableToEmployees?: {
                        __typename?: 'ModelReservableToEmployeeConnection'
                        items: Array<{
                            __typename?: 'ReservableToEmployee'
                            id: string
                            employeeID: string
                            employee: {
                                __typename?: 'Employee'
                                id: string
                                firstname?: string | null
                                lastname?: string | null
                                fullName?: string | null
                                photo?: string | null
                                departmentIDs?: Array<string | null> | null
                                shareAttendance?: ShareAttendance | null
                            }
                        } | null>
                    } | null
                    ReservableToDepartments?: {
                        __typename?: 'ModelReservableToDepartmentConnection'
                        items: Array<{
                            __typename?: 'ReservableToDepartment'
                            id: string
                            departmentID: string
                            Department?: { __typename?: 'Department'; id: string; name: string } | null
                        } | null>
                    } | null
                    admin?: {
                        __typename?: 'Employee'
                        id: string
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type ListDepartmentsQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    filter?: InputMaybe<ModelDepartmentFilterInput>
}>

export type ListDepartmentsQuery = {
    __typename?: 'Query'
    listDepartmentsByCompanyAndName?: {
        __typename?: 'ModelDepartmentConnection'
        items: Array<{
            __typename?: 'Department'
            id: string
            name: string
            companyID: string
            DepartmentRules?: {
                __typename?: 'DepartmentRules'
                blockReservationOnNonOfficeDays?: boolean | null
                enableMinDaysInOffice?: boolean | null
                minDaysInOffice?: number | null
                officeDays?: Array<string | null> | null
                minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
            } | null
            EmployeeToDepartment?: {
                __typename?: 'ModelEmployeeToDepartmentConnection'
                nextToken?: string | null
                items: Array<{
                    __typename?: 'EmployeeToDepartment'
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        email?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                        officeID?: string | null
                        positionID?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        defaultPlan?: Array<string | null> | null
                        status?: EmployeeStatus | null
                        companyID: string
                        notificationChannels?: Array<NotificationChannel | null> | null
                        createdAt?: any | null
                        role?: Role | null
                        locale?: UserLocale | null
                        shareAttendance?: ShareAttendance | null
                        favouriteColleagueIDs?: Array<string | null> | null
                        buddyID?: string | null
                        favouriteReservableID?: string | null
                        birthday?: any | null
                        turnOnExperimentalFeatures?: boolean | null
                    } | null
                } | null>
            } | null
            TeamLead?: {
                __typename?: 'Employee'
                id: string
                email?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                photo?: string | null
                officeID?: string | null
                positionID?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                defaultPlan?: Array<string | null> | null
                status?: EmployeeStatus | null
                companyID: string
                notificationChannels?: Array<NotificationChannel | null> | null
                createdAt?: any | null
                role?: Role | null
                locale?: UserLocale | null
                shareAttendance?: ShareAttendance | null
                favouriteColleagueIDs?: Array<string | null> | null
                buddyID?: string | null
                favouriteReservableID?: string | null
                birthday?: any | null
                turnOnExperimentalFeatures?: boolean | null
            } | null
        } | null>
    } | null
}

export type ListPositionsQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
}>

export type ListPositionsQuery = {
    __typename?: 'Query'
    listPositionsByCompanyAndName?: {
        __typename?: 'ModelPositionConnection'
        items: Array<{ __typename?: 'Position'; id: string; name: string; companyID: string } | null>
    } | null
}

export type GetPaylinkUrlForCheckoutQueryVariables = Exact<{
    planID: Scalars['String']['input']
    returnUrl: Scalars['String']['input']
}>

export type GetPaylinkUrlForCheckoutQuery = {
    __typename?: 'Query'
    getPaylinkUrlForCheckout: { __typename?: 'PaylinkUrlForCheckout'; url: string }
}

export type GetSubscriptionsForCompanyQueryVariables = Exact<{ [key: string]: never }>

export type GetSubscriptionsForCompanyQuery = {
    __typename?: 'Query'
    getSubscriptionsForCompany: {
        __typename?: 'SubscribedBillingItems'
        items?: Array<{
            __typename?: 'SubscribedBilling'
            subscriptionId: string
            companyId: string
            status: BillingSubscriptionStatus
            subscriptionPlanId: string
            expiresAt: any
        } | null> | null
    }
}

export type ListPaymentsQueryVariables = Exact<{
    subscriptionId: Scalars['String']['input']
}>

export type ListPaymentsQuery = {
    __typename?: 'Query'
    listPayments?: Array<{
        __typename?: 'BillingPayment'
        id: string
        subscriptionId: string
        amount: number
        currency: string
        payoutDate: string
        invoiceUrl: string
    }> | null
}

export type ListSubscriptionsPlansQueryVariables = Exact<{ [key: string]: never }>

export type ListSubscriptionsPlansQuery = {
    __typename?: 'Query'
    listSubscriptionsPlans: {
        __typename?: 'BillingPlans'
        plans: Array<{
            __typename?: 'BillingPlan'
            id: string
            name: string
            billingType: string
            billingPeriod: string
            initialPrice: Array<{ __typename?: 'Money'; currency: string; amount: string }>
            recurringPrice: Array<{ __typename?: 'Money'; currency: string; amount: string }>
        }>
    }
}

export type GetPersonioIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetPersonioIntegrationQuery = {
    __typename?: 'Query'
    getPersonioIntegration?: {
        __typename?: 'PersonioIntegration'
        companyID: string
        clientID: string
        clientSecret: string
        status?: PersonioIntegrationStatus | null
        error?: string | null
        nextSyncAt?: any | null
        lastSyncedAt?: any | null
        hasInvitationEnabled: boolean
        hasSyncBookingsToPersonioEnabled: boolean
    } | null
}

export type GetSlackIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetSlackIntegrationQuery = {
    __typename?: 'Query'
    getSlackIntegration?: {
        __typename?: 'SlackIntegration'
        companyID: string
        sendDayScheduleAt?: any | null
        enableCheckInFeature: boolean
        enableSendingDaySchedule?: boolean | null
    } | null
}

export type ListNotificationIntegrationsQueryVariables = Exact<{ [key: string]: never }>

export type ListNotificationIntegrationsQuery = {
    __typename?: 'Query'
    listNotificationIntegrations: Array<{
        __typename?: 'GenericIntegration'
        type: IntegrationType
        group: IntegrationGroup
        status: IntegrationStatus
    }>
}

export type GetSlackIntegrationInstallUrlQueryVariables = Exact<{ [key: string]: never }>

export type GetSlackIntegrationInstallUrlQuery = { __typename?: 'Query'; getSlackIntegrationInstallUrl: string }

export type GetBambooHrIntegrationQueryVariables = Exact<{ [key: string]: never }>

export type GetBambooHrIntegrationQuery = {
    __typename?: 'Query'
    getBambooHRIntegration?: {
        __typename?: 'BambooHRIntegration'
        companyId: string
        hasInvitationEnabled: boolean
        syncStatus: SyncStatus
        lastSyncAt?: any | null
        companyDomain: string
    } | null
}

export type GetCalendarEmployeesWithBookingsQueryVariables = Exact<{
    weekNumber: Scalars['Int']['input']
    year: Scalars['Int']['input']
    filter?: InputMaybe<SearchableEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}>

export type GetCalendarEmployeesWithBookingsQuery = {
    __typename?: 'Query'
    employees?: {
        __typename?: 'SearchableEmployeeConnection'
        nextToken?: string | null
        total?: number | null
        items: Array<{
            __typename?: 'Employee'
            companyID: string
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            status?: EmployeeStatus | null
            id: string
            BookingsByWeek?: {
                __typename?: 'ModelBookingConnection'
                items: Array<{
                    __typename?: 'Booking'
                    id: string
                    startTime?: any | null
                    endTime?: any | null
                    isTeamEvent?: boolean | null
                    isFullDay?: boolean | null
                    isBlocked?: boolean | null
                    BookingRequest?: { __typename?: 'BookingRequest'; repeatType?: RepeatType | null } | null
                    reservable?: {
                        __typename?: 'Reservable'
                        id: string
                        type: ReservableType
                        Space?: { __typename?: 'Space'; id: string; name?: string | null } | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type CalendarDataFieldsFragment = {
    __typename?: 'Employee'
    id: string
    BookingsByWeek?: {
        __typename?: 'ModelBookingConnection'
        items: Array<{
            __typename?: 'Booking'
            id: string
            startTime?: any | null
            endTime?: any | null
            isTeamEvent?: boolean | null
            isFullDay?: boolean | null
            isBlocked?: boolean | null
            BookingRequest?: { __typename?: 'BookingRequest'; repeatType?: RepeatType | null } | null
            reservable?: {
                __typename?: 'Reservable'
                id: string
                type: ReservableType
                Space?: { __typename?: 'Space'; id: string; name?: string | null } | null
            } | null
        } | null>
    } | null
}

export type GetCalendarFavoriteEmployeesQueryVariables = Exact<{
    weekNumber: Scalars['Int']['input']
    year: Scalars['Int']['input']
    filter?: InputMaybe<SearchableEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
}>

export type GetCalendarFavoriteEmployeesQuery = {
    __typename?: 'Query'
    employees?: {
        __typename?: 'SearchableEmployeeConnection'
        nextToken?: string | null
        total?: number | null
        items: Array<{
            __typename?: 'Employee'
            companyID: string
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            status?: EmployeeStatus | null
            id: string
            BookingsByWeek?: {
                __typename?: 'ModelBookingConnection'
                items: Array<{
                    __typename?: 'Booking'
                    id: string
                    startTime?: any | null
                    endTime?: any | null
                    isTeamEvent?: boolean | null
                    isFullDay?: boolean | null
                    isBlocked?: boolean | null
                    BookingRequest?: { __typename?: 'BookingRequest'; repeatType?: RepeatType | null } | null
                    reservable?: {
                        __typename?: 'Reservable'
                        id: string
                        type: ReservableType
                        Space?: { __typename?: 'Space'; id: string; name?: string | null } | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetEmployeesForBookingQueryVariables = Exact<{
    filter?: InputMaybe<SearchableEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    date: Scalars['AWSDate']['input']
}>

export type GetEmployeesForBookingQuery = {
    __typename?: 'Query'
    searchEmployees?: {
        __typename?: 'SearchableEmployeeConnection'
        nextToken?: string | null
        total?: number | null
        items: Array<{
            __typename?: 'Employee'
            id: string
            companyID: string
            departmentIDs?: Array<string | null> | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            isSeatingInOffice?: boolean | null
            status?: EmployeeStatus | null
            Position?: { __typename?: 'Position'; name: string } | null
        } | null>
    } | null
}

export type ListSpaceWeekAggregationBySpaceAndYearAndWeekNumberQueryVariables = Exact<{
    spaceID: Scalars['ID']['input']
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
}>

export type ListSpaceWeekAggregationBySpaceAndYearAndWeekNumberQuery = {
    __typename?: 'Query'
    listSpaceWeekAggregationBySpaceAndYearAndWeekNumber?: {
        __typename?: 'ModelSpaceWeekAggregationConnection'
        items: Array<{
            __typename?: 'SpaceWeekAggregation'
            weekNumber: number
            year: number
            dayAggregations?: {
                __typename?: 'ModelSpaceDayAggregationConnection'
                items: Array<{
                    __typename?: 'SpaceDayAggregation'
                    date: any
                    spaceCapacityLast: number
                    spaceBookingsSum: number
                    homeBookingsSum: number
                } | null>
            } | null
        } | null>
    } | null
}

export type ListSpaceWeekAggregationByOfficeAndMomentQueryVariables = Exact<{
    officeID: Scalars['ID']['input']
    startWeekNumber?: InputMaybe<Scalars['String']['input']>
    startYear?: InputMaybe<Scalars['Int']['input']>
    endWeekNumber?: InputMaybe<Scalars['String']['input']>
    endYear?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
}>

export type ListSpaceWeekAggregationByOfficeAndMomentQuery = {
    __typename?: 'Query'
    listSpaceWeekAggregationByOfficeAndMoment?: {
        __typename?: 'ModelSpaceWeekAggregationConnection'
        items: Array<{
            __typename?: 'SpaceWeekAggregation'
            weekNumber: number
            year: number
            spaceID: string
            spaceCapacityMax: number
            spaceBookingsSum: number
            type?: AggregationByReservableType | null
            bookedHours?: number | null
            capacityInHours?: number | null
            percentageOfUtilization?: number | null
            Space?: { __typename?: 'Space'; name?: string | null } | null
        } | null>
    } | null
}

export type ListSpaceWeekAggregationByOfficeAndMomentForWeekQueryVariables = Exact<{
    officeID: Scalars['ID']['input']
    weekNumber?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
}>

export type ListSpaceWeekAggregationByOfficeAndMomentForWeekQuery = {
    __typename?: 'Query'
    listSpaceWeekAggregationByOfficeAndMoment?: {
        __typename?: 'ModelSpaceWeekAggregationConnection'
        items: Array<{
            __typename?: 'SpaceWeekAggregation'
            weekNumber: number
            year: number
            spaceID: string
            spaceCapacityMax: number
            spaceBookingsSum: number
            type?: AggregationByReservableType | null
            bookedHours?: number | null
            capacityInHours?: number | null
            percentageOfUtilization?: number | null
            Space?: { __typename?: 'Space'; name?: string | null } | null
        } | null>
    } | null
}

export type ListCompanyWeekAggregationByCompanyQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    weekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
}>

export type ListCompanyWeekAggregationByCompanyQuery = {
    __typename?: 'Query'
    listCompanyWeekAggregationByCompany?: {
        __typename?: 'ModelCompanyWeekAggregationConnection'
        items: Array<{
            __typename?: 'CompanyWeekAggregation'
            weekNumber: number
            year: number
            dayAggregations?: {
                __typename?: 'ModelCompanyDayAggregationConnection'
                items: Array<{
                    __typename?: 'CompanyDayAggregation'
                    date: any
                    officeCapacityLast: number
                    officeBookingsSum: number
                    homeBookingsSum: number
                    updatedAt: any
                    createdAt?: any | null
                    type?: AggregationByReservableType | null
                } | null>
            } | null
        } | null>
    } | null
}

export type ListAttendanceWeekAggregationByCompanyAndMomentQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    currentWeekNumberString?: InputMaybe<Scalars['String']['input']>
    currentYear?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
}>

export type ListAttendanceWeekAggregationByCompanyAndMomentQuery = {
    __typename?: 'Query'
    listCompanyWeekAggregationByCompanyAndMoment?: {
        __typename?: 'ModelCompanyWeekAggregationConnection'
        items: Array<{
            __typename?: 'CompanyWeekAggregation'
            weekNumber: number
            year: number
            officeCapacityMax: number
            officeBookingsSum: number
            capacityInHours?: number | null
            bookedHours?: number | null
            employeesLast: number
            employeesInOffice?: number | null
        } | null>
    } | null
}

export type ListEmployeeWeekAttendanceAggregationByCompanyAndMomentQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    currentWeekNumberString?: InputMaybe<Scalars['String']['input']>
    currentYear?: InputMaybe<Scalars['Int']['input']>
}>

export type ListEmployeeWeekAttendanceAggregationByCompanyAndMomentQuery = {
    __typename?: 'Query'
    listEmployeeWeekAggregationByCompanyAndMoment?: {
        __typename?: 'ModelEmployeeWeekAggregationConnection'
        items: Array<{
            __typename?: 'EmployeeWeekAggregation'
            weekNumber: number
            year: number
            officeBookingsSum?: number | null
            capacityInHours?: number | null
            bookedHours?: number | null
            employeesLast?: number | null
            employeesInOffice?: number | null
            averageDaysInOffice?: number | null
        } | null>
    } | null
}

export type ListOfficeWeekAggregationByOfficeAndMomentQueryVariables = Exact<{
    officeID: Scalars['ID']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    currentWeekNumberString?: InputMaybe<Scalars['String']['input']>
    currentYear?: InputMaybe<Scalars['Int']['input']>
    type?: InputMaybe<AggregationByReservableType>
    withDayAggregations?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListOfficeWeekAggregationByOfficeAndMomentQuery = {
    __typename?: 'Query'
    listOfficeWeekAggregationByOfficeAndMoment?: {
        __typename?: 'ModelOfficeWeekAggregationConnection'
        items: Array<{
            __typename?: 'OfficeWeekAggregation'
            weekNumber: number
            year: number
            officeCapacityMax: number
            officeBookingsSum: number
            capacityInHours?: number | null
            bookedHours?: number | null
            dayAggregations?: {
                __typename?: 'ModelOfficeDayAggregationConnection'
                items: Array<{
                    __typename?: 'OfficeDayAggregation'
                    date: any
                    officeCapacityLast: number
                    officeBookingsSum: number
                    capacityInHours?: number | null
                    bookedHours?: number | null
                    homeBookingsSum: number
                } | null>
            } | null
        } | null>
    } | null
}

export type ListOfficeWeekAggregationByOfficeAndMomentForWeekQueryVariables = Exact<{
    officeID: Scalars['ID']['input']
    year?: InputMaybe<Scalars['Int']['input']>
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    type?: InputMaybe<AggregationByReservableType>
    withDayAggregations?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListOfficeWeekAggregationByOfficeAndMomentForWeekQuery = {
    __typename?: 'Query'
    listOfficeWeekAggregationByOfficeAndMoment?: {
        __typename?: 'ModelOfficeWeekAggregationConnection'
        items: Array<{
            __typename?: 'OfficeWeekAggregation'
            weekNumber: number
            year: number
            officeCapacityMax: number
            officeBookingsSum: number
            capacityInHours?: number | null
            bookedHours?: number | null
            dayAggregations?: {
                __typename?: 'ModelOfficeDayAggregationConnection'
                items: Array<{
                    __typename?: 'OfficeDayAggregation'
                    date: any
                    officeCapacityLast: number
                    officeBookingsSum: number
                    capacityInHours?: number | null
                    bookedHours?: number | null
                    homeBookingsSum: number
                } | null>
            } | null
        } | null>
    } | null
}

export type ListCompanyWeekAggregationByCompanyAndMomentQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    currentYear?: InputMaybe<Scalars['Int']['input']>
    currentWeekNumberString?: InputMaybe<Scalars['String']['input']>
}>

export type ListCompanyWeekAggregationByCompanyAndMomentQuery = {
    __typename?: 'Query'
    listCompanyWeekAggregationByCompanyAndMoment?: {
        __typename?: 'ModelCompanyWeekAggregationConnection'
        items: Array<{
            __typename?: 'CompanyWeekAggregation'
            weekNumber: number
            year: number
            officeCapacityMax: number
            officeBookingsSum: number
            bookingsDistribution: Array<{
                __typename?: 'DashboardWeekAggregationBookingsDistribution'
                amountOfDays: number
                bookingsSum: number
            }>
        } | null>
    } | null
}

export type ListDepartmentAttendanceWeekAggregationQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
    weekNumberString?: InputMaybe<Scalars['String']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    currentWeekNumberString?: InputMaybe<Scalars['String']['input']>
    currentYear?: InputMaybe<Scalars['Int']['input']>
}>

export type ListDepartmentAttendanceWeekAggregationQuery = {
    __typename?: 'Query'
    listDepartmentWeekAggregationByCompanyAndMoment?: {
        __typename?: 'ModelDepartmentWeekAggregationConnection'
        items: Array<{
            __typename?: 'DepartmentWeekAggregation'
            weekNumber: number
            year: number
            officeBookingsSum: number
            bookedHours?: number | null
            employeesLast: number
            departmentID: string
            employeesInOffice?: number | null
            averageDaysInOffice?: number | null
            Department?: { __typename?: 'Department'; id: string; name: string } | null
        } | null>
    } | null
}

export type GetSpacesWithReservablesAndCalendarEventsQueryVariables = Exact<{
    companyId: Scalars['ID']['input']
    type?: InputMaybe<ReservableType>
    date: Scalars['String']['input']
    filter?: InputMaybe<ModelSpaceFilterInput>
}>

export type GetSpacesWithReservablesAndCalendarEventsQuery = {
    __typename?: 'Query'
    listSpacesByCompanyAndName?: {
        __typename?: 'ModelSpaceConnection'
        items: Array<{
            __typename?: 'Space'
            id: string
            name?: string | null
            officeID: string
            workingHoursFrom?: any | null
            workingHoursTo?: any | null
            Office?: { __typename?: 'Office'; name: string } | null
            Reservables?: {
                __typename?: 'ModelReservableConnection'
                items: Array<{
                    __typename?: 'Reservable'
                    id: string
                    name?: string | null
                    maxAmountOfPeople?: number | null
                    availabilityType: AvailabilityType
                    externalRoomID?: string | null
                    ReservableToEmployees?: {
                        __typename?: 'ModelReservableToEmployeeConnection'
                        items: Array<{
                            __typename?: 'ReservableToEmployee'
                            employee: { __typename?: 'Employee'; id: string }
                        } | null>
                    } | null
                    ReservableToDepartments?: {
                        __typename?: 'ModelReservableToDepartmentConnection'
                        items: Array<{
                            __typename?: 'ReservableToDepartment'
                            Department?: { __typename?: 'Department'; id: string } | null
                        } | null>
                    } | null
                    Tags?: {
                        __typename?: 'ModelReservableTagConnection'
                        items: Array<{
                            __typename?: 'ReservableTag'
                            Tag?: { __typename?: 'Tag'; name: string } | null
                        } | null>
                    } | null
                    CalendarEvents?: {
                        __typename?: 'ModelCalendarEventsConnection'
                        items: Array<{
                            __typename?: 'CalendarEvents'
                            id: string
                            title?: string | null
                            description?: string | null
                            startTime: any
                            endTime: any
                            canCurrentUserEdit?: boolean | null
                            parentEventExternalId?: string | null
                            isOnlineMeeting?: boolean | null
                            onlineMeetingUrl?: string | null
                            isPrivate?: boolean | null
                            employeesIDs: Array<string>
                            organiserEmployeeID?: string | null
                            cateringID?: string | null
                            reservableID?: string | null
                            Catering?: {
                                __typename?: 'Catering'
                                title?: string | null
                                id: string
                                email: string
                            } | null
                            menuItems?: Array<{
                                __typename?: 'MenuItemModel'
                                id: string
                                title?: string | null
                                description?: string | null
                                quantity?: number | null
                            }> | null
                        } | null>
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetSpacesWithRoomsQueryVariables = Exact<{
    companyId: Scalars['ID']['input']
    type?: InputMaybe<ReservableType>
    filter?: InputMaybe<ModelSpaceFilterInput>
}>

export type GetSpacesWithRoomsQuery = {
    __typename?: 'Query'
    listSpacesByCompanyAndName?: {
        __typename?: 'ModelSpaceConnection'
        items: Array<{
            __typename?: 'Space'
            id: string
            name?: string | null
            officeID: string
            workingHoursFrom?: any | null
            workingHoursTo?: any | null
            Office?: { __typename?: 'Office'; name: string } | null
            Reservables?: {
                __typename?: 'ModelReservableConnection'
                items: Array<{
                    __typename?: 'Reservable'
                    id: string
                    name?: string | null
                    maxAmountOfPeople?: number | null
                    availabilityType: AvailabilityType
                    externalRoomID?: string | null
                    relatedReservableID?: string | null
                    ReservableToEmployees?: {
                        __typename?: 'ModelReservableToEmployeeConnection'
                        items: Array<{
                            __typename?: 'ReservableToEmployee'
                            employee: { __typename?: 'Employee'; id: string; fullName?: string | null }
                        } | null>
                    } | null
                    ReservableToDepartments?: {
                        __typename?: 'ModelReservableToDepartmentConnection'
                        items: Array<{
                            __typename?: 'ReservableToDepartment'
                            Department?: { __typename?: 'Department'; id: string; name: string } | null
                        } | null>
                    } | null
                    Tags?: {
                        __typename?: 'ModelReservableTagConnection'
                        items: Array<{
                            __typename?: 'ReservableTag'
                            id: string
                            Tag?: { __typename?: 'Tag'; id: string; name: string } | null
                        } | null>
                    } | null
                    ReservableImages?: {
                        __typename?: 'ModelReservableImageConnection'
                        items: Array<{
                            __typename?: 'ReservableImage'
                            name?: string | null
                            isMain?: boolean | null
                            location?: string | null
                        } | null>
                    } | null
                    RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetCalendarEventsByOfficeQueryVariables = Exact<{
    officeId: Scalars['ID']['input']
    date: Scalars['String']['input']
    filter?: InputMaybe<ModelCalendarEventsFilterInput>
}>

export type GetCalendarEventsByOfficeQuery = {
    __typename?: 'Query'
    listCalendarEventsByOfficeAndStartTime?: {
        __typename?: 'ModelCalendarEventsConnection'
        items: Array<{
            __typename?: 'CalendarEvents'
            id: string
            title?: string | null
            description?: string | null
            startTime: any
            endTime: any
            canCurrentUserEdit?: boolean | null
            parentEventExternalId?: string | null
            isOnlineMeeting?: boolean | null
            onlineMeetingUrl?: string | null
            isPrivate?: boolean | null
            employeesIDs: Array<string>
            organiserEmployeeID?: string | null
            cateringID?: string | null
            reservableID?: string | null
            Catering?: { __typename?: 'Catering'; title?: string | null; id: string; email: string } | null
            menuItems?: Array<{
                __typename?: 'MenuItemModel'
                id: string
                title?: string | null
                description?: string | null
                quantity?: number | null
            }> | null
            equipmentItems?: Array<{
                __typename?: 'EquipmentItemModel'
                id: string
                title?: string | null
                description?: string | null
            }> | null
        } | null>
    } | null
}

export type GetEventsForRoomsQueryVariables = Exact<{
    reservableId: Scalars['ID']['input']
    date: Scalars['String']['input']
}>

export type GetEventsForRoomsQuery = {
    __typename?: 'Query'
    listCalendarEventsByReservableAndStartTime?: {
        __typename?: 'ModelCalendarEventsConnection'
        items: Array<{
            __typename?: 'CalendarEvents'
            id: string
            title?: string | null
            description?: string | null
            startTime: any
            endTime: any
            canCurrentUserEdit?: boolean | null
            parentEventExternalId?: string | null
            isOnlineMeeting?: boolean | null
            onlineMeetingUrl?: string | null
            isPrivate?: boolean | null
            Catering?: { __typename?: 'Catering'; id: string; email: string } | null
            menuItems?: Array<{
                __typename?: 'MenuItemModel'
                description?: string | null
                quantity?: number | null
                id: string
                title?: string | null
            }> | null
        } | null>
    } | null
}

export type GetAttendeesForEventQueryVariables = Exact<{
    eventId: Scalars['ID']['input']
}>

export type GetAttendeesForEventQuery = {
    __typename?: 'Query'
    getCalendarEvents?: {
        __typename?: 'CalendarEvents'
        Employees?: {
            __typename?: 'ModelCalendarEventEmployeeConnection'
            items: Array<{
                __typename?: 'CalendarEventEmployee'
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    fullName?: string | null
                    isSeatingInOffice?: boolean | null
                    departmentIDs?: Array<string | null> | null
                    shareAttendance?: ShareAttendance | null
                } | null
            } | null>
        } | null
        OrganiserEmployee?: {
            __typename?: 'Employee'
            id: string
            fullName?: string | null
            photo?: string | null
            isSeatingInOffice?: boolean | null
        } | null
    } | null
}

export type GetListOfficesByCompanyQueryVariables = Exact<{
    companyId: Scalars['ID']['input']
}>

export type GetListOfficesByCompanyQuery = {
    __typename?: 'Query'
    listOfficesByCompanyAndOrder?: {
        __typename?: 'ModelOfficeConnection'
        items: Array<{
            __typename?: 'Office'
            id: string
            name: string
            workingFrom?: any | null
            workingTo?: any | null
            address?: string | null
            timeZone?: string | null
            externalOfficeId?: string | null
            Catering?: {
                __typename?: 'Catering'
                companyID: string
                id: string
                title?: string | null
                email: string
                enabled: boolean
                officeID?: string | null
                MenuItems?: {
                    __typename?: 'ModelMenuItemConnection'
                    items: Array<{
                        __typename?: 'MenuItem'
                        id: string
                        title: string
                        description?: string | null
                        enabled: boolean
                    } | null>
                } | null
            } | null
            SpaceRows?: {
                __typename?: 'ModelSpaceRowConnection'
                items: Array<{
                    __typename?: 'SpaceRow'
                    id: string
                    Spaces?: {
                        __typename?: 'ModelSpaceConnection'
                        items: Array<{
                            __typename?: 'Space'
                            id: string
                            floorPlanFilePath?: string | null
                            name?: string | null
                            totalSeats?: number | null
                            officeID: string
                            totalParkingSlots?: number | null
                        } | null>
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetReservableWithAvailabilityQueryVariables = Exact<{
    employee?: InputMaybe<Array<InputMaybe<EmployeesWithDepartments>> | InputMaybe<EmployeesWithDepartments>>
    dates: Array<InputMaybe<Scalars['AWSDate']['input']>> | InputMaybe<Scalars['AWSDate']['input']>
    bookingRepeatType?: InputMaybe<RepeatType>
    daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
    reservableId: Scalars['ID']['input']
}>

export type GetReservableWithAvailabilityQuery = {
    __typename?: 'Query'
    getReservable?: { __typename?: 'Reservable'; id: string; availabilityByDatesAndTime?: boolean | null } | null
}

export type GetReservableByIdQueryVariables = Exact<{
    employee?: InputMaybe<Array<InputMaybe<EmployeesWithDepartments>> | InputMaybe<EmployeesWithDepartments>>
    dates?: InputMaybe<Array<InputMaybe<Scalars['AWSDate']['input']>> | InputMaybe<Scalars['AWSDate']['input']>>
    bookingRepeatType?: InputMaybe<RepeatType>
    daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
    dateFilter?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
    reservableId: Scalars['ID']['input']
}>

export type GetReservableByIdQuery = {
    __typename?: 'Query'
    getReservable?: {
        __typename?: 'Reservable'
        id: string
        name?: string | null
        x?: number | null
        y?: number | null
        type: ReservableType
        availabilityType: AvailabilityType
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        availabilityByDatesAndTime?: boolean | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Space?: {
            __typename?: 'Space'
            name?: string | null
            id: string
            address?: string | null
            Office?: { __typename?: 'Office'; address?: string | null; name: string; id: string } | null
        } | null
        Department?: { __typename?: 'Department'; name: string } | null
        Tags?: {
            __typename?: 'ModelReservableTagConnection'
            items: Array<{
                __typename?: 'ReservableTag'
                Tag?: { __typename?: 'Tag'; id: string; name: string } | null
            } | null>
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        BookingsByDateAndDayOfWeek?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                isFullDay?: boolean | null
                isBlocked?: boolean | null
                employeeID: string
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
            } | null>
        } | null
    } | null
}

export type ListCateringsByOfficeQueryVariables = Exact<{
    filter?: InputMaybe<ModelCateringFilterInput>
    filterMenuItems?: InputMaybe<ModelMenuItemFilterInput>
}>

export type ListCateringsByOfficeQuery = {
    __typename?: 'Query'
    listCaterings?: {
        __typename?: 'ModelCateringConnection'
        items: Array<{
            __typename?: 'Catering'
            id: string
            title?: string | null
            email: string
            MenuItems?: {
                __typename?: 'ModelMenuItemConnection'
                items: Array<{
                    __typename?: 'MenuItem'
                    title: string
                    id: string
                    description?: string | null
                    enabled: boolean
                } | null>
            } | null
        } | null>
    } | null
}

export type ListMenuItemsByCateringQueryVariables = Exact<{
    cateringID: Scalars['ID']['input']
    filter?: InputMaybe<ModelMenuItemFilterInput>
}>

export type ListMenuItemsByCateringQuery = {
    __typename?: 'Query'
    listMenuItemsByCatering?: {
        __typename?: 'ModelMenuItemConnection'
        items: Array<{
            __typename?: 'MenuItem'
            id: string
            title: string
            description?: string | null
            enabled: boolean
        } | null>
    } | null
}

export type SearchResourcesQueryVariables = Exact<{
    filter?: InputMaybe<SearchableEquipmentFilterInput>
    nextToken?: InputMaybe<Scalars['String']['input']>
    withBookings?: InputMaybe<Scalars['Boolean']['input']>
    filterBookings?: InputMaybe<ModelBookingEquipmentFilterInput>
    startTime?: InputMaybe<ModelStringKeyConditionInput>
    limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SearchResourcesQuery = {
    __typename?: 'Query'
    searchEquipment?: {
        __typename?: 'SearchableEquipmentConnection'
        nextToken?: string | null
        items: Array<{
            __typename?: 'Equipment'
            id: string
            name?: string | null
            description?: string | null
            quantity?: number | null
            canBookedSeparately?: boolean | null
            officeID?: string | null
            companyID: string
            enabled?: boolean | null
            Bookings?: {
                __typename?: 'ModelBookingEquipmentConnection'
                items: Array<{
                    __typename?: 'BookingEquipment'
                    id: string
                    startTime: any
                    endTime: any
                    isPrivate?: boolean | null
                    employeeID?: string | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        fullName?: string | null
                        photo?: string | null
                        fullNameLowerCase?: string | null
                    } | null
                } | null>
            } | null
            EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
            EquipmentImages?: {
                __typename?: 'ModelEquipmentImageConnection'
                items: Array<{
                    __typename?: 'EquipmentImage'
                    id: string
                    name?: string | null
                    location?: string | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetResourcesByOfficeQueryVariables = Exact<{
    officeID: Scalars['ID']['input']
    filter?: InputMaybe<ModelEquipmentFilterInput>
}>

export type GetResourcesByOfficeQuery = {
    __typename?: 'Query'
    listEquipmentsByOffice?: {
        __typename?: 'ModelEquipmentConnection'
        items: Array<{
            __typename?: 'Equipment'
            id: string
            name?: string | null
            description?: string | null
            quantity?: number | null
            canBookedSeparately?: boolean | null
            officeID?: string | null
            companyID: string
            enabled?: boolean | null
            EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
            EquipmentImages?: {
                __typename?: 'ModelEquipmentImageConnection'
                items: Array<{
                    __typename?: 'EquipmentImage'
                    id: string
                    name?: string | null
                    location?: string | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetBookingResourcesByOfficeQueryVariables = Exact<{
    officeId: Scalars['ID']['input']
    isoWeekNumber?: InputMaybe<Scalars['Int']['input']>
    year?: InputMaybe<Scalars['Int']['input']>
    filter?: InputMaybe<ModelBookingEquipmentFilterInput>
}>

export type GetBookingResourcesByOfficeQuery = {
    __typename?: 'Query'
    listBookingEquipmentsByOfficeAndYearAndWeek?: {
        __typename?: 'ModelBookingEquipmentConnection'
        items: Array<{
            __typename?: 'BookingEquipment'
            id: string
            startTime: any
            endTime: any
            isPrivate?: boolean | null
            employeeID?: string | null
            equipmentID?: string | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                fullName?: string | null
                photo?: string | null
                fullNameLowerCase?: string | null
            } | null
        } | null>
    } | null
}

export type ListReservablesWithBookingsBySpaceAndTypeV2QueryVariables = Exact<{
    spaceID: Scalars['ID']['input']
    dateFilter?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
    filterByUser?: InputMaybe<ModelReservableFilterInput>
    type?: InputMaybe<ModelStringKeyConditionInput>
    parkingFilter?: InputMaybe<ModelBookingReservableFilterInput>
    loadBookingsForSeats?: InputMaybe<Scalars['Boolean']['input']>
    loadBookingsForParking?: InputMaybe<Scalars['Boolean']['input']>
}>

export type ListReservablesWithBookingsBySpaceAndTypeV2Query = {
    __typename?: 'Query'
    listReservablesBySpaceAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            name?: string | null
            x?: number | null
            y?: number | null
            type: ReservableType
            availabilityType: AvailabilityType
            maxAmountOfPeople?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
            Department?: { __typename?: 'Department'; id: string; name: string } | null
            Space?: {
                __typename?: 'Space'
                id: string
                name?: string | null
                address?: string | null
                workingHoursFrom?: any | null
                workingHoursTo?: any | null
                officeID: string
                Office?: { __typename?: 'Office'; id: string; name: string; address?: string | null } | null
            } | null
            Tags?: {
                __typename?: 'ModelReservableTagConnection'
                items: Array<{
                    __typename?: 'ReservableTag'
                    Tag?: { __typename?: 'Tag'; id: string; name: string } | null
                } | null>
            } | null
            ReservableToDepartments?: {
                __typename?: 'ModelReservableToDepartmentConnection'
                items: Array<{
                    __typename?: 'ReservableToDepartment'
                    Department?: { __typename?: 'Department'; id: string; name: string } | null
                } | null>
            } | null
            ReservableToEmployees?: {
                __typename?: 'ModelReservableToEmployeeConnection'
                items: Array<{
                    __typename?: 'ReservableToEmployee'
                    employee: {
                        __typename?: 'Employee'
                        id: string
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        shareAttendance?: ShareAttendance | null
                    }
                } | null>
            } | null
            BookingsByDateAndDayOfWeek?: {
                __typename?: 'ModelBookingConnection'
                items: Array<{
                    __typename?: 'Booking'
                    id: string
                    startTime?: any | null
                    endTime?: any | null
                    isFullDay?: boolean | null
                    isBlocked?: boolean | null
                    employeeID: string
                    date?: string | null
                    createdAt?: any | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        photo?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        shareAttendance?: ShareAttendance | null
                    } | null
                } | null>
            } | null
            BookingReservablesByCreatedAt?: {
                __typename?: 'ModelBookingReservableConnection'
                items: Array<{
                    __typename?: 'BookingReservable'
                    booking?: {
                        __typename?: 'Booking'
                        startTime?: any | null
                        endTime?: any | null
                        isFullDay?: boolean | null
                        isBlocked?: boolean | null
                        employeeID: string
                        Employee?: {
                            __typename?: 'Employee'
                            id: string
                            photo?: string | null
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            departmentIDs?: Array<string | null> | null
                            departmentsString?: string | null
                            shareAttendance?: ShareAttendance | null
                        } | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type BookingFieldsForCalendarV2Fragment = {
    __typename?: 'Booking'
    id: string
    startTime?: any | null
    endTime?: any | null
    reservableID?: string | null
    isBlocked?: boolean | null
    isFullDay?: boolean | null
    bookingType?: BookingType | null
    bookingSubType?: BookingSubType | null
    isTeamEvent?: boolean | null
    checkInStatus?: BookingCheckInStatus | null
    Employee?: {
        __typename?: 'Employee'
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
    } | null
    Space?: {
        __typename?: 'Space'
        id: string
        name?: string | null
        floorPlanFilePath?: string | null
        workingHoursFrom?: any | null
        workingHoursTo?: any | null
        officeID: string
        Office?: { __typename?: 'Office'; id: string; name: string } | null
    } | null
    reservable?: {
        __typename?: 'Reservable'
        id: string
        name?: string | null
        type: ReservableType
        x?: number | null
        y?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        Space?: {
            __typename?: 'Space'
            id: string
            name?: string | null
            Office?: { __typename?: 'Office'; id: string; name: string } | null
        } | null
    } | null
    BookingRequest?: {
        __typename?: 'BookingRequest'
        id: string
        repeatType?: RepeatType | null
        addParkingSlot?: boolean | null
        daysOfWeek?: Array<string> | null
        ParkingSlot?: {
            __typename?: 'Reservable'
            id: string
            name?: string | null
            type: ReservableType
            y?: number | null
            x?: number | null
            Space?: {
                __typename?: 'Space'
                id: string
                name?: string | null
                floorPlanFilePath?: string | null
                workingHoursFrom?: any | null
                workingHoursTo?: any | null
                officeID: string
                Office?: { __typename?: 'Office'; id: string; name: string } | null
            } | null
        } | null
    } | null
    reservables?: {
        __typename?: 'ModelBookingReservableConnection'
        items: Array<{
            __typename?: 'BookingReservable'
            id: string
            reservableID: string
            reservable?: {
                __typename?: 'Reservable'
                id: string
                name?: string | null
                Space?: {
                    __typename?: 'Space'
                    id: string
                    name?: string | null
                    floorPlanFilePath?: string | null
                    workingHoursFrom?: any | null
                    workingHoursTo?: any | null
                    officeID: string
                    Office?: { __typename?: 'Office'; id: string; name: string } | null
                } | null
            } | null
        } | null>
    } | null
}

export type ListBookingsByEmployeeAndMomentQueryVariables = Exact<{
    employeeID: Scalars['ID']['input']
    startTime?: InputMaybe<Scalars['String']['input']>
    filter?: InputMaybe<ModelBookingFilterInput>
}>

export type ListBookingsByEmployeeAndMomentQuery = {
    __typename?: 'Query'
    listBookingsByEmployeeAndStartTime?: {
        __typename?: 'ModelBookingConnection'
        items: Array<{
            __typename?: 'Booking'
            id: string
            startTime?: any | null
            endTime?: any | null
            reservableID?: string | null
            isBlocked?: boolean | null
            isFullDay?: boolean | null
            bookingType?: BookingType | null
            bookingSubType?: BookingSubType | null
            isTeamEvent?: boolean | null
            checkInStatus?: BookingCheckInStatus | null
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
            Space?: {
                __typename?: 'Space'
                id: string
                name?: string | null
                floorPlanFilePath?: string | null
                workingHoursFrom?: any | null
                workingHoursTo?: any | null
                officeID: string
                Office?: { __typename?: 'Office'; id: string; name: string } | null
            } | null
            reservable?: {
                __typename?: 'Reservable'
                id: string
                name?: string | null
                type: ReservableType
                x?: number | null
                y?: number | null
                zonePoints?: Array<number | null> | null
                color?: string | null
                Space?: {
                    __typename?: 'Space'
                    id: string
                    name?: string | null
                    Office?: { __typename?: 'Office'; id: string; name: string } | null
                } | null
            } | null
            BookingRequest?: {
                __typename?: 'BookingRequest'
                id: string
                repeatType?: RepeatType | null
                addParkingSlot?: boolean | null
                daysOfWeek?: Array<string> | null
                ParkingSlot?: {
                    __typename?: 'Reservable'
                    id: string
                    name?: string | null
                    type: ReservableType
                    y?: number | null
                    x?: number | null
                    Space?: {
                        __typename?: 'Space'
                        id: string
                        name?: string | null
                        floorPlanFilePath?: string | null
                        workingHoursFrom?: any | null
                        workingHoursTo?: any | null
                        officeID: string
                        Office?: { __typename?: 'Office'; id: string; name: string } | null
                    } | null
                } | null
            } | null
            reservables?: {
                __typename?: 'ModelBookingReservableConnection'
                items: Array<{
                    __typename?: 'BookingReservable'
                    id: string
                    reservableID: string
                    reservable?: {
                        __typename?: 'Reservable'
                        id: string
                        name?: string | null
                        Space?: {
                            __typename?: 'Space'
                            id: string
                            name?: string | null
                            floorPlanFilePath?: string | null
                            workingHoursFrom?: any | null
                            workingHoursTo?: any | null
                            officeID: string
                            Office?: { __typename?: 'Office'; id: string; name: string } | null
                        } | null
                    } | null
                } | null>
            } | null
        } | null>
    } | null
}

export type GetBookingByIdWithTeamEventRequestQueryVariables = Exact<{
    bookingID: Scalars['ID']['input']
}>

export type GetBookingByIdWithTeamEventRequestQuery = {
    __typename?: 'Query'
    getBooking?: {
        __typename?: 'Booking'
        id: string
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        isBlocked?: boolean | null
        isFullDay?: boolean | null
        bookingType?: BookingType | null
        bookingSubType?: BookingSubType | null
        isTeamEvent?: boolean | null
        checkInStatus?: BookingCheckInStatus | null
        TeamEventRequest?: {
            __typename?: 'TeamEventRequest'
            owner?: string | null
            id: string
            companyID: string
            startTime?: any | null
            endTime?: any | null
            reservableID?: string | null
            repeatType?: RepeatType | null
            daysOfWeek?: Array<string> | null
            Space?: { __typename?: 'Space'; companyID: string; id: string; address?: string | null } | null
            Reservable?: {
                __typename?: 'Reservable'
                id: string
                type: ReservableType
                x?: number | null
                y?: number | null
                zonePoints?: Array<number | null> | null
                color?: string | null
            } | null
        } | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Space?: {
            __typename?: 'Space'
            id: string
            name?: string | null
            floorPlanFilePath?: string | null
            workingHoursFrom?: any | null
            workingHoursTo?: any | null
            officeID: string
            Office?: { __typename?: 'Office'; id: string; name: string } | null
        } | null
        reservable?: {
            __typename?: 'Reservable'
            id: string
            name?: string | null
            type: ReservableType
            x?: number | null
            y?: number | null
            zonePoints?: Array<number | null> | null
            color?: string | null
            Space?: {
                __typename?: 'Space'
                id: string
                name?: string | null
                Office?: { __typename?: 'Office'; id: string; name: string } | null
            } | null
        } | null
        BookingRequest?: {
            __typename?: 'BookingRequest'
            id: string
            repeatType?: RepeatType | null
            addParkingSlot?: boolean | null
            daysOfWeek?: Array<string> | null
            ParkingSlot?: {
                __typename?: 'Reservable'
                id: string
                name?: string | null
                type: ReservableType
                y?: number | null
                x?: number | null
                Space?: {
                    __typename?: 'Space'
                    id: string
                    name?: string | null
                    floorPlanFilePath?: string | null
                    workingHoursFrom?: any | null
                    workingHoursTo?: any | null
                    officeID: string
                    Office?: { __typename?: 'Office'; id: string; name: string } | null
                } | null
            } | null
        } | null
        reservables?: {
            __typename?: 'ModelBookingReservableConnection'
            items: Array<{
                __typename?: 'BookingReservable'
                id: string
                reservableID: string
                reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    name?: string | null
                    Space?: {
                        __typename?: 'Space'
                        id: string
                        name?: string | null
                        floorPlanFilePath?: string | null
                        workingHoursFrom?: any | null
                        workingHoursTo?: any | null
                        officeID: string
                        Office?: { __typename?: 'Office'; id: string; name: string } | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type GetReservableByIdWithBookingsBySpaceQueryVariables = Exact<{
    id: Scalars['ID']['input']
    employee: Array<InputMaybe<EmployeesWithDepartments>> | InputMaybe<EmployeesWithDepartments>
    dates: Array<InputMaybe<Scalars['AWSDate']['input']>> | InputMaybe<Scalars['AWSDate']['input']>
    bookingRepeatType?: InputMaybe<RepeatType>
    daysOfWeek?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
    startTime?: InputMaybe<Scalars['String']['input']>
    endTime?: InputMaybe<Scalars['String']['input']>
    dateFilter?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
    parkingFilter?: InputMaybe<ModelBookingReservableFilterInput>
}>

export type GetReservableByIdWithBookingsBySpaceQuery = {
    __typename?: 'Query'
    getReservable?: {
        __typename?: 'Reservable'
        id: string
        name?: string | null
        x?: number | null
        y?: number | null
        type: ReservableType
        availabilityType: AvailabilityType
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        availabilityByDatesAndTime?: boolean | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Department?: { __typename?: 'Department'; name: string } | null
        Tags?: {
            __typename?: 'ModelReservableTagConnection'
            items: Array<{
                __typename?: 'ReservableTag'
                Tag?: { __typename?: 'Tag'; id: string; name: string } | null
            } | null>
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        BookingReservablesByCreatedAt?: {
            __typename?: 'ModelBookingReservableConnection'
            items: Array<{
                __typename?: 'BookingReservable'
                booking?: {
                    __typename?: 'Booking'
                    bookingType?: BookingType | null
                    bookingSubType?: BookingSubType | null
                    startTime?: any | null
                    endTime?: any | null
                    isFullDay?: boolean | null
                    isBlocked?: boolean | null
                    employeeID: string
                    reservable?: {
                        __typename?: 'Reservable'
                        type: ReservableType
                        id: string
                        name?: string | null
                    } | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        photo?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        shareAttendance?: ShareAttendance | null
                    } | null
                } | null
            } | null>
        } | null
        BookingsByDateAndDayOfWeek?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                isFullDay?: boolean | null
                isBlocked?: boolean | null
                employeeID: string
                bookingType?: BookingType | null
                bookingSubType?: BookingSubType | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
                Space?: { __typename?: 'Space'; Office?: { __typename?: 'Office'; name: string } | null } | null
                BookingRequest?: {
                    __typename?: 'BookingRequest'
                    id: string
                    repeatType?: RepeatType | null
                    daysOfWeek?: Array<string> | null
                    addParkingSlot?: boolean | null
                    ParkingSlot?: {
                        __typename?: 'Reservable'
                        id: string
                        name?: string | null
                        type: ReservableType
                        y?: number | null
                        x?: number | null
                        Space?: {
                            __typename?: 'Space'
                            id: string
                            name?: string | null
                            Office?: { __typename?: 'Office'; id: string; name: string } | null
                        } | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type GetReservableByIdWithBookingsQueryVariables = Exact<{
    id: Scalars['ID']['input']
    dateFilter?: InputMaybe<ModelBookingByReservableAndDateAndDayOfWeekCompositeKeyConditionInput>
    filter?: InputMaybe<ModelBookingFilterInput>
}>

export type GetReservableByIdWithBookingsQuery = {
    __typename?: 'Query'
    getReservable?: {
        __typename?: 'Reservable'
        id: string
        name?: string | null
        x?: number | null
        y?: number | null
        type: ReservableType
        availabilityType: AvailabilityType
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        BookingsByDateAndDayOfWeek?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                isFullDay?: boolean | null
                isBlocked?: boolean | null
                employeeID: string
                bookingType?: BookingType | null
                bookingSubType?: BookingSubType | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
                Space?: { __typename?: 'Space'; Office?: { __typename?: 'Office'; name: string } | null } | null
                BookingRequest?: {
                    __typename?: 'BookingRequest'
                    id: string
                    repeatType?: RepeatType | null
                    daysOfWeek?: Array<string> | null
                    addParkingSlot?: boolean | null
                    ParkingSlot?: {
                        __typename?: 'Reservable'
                        id: string
                        name?: string | null
                        type: ReservableType
                        y?: number | null
                        x?: number | null
                        Space?: {
                            __typename?: 'Space'
                            id: string
                            name?: string | null
                            Office?: { __typename?: 'Office'; id: string; name: string } | null
                        } | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type GetReservableByIdWithBookingsAndAvailabilityQueryVariables = Exact<{
    id: Scalars['ID']['input']
    parkingFilter?: InputMaybe<ModelBookingReservableFilterInput>
}>

export type GetReservableByIdWithBookingsAndAvailabilityQuery = {
    __typename?: 'Query'
    getReservable?: {
        __typename?: 'Reservable'
        id: string
        name?: string | null
        x?: number | null
        y?: number | null
        type: ReservableType
        availabilityType: AvailabilityType
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        BookingReservablesByCreatedAt?: {
            __typename?: 'ModelBookingReservableConnection'
            items: Array<{
                __typename?: 'BookingReservable'
                booking?: {
                    __typename?: 'Booking'
                    bookingType?: BookingType | null
                    bookingSubType?: BookingSubType | null
                    startTime?: any | null
                    endTime?: any | null
                    isFullDay?: boolean | null
                    isBlocked?: boolean | null
                    employeeID: string
                    reservable?: {
                        __typename?: 'Reservable'
                        type: ReservableType
                        id: string
                        name?: string | null
                    } | null
                    Employee?: {
                        __typename?: 'Employee'
                        id: string
                        photo?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        shareAttendance?: ShareAttendance | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type CalendarEventFullFieldsFragment = {
    __typename?: 'CalendarEvents'
    id: string
    startTime: any
    endTime: any
    reservableID?: string | null
    organiserEmployeeID?: string | null
    title?: string | null
    description?: string | null
    owner?: string | null
    OrganiserEmployee?: {
        __typename?: 'Employee'
        isSeatingInOffice?: boolean | null
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
    } | null
    Employees?: {
        __typename?: 'ModelCalendarEventEmployeeConnection'
        items: Array<{
            __typename?: 'CalendarEventEmployee'
            Employee?: {
                __typename?: 'Employee'
                isSeatingInOffice?: boolean | null
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
        } | null>
    } | null
    Catering?: { __typename?: 'Catering'; id: string; enabled: boolean; title?: string | null } | null
    equipmentItems?: Array<{
        __typename?: 'EquipmentItemModel'
        id: string
        title?: string | null
        description?: string | null
        quantity?: number | null
    }> | null
    menuItems?: Array<{
        __typename?: 'MenuItemModel'
        id: string
        description?: string | null
        title?: string | null
        quantity?: number | null
    }> | null
}

export type CalendarEventRequestFieldsFragment = {
    __typename?: 'CalendarEventRequest'
    id: string
    startTime?: any | null
    endTime?: any | null
    reservableID?: string | null
    organiserEmployeeID: string
    title?: string | null
    description?: string | null
    status?: CalendarEventRequestStatus | null
    repeatType?: RepeatType | null
    dates?: Array<any> | null
    daysOfWeek?: Array<string> | null
    OrganiserEmployee?: {
        __typename?: 'Employee'
        id: string
        photo?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        shareAttendance?: ShareAttendance | null
    } | null
    Employees?: {
        __typename?: 'ModelCalendarEventEmployeeConnection'
        items: Array<{
            __typename?: 'CalendarEventEmployee'
            Employee?: {
                __typename?: 'Employee'
                id: string
                photo?: string | null
                firstname?: string | null
                lastname?: string | null
                fullName?: string | null
                departmentIDs?: Array<string | null> | null
                departmentsString?: string | null
                shareAttendance?: ShareAttendance | null
            } | null
        } | null>
    } | null
    Catering?: { __typename?: 'Catering'; id: string; enabled: boolean; title?: string | null } | null
    menuItems?: Array<{
        __typename?: 'MenuItemModel'
        id: string
        description?: string | null
        title?: string | null
        quantity?: number | null
    }> | null
}

export type GetCalendarEventWithDetailsQueryVariables = Exact<{
    eventId: Scalars['ID']['input']
}>

export type GetCalendarEventWithDetailsQuery = {
    __typename?: 'Query'
    getCalendarEvents?: {
        __typename?: 'CalendarEvents'
        id: string
        startTime: any
        endTime: any
        reservableID?: string | null
        organiserEmployeeID?: string | null
        title?: string | null
        description?: string | null
        owner?: string | null
        OrganiserEmployee?: {
            __typename?: 'Employee'
            isSeatingInOffice?: boolean | null
            id: string
            photo?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            shareAttendance?: ShareAttendance | null
        } | null
        Employees?: {
            __typename?: 'ModelCalendarEventEmployeeConnection'
            items: Array<{
                __typename?: 'CalendarEventEmployee'
                Employee?: {
                    __typename?: 'Employee'
                    isSeatingInOffice?: boolean | null
                    id: string
                    photo?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    shareAttendance?: ShareAttendance | null
                } | null
            } | null>
        } | null
        Catering?: { __typename?: 'Catering'; id: string; enabled: boolean; title?: string | null } | null
        equipmentItems?: Array<{
            __typename?: 'EquipmentItemModel'
            id: string
            title?: string | null
            description?: string | null
            quantity?: number | null
        }> | null
        menuItems?: Array<{
            __typename?: 'MenuItemModel'
            id: string
            description?: string | null
            title?: string | null
            quantity?: number | null
        }> | null
    } | null
}

export type ListCalendarEventsByEmployeeAndMomentQueryVariables = Exact<{
    employeeID: Scalars['ID']['input']
    year?: InputMaybe<Scalars['Int']['input']>
    week?: InputMaybe<Scalars['Int']['input']>
    filter?: InputMaybe<ModelCalendarEventEmployeeFilterInput>
}>

export type ListCalendarEventsByEmployeeAndMomentQuery = {
    __typename?: 'Query'
    listCalendarEventEmployeesByEmployeeAndWeekAndYear?: {
        __typename?: 'ModelCalendarEventEmployeeConnection'
        items: Array<{
            __typename?: 'CalendarEventEmployee'
            CalendarEvent?: {
                __typename?: 'CalendarEvents'
                title?: string | null
                createdAt?: any | null
                owner?: string | null
                companyID: string
                id: string
                dayOfMonth?: number | null
                daysOfWeek?: Array<string | null> | null
                description?: string | null
                endTime: any
                startTime: any
                status?: CalendarEventStatus | null
                onlineMeetingUrl?: string | null
                isPrivate?: boolean | null
                employeesIDs: Array<string>
                organiserEmployeeID?: string | null
                Reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    companyID: string
                    name?: string | null
                    availabilityType: AvailabilityType
                    type: ReservableType
                    x?: number | null
                    y?: number | null
                    Space?: {
                        __typename?: 'Space'
                        name?: string | null
                        companyID: string
                        id: string
                        address?: string | null
                        Office?: { __typename?: 'Office'; name: string; address?: string | null; id: string } | null
                    } | null
                } | null
                Employees?: {
                    __typename?: 'ModelCalendarEventEmployeeConnection'
                    items: Array<{
                        __typename?: 'CalendarEventEmployee'
                        id: string
                        companyID: string
                        employeeFullName?: string | null
                        weekNumber?: number | null
                        year?: number | null
                        createdAt?: any | null
                        owner?: string | null
                    } | null>
                } | null
                CalendarEventRequest?: {
                    __typename?: 'CalendarEventRequest'
                    id: string
                    startTime?: any | null
                    endTime?: any | null
                    reservableID?: string | null
                    organiserEmployeeID: string
                    title?: string | null
                    description?: string | null
                    status?: CalendarEventRequestStatus | null
                    repeatType?: RepeatType | null
                    dates?: Array<any> | null
                    daysOfWeek?: Array<string> | null
                    OrganiserEmployee?: {
                        __typename?: 'Employee'
                        id: string
                        photo?: string | null
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        departmentIDs?: Array<string | null> | null
                        departmentsString?: string | null
                        shareAttendance?: ShareAttendance | null
                    } | null
                    Employees?: {
                        __typename?: 'ModelCalendarEventEmployeeConnection'
                        items: Array<{
                            __typename?: 'CalendarEventEmployee'
                            Employee?: {
                                __typename?: 'Employee'
                                id: string
                                photo?: string | null
                                firstname?: string | null
                                lastname?: string | null
                                fullName?: string | null
                                departmentIDs?: Array<string | null> | null
                                departmentsString?: string | null
                                shareAttendance?: ShareAttendance | null
                            } | null
                        } | null>
                    } | null
                    Catering?: { __typename?: 'Catering'; id: string; enabled: boolean; title?: string | null } | null
                    menuItems?: Array<{
                        __typename?: 'MenuItemModel'
                        id: string
                        description?: string | null
                        title?: string | null
                        quantity?: number | null
                    }> | null
                } | null
            } | null
        } | null>
    } | null
}

export type GetListOfEmployeesQueryVariables = Exact<{
    filter?: InputMaybe<SearchableEmployeeFilterInput>
    limit?: InputMaybe<Scalars['Int']['input']>
    nextToken?: InputMaybe<Scalars['String']['input']>
    sortName?: InputMaybe<SearchableEmployeeSortableFields>
    sortOrder?: InputMaybe<SearchableSortDirection>
    filterBookingsByCreatedAt?: InputMaybe<ModelBookingFilterInput>
    loadBookings?: InputMaybe<Scalars['Boolean']['input']>
}>

export type GetListOfEmployeesQuery = {
    __typename?: 'Query'
    searchEmployees?: {
        __typename?: 'SearchableEmployeeConnection'
        nextToken?: string | null
        total?: number | null
        items: Array<{
            __typename?: 'Employee'
            id: string
            companyID: string
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            positionID?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            email?: string | null
            photo?: string | null
            active?: boolean | null
            roleString?: string | null
            status?: EmployeeStatus | null
            role?: Role | null
            birthday?: any | null
            BookingsByCreatedAt?: {
                __typename?: 'ModelBookingConnection'
                items: Array<{
                    __typename?: 'Booking'
                    spaceID?: string | null
                    id: string
                    date?: string | null
                    Space?: {
                        __typename?: 'Space'
                        id: string
                        floorPlanFilePath?: string | null
                        name?: string | null
                        address?: string | null
                        workingHoursFrom?: any | null
                        workingHoursTo?: any | null
                        officeID: string
                        companyID: string
                        Office?: { __typename?: 'Office'; id: string; name: string; address?: string | null } | null
                    } | null
                    reservable?: {
                        __typename?: 'Reservable'
                        name?: string | null
                        id: string
                        type: ReservableType
                        x?: number | null
                        y?: number | null
                        zonePoints?: Array<number | null> | null
                    } | null
                } | null>
            } | null
            Position?: { __typename?: 'Position'; id: string; name: string } | null
            EmployeeToDepartment?: {
                __typename?: 'ModelEmployeeToDepartmentConnection'
                items: Array<{
                    __typename?: 'EmployeeToDepartment'
                    Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
                } | null>
            } | null
            Office?: { __typename?: 'Office'; id: string; name: string } | null
            buddy?: {
                __typename?: 'Employee'
                birthday?: any | null
                active?: boolean | null
                buddyID?: string | null
                id: string
                email?: string | null
                firstname?: string | null
                lastname?: string | null
                photo?: string | null
                fullName?: string | null
                Position?: { __typename?: 'Position'; name: string; id: string } | null
            } | null
        } | null>
    } | null
}

export type OnUpdateEmployeeSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnUpdateEmployeeSubscription = {
    __typename?: 'Subscription'
    onUpdateEmployee?: { __typename?: 'Employee'; id: string } | null
}

export type OnCreateEmployeeSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreateEmployeeSubscription = {
    __typename?: 'Subscription'
    onCreateEmployee?: {
        __typename?: 'Employee'
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
    } | null
}

export type OnDeleteEmployeeSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeleteEmployeeSubscription = {
    __typename?: 'Subscription'
    onDeleteEmployee?: {
        __typename?: 'Employee'
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
        EmployeeToDepartment?: {
            __typename?: 'ModelEmployeeToDepartmentConnection'
            items: Array<{
                __typename?: 'EmployeeToDepartment'
                Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
            } | null>
        } | null
        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
    } | null
}

export type GetBookingEquipmentByIdQueryVariables = Exact<{
    bookingID: Scalars['ID']['input']
}>

export type GetBookingEquipmentByIdQuery = {
    __typename?: 'Query'
    getBookingEquipment?: {
        __typename?: 'BookingEquipment'
        id: string
        owner?: string | null
        officeID?: string | null
        companyID: string
        startTime: any
        endTime: any
        employeeID?: string | null
        equipmentID?: string | null
        Equipment?: {
            __typename?: 'Equipment'
            id: string
            name?: string | null
            description?: string | null
            quantity?: number | null
            canBookedSeparately?: boolean | null
            officeID?: string | null
            companyID: string
            enabled?: boolean | null
            EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
            EquipmentImages?: {
                __typename?: 'ModelEquipmentImageConnection'
                items: Array<{
                    __typename?: 'EquipmentImage'
                    id: string
                    name?: string | null
                    location?: string | null
                } | null>
            } | null
        } | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
    } | null
}

export type ListReservableTagsByOfficeAndTypeQueryVariables = Exact<{
    filter: ModelReservableTagFilterInput
    officeID: Scalars['ID']['input']
    reservableType?: InputMaybe<ModelStringKeyConditionInput>
}>

export type ListReservableTagsByOfficeAndTypeQuery = {
    __typename?: 'Query'
    listReservableTagsByOfficeAndType?: {
        __typename?: 'ModelReservableTagConnection'
        items: Array<{
            __typename?: 'ReservableTag'
            id: string
            Tag?: {
                __typename?: 'Tag'
                name: string
                id: string
                companyID: string
                Reservables?: {
                    __typename?: 'ModelReservableTagConnection'
                    items: Array<{ __typename?: 'ReservableTag'; id: string; reservableID: string } | null>
                } | null
            } | null
            Company: { __typename?: 'Company'; id: string }
        } | null>
    } | null
}

export type OnCreateDepartmentSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreateDepartmentSubscription = {
    __typename?: 'Subscription'
    onCreateDepartment?: {
        __typename?: 'Department'
        id: string
        name: string
        companyID: string
        TeamLead?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
        DepartmentRules?: {
            __typename?: 'DepartmentRules'
            blockReservationOnNonOfficeDays?: boolean | null
            enableMinDaysInOffice?: boolean | null
            minDaysInOffice?: number | null
            officeDays?: Array<string | null> | null
            minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
        } | null
        EmployeeToDepartment?: {
            __typename?: 'ModelEmployeeToDepartmentConnection'
            items: Array<{
                __typename?: 'EmployeeToDepartment'
                id: string
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    email?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    officeID?: string | null
                    positionID?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    defaultPlan?: Array<string | null> | null
                    status?: EmployeeStatus | null
                    companyID: string
                    notificationChannels?: Array<NotificationChannel | null> | null
                    createdAt?: any | null
                    role?: Role | null
                    locale?: UserLocale | null
                    shareAttendance?: ShareAttendance | null
                    favouriteColleagueIDs?: Array<string | null> | null
                    buddyID?: string | null
                    favouriteReservableID?: string | null
                    birthday?: any | null
                    turnOnExperimentalFeatures?: boolean | null
                } | null
            } | null>
        } | null
    } | null
}

export type OnUpdateDepartmentSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnUpdateDepartmentSubscription = {
    __typename?: 'Subscription'
    onUpdateDepartment?: {
        __typename?: 'Department'
        id: string
        name: string
        companyID: string
        TeamLead?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
        DepartmentRules?: {
            __typename?: 'DepartmentRules'
            blockReservationOnNonOfficeDays?: boolean | null
            enableMinDaysInOffice?: boolean | null
            minDaysInOffice?: number | null
            officeDays?: Array<string | null> | null
            minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
        } | null
        EmployeeToDepartment?: {
            __typename?: 'ModelEmployeeToDepartmentConnection'
            items: Array<{
                __typename?: 'EmployeeToDepartment'
                id: string
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    email?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    officeID?: string | null
                    positionID?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    defaultPlan?: Array<string | null> | null
                    status?: EmployeeStatus | null
                    companyID: string
                    notificationChannels?: Array<NotificationChannel | null> | null
                    createdAt?: any | null
                    role?: Role | null
                    locale?: UserLocale | null
                    shareAttendance?: ShareAttendance | null
                    favouriteColleagueIDs?: Array<string | null> | null
                    buddyID?: string | null
                    favouriteReservableID?: string | null
                    birthday?: any | null
                    turnOnExperimentalFeatures?: boolean | null
                } | null
            } | null>
        } | null
    } | null
}

export type OnDeleteDepartmentSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeleteDepartmentSubscription = {
    __typename?: 'Subscription'
    onDeleteDepartment?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
}

export type OnCreatePositionSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreatePositionSubscription = {
    __typename?: 'Subscription'
    onCreatePosition?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
}

export type OnUpdatePositionSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnUpdatePositionSubscription = {
    __typename?: 'Subscription'
    onUpdatePosition?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
}

export type OnDeletePositionSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeletePositionSubscription = {
    __typename?: 'Subscription'
    onDeletePosition?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
}

export type OnCreateReservableSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreateReservableSubscription = {
    __typename?: 'Subscription'
    onCreateReservable?: {
        __typename?: 'Reservable'
        id: string
        x?: number | null
        y?: number | null
        availabilityType: AvailabilityType
        type: ReservableType
        employeeID?: string | null
        departmentID?: string | null
        spaceID?: string | null
        companyID: string
        name?: string | null
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        relatedReservableID?: string | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null
        Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
        RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
        ReservableToEmployees?: {
            __typename?: 'ModelReservableToEmployeeConnection'
            items: Array<{
                __typename?: 'ReservableToEmployee'
                id: string
                employeeID: string
                employee: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    departmentIDs?: Array<string | null> | null
                    shareAttendance?: ShareAttendance | null
                }
            } | null>
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                id: string
                departmentID: string
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        admin?: {
            __typename?: 'Employee'
            id: string
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
        } | null
    } | null
}

export type OnUpdateReservableSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnUpdateReservableSubscription = {
    __typename?: 'Subscription'
    onUpdateReservable?: {
        __typename?: 'Reservable'
        id: string
        x?: number | null
        y?: number | null
        availabilityType: AvailabilityType
        type: ReservableType
        employeeID?: string | null
        departmentID?: string | null
        spaceID?: string | null
        companyID: string
        name?: string | null
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        relatedReservableID?: string | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                id: string
                departmentID: string
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        ReservableToEmployees?: {
            __typename?: 'ModelReservableToEmployeeConnection'
            items: Array<{
                __typename?: 'ReservableToEmployee'
                id: string
                employeeID: string
                employee: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    departmentIDs?: Array<string | null> | null
                    shareAttendance?: ShareAttendance | null
                }
            } | null>
        } | null
        Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
        admin?: {
            __typename?: 'Employee'
            id: string
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            email?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
        } | null
        RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
    } | null
}

export type OnDeleteReservableSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeleteReservableSubscription = {
    __typename?: 'Subscription'
    onDeleteReservable?: {
        __typename?: 'Reservable'
        id: string
        x?: number | null
        y?: number | null
        availabilityType: AvailabilityType
        type: ReservableType
        employeeID?: string | null
        departmentID?: string | null
        spaceID?: string | null
        companyID: string
        name?: string | null
        maxAmountOfPeople?: number | null
        zonePoints?: Array<number | null> | null
        color?: string | null
        relatedReservableID?: string | null
        Employee?: {
            __typename?: 'Employee'
            id: string
            email?: string | null
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
            officeID?: string | null
            positionID?: string | null
            departmentIDs?: Array<string | null> | null
            departmentsString?: string | null
            defaultPlan?: Array<string | null> | null
            status?: EmployeeStatus | null
            companyID: string
            notificationChannels?: Array<NotificationChannel | null> | null
            createdAt?: any | null
            role?: Role | null
            locale?: UserLocale | null
            shareAttendance?: ShareAttendance | null
            favouriteColleagueIDs?: Array<string | null> | null
            buddyID?: string | null
            favouriteReservableID?: string | null
            birthday?: any | null
            turnOnExperimentalFeatures?: boolean | null
            Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        } | null
        Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
        RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
        ReservableToEmployees?: {
            __typename?: 'ModelReservableToEmployeeConnection'
            items: Array<{
                __typename?: 'ReservableToEmployee'
                id: string
                employeeID: string
                employee: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    departmentIDs?: Array<string | null> | null
                    shareAttendance?: ShareAttendance | null
                }
            } | null>
        } | null
        ReservableToDepartments?: {
            __typename?: 'ModelReservableToDepartmentConnection'
            items: Array<{
                __typename?: 'ReservableToDepartment'
                id: string
                departmentID: string
                Department?: { __typename?: 'Department'; id: string; name: string } | null
            } | null>
        } | null
        admin?: {
            __typename?: 'Employee'
            id: string
            firstname?: string | null
            lastname?: string | null
            fullName?: string | null
            photo?: string | null
        } | null
    } | null
}

export type OnCreateSpaceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreateSpaceSubscription = {
    __typename?: 'Subscription'
    onCreateSpace?: {
        __typename?: 'Space'
        id: string
        floorPlanFilePath?: string | null
        name?: string | null
        address?: string | null
        workingHoursFrom?: any | null
        workingHoursTo?: any | null
        officeID: string
        companyID: string
        Reservables?: {
            __typename?: 'ModelReservableConnection'
            items: Array<{
                __typename?: 'Reservable'
                id: string
                x?: number | null
                y?: number | null
                availabilityType: AvailabilityType
                type: ReservableType
                employeeID?: string | null
                departmentID?: string | null
                spaceID?: string | null
                companyID: string
                name?: string | null
                maxAmountOfPeople?: number | null
                zonePoints?: Array<number | null> | null
                color?: string | null
                relatedReservableID?: string | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    email?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    officeID?: string | null
                    positionID?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    defaultPlan?: Array<string | null> | null
                    status?: EmployeeStatus | null
                    companyID: string
                    notificationChannels?: Array<NotificationChannel | null> | null
                    createdAt?: any | null
                    role?: Role | null
                    locale?: UserLocale | null
                    shareAttendance?: ShareAttendance | null
                    favouriteColleagueIDs?: Array<string | null> | null
                    buddyID?: string | null
                    favouriteReservableID?: string | null
                    birthday?: any | null
                    turnOnExperimentalFeatures?: boolean | null
                    Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
                } | null
                Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
                RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                ReservableToEmployees?: {
                    __typename?: 'ModelReservableToEmployeeConnection'
                    items: Array<{
                        __typename?: 'ReservableToEmployee'
                        id: string
                        employeeID: string
                        employee: {
                            __typename?: 'Employee'
                            id: string
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            photo?: string | null
                            departmentIDs?: Array<string | null> | null
                            shareAttendance?: ShareAttendance | null
                        }
                    } | null>
                } | null
                ReservableToDepartments?: {
                    __typename?: 'ModelReservableToDepartmentConnection'
                    items: Array<{
                        __typename?: 'ReservableToDepartment'
                        id: string
                        departmentID: string
                        Department?: { __typename?: 'Department'; id: string; name: string } | null
                    } | null>
                } | null
                admin?: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                } | null
            } | null>
        } | null
    } | null
}

export type OnUpdateSpaceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnUpdateSpaceSubscription = {
    __typename?: 'Subscription'
    onUpdateSpace?: {
        __typename?: 'Space'
        id: string
        floorPlanFilePath?: string | null
        name?: string | null
        address?: string | null
        workingHoursFrom?: any | null
        workingHoursTo?: any | null
        officeID: string
        companyID: string
        Reservables?: {
            __typename?: 'ModelReservableConnection'
            items: Array<{
                __typename?: 'Reservable'
                id: string
                x?: number | null
                y?: number | null
                availabilityType: AvailabilityType
                type: ReservableType
                employeeID?: string | null
                departmentID?: string | null
                spaceID?: string | null
                companyID: string
                name?: string | null
                maxAmountOfPeople?: number | null
                zonePoints?: Array<number | null> | null
                color?: string | null
                relatedReservableID?: string | null
                Employee?: {
                    __typename?: 'Employee'
                    id: string
                    email?: string | null
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                    officeID?: string | null
                    positionID?: string | null
                    departmentIDs?: Array<string | null> | null
                    departmentsString?: string | null
                    defaultPlan?: Array<string | null> | null
                    status?: EmployeeStatus | null
                    companyID: string
                    notificationChannels?: Array<NotificationChannel | null> | null
                    createdAt?: any | null
                    role?: Role | null
                    locale?: UserLocale | null
                    shareAttendance?: ShareAttendance | null
                    favouriteColleagueIDs?: Array<string | null> | null
                    buddyID?: string | null
                    favouriteReservableID?: string | null
                    birthday?: any | null
                    turnOnExperimentalFeatures?: boolean | null
                    Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
                } | null
                bookingReservables?: {
                    __typename?: 'ModelBookingReservableConnection'
                    items: Array<{ __typename?: 'BookingReservable'; id: string } | null>
                } | null
                Department?: { __typename?: 'Department'; id: string; name: string; companyID: string } | null
                RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                ReservableToEmployees?: {
                    __typename?: 'ModelReservableToEmployeeConnection'
                    items: Array<{
                        __typename?: 'ReservableToEmployee'
                        id: string
                        employeeID: string
                        employee: {
                            __typename?: 'Employee'
                            id: string
                            firstname?: string | null
                            lastname?: string | null
                            fullName?: string | null
                            photo?: string | null
                            departmentIDs?: Array<string | null> | null
                            shareAttendance?: ShareAttendance | null
                        }
                    } | null>
                } | null
                ReservableToDepartments?: {
                    __typename?: 'ModelReservableToDepartmentConnection'
                    items: Array<{
                        __typename?: 'ReservableToDepartment'
                        id: string
                        departmentID: string
                        Department?: { __typename?: 'Department'; id: string; name: string } | null
                    } | null>
                } | null
                admin?: {
                    __typename?: 'Employee'
                    id: string
                    firstname?: string | null
                    lastname?: string | null
                    fullName?: string | null
                    photo?: string | null
                } | null
            } | null>
        } | null
    } | null
}

export type OnDeleteSpaceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeleteSpaceSubscription = {
    __typename?: 'Subscription'
    onDeleteSpace?: { __typename?: 'Space'; id: string; name?: string | null; companyID: string } | null
}

export type OnCreateReservableImageSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreateReservableImageSubscription = {
    __typename?: 'Subscription'
    onCreateReservableImage?: {
        __typename?: 'ReservableImage'
        id: string
        name?: string | null
        location?: string | null
        reservableID: string
        createdAt: any
        updatedAt: any
    } | null
}

export type OnCreateEmployeeWithBookingsSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreateEmployeeWithBookingsSubscription = {
    __typename?: 'Subscription'
    onCreateEmployee?: {
        __typename?: 'Employee'
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
        Bookings?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                reservableID?: string | null
                status?: BookingStatus | null
                employeeID: string
                companyID: string
                isTeamEvent?: boolean | null
                checkInStatus?: BookingCheckInStatus | null
                startBookingTime?: any | null
                endBookingTime?: any | null
                reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    x?: number | null
                    y?: number | null
                    availabilityType: AvailabilityType
                    type: ReservableType
                    employeeID?: string | null
                    departmentID?: string | null
                    spaceID?: string | null
                    companyID: string
                    name?: string | null
                    maxAmountOfPeople?: number | null
                    zonePoints?: Array<number | null> | null
                    color?: string | null
                    relatedReservableID?: string | null
                    RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                    ReservableToEmployees?: {
                        __typename?: 'ModelReservableToEmployeeConnection'
                        items: Array<{
                            __typename?: 'ReservableToEmployee'
                            id: string
                            employeeID: string
                            employee: {
                                __typename?: 'Employee'
                                id: string
                                firstname?: string | null
                                lastname?: string | null
                                fullName?: string | null
                                photo?: string | null
                                departmentIDs?: Array<string | null> | null
                                shareAttendance?: ShareAttendance | null
                            }
                        } | null>
                    } | null
                    ReservableToDepartments?: {
                        __typename?: 'ModelReservableToDepartmentConnection'
                        items: Array<{
                            __typename?: 'ReservableToDepartment'
                            id: string
                            departmentID: string
                            Department?: { __typename?: 'Department'; id: string; name: string } | null
                        } | null>
                    } | null
                    admin?: {
                        __typename?: 'Employee'
                        id: string
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type OnUpdateEmployeeWithBookingsSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnUpdateEmployeeWithBookingsSubscription = {
    __typename?: 'Subscription'
    onUpdateEmployee?: {
        __typename?: 'Employee'
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
    } | null
}

export type OnDeleteEmployeeWithBookingsSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeleteEmployeeWithBookingsSubscription = {
    __typename?: 'Subscription'
    onDeleteEmployee?: {
        __typename?: 'Employee'
        id: string
        email?: string | null
        firstname?: string | null
        lastname?: string | null
        fullName?: string | null
        photo?: string | null
        officeID?: string | null
        positionID?: string | null
        departmentIDs?: Array<string | null> | null
        departmentsString?: string | null
        defaultPlan?: Array<string | null> | null
        status?: EmployeeStatus | null
        companyID: string
        notificationChannels?: Array<NotificationChannel | null> | null
        createdAt?: any | null
        role?: Role | null
        locale?: UserLocale | null
        shareAttendance?: ShareAttendance | null
        favouriteColleagueIDs?: Array<string | null> | null
        buddyID?: string | null
        favouriteReservableID?: string | null
        birthday?: any | null
        turnOnExperimentalFeatures?: boolean | null
        Position?: { __typename?: 'Position'; id: string; name: string; companyID: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string; companyID: string } | null
        Bookings?: {
            __typename?: 'ModelBookingConnection'
            items: Array<{
                __typename?: 'Booking'
                id: string
                startTime?: any | null
                endTime?: any | null
                reservableID?: string | null
                status?: BookingStatus | null
                employeeID: string
                companyID: string
                isTeamEvent?: boolean | null
                checkInStatus?: BookingCheckInStatus | null
                startBookingTime?: any | null
                endBookingTime?: any | null
                reservable?: {
                    __typename?: 'Reservable'
                    id: string
                    x?: number | null
                    y?: number | null
                    availabilityType: AvailabilityType
                    type: ReservableType
                    employeeID?: string | null
                    departmentID?: string | null
                    spaceID?: string | null
                    companyID: string
                    name?: string | null
                    maxAmountOfPeople?: number | null
                    zonePoints?: Array<number | null> | null
                    color?: string | null
                    relatedReservableID?: string | null
                    RelatedReservable?: { __typename?: 'Reservable'; id: string; name?: string | null } | null
                    ReservableToEmployees?: {
                        __typename?: 'ModelReservableToEmployeeConnection'
                        items: Array<{
                            __typename?: 'ReservableToEmployee'
                            id: string
                            employeeID: string
                            employee: {
                                __typename?: 'Employee'
                                id: string
                                firstname?: string | null
                                lastname?: string | null
                                fullName?: string | null
                                photo?: string | null
                                departmentIDs?: Array<string | null> | null
                                shareAttendance?: ShareAttendance | null
                            }
                        } | null>
                    } | null
                    ReservableToDepartments?: {
                        __typename?: 'ModelReservableToDepartmentConnection'
                        items: Array<{
                            __typename?: 'ReservableToDepartment'
                            id: string
                            departmentID: string
                            Department?: { __typename?: 'Department'; id: string; name: string } | null
                        } | null>
                    } | null
                    admin?: {
                        __typename?: 'Employee'
                        id: string
                        firstname?: string | null
                        lastname?: string | null
                        fullName?: string | null
                        photo?: string | null
                    } | null
                } | null
            } | null>
        } | null
    } | null
}

export type OnUpdatedBambooHrIntegrationSubscriptionVariables = Exact<{
    companyId: Scalars['String']['input']
}>

export type OnUpdatedBambooHrIntegrationSubscription = {
    __typename?: 'Subscription'
    onUpdatedBambooHRIntegration?: {
        __typename?: 'BambooHRIntegration'
        companyId: string
        hasInvitationEnabled: boolean
        syncStatus: SyncStatus
        lastSyncAt?: any | null
        companyDomain: string
    } | null
}

export type OnLogPersonioIntegrationExecutionResultSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnLogPersonioIntegrationExecutionResultSubscription = {
    __typename?: 'Subscription'
    onLogPersonioIntegrationExecutionResult?: {
        __typename?: 'PersonioIntegration'
        companyID: string
        clientID: string
        clientSecret: string
        status?: PersonioIntegrationStatus | null
        error?: string | null
        nextSyncAt?: any | null
        lastSyncedAt?: any | null
        hasInvitationEnabled: boolean
        hasSyncBookingsToPersonioEnabled: boolean
    } | null
}

export type OnUpdateBillingSubscriptionSubscriptionVariables = Exact<{
    companyId: Scalars['String']['input']
}>

export type OnUpdateBillingSubscriptionSubscription = {
    __typename?: 'Subscription'
    onUpdateBillingSubscription?: {
        __typename?: 'SubscribedBillingOutput'
        quantity: number
        updateUrl: string
        cancelUrl: string
        subscribedAt: any
        subscriptionId: string
        companyId: string
        status: BillingSubscriptionStatus
        subscriptionPlanId: string
        expiresAt: any
        discount?: { __typename?: 'Discount'; type: DiscountType; value: number } | null
    } | null
}

export type OnCompanyTariffUpdateSubscriptionVariables = Exact<{
    companyId: Scalars['String']['input']
}>

export type OnCompanyTariffUpdateSubscription = {
    __typename?: 'Subscription'
    onCompanyTariffUpdate?: { __typename?: 'CompanyTariffUpdated'; companyId: string; tariff: Tariff } | null
}

export type OnUpdateRulesSubscriptionVariables = Exact<{
    companyId: Scalars['String']['input']
}>

export type OnUpdateRulesSubscription = {
    __typename?: 'Subscription'
    onUpdateRules?: {
        __typename?: 'Rules'
        id: string
        enableMinDaysInOffice?: boolean | null
        minDaysInOffice?: number | null
        minDaysInOfficeTimeUnit?: MinDaysInOfficeTimeUnit | null
        maxDaysInOffice?: number | null
        homeIsDefault: boolean
        turnOnCheckIn?: boolean | null
        autoAssignWaitingList: boolean
        horizonOfPlanningTimeUnit?: HorizonOfPlanningTimeUnit | null
        horizonOfPlanningTimeAmount?: number | null
        denyRecurringBookingOverThePlanningHorizon?: boolean | null
        startTimeOfNotifications?: number | null
        checkInWaitingTime?: number | null
        checkInCancellingTime?: number | null
        turnOnAttendanceNotifications?: boolean | null
        dayOfAttendanceNotifications?: number | null
        hourOfAttendanceNotifications?: number | null
        updateEmployeeDataFromSource?: boolean | null
        turnOffCheckInForPersonalSeats?: boolean | null
        isPersonalSeatAvailableToday?: boolean | null
        isTeamSeatAvailableToday?: boolean | null
        turnOnMonthlyReport?: boolean | null
        emailForMonthlyReport?: string | null
        workingDays?: Array<string | null> | null
        blockReservationOnNonWorkingDays?: boolean | null
        disableProfileEditing?: boolean | null
        defaultShareAttendance?: ShareAttendance | null
        turnOnDeletingData?: boolean | null
        deleteDataAfter?: number | null
    } | null
}

export type OnUpdateUserSubscriptionVariables = Exact<{
    companyId: Scalars['String']['input']
    id: Scalars['String']['input']
}>

export type OnUpdateUserSubscription = {
    __typename?: 'Subscription'
    onUpdateEmployeeById?: {
        __typename?: 'Employee'
        id: string
        firstname?: string | null
        lastname?: string | null
        cognitoUserId?: string | null
        email?: string | null
        photo?: string | null
        role?: Role | null
        locale?: UserLocale | null
        favouriteOfficeID?: string | null
        departmentIDs?: Array<string | null> | null
        notificationChannels?: Array<NotificationChannel | null> | null
        shareAttendance?: ShareAttendance | null
        demoAccountID?: string | null
        favouriteColleagueIDs?: Array<string | null> | null
        favouriteReservableID?: string | null
        turnOnExperimentalFeatures?: boolean | null
        Position?: { __typename?: 'Position'; id: string; name: string } | null
        Office?: { __typename?: 'Office'; id: string; name: string } | null
    } | null
}

export type OnCreateRoomEventSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}>

export type OnCreateRoomEventSubscription = {
    __typename?: 'Subscription'
    onCreateCalendarEventByReservable?: {
        __typename?: 'CalendarEvents'
        id: string
        startTime: any
        endTime: any
        companyID: string
        isOnlineMeeting?: boolean | null
        onlineMeetingUrl?: string | null
    } | null
}

export type OnUpdateRoomEventSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}>

export type OnUpdateRoomEventSubscription = {
    __typename?: 'Subscription'
    onUpdateCalendarEventByReservable?: {
        __typename?: 'CalendarEvents'
        id: string
        startTime: any
        endTime: any
        companyID: string
        isOnlineMeeting?: boolean | null
        onlineMeetingUrl?: string | null
    } | null
}

export type OnDeleteRoomEventSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}>

export type OnDeleteRoomEventSubscription = {
    __typename?: 'Subscription'
    onDeleteCalendarEventByReservable?: {
        __typename?: 'CalendarEvents'
        id: string
        startTime: any
        endTime: any
        companyID: string
        isOnlineMeeting?: boolean | null
        onlineMeetingUrl?: string | null
    } | null
}

export type OnCreateResourceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreateResourceSubscription = {
    __typename?: 'Subscription'
    onCreateEquipment?: {
        __typename?: 'Equipment'
        id: string
        name?: string | null
        description?: string | null
        quantity?: number | null
        canBookedSeparately?: boolean | null
        officeID?: string | null
        companyID: string
        enabled?: boolean | null
        EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
        EquipmentImages?: {
            __typename?: 'ModelEquipmentImageConnection'
            items: Array<{
                __typename?: 'EquipmentImage'
                id: string
                name?: string | null
                location?: string | null
            } | null>
        } | null
    } | null
}

export type OnUpdateResourceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnUpdateResourceSubscription = {
    __typename?: 'Subscription'
    onUpdateEquipment?: {
        __typename?: 'Equipment'
        id: string
        name?: string | null
        description?: string | null
        quantity?: number | null
        canBookedSeparately?: boolean | null
        officeID?: string | null
        companyID: string
        enabled?: boolean | null
        EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
        EquipmentImages?: {
            __typename?: 'ModelEquipmentImageConnection'
            items: Array<{
                __typename?: 'EquipmentImage'
                id: string
                name?: string | null
                location?: string | null
            } | null>
        } | null
    } | null
}

export type OnDeleteResourceSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeleteResourceSubscription = {
    __typename?: 'Subscription'
    onDeleteEquipment?: {
        __typename?: 'Equipment'
        id: string
        name?: string | null
        description?: string | null
        quantity?: number | null
        canBookedSeparately?: boolean | null
        officeID?: string | null
        companyID: string
        enabled?: boolean | null
        EquipmentCategory?: { __typename?: 'EquipmentCategory'; id: string; name: string } | null
        EquipmentImages?: {
            __typename?: 'ModelEquipmentImageConnection'
            items: Array<{
                __typename?: 'EquipmentImage'
                id: string
                name?: string | null
                location?: string | null
            } | null>
        } | null
    } | null
}

export type OnCreateBookingByReservableSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}>

export type OnCreateBookingByReservableSubscription = {
    __typename?: 'Subscription'
    onCreateBookingByReservable?: {
        __typename?: 'Booking'
        id: string
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        status?: BookingStatus | null
        employeeID: string
        companyID: string
        isTeamEvent?: boolean | null
        checkInStatus?: BookingCheckInStatus | null
        startBookingTime?: any | null
        endBookingTime?: any | null
    } | null
}

export type OnUpdateBookingByReservableSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}>

export type OnUpdateBookingByReservableSubscription = {
    __typename?: 'Subscription'
    onUpdateBookingByReservable?: {
        __typename?: 'Booking'
        id: string
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        status?: BookingStatus | null
        employeeID: string
        companyID: string
        isTeamEvent?: boolean | null
        checkInStatus?: BookingCheckInStatus | null
        startBookingTime?: any | null
        endBookingTime?: any | null
    } | null
}

export type OnDeleteBookingByReservableSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
    reservableID: Scalars['String']['input']
}>

export type OnDeleteBookingByReservableSubscription = {
    __typename?: 'Subscription'
    onDeleteBookingByReservable?: {
        __typename?: 'Booking'
        id: string
        startTime?: any | null
        endTime?: any | null
        reservableID?: string | null
        status?: BookingStatus | null
        employeeID: string
        companyID: string
        isTeamEvent?: boolean | null
        checkInStatus?: BookingCheckInStatus | null
        startBookingTime?: any | null
        endBookingTime?: any | null
    } | null
}

export type ListRoomsByCompanyAndTypeQueryVariables = Exact<{
    companyID: Scalars['ID']['input']
}>

export type ListRoomsByCompanyAndTypeQuery = {
    __typename?: 'Query'
    listReservablesByCompanyAndType?: {
        __typename?: 'ModelReservableConnection'
        items: Array<{
            __typename?: 'Reservable'
            id: string
            Space?: { __typename?: 'Space'; id: string } | null
        } | null>
    } | null
}

export type OnCreateRoomSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnCreateRoomSubscription = {
    __typename?: 'Subscription'
    onCreateReservable?: { __typename?: 'Reservable'; type: ReservableType } | null
}

export type OnDeleteRoomSubscriptionVariables = Exact<{
    companyID: Scalars['String']['input']
}>

export type OnDeleteRoomSubscription = {
    __typename?: 'Subscription'
    onDeleteReservable?: { __typename?: 'Reservable'; type: ReservableType } | null
}
